import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate';
import APIInvoke from '../../utils/APIInvoke';
import { fromToFormat } from '../../utils/DateUtils'
import Pagination from '../../components/Pagination';
import Toolbar from '../../components/Toolbar'
import DalalaDialog from '../../components/DalalaDialog';
import ConsultaTareaPage from './ConsultaTarea';
import { useDispatch } from 'react-redux';
import { hasText, hasValue } from '../../utils/StringUtils';
import { getRamoById, getTareaPrioridadById, getTareaTipoById } from '../../utils/EnumUtils';
import { DateTime } from 'luxon';
import FilterBuilder2 from '../../components/FilterBuilder/FilterBuilder2';
import TextFilter from '../../components/FilterBuilder/TextFilter';
import SelectFilter from '../../components/FilterBuilder/SelectFilter';
import BooleanFilter from '../../components/FilterBuilder/BooleanFilter';
import SearchFilter from '../../components/FilterBuilder/SearchFilter';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import TodayIcon from '@mui/icons-material/Today';
import { Checkbox } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


const filters = [
    {
        id: "nombre",
        name: "nombre",
        placeholder: "Nombre",
        filter: "nombre[cn]",
        type: TextFilter,
        defaultValue: ""
    }, {
        id: "descripcion",
        name: "descripcion",
        placeholder: "Descripcion",
        filter: "descripcion[cn]",
        type: TextFilter,
        defaultValue: ""
    },{
        id: "vendedorId",
        name: "vendedorId",
        placeholder: "Vendedor",
        filter: "vendedor_id",
        type: SearchFilter,
        values: async (filter) => {
            if (filter.value.length < 3) return []
            const response = await (await APIInvoke.invokeGET(`/vendedores?nombre[cn]=${filter.value}&size=20&order=nombres%20asc`)).json()
            return response.body.items
        },
        getOptionLabel: (option) => {
            return hasValue(option) ? `${option.nombres} ${option.apPat} ${option.apMat}` : ""
        },
        isOptionEqualToValue: (option, value) => {
            return option.id === value.id
        },
        processInput: (value) => {
            return hasValue(value) ? value.id : ""
        },
        defaultValue: ""
    }, {
        id: "status",
        name: "status",
        placeholder: "Ver tareas cerradas",
        filter: "status",
        type: BooleanFilter,
        defaultValue: false,
        processInput: (value) => value === true ? null : "abierta",
        getOptionLabel: (option) => option === true ? "SI" : "NO"
    }
]

const TareasPage = (props) => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [openTareaDialog, setOpenTareaDialog] = useState({ show: false, id: null })
    const [state, setState] = useState({
        queryBuilder: {
            queryString: `?status=abierta`
        },
        items: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0,
    });

    useEffect(() => {
        filter(state)
    }, [])

    const filter = async (state) => {
        try {
            setLoading(true)
            const query = state.queryBuilder.queryString
            const response = await (await APIInvoke.invokeGET(`/tareas${state.queryBuilder.queryString}${hasText(query) ? "&" : "?"}page=${state.currentPage}`)).json();
            setState({
                ...state,
                ...response.body,
            });
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onFilterProcess = async (queryBuilder) => {
        const newState = {
            ...state,
            queryBuilder: queryBuilder,
            items: [],
            totalItems: 0,
            currentPage: 1,
            totalPages: 0,
            pageSize: 0,
        }
        setState(newState)
        filter(newState)
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading}>
                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={openTareaDialog.show}
                    handleClose={() => setOpenTareaDialog({ show: false, id: null })}
                >
                    <ConsultaTareaPage modal={true} tareaId={openTareaDialog.id} onCompleted={() => setOpenTareaDialog({ show: false, id: null })} />
                </DalalaDialog>
                <Toolbar title="Tareas" >
                    <div className="layout py-4">
                        <div className="text-left">
                            <Link className="no-underline" to={{ pathname: "/tareas/", state: { backUrl: "/tareas" } }} >
                                <Button className="bg-yellow"><AddBoxIcon className="text-dark-600"></AddBoxIcon><p className="text-dark-600 text-base capitalize">Añadir</p></Button>
                            </Link>
                        </div>
                    </div>
                </Toolbar>

                <div className="main">
                    <div className="layout mb-8 grid gap-8">
                        <FilterBuilder2 filters={filters} onProcess={onFilterProcess} />
                        <div className="grid gap-2 lg:gap-4 grid-cols-1 bg-dark-400 p-2 lg:p-4 rounded-md">
                            {state.items.map(tarea => {
                                const tipo = getTareaTipoById(tarea.tipo.name)
                                const prioridad = getTareaPrioridadById(tarea.prioridad.name)
                                return (
                                    <Link className="no-underline" key={tarea.id} to="#" onClick={e => setOpenTareaDialog({ show: true, id: tarea.id })}>
                                        <article key={tarea.id} className="flex border-dark-400 border rounded-md bg-dark-300 p-2 text-gray-100 text-sm">
                                            <div>
                                                <Checkbox
                                                    className="m-0"
                                                    checked={tarea.status.name === "cerrada"}
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    checkedIcon={<TaskAltIcon />}
                                                />
                                            </div>
                                            <div className="flex flex-col flex-1 gap-1">
                                                <div className="flex justify-between">
                                                    <p className="font-bold">{tarea.nombre}</p>
                                                    <p className="p-1 bg-dark-300 rounded">
                                                        <FlagCircleIcon className={`text-lg mr-1 ${prioridad.color}`} />
                                                        <span>{tarea.prioridad.displayName}</span>
                                                    </p>
                                                </div>
                                                <p className="text-xs font-thin">{tarea.descripcion}</p>
                                                <div className="flex items-center gap-2">
                                                    <span className="text-xs mr-1 text-gray-800">{<tipo.icon className="text-sm" />} {tarea.tipo.name}</span>
                                                    <span className="text-xs text-gray-800"><TodayIcon className="text-sm" /> {tarea.vencimiento ? fromToFormat(tarea.vencimiento, "yyyy-MM-dd HH:mm:dd", "dd MMMM HH:mm") : "Sin definir"}</span>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                )
                            })}

                            <div className="" >
                                <Pagination
                                    totalItems={state.totalItems}
                                    pages={state.totalPages}
                                    page={state.currentPage}
                                    onPageChange={newPage => {
                                        const newState = {
                                            ...state,
                                            currentPage: newPage,
                                        }
                                        setState(newState)
                                        filter(newState)
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate >
        )
    }

    return render()
}

export default TareasPage


