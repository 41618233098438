import React, { useEffect, useState, useCallback } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import Toolbar from '../../components/Toolbar'
import APIInvoke from '../../utils/APIInvoke';
import { DateTime } from 'luxon';
import { DataGrid, GridToolbar, GridRow } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import DateGridlocaleTextConstantsES from '../../utils/DateGridlocaleTextConstantsES'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { IconButton, TextField } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DalalaDialog from '../../components/DalalaDialog';
import ConsultaCliente from '../ConsultaCliente';
import { Autocomplete } from '@mui/material';
import { CircularProgress } from '@mui/material';
import debounce from "lodash.debounce";


const ReporteClientes = (props) => {

    const [data, setData] = useState({
        items: [],
        totalItems: 1,
        currentPage: 1,
        totalPages: 1,
        pageSize: 1
    })
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        page: 1,
        size: 10,
        vigenciaDe: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).plus({ year: -1 }),
        vigenciaA: DateTime.now().set({ hour: 0, minute: 0, second: 0 }),
        status: "",
        cliente: null
    })
    const [openSearchCliente, setOpenSearchCliente] = useState(false)
    const [openClienteDialog, setOpenClienteDialog] = useState({ show: false, id: null })

    const [clientes, setClientes] = useState([])

    const fetchContratante = async (e) => {
        try {
            setContratantesLoading(true)
            const newValue = e.target.value
            if (newValue.length <= 3 || null) {
                setClientes([])
            } else {
                const searchResponse = await (await APIInvoke.invokeGET(`/clientes?nombre=${e.target.value}&size=20&order=nombres asc`)).json()
                setClientes(searchResponse.body.items)
            }
        } catch (e) {
            console.error(e)
        } finally {
            setContratantesLoading(false)
        }

    }

    const fetchContratanteDebounce = useCallback(debounce(fetchContratante, 500), [])
    const [contratantesLoading, setContratantesLoading] = useState(false)

    const columns = [
        {
            field: 'id',
            headerName: ' ',
            type: 'string',
            width: 50,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => (<IconButton onClick={e => setOpenClienteDialog({ show: true, id: params.row.id })} color="primary" className="flex items-center" size="small">
                <VisibilityIcon className="text-yellow" size="small" />
            </IconButton>)
        }, {
            field: 'nombres',
            headerName: 'Nombre',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: (params) => `${params.row.nombres}`
        }, {
            field: 'apPat',
            headerName: 'Paterno',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: (params) => `${params.row.apPat}`
        }, {
            field: 'apMat',
            headerName: 'Materno',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: (params) => `${params.row.apMat}`
        }, {
            field: 'fecha',
            headerName: 'Fecha Creación',
            type: 'dateTime',
            width: 200,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecha.toFormat("dd/MM/yyyy HH:mm:ss")}
                    </p>
                </div>
            )
        }, {
            field: 'fecNacimiento',
            headerName: 'F. Nacimiento',
            type: 'date',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecNacimiento && params.row.fecNacimiento.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'rfc',
            headerName: 'RFC',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: (params) => `${params.row.rfc}`
        }, {
            field: 'sexo',
            headerName: 'Género',
            type: 'string',
            width: 100,
            editable: false,
            valueGetter: (params) => `${params.row.sexo}`
        }, {
            field: 'telefono',
            headerName: 'Teléfono',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: (params) => `${params.row.telefono || ""}`
        }, {
            field: 'correo',
            headerName: 'Correo',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.correo || ""}`
        }, {
            field: 'polizas',
            headerName: 'Pólizas',
            type: 'int',
            width: 100,
            editable: false,
            valueGetter: (params) => `${params.row.polizas || ""}`
        }, {
            field: 'esRobot',
            type: 'boolean',
            headerName: 'Robot',
            width: 80,
            editable: false,
            renderCell: (params) => (<Checkbox disabled checked={params.row.esRobot} />)
        }
    ]

    useEffect(async () => {
        fetchDate()
    }, [state])

    const fetchDate = async () => {
        try {
            setLoading(true)
            const cliente = state.cliente ? `?id=${state.cliente.id}` : ""
            const response = await (await APIInvoke.invokeGET(`/reportes/clientes${cliente}`)).json()
            setData({
                ...response.body,
                items: response.body.items.map(x => {
                    return {
                        ...x,
                        fecha: DateTime.fromFormat(x.fecha, "yyyy-MM-dd HH:mm:ss"),
                        fecNacimiento: x.fecNacimiento && DateTime.fromFormat(x.fecNacimiento, "yyyy-MM-dd").set({ hour: 0, minute: 0, second: 0 })
                    }
                })
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const onChangeContratante = (event, newValue) => {
        try {
            setState({
                ...state,
                cliente: newValue
            })
        } catch (error) {
            console.log("error en autocompletado: ", error)
        }
    }



    const render = () => {

        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Reporte de Clientes" />

                <DalalaDialog
                    title={"Detalle de Cliente"}
                    open={openClienteDialog.show}
                    handleClose={() => setOpenClienteDialog({ show: false, id: null })}
                >
                    <ConsultaCliente modal={true} clienteId={openClienteDialog.id} onCompleted={() => setOpenClienteDialog({ show: false, id: null })} />
                </DalalaDialog>

                <div className="main">
                    <div className="mx-4 lg:mx-8">
                        <div className="grid grid-cols-1 gap-4 ">
                            <div className=" bg-dark-400 p-4 rounded-md">
                                <div className="grid grid-cols-1 gap-4">
                                    <p className="font-bold"><FilterAltIcon /> Filtros:</p>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">

                                        <Autocomplete
                                            id="contratante"
                                            name="contratante"
                                            className="flex-1"
                                            required
                                            clearText="clearText"
                                            closeText="closeText"
                                            noOptionsText="Sin resultados"
                                            loadingText="Cargando..."
                                            openText="openText"
                                            open={openSearchCliente}
                                            value={state.cliente}
                                            onOpen={() => setOpenSearchCliente(true)}
                                            onClose={() => setOpenSearchCliente(false)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={(option) => `${option.nombres} ${option.apPat} ${option.apMat}`}
                                            options={clientes}
                                            onInput={fetchContratanteDebounce}
                                            loading={contratantesLoading}
                                            onChange={onChangeContratante}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Contratante"
                                                    required
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {contratantesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="border-b-2 border-dark-300" />

                            <div className=" bg-dark-400 p-4 rounded-md">
                                <div className="grid grid-cols-1 bg-gray-100 rounded-md" style={{ height: "700px" }} >
                                    <DataGrid
                                        className="bg-dark-300 text-gray-400 border-dark-600"
                                        rows={data.items}
                                        columns={columns}
                                        getRowId={e => e.id}
                                        pageSize={data.pageSize}
                                        rowsPerPageOptions={[10, 50, 100]}
                                        onPageSizeChange={(pageSize, details) => setData({ ...data, pageSize: pageSize })}
                                        rowCount={data.totalItems}
                                        paginationMode="client"
                                        onFilterModelChange={(model, details) => {
                                            console.log("model", model)
                                            console.log("details", details)
                                        }}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        localeText={DateGridlocaleTextConstantsES}
                                        components={{
                                            Toolbar: GridToolbar,
                                            Row: GridRow, // Default value
                                            //Footer: GridFooter
                                            //Footer: (params) => (<div className="p-2"><Pagination totalItems={data.totalItems} pages={data.totalPages} page={data.currentPage} onPageChange={newPage => setState({ ...state, page: newPage })} /></div>)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default ReporteClientes