const config = {
    api: {
        baseUrl: "http://localhost:8080", // Localhost
        //baseUrl: "https://dalala-gateway-cko2rscy.uc.gateway.dev" // qa Gateway
        //baseUrl: "https://dalala-gateway-104z13xo.uc.gateway.dev" // prod gateway
    },
    uploader: {
        baseUrl: "https://dalala-storage-gcp-api-ldawljzoxq-uc.a.run.app",
        //baseUrl: "http://localhost:8082",
        token: "xxx"
    },
    bucket: {
        name: "dalala-app-qa"
    },
    props: {
        taza: 0.16
    },
    contacts: {
        emailContacto: "info@dalala.mx",
        emailSoporte: "info@dalala.mx"
    }
}


module.exports = config