import React, { useEffect, useState, useContext } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import APIInvoke from '../../utils/APIInvoke'
import Toolbar from '../../components/Toolbar'
import { Button, TextField } from '@mui/material'
import { onlyLetter, onlyNumbers } from '../../utils/FormUtils'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import Divider from '@mui/material/Divider';
import FileUploader from '../../components/FileUploader';
import config from '../../config'
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import UserContext from '../../state/UserContext';
import Alert from '../../components/Alert'

const Empresa = (props) => {

    const [state, setState] = useState({
        razonSocial: "",
        direccion: "",
        tel1: "",
        tel2: "",
        correo1: "",
        correo2: "",
        facebook: "",
        youtube: "",
        tiktok: "",
        twitter: "",
        web: "",
        logoUrl: ""
    })
    const [message, setMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const userContext = useContext(UserContext)

    useEffect(() => {
        fetchEmpresa()
    }, [])

    const fetchEmpresa = async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/empresas/default`)).json()
            if (response.body) {
                setState(response.body)
            } else {
                setState({
                    razonSocial: "",
                    direccion: "",
                    tel1: "",
                    tel2: "",
                    correo1: "",
                    correo2: "",
                    facebook: "",
                    youtube: "",
                    tiktok: "",
                    twitter: "",
                    web: "",
                    logoUrl: ""
                })
            }
        } catch (error) {
            console.error(error.message, error)
        } finally {
            setLoading(false)
        }
    }

    const onChangeTel = (field, tel) => {
        setState({
            ...state,
            [field]: tel
        })
    }

    const onChange = (e) => {
        let value = e.target.value
        const name = e.target.name
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }

        if (name === "rfc") {
            value = value.toUpperCase()
        }

        setState({
            ...state,
            [name]: value
        })
    }

    const onUpload = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const thisFiles = e.target.files[0]

            const body = new FormData();
            body.append('bucket', config.bucket.name);
            body.append('path', `agentes/${userContext.user.agenteId}/empresa/logo/${thisFiles.name}`);
            body.append('file', thisFiles)

            const request = {
                method: "POST",
                body
            }

            const uploading = await (await fetch(`${config.uploader.baseUrl}/storage/upload`, request)).json();

            if (uploading.ok !== true) {
                console.error(uploading.message)
                return
            }

            setState({
                ...state,
                logoUrl: uploading.body.url
            })


        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
            e.target.value = '';
        }
    }

    const save = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            const request = {
                razonSocial: state.razonSocial,
                direccion: state.direccion,
                tel1: state.tel1,
                tel2: state.tel2,
                correo1: state.correo1,
                correo2: state.correo2,
                facebook: state.facebook,
                youtube: state.youtube,
                tiktok: state.tiktok,
                twitter: state.twitter,
                web: state.web,
                logoUrl: state.logoUrl
            }

            const responde = await (await APIInvoke.invokePOST(`/empresas`, request)).json()
            if (responde.ok) {
                setMessage({
                    type: "success",
                    message: responde.message
                })
            } else {
                setMessage({
                    type: "error",
                    message: responde.message
                })
            }

        } catch (error) {
            console.error(error.message, error)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {

        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Toolbar title="Datos de la empresa" backUrl={`/configuracion`} modal={props.modal} />
                <div className="main center">
                    <div className="layout-compact">
                        <form onSubmit={save}>
                            <div className="grid grid-cols-1 gap-4">
                                <div className="text-center">
                                    <div className=" block w-full">
                                        <FileUploader
                                            accept=".jpg,.jpge,.png"
                                            onUpload={onUpload}
                                            icon={
                                                state.logoUrl
                                                    ? <img src={state.logoUrl} alt={state.razonSocial} className="h-40" />
                                                    : <WallpaperIcon className="text-gray-500 inline-block text-9xl" />
                                            }
                                        />
                                    </div>
                                </div>
                                <TextField
                                    label="Razón Social"
                                    type="text"
                                    variant="outlined"
                                    required
                                    name="razonSocial"
                                    onInput={event => onlyLetter(event, 300)}
                                    value={state.razonSocial}
                                    onChange={onChange}
                                />
                                <TextField
                                    label="Dirección"
                                    type="text"
                                    variant="outlined"
                                    name="direccion"
                                    onInput={event => onlyLetter(event, 500)}
                                    value={state.direccion}
                                    onChange={onChange}
                                />


                                <Divider className="border-gray-800"><p className="text-gray-800">Datos de contacto</p></Divider>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <PhoneInput
                                        className="block w-full"
                                        country={'mx'}
                                        name="tel1"
                                        placeholder=""
                                        specialLabel="Teléfono primario *"
                                        preferredCountries={["mx", "us"]}
                                        disableDropdown={true}
                                        value={state.tel1}
                                        onChange={tel => onChangeTel("tel1", tel)}
                                        onInput={(e) => onlyNumbers(e, 12)}
                                        inputProps={{
                                            required: true,
                                            //pattern: "^\d{2}\d{10}$",
                                            title: "Teléfono con lada ejemplo: +52 123 456 7890"
                                        }}
                                    />

                                    <PhoneInput
                                        className="block w-full"
                                        //country={'mx'}
                                        placeholder=""
                                        name="tel2"
                                        specialLabel="Teléfono secundario"
                                        preferredCountries={["mx", "us"]}
                                        disableDropdown={true}
                                        value={state.tel2}
                                        onChange={tel => onChangeTel("tel2", tel)}
                                        onInput={(e) => onlyNumbers(e, 12)}
                                    />
                                </div>



                                <TextField
                                    label="Correo primario"
                                    type="text"
                                    required
                                    variant="outlined"
                                    name="correo1"
                                    onInput={event => onlyLetter(event, 100)}
                                    value={state.correo1}
                                    onChange={onChange}
                                />
                                <TextField
                                    label="Correo secundario"
                                    type="text"
                                    variant="outlined"
                                    name="correo2"
                                    onInput={event => onlyLetter(event, 100)}
                                    value={state.correo2}
                                    onChange={onChange}
                                />

                                <Divider className="border-gray-800"><p className="text-gray-800">Redes sociales</p></Divider>

                                <TextField
                                    label="Facebook"
                                    type="text"
                                    variant="outlined"
                                    name="facebook"
                                    onInput={event => onlyLetter(event, 500)}
                                    value={state.facebook}
                                    onChange={onChange}
                                />

                                <TextField
                                    label="Youtube"
                                    type="text"
                                    variant="outlined"
                                    name="youtube"
                                    onInput={event => onlyLetter(event, 500)}
                                    value={state.youtube}
                                    onChange={onChange}
                                />


                                <TextField
                                    label="Tiktok"
                                    type="text"
                                    variant="outlined"
                                    name="tiktok"
                                    onInput={event => onlyLetter(event, 500)}
                                    value={state.tiktok}
                                    onChange={onChange}
                                />

                                <TextField
                                    label="Twitter"
                                    type="text"
                                    variant="outlined"
                                    name="twitter"
                                    onInput={event => onlyLetter(event, 500)}
                                    value={state.twitter}
                                    onChange={onChange}
                                />

                                <TextField
                                    label="Web"
                                    type="text"
                                    variant="outlined"
                                    name="web"
                                    onInput={event => onlyLetter(event, 500)}
                                    value={state.web}
                                    onChange={onChange}
                                />

                                <Button type="submit" className="btn btn-yellow">GUARDAR</Button>
                                {message && <Alert {...message} />}
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default Empresa