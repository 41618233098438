import { React, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import AdminTemplate from '../../template/AdminTemplate'
import Pagination from '../../components/Pagination'
import { Link } from 'react-router-dom'
import { Accordion, AccordionSummary, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox';
import Toolbar from '../../components/Toolbar'
import ConsultaCotizacionTemplate from './ConsultaCotizacionTemplate';
import DalalaDialog from '../../components/DalalaDialog';
import { DateTime } from 'luxon'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { getCotizacionStatuById, getCotizacionStatus, getRamoById } from '../../utils/EnumUtils'
import { isEmpty } from '../../utils/StringUtils'
import GrantContent from '../../components/GrantContent'
import * as Permisos from '../../utils/Permisos'

const CotizacionesTemplate = (props) => {

    const [loading, setLoading] = useState(true)

    const [state, setState] = useState({
        cotizaciones: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0
    })

    const [filter, setFilter] = useState({
        showTerminated: false,
        noPoliza: "",
        noCotizacion: "",
        cliente: ""
    })

    const [openDetalleDialog, setOpenDetalleDialog] = useState({ show: false, id: null })

    useEffect(async () => {
        fetchCotizaciones()
    }, [state.currentPage, state.nombre, filter.showTerminated])

    const fetchCotizaciones = async () => {
        setLoading(true)
        try {
            const filterNombre = isEmpty(filter.nombre) ? "" : `&nombre[cn]=${filter.nombre}`
            const filterValues = `${filterNombre}`

            const response = await (await APIInvoke.invokeGET(`/cotizaciones/templates?page=${state.currentPage}${filterValues}`)).json()
            setState({
                ...state,
                cotizaciones: response.body.items.map(cotizacion => {
                    return {
                        ...cotizacion
                    }
                }),
                totalItems: response.body.totalItems,
                currentPage: response.body.currentPage,
                totalPages: response.body.totalPages,
                pageSize: response.body.pageSize
            })
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const renderCotizacion = (cotizacion) => {
        const status = getCotizacionStatuById(cotizacion.status)
        const ramo = getRamoById(cotizacion.ramo)
        return (
            <Link className="no-underline" key={cotizacion.id} to="#" onClick={e => setOpenDetalleDialog({ show: true, id: cotizacion.id })}>
                <article className="bg-dark-300 rounded-md p-2 lg:p-4 ">
                    <div className="grid gap-1 grid-cols-1 lg:grid-cols-2">
                        <div className="flex flex-row gap-2 justify-between">
                            <p className="text-sm text-gray-400">Nombre: {cotizacion.nombre}</p>
                            <span className="text-white text-xs inline-block md:hidden text-size-inherit">{ramo.icon}</span>
                        </div>
                        <p className="text-sm text-gray-400">Aseguradora: {cotizacion.aseguradora}</p>
                        <p className="text-sm text-gray-400">Plan: {cotizacion.plan}</p>
                        <p className="text-sm text-gray-400">Moneda: {cotizacion.moneda.abreviacion}</p>
                    </div>
                </article>
            </Link>
        )
    }

    const onChangeFilters = (e) => {
        let { value, name } = e.target
        if (e.target.type === "checkbox") value = e.target.checked
        setFilter({
            ...filter,
            [e.target.name]: value
        })
    }

    const renderFilters = () => {
        return (

            <Accordion className="bg-dark-400 rounded-md">
                <AccordionSummary expandIcon={<ExpandMoreIcon className="text-gray-400" />} aria-controls="panel1a-content" id="panel1a-header">
                    <p className="font-bold"><FilterAltIcon /> Filtros:</p>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="grid grid-cols-1  gap-4 bg-dark-400">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <TextField
                                label="Nombre"
                                type="text"
                                variant="outlined"
                                required
                                name="nombre"
                                value={state.nombre}
                                onChange={onChangeFilters}
                            />
                        </div>
                        <Button onClick={fetchCotizaciones} className="btn btn-yellow">FILTRAR</Button>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }

    const onDeleteCompleted = (result) => {
        console.log("onDeleteCompleted", result)
        if (!result) return

        setState({
            ...state,
            cotizaciones: state.cotizaciones.filter(x => x.id !== openDetalleDialog.id)
        })

        setOpenDetalleDialog({ show: false, id: null })
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} title="Dalala - Template de Cotizaciones">
                <DalalaDialog
                    title={"Detalle del la cotización"}
                    open={openDetalleDialog.show}
                    handleClose={() => setOpenDetalleDialog({ show: false, id: null })}
                >
                    <ConsultaCotizacionTemplate modal={true} cotizacionId={openDetalleDialog.id} onCompleted={onDeleteCompleted} />
                </DalalaDialog>
                <Toolbar title="Templates de Cotizaciones" backUrl="/cotizaciones" >
                    <div className="layout">
                        <div className="flex flex-row gap-4">
                            <GrantContent permisos={[Permisos.COTIZACIONES_TEMP_W]}>
                                <Link to={{ pathname: "/cotizaciones/templates/", state: { backUrl: "/cotizaciones/templates" } }} className="btn btn-yellow px-4 py-2" size="small" ><AddBoxIcon /> Añadir Template</Link>
                            </GrantContent>
                        </div>
                    </div>
                </Toolbar>
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-4">
                            {renderFilters()}
                            <div className="grid grid-cols-1 gap-2 bg-dark-400 p-2 rounded-md">
                                {state.cotizaciones && state.cotizaciones.map((cotizacion, i) => renderCotizacion(cotizacion))}
                                <div className="">
                                    <Pagination totalItems={state.totalItems} pages={state.totalPages} page={state.currentPage} onPageChange={newPage => setState({ ...state, currentPage: newPage })} />
                                </div>

                                {state.cotizaciones.length === 0 && (
                                    <p className="text-yellow text-center mb-8">Sin resultados</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}
export default CotizacionesTemplate