import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import React from 'react'

const BooleanFilter = ({
    id,
    name,
    filter,
    placeholder,
    value,
    processFilter,
    updateState,
    required
}) => {

    const render = () => {
        const checked = value === true
        return (
            <FormControl variant="outlined" size="large" fullWidth={true}>
                <FormControlLabel
                    label={placeholder}
                    control={
                        <Checkbox
                            id={id}
                            name={name}
                            checked={checked}
                            onChange={(e) => { updateState(id, e.target.checked, true) }}
                        />
                    }
                />
            </FormControl>
        )
    }

    return render()
}

BooleanFilter.processInput = (value) => value
BooleanFilter.getOptionLabel = (value) => value
BooleanFilter.getOptionId = (value) => value

export default BooleanFilter