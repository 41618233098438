
import { State } from '@egjs/react-flicking'
import { aseguradoInitalState, caracteristicaInitialState, coberturaInitialState, planPagoInitalState } from '../../redux/reducers/nueva-cotizacion-reduce'
import { NUEVA_COTIZACION_TEMPLATE_RESET, NUEVA_COTIZACION_TEMPLATE_SET } from '../../redux/redux-const'
import store from '../../redux/store'
import APIInvoke from '../../utils/APIInvoke'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from 'luxon'
import { getFormaPagoById } from '../../utils/EnumUtils'
import { SatelliteAlt } from '@mui/icons-material'
import { isEmpty } from '../../utils/StringUtils'
import { format, parse } from '../../utils/DateUtils'
import { useHistory } from 'react-router-dom'

export const getState = () => {
    return store.getState().pages.nuevaCotizacionTemplate
}

export const reset = () => {
    store.dispatch({ type: NUEVA_COTIZACION_TEMPLATE_RESET })
}

export const setLoading = (loading) => {
    store.dispatch({
        type: NUEVA_COTIZACION_TEMPLATE_SET,
        payload: {
            loading
        }
    })
}

export const setState = (payload) => {
    store.dispatch({
        type: NUEVA_COTIZACION_TEMPLATE_SET,
        payload
    })
}

export const onChange = (e) => {
    let { name, value, type } = e.target
    if (type === "checkbox") name = e.target.checked
    store.dispatch({
        type: NUEVA_COTIZACION_TEMPLATE_SET,
        payload: { [name]: value }
    })
}

export const onChangeRamo = async (e) => {
    const { name, value, type } = e.target

    const coberturas = await fetchCoberturas(value)

    store.dispatch({
        type: NUEVA_COTIZACION_TEMPLATE_SET,
        payload: {
            ramo: value,
            coberturasOptions: coberturas,
            coberturas: [],
            asegurados: []
        }
    })
}


export const onChangeAsegurado = (e, index) => {
    const state = getState()
    const { name, value } = e.target

    state.asegurados[index][name] = value
    setState({
        asegurados: [
            ...state.asegurados
        ]
    })
}

export const onChangeCobertura = (e, index) => {

    const state = getState()
    const { name, value } = e.target
    console.log(name, value)

    state.coberturas[index][name] = value
    setState({
        coberturas: [
            ...state.coberturas
        ]
    })
}

export const onChangeCoberturaDescripcion = (event, value, index) => {
    const state = getState()
    const newValue = value ? value : ""
    const finIndex = state.coberturasOptions.findIndex(x => x.descripcion === value)

    if (finIndex === -1) {
        state.coberturasOptions.unshift({ id: uuidv4(), descripcion: value })
    }

    state.coberturas[index].descripcion = newValue
    setState({
        coberturas: [
            ...state.coberturas
        ],
        coberturasOptions: [
            ...state.coberturasOptions
        ]
    })
}

export const onChangeAseguradoNacimiento = (date, index) => {
    const state = getState()

    const diff = DateTime.now().diff(DateTime.fromJSDate(date), ["years"])

    state.asegurados[index].nacimiento = date
    state.asegurados[index].edad = parseInt(diff.years)
    setState({
        asegurados: [
            ...state.asegurados
        ]
    })
}

export const onChangeFechaVencimiento = (date) => {
    if (date instanceof Date && !isNaN(date)) {
        setState({
            fecVencimiento: date
        })
    } else {
        setState({
            fecVencimiento: null
        })
    }
}

export const initPage = async (cotizacionId) => {
    try {
        setLoading(true)
        const aseguradorasResponse = await (await APIInvoke.invokeGET(`/aseguradoras`)).json()
        const monedasResponse = await (await APIInvoke.invokeGET(`/monedas`)).json()


        if (cotizacionId) {
            const cotizacionResponse = await (await APIInvoke.invokeGET(`/cotizaciones/templates/${cotizacionId}`)).json()
            setState({
                ...cotizacionResponse.body,
                moneda: cotizacionResponse.body.moneda.abreviacion,
                aseguradoras: aseguradorasResponse.body.items,
                monedas: monedasResponse.body
            })
        } else {
            setState({
                aseguradoras: aseguradorasResponse.body.items,
                monedas: monedasResponse.body
            })
        }
    } catch (error) {
        console.error("Error al inicializar la página", error)
    } finally {
        setLoading(false)
    }
}

export const onChangeAseguradora = (event, newValue) => {
    if (newValue) {
        setState({
            aseguradoraId: newValue.id,
            aseguradora: newValue
        })
    } else {
        setState({
            aseguradoraId: null,
            aseguradora: null
        })
    }
}

export const addInsured = () => {
    const state = getState()
    setState({
        asegurados: [
            ...state.asegurados,
            { ...aseguradoInitalState, parentesco: state.asegurados.length === 0 ? "titular" : "" }
        ]
    })
}

export const addCoverage = () => {
    const state = getState()
    setState({
        coberturas: [
            ...state.coberturas,
            { ...coberturaInitialState, uuid: uuidv4() }
        ]
    })
}

export const removeInsured = (index) => {
    const state = getState()
    state.asegurados.splice(index, 1)
    setState({
        asegurados: [...state.asegurados]
    })
}

export const removeCoverage = (index) => {
    const state = getState()
    state.coberturas.splice(index, 1)
    setState({
        coberturas: [...state.coberturas]
    })
}

export const fetchCoberturas = async (ramo) => {
    try {
        setLoading(true)
        const response = await (await APIInvoke.invokeGET(`/coberturas?ramo=${ramo}`)).json()
        if (response.ok) {
            return response.body.items
        } else {
            return []
        }
    } catch (error) {
        console.error("Error al cargar las coberturas", error)
    } finally {
        setLoading(false)
    }
}

export const downCobertura = (index) => {
    const state = getState()
    if (index >= (state.coberturas.length - 1)) return

    const removed = state.coberturas.splice(index, 1)[0]
    state.coberturas.splice(index + 1, 0, { ...removed })

    setState({
        coberturas: [...state.coberturas]
    })
}

export const upCobertura = (index) => {
    const state = getState()
    if (index <= 0) return

    const removed = state.coberturas.splice(index, 1)[0]
    state.coberturas.splice(index - 1, 0, { ...removed })

    setState({
        coberturas: [...state.coberturas]
    })
}

export const downAsegurados = (index) => {
    const state = getState()
    if (index >= (state.asegurados.length - 1)) return

    const removed = state.asegurados.splice(index, 1)[0]
    state.asegurados.splice(index + 1, 0, { ...removed })

    setState({
        asegurados: [...state.asegurados]
    })
}

export const upAsegurados = (index) => {
    const state = getState()
    if (index <= 0) return

    const removed = state.asegurados.splice(index, 1)[0]
    state.asegurados.splice(index - 1, 0, { ...removed })

    setState({
        asegurados: [...state.asegurados]
    })
}

export const save = async (e, history) => {
    e.preventDefault()
    const state = getState()

    try {
        setLoading(true)

        let body = {
            nombre: state.nombre,
            ramo: state.ramo,
            aseguradora: state.aseguradora.id,
            plan: state.plan,
            moneda: state.moneda,
            caracteristicas: state.caracteristicas.map(caracteristica => {
                return {
                    id: caracteristica.id,
                    descripcion: caracteristica.descripcion,
                    valor: caracteristica.valor
                }
            }),
            coberturas: state.coberturas.map(cobertura => {
                return {
                    id: cobertura.id,
                    descripcion: cobertura.descripcion,
                    valor: cobertura.valor
                }
            }),
        }

        if (state.ramo === "Auto") {
            body.deducible = state.deducible
            body.sumaAsegurada = state.sumaAsegurada
        } else if (state.ramo === "Diversos") {
            body.deducible = state.deducible
            body.sumaAsegurada = state.sumaAsegurada
        } else if (state.ramo === "Vida") {
            body.sumaAsegurada = state.sumaAsegurada
        } else if (state.ramo === "Salud") {
            body.topeCoaseguro = state.topeCoaseguro
            body.copago = state.copago
            body.coaseguro = state.coaseguro
            body.deducible = state.deducible
            body.sumaAsegurada = state.sumaAsegurada
        } else {
            new Error(`El ramo '${state.ramo}' no es válido`)
        }

        const response = state.id
            ? await (await APIInvoke.invokePUT(`/cotizaciones/templates/${state.id}`, body)).json()
            : await (await APIInvoke.invokePOST(`/cotizaciones/templates`, body)).json()

        setState({
            id: state.id || response.body?.id,
            message: {
                type: response.ok ? "success" : "error",
                message: response.message
            }
        })

        if (response.ok) {
            history.push(`/cotizacion/templates/${response.body.id}`)
        }
    } catch (error) {
        console.error("Error al guardar la cotización", error)
    } finally {
        setLoading(false)
    }

}

export const editInsured = (index) => {
    const state = getState()
    state.asegurados[index].edit = true
    setState({
        asegurados: [
            ...state.asegurados
        ]
    })
}

export const saveAsegurado = (index) => {
    const state = getState()
    state.asegurados[index].edit = false
    setState({
        asegurados: [
            ...state.asegurados
        ]
    })
}

export const saveCoverage = (index) => {
    const state = getState()
    state.coberturas[index].edit = false
    setState({
        coberturas: [
            ...state.coberturas
        ]
    })
}

export const editCoverage = (index) => {
    const state = getState()
    state.coberturas[index].edit = true
    setState({
        coberturas: [
            ...state.coberturas
        ]
    })
}

export const onChangeVehiculo = (e) => {
    const { name, value, type } = e.target
    const state = getState()

    state.vehiculo[name] = value

    setState({
        ...state,
        vehiculo: {
            ...state.vehiculo
        }
    })
}

export const onChangeCaracteristica = (e, index) => {
    const state = getState()
    const { name, value } = e.target

    state.caracteristicas[index][name] = value
    setState({
        caracteristicas: [
            ...state.caracteristicas
        ]
    })
}

export const removeCaracteristica = (index) => {
    const state = getState()
    state.caracteristicas.splice(index, 1)
    setState({
        caracteristicas: [...state.caracteristicas]
    })
}

export const saveCaracteristica = (index) => {
    const state = getState()
    state.caracteristicas[index].edit = false
    setState({
        caracteristicas: [
            ...state.caracteristicas
        ]
    })
}


export const downCaracteristica = (index) => {
    const state = getState()
    if (index >= (state.caracteristicas.length - 1)) return

    const removed = state.caracteristicas.splice(index, 1)[0]
    state.caracteristicas.splice(index + 1, 0, { ...removed })

    setState({
        caracteristicas: [...state.caracteristicas]
    })
}

export const upCaracteristica = (index) => {
    const state = getState()
    if (index <= 0) return

    const removed = state.caracteristicas.splice(index, 1)[0]
    state.caracteristicas.splice(index - 1, 0, { ...removed })

    setState({
        caracteristicas: [...state.caracteristicas]
    })
}

export const editCaracteristica = (index) => {
    const state = getState()
    state.caracteristicas[index].edit = true
    setState({
        caracteristicas: [
            ...state.caracteristicas
        ]
    })
}

export const addCaracteristica = () => {
    const state = getState()
    setState({
        caracteristicas: [
            ...state.caracteristicas,
            { ...caracteristicaInitialState, uuid: uuidv4() }
        ]
    })
}

export const onChangePlanPagoPrimas = (e, index) => {
    const state = getState()
    const { name, value, type } = e.target

    const planPago = state.planesPago[index]

    if (name === "formaPago") {
        planPago.total = 0
        planPago.primaPrimerRecibo = 0
        planPago.primaReciboSub = 0
        planPago.formaPago = value
    } else {
        planPago[name] = isEmpty(value) ? 0 : parseFloat(value.replaceAll(",", ""))
    }

    if (name === "primaPrimerRecibo" || name === "primaReciboSub") {
        const formaPago = getFormaPagoById(planPago.formaPago)
        const total = ((formaPago.parcialidades - 1) * planPago.primaReciboSub) + planPago.primaPrimerRecibo
        planPago.total = total
    }

    setState({
        planesPago: [
            ...state.planesPago
        ]
    })

}



export const prepareOpenClienteDialog = (e) => {

}

export const openNewCliente = (e) => {
    setState({
        clienteAnchor: e.currentTarget,
        openNewCliente: true
    })
}

export const closeNewCliente = (e) => {
    setState({
        clienteAnchor: null,
        openNewCliente: false
    })
}

export const toggleClienteList = (open) => {
    setState({
        openClienteList: open
    })
}


export const onClienteChange = (event, cliente) => {
    setState({
        cliente: cliente,
        clienteId: cliente
    })
}

export const fetchClientes = async (e) => {
    try {
        const value = e.target.value
        if (value.length < 3) return
        setState({ contratantesLoading: true })
        const clientesProm = await (await APIInvoke.invokeGET(`/clientes?nombre=${value}`)).json()
        const prospectosProm = await (await APIInvoke.invokeGET(`/prospectos?nombres[cn]=${value}`)).json()

        setState({
            clientesOptions: [
                ...clientesProm.body.items.map(cliente => {
                    return {
                        id: cliente.id,
                        nombre: `${cliente.nombres} ${cliente.apPat} ${cliente.apMat}`,
                        type: "clientes"
                    }
                }),
                ...prospectosProm.body.items.map(prospecto => {
                    return {
                        id: prospecto.id,
                        nombre: `${prospecto.nombres} ${prospecto.apPat} ${prospecto.apMat}`,
                        type: "prospectos"
                    }
                }),
            ]
        })


    } catch (error) {
        console.error("Error al consultar los clientes", error)
    } finally {
        setState({ contratantesLoading: false })
    }

}


export const addPlanPago = () => {
    const state = getState()
    setState({
        planesPago: [
            ...state.planesPago,
            { ...planPagoInitalState, uuid: uuidv4() }
        ]
    })
}


export const removePlanPago = (index) => {
    const state = getState()
    state.planesPago.splice(index, 1)
    setState({
        planesPago: [...state.planesPago]
    })
}

export const savePlanPago = (index) => {
    const state = getState()
    state.planesPago[index].edit = false
    setState({
        planesPago: [
            ...state.planesPago
        ]
    })
}

export const downPlanPago = (index) => {
    const state = getState()
    if (index >= (state.planesPago.length - 1)) return

    const removed = state.planesPago.splice(index, 1)[0]
    state.planesPago.splice(index + 1, 0, { ...removed })

    setState({
        planesPago: [...state.planesPago]
    })
}

export const upPlanPago = (index) => {
    const state = getState()
    if (index <= 0) return

    const removed = state.planesPago.splice(index, 1)[0]
    state.planesPago.splice(index - 1, 0, { ...removed })

    setState({
        planesPago: [...state.planesPago]
    })
}

export const editPlanPago = (index) => {
    const state = getState()
    state.planesPago[index].edit = true
    setState({
        planesPago: [
            ...state.planesPago
        ]
    })
}

export const openNewClienteDialog = () => {
    setState({
        clienteAnchor: null,
        openNewCliente: false,
        openNewClienteDialog: true
    })
}

export const closeNewClienteDialog = () => {
    setState({
        openNewClienteDialog: false
    })
}

export const openNewProspectoDialog = () => {
    setState({
        clienteAnchor: null,
        openNewCliente: false,
        openNewProspectoDialog: true
    })
}

export const closeNewProspectoDialog = () => {
    setState({
        openNewProspectoDialog: false
    })
}

export const onCompletedCliente = (cliente) => {
    console.log("cliente", cliente)
    const state = getState()
    const clienteOpt = {
        id: cliente.id,
        nombre: `${cliente.nombres} ${cliente.apPat} ${cliente.apMat}`,
        type: "clientes"
    }
    setState({
        cliente: clienteOpt,
        clienteId: clienteOpt,
        openNewClienteDialog: false,
        clientesOptions: [
            clienteOpt,
            ...state.clientesOptions
        ]
    })
}



export const onCompletedProspecto = (prospecto) => {
    console.log("prospecto", prospecto)
    const state = getState()
    const prospectoOpt = {
        id: prospecto.id,
        nombre: `${prospecto.nombres} ${prospecto.apPat} ${prospecto.apMat}`,
        type: "prospectos"
    }
    setState({
        cliente: prospectoOpt,
        clienteId: prospectoOpt,
        openNewProspectoDialog: false,
        clientesOptions: [
            prospectoOpt,
            ...state.clientesOptions
        ]
    })
}

