import React, { useEffect, useState } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import Toolbar from '../components/Toolbar'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import APIInvoke from '../utils/APIInvoke';
import NumberFormat from 'react-number-format';
import { parse } from '../utils/DateUtils'
import Alert from '../components/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Metas = (props) => {

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState([])
    const [aseguradoras, setAseguradoras] = useState([])
    const [expanded, setExpanded] = useState("panel-0")
    const [deleteIndex, setDeleteIndex] = useState(null)

    const months = [
        { key: "anual", value: "ANUAL" },
        ...[...Array(12).keys()].map(key => new Date(0, key).toLocaleString('es', { month: 'long' }))
            .map((m, index) => { return { key: `${index}`, value: m.toUpperCase() } })
    ]

    useEffect(async () => {
        console.log("efecctre")
        try {
            setLoading(true)
            const aseguradoraResponse = await (await APIInvoke.invokeGET(`/aseguradoras/registradas`)).json()
            setAseguradoras([
                { id: "-1", aseguradora: "Todas las Aseguradoras", abreviacion: "TODAS" },
                ...aseguradoraResponse.body
            ])

            const metaResponse = await (await APIInvoke.invokeGET(`/metas`)).json()
            setState(metaResponse.body.map(x => {
                const periodo = parse(x.periodo, "yyyy-MM-dd")
                return {
                    id: x.id,
                    aseguradora: x.aseguradora?.id || "-1",
                    ramo: x.modelo === 0 ? "todos" : x.modelo,
                    anio: periodo.getFullYear(),
                    meta: x.tipo === "anual" ? "anual" : `${periodo.getMonth()}`,
                    polizas: x.polizas,
                    primas: x.primas
                }
            }))

        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [])


    const onChangeAccordion = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const onChange = (e, index) => {
        let meta = state[index]
        let value = e.target.value
        const name = e.target.name

        if (name === "primas") {
            value = parseFloat(value.replaceAll(",", ""))
        }

        meta[name] = value
        setState([...state])

    }

    const addMeta = () => {
        const newSelectedMeta = state.length
        setState([
            ...state,
            {
                aseguradora: "-1",
                ramo: "todos",
                anio: new Date().getFullYear(),
                meta: "anual",
                polizas: 0,
                primas: 0.0
            }
        ])
        setExpanded(`panel-${newSelectedMeta}`)
    }

    const anio = () => {
        const anio = parseInt(new Date().getFullYear())
        const max = anio + 1
        const min = anio - 1
        let list = [];
        for (let year = min; year <= max; year++) {
            list.push(year);
        }
        return list
    }

    const onSubmit = async (e, index) => {
        e.preventDefault()

        try {
            setLoading(true)

            const meta = state[index]

            const request = {
                aseguradoraId: meta.aseguradora === "-1" ? null : meta.aseguradora,
                anio: meta.anio,
                mes: meta.meta === 'anual' ? 0 : parseInt(meta.meta) + 1,
                tipo: meta.meta === 'anual' ? 'anual' : 'mensual',
                modelo: meta.ramo === 'todos' ? 0 : parseInt(meta.ramo),
                polizas: meta.polizas,
                primas: meta.primas
            }

            const response = meta.id
                ? await (await APIInvoke.invokePUT(`/metas/${meta.id}`, request)).json()
                : await (await APIInvoke.invokePOST(`/metas`, request)).json()



            if (response.ok) {
                meta.id = response.body.id
                meta.message = {
                    message: response.message,
                    type: "success"
                }
                setState([...state])
            } else {
                meta.message = {
                    message: response.message,
                    type: "error"
                }
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const deleteMeta = async () => {
        try {
            setLoading(true)
            const meta = state[deleteIndex]
            const response = await (await APIInvoke.invokeDELETE(`/metas/${meta.id}`)).json()

            if (response.ok) {
                setState(state.filter((item, index) => index !== deleteIndex))
            } else {
                meta.message = {
                    message: response.message,
                    type: "error"
                }
                setState([...state])
            }

            setDeleteIndex(null)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Metas" backUrl="/configuracion" >
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        <div className="grid grid-cols-1 gap-0">

                            {state.map((meta, index) => (
                                <Accordion
                                    key={index}
                                    expanded={expanded === `panel-${index}`}
                                    onChange={onChangeAccordion(`panel-${index}`)}
                                    className="bg-dark-400" key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className="text-white" />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Typography >
                                            <span className=" text-white">{`${aseguradoras.find(x => x.id === meta.aseguradora)?.abreviacion || ""}`} <span className="text-gray-800  italic">{`(${months.find(x => x.key === meta.meta)?.value}, ${meta.anio})`}</span></span>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <form onSubmit={e => onSubmit(e, index)}>
                                            <div className="grid grid-cols-1 gap-4">
                                                <p className="">Configuración de Metas</p>

                                                <FormControl variant="outlined" size="medium" >
                                                    <InputLabel id="anio" required>Aseguradora</InputLabel>
                                                    <Select labelId="aseguradora" label="Aseguradora" name="aseguradora" value={meta.aseguradora} onChange={e => onChange(e, index)}>
                                                        <MenuItem disabled value="" ><em>Seleccione</em></MenuItem>
                                                        {aseguradoras.map((x, index) => <MenuItem key={index} value={x.id} >{x.aseguradora}</MenuItem>)}
                                                    </Select>
                                                </FormControl>

                                                <FormControl variant="outlined" size="medium" >
                                                    <InputLabel id="ramo" required>Ramo del Negocio</InputLabel>
                                                    <Select labelId="ramo" label="Ramo del Negocio" name="ramo" value={meta.ramo} onChange={e => onChange(e, index)}>
                                                        <MenuItem disabled ><em>Seleccione</em></MenuItem>
                                                        <MenuItem value="todos" >Cartera Total</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <div className="grid grid-cols-2 gap-4 mb-4">
                                                    <FormControl variant="outlined" size="medium" >
                                                        <InputLabel id="anio" required>Año</InputLabel>
                                                        <Select labelId="anio" label="Año" name="anio" value={meta.anio} onChange={e => onChange(e, index)}>
                                                            <MenuItem disabled ><em>Seleccione</em></MenuItem>
                                                            {anio().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="outlined" size="medium" >
                                                        <InputLabel id="meta" required>Meta</InputLabel>
                                                        <Select labelId="meta" label="Meta" name="meta" value={meta.meta} onChange={e => onChange(e, index)}>
                                                            <MenuItem disabled ><em>Seleccione</em></MenuItem>
                                                            {months.map((mes, index) => <MenuItem key={index} value={mes.key}>{mes.value}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                </div>

                                                <p className=" ">Valores de Meta</p>
                                                <div className="grid grid-cols-2 gap-4 mb-4">

                                                    <NumberFormat
                                                        name="polizas"
                                                        value={meta.polizas}
                                                        onChange={e => onChange(e, index)}
                                                        customInput={TextField}
                                                        onFocus={e => e.target.select()}
                                                        label="Número de Pólizas"
                                                        variant="outlined"
                                                        size="medium"
                                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 5 }} // the change is here
                                                        type="text"
                                                        decimalScale={0}
                                                        allowNegative={false}
                                                        isNumericString={true}
                                                        required
                                                    />

                                                    <NumberFormat
                                                        name="primas"
                                                        value={meta.primas}
                                                        onChange={e => onChange(e, index)}
                                                        customInput={TextField}
                                                        onFocus={e => e.target.select()}
                                                        label="Prima Neta"
                                                        variant="outlined"
                                                        size="medium"
                                                        inputProps={{ style: { textAlign: 'right' }, maxLength: 15 }} // the change is here
                                                        type="text"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={","}
                                                        thousandSeparator={true}
                                                        allowNegative={false}
                                                        isNumericString={false}
                                                        required
                                                    />
                                                </div>

                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                                    {meta.id ? <Button onClick={e => setDeleteIndex(index)} className="btn btn-yellow bg-red-500 text-white font-bold">Eliminar Meta</Button> : <div />}
                                                    <Button type="submit" className="btn btn-yellow font-bold">Guardar Meta</Button>
                                                    <div className="col-span-2">
                                                        <Alert className="" {...meta.message} show={meta.message} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                            <Button onClick={addMeta} type="button" className="btn btn-yellow mt-8 font-bold"><AddBoxIcon className="mr-2" /> Agregar Meta</Button>
                        </div>
                    </div>
                </div>

                {
                    deleteIndex !== null && (
                        <Dialog
                            open={deleteIndex !== null}
                            onClose={e => setDeleteIndex(null)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                            <DialogContent>
                                <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la Meta?</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button className="mr-4" onClick={e => setDeleteIndex(null)}>CANCELAR</Button>
                                <Button className="bg-red-500 text-white" onClick={e => deleteMeta(e)} autoFocus>ACEPTAR</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </AdminTemplate >
        )
    }



    return render()
}

export default Metas