import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { IconButton } from '@material-ui/core';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Toolbar = (props) => {

    const location = useLocation()

    const render = () => {
        const backUrl = location.state?.backUrl || props.backUrl
        const preserveState = location.state?.preserveState || false
        const modal = props.modal || false

        return (
            <div className="bg-dark-400" >
                <div className=" bg-dark-300 " style={{ display: modal ? "none" : "block" }}>
                    <div className="layout flex flex-row justify-between h-12">
                        <div className="flex items-center">
                            <div>
                                
                            </div>
                            {backUrl &&
                                <Link to={{ pathname: backUrl, state: { preserveState } }} className="mr-2">
                                    <IconButton color="primary" className="flex items-center" size="small">
                                        <ArrowBackIcon className="text-yellow" />
                                    </IconButton>
                                </Link>
                            }
                            <span className="text-gray-400">{props.title}</span>
                            {props.helpLink && (
                                <a className="ml-4" href={props.helpLink}  target="_blank" rel="noreferrer">
                                    <HelpOutlineIcon />
                                </a>
                            )}
                        </div>

                        <div className="flex items-center">
                            {props.component}
                        </div>
                    </div>
                </div>
                {props.children && (
                    <div className="py-2">
                        {props.children}
                    </div>
                )}
            </div>
        )
    }

    return render()
}

Toolbar.defaultProps = {
    showBackButton: false
}

export default Toolbar