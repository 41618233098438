import { React, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import AdminTemplate from '../../template/AdminTemplate'
import { Link } from 'react-router-dom'
import Toolbar from '../../components/Toolbar'
import { useDispatch } from 'react-redux'
import * as Permisos from '../../utils/Permisos'
import GrantContent from '../../components/GrantContent'
import toast from 'react-hot-toast';
import { hasValue } from '../../utils/StringUtils'
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import NumberFormat from 'react-number-format';
import { InputAdornment } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';


export default function Monedas(props) {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const [state, setState] = useState([])

    useEffect(() => {
        try {
            setLoading(true)
            init()
        } catch (error) {
            console.error("Error al cargar la página", error)
            toast.error("Error al cargar la página")
        } finally {
            setLoading(false)
        }
    }, [])

    const init = async () => {
        const rawResponse = await (await APIInvoke.invokeGET(`/monedas`)).json()
        const hasOne = rawResponse.body.reduce((accum, current) => accum || hasValue(current.agenteId), false)
        console.log("hasOne", hasOne)
        if (hasOne) {
            setState(rawResponse.body.filter(moneda => hasValue(moneda.agenteId)).map(moneda => {
                return {
                    ...moneda,
                    checked: hasValue(moneda.agenteId),
                    edit: false
                }
            }))
        } else {
            return
        }

    }

    const render = () => {
        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Monedas">
                    <div className="layout py-4">
                        <div className="text-left">
                            <Link className="no-underline" to={{ pathname: "/monedas/", state: { backUrl: "/monedas" } }} >
                                <GrantContent permisos={[Permisos.MONEDA_W]}>
                                    <Button className="bg-yellow"><AddBoxIcon className="text-dark-600"></AddBoxIcon><p className="text-dark-600 text-base capitalize">Añadir</p></Button>
                                </GrantContent>
                            </Link>
                        </div>
                    </div>
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        <div className="grid gap-2">
                            {state.map(moneda => (
                                <Link key={moneda.id} className="no-underline" to={{ pathname: `/monedas/${moneda.id}`, state: { backUrl: "/monedas" } }} >
                                    <article className="bg-dark-400 rounded-md p-4 flex justify-between">
                                        <div className="flex flex-col flex-1 gap-4 justify-center">
                                            <p className="text-gray">{moneda.abreviacion} - {moneda.moneda}</p>
                                            {(hasValue(moneda.agenteId) && moneda.edit) && (
                                                <div className="grid grid-cols-2 gap-4">
                                                    <NumberFormat
                                                        name={"tipoCambio"}
                                                        value={moneda.tipoCambio}
                                                        //onChange={onChange}
                                                        customInput={TextField}
                                                        onFocus={e => e.target.select()}
                                                        label="Tipo de cambio"
                                                        variant="outlined"
                                                        size="small"
                                                        inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                                        type="text"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={","}
                                                        allowNegative={false}
                                                        isNumericString={true}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                    />
                                                    <FormControl variant="outlined" size="small" >
                                                        <InputLabel id="formato" required>Formato</InputLabel>
                                                        <Select
                                                            labelId="formato"
                                                            label="Formato"
                                                            size="small"
                                                            name="formato"
                                                            value={moneda.formato}
                                                        //onChange={onChange}
                                                        >
                                                            <MenuItem value="###,##0.00" >###,##0.00</MenuItem>
                                                            <MenuItem value="###.###,00">###.###,00</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            )}
                                            {(hasValue(moneda.agenteId) && !moneda.edit) ? (
                                                <div className="grid grid-cols-2 gap-4">
                                                    <span className="text-sm text-gray-800">Tipo de cambio: {moneda.tipoCambio}</span>
                                                    <span className="text-sm text-gray-800">Formato: {moneda.formato}</span>
                                                </div>
                                            ) : (hasValue(moneda.agenteId) && moneda.edit) && (
                                                <div className="grid grid-cols-2 gap-4">
                                                    <Button className="btn btn-yellow secundary h-8">CANCELAR</Button>
                                                    <Button className="btn btn-yellow h-9">GUARDAR</Button>
                                                </div>
                                            )}
                                        </div>
                                    </article>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}