import { Button } from "@material-ui/core";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import React from "react";

import { format, parse } from "../utils/DateUtils";
import { numberFormat } from '../utils/NumberUtils';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Tooltip from '@mui/material/Tooltip';


const PolizaItem = ({ contratante, tipo, noPoliza, concepto, aseguradora, total, finVigenciaA, finVigenciaDe, status, moneda, esRobot }) => {

    let icons;

    switch (tipo) {
        case "Vida":
            icons = <PersonIcon />;
            break;
        case "Auto":
            icons = <DirectionsCarIcon />;
            break;
        case "Salud":
            icons = <FavoriteIcon />;
            break;
        case "Diversos":
            icons = <HomeIcon />;
            break;
        default:
            new Error("Invalid type " + tipo)
    }

    let className = '';
    if (status === "vigente") {
        className = "capitalize text-right text-blue-200"
    } else if (status === "pendiente") {
        className = "capitalize text-right text-green-200"
    } else if (status === "cancelado" || status === "terminado") {
        className = "capitalize text-right text-red"
    } else {
        className = "capitalize text-right text-red-100"
    }

    const render = () => {
        return (

            <div className="bg-dark-300 p-2 lg:p-4 grid gap-1 grid-cols-1 rounded-md text-gray-400 capitalize">
                <div className="flex flex-row justify-between text-sm capitalize">

                    <div className="flex items-center">
                        <span className="hidden lg:inline-block">Póliza: </span>
                        <p className="text-yellow lg:text-gray-400 lg:pl-1"> {noPoliza}</p>
                    </div>

                    <div className="flex flex-row items-center">
                        <span className="pr-4 hidden lg:inline-block">{aseguradora}</span>
                        <Button className="bg-dark-600 capitalize" >
                            <div className="pr-1 ml-1 flex items-center"> {tipo}
                                <span className="pl-1 flex items-center">{icons}</span>
                                {esRobot && (
                                    <Tooltip title="Cliente creado automáticamente con la tecnología de sincronización dalala" disableInteractive>
                                        <div className="ml-2 text-yellow"> <WarningRoundedIcon /></div>
                                    </Tooltip>
                                )}
                            </div>
                        </Button>
                    </div>

                </div>

                <div className="grid grid-cols-1  gap-2 text-sm capitalize pb-1">
                    <div className="flex items-center ">
                        <p>Cliente: {contratante}</p>
                    </div>
                    <div className="flex flex-row items-center ">
                        <span className="inline-block">Concepto: {concepto}</span>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-2 lg:grid-cols-3 text-sm capitalize">
                    <div className="flex items-center">
                        <span className="">Vigencia: <span> {format(parse(finVigenciaDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(finVigenciaA, "yyyy-MM-dd"), "dd MMM yyyy")}</span></span>
                    </div>
                    <div className="text-left inline-block">
                        <span className="">Total: {numberFormat(total)} {moneda}</span>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-1">
                        <div className="flex flex-row items-center lg:hidden">
                            <span className="inline-block lg:hidden">Aseguradora: {aseguradora}</span>
                        </div>
                        <div>
                            <p className="text-right">Status: <span className={className}> {status} </span></p>
                        </div>
                    </div>
                </div>

                {/* 
                <div className="flex flex-row flex-wrap justify-between text-sm capitalize">
                    <div className="flex items-center">
                        <span className="">Vigencia: </span>
                        <p className="font-semibold pl-1"> {format(parse(finVigenciaDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(finVigenciaA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                    </div>
                    <div className="flex items-center">
                        <span className="">Status: </span>
                        <p className={className}> {status} </p>
                    </div>
                    <div className="flex flex-row items-center pb-1">
                        <span className="hidden lg:inline-block">Total:</span>
                        <p className="font-semibold pl-1 hidden lg:inline-block"> {numberFormat(total)} </p>
                    </div>
                </div>
                */}
            </div>

        )

    }
    return render()
}

export default PolizaItem