export const ROLE_SYSTEM = "ROLE_SYSTEM"
export const ROLE_USER = "ROLE_USER"
export const ROLE_UNSUBSCRIBE_USER = "ROLE_UNSUBSCRIBE_USER"
export const ROLE_ADMIN = "ROLE_ADMIN"
export const ROLE_CLIENTES = "ROLE_CLIENTES"
export const ROLE_CLIENTES_R = "ROLE_CLIENTES_R"
export const ROLE_CLIENTES_W = "ROLE_CLIENTES_W"
export const ROLE_CLIENTES_D = "ROLE_CLIENTES_D"
export const ROLE_PROSPECTOS = "ROLE_PROSPECTOS"
export const ROLE_PROSPECTOS_R = "ROLE_PROSPECTOS_R"
export const ROLE_PROSPECTOS_W = "ROLE_PROSPECTOS_W"
export const ROLE_PROSPECTOS_D = "ROLE_PROSPECTOS_D"
export const ROLE_VENDEDORES = "ROLE_VENDEDORES"
export const ROLE_VENDEDORES_R = "ROLE_VENDEDORES_R"
export const ROLE_VENDEDORES_W = "ROLE_VENDEDORES_W"
export const ROLE_VENDEDORES_D = "ROLE_VENDEDORES_D"
export const ROLE_POLIZAS = "ROLE_POLIZAS"
export const ROLE_POLIZAS_R = "ROLE_POLIZAS_R"
export const ROLE_POLIZAS_W = "ROLE_POLIZAS_W"
export const ROLE_POLIZAS_D = "ROLE_POLIZAS_D"
export const ROLE_POLIZAS_RENOVACIONES = "ROLE_POLIZAS_RENOVACIONES"
export const ROLE_POLIZAS_IMPORT = "ROLE_POLIZAS_IMPORT"
export const ROLE_POLIZAS_COBRANZA = "ROLE_POLIZAS_COBRANZA"
export const ROLE_SINIESTROS = "ROLE_SINIESTROS"
export const ROLE_SINIESTROS_R = "ROLE_SINIESTROS_R"
export const ROLE_SINIESTROS_W = "ROLE_SINIESTROS_W"
export const ROLE_SINIESTROS_D = "ROLE_SINIESTROS_D"
export const ROLE_COTIZACIONES = "ROLE_COTIZACIONES"
export const ROLE_COTIZACIONES_D = "ROLE_COTIZACIONES_D"
export const ROLE_COTIZACIONES_W = "ROLE_COTIZACIONES_W"
export const ROLE_COTIZACIONES_R = "ROLE_COTIZACIONES_R"
export const ROLE_COTIZACIONES_TEMP = "ROLE_COTIZACIONES_TEMP"
export const ROLE_CALENDARIO = "ROLE_CALENDARIO"
export const ROLE_CALENDARIO_R = "ROLE_CALENDARIO_R"
export const ROLE_CALENDARIO_W = "ROLE_CALENDARIO_W"
export const ROLE_CALENDARIO_D = "ROLE_CALENDARIO_D"
export const ROLE_TAREAS = "ROLE_TAREAS"
export const ROLE_TAREAS_R = "ROLE_TAREAS_R"
export const ROLE_TAREAS_W = "ROLE_TAREAS_W"
export const ROLE_TAREAS_D = "ROLE_TAREAS_D"
export const ROLE_REPORTES = "ROLE_REPORTES"
export const ROLE_REPORTES_POLIZAS = "ROLE_REPORTES_POLIZAS"
export const ROLE_REPORTES_RECIBOS = "ROLE_REPORTES_RECIBOS"
export const ROLE_REPORTES_CLIENTES = "ROLE_REPORTES_CLIENTES"
export const ROLE_REPORTES_COMISIONES = "ROLE_REPORTES_COMISIONES"
export const ROLE_REPORTES_AUTOMATIZACIONES = "ROLE_REPORTES_AUTOMATIZACIONES"
export const ROLE_REPORTE_SEMANAL = "ROLE_REPORTE_SEMANAL"
export const ROLE_CLAVES = "ROLE_CLAVES"
export const ROLE_CLAVES_R = "ROLE_CLAVES_R"
export const ROLE_CLAVES_D = "ROLE_CLAVES_D"
export const ROLE_CLAVES_W = "ROLE_CLAVES_W"
export const ROLE_USUARIOS = "ROLE_USUARIOS"
export const ROLE_FACTURACION = "ROLE_FACTURACION"
export const ROLE_INTEGRACIONES = "ROLE_INTEGRACIONES"
export const ROLE_METAS = "ROLE_METAS"
export const ROLE_PROSPECCION = "ROLE_PROSPECCION"
export const ROLE_PROSPECCION_D = "ROLE_PROSPECCION_D"
export const ROLE_PROSPECCION_W = "ROLE_PROSPECCION_W"
export const ROLE_PROSPECCION_R = "ROLE_PROSPECCION_R"
export const ROLE_ASEGURADORAS = "ROLE_ASEGURADORAS"
export const ROLE_ASEGURADORAS_R = "ROLE_ASEGURADORAS_R"
export const ROLE_ASEGURADORAS_W = "ROLE_ASEGURADORAS_W"
export const ROLE_ASEGURADORAS_D = "ROLE_ASEGURADORAS_D"
export const ROLE_EMPRESA = "ROLE_EMPRESA"
export const ROLE_EMPRESA_R = "ROLE_EMPRESA_R"
export const ROLE_EMPRESA_W = "ROLE_EMPRESA_W"
export const ROLE_CONFIG = "ROLE_CONFIG"
export const ROLE_COMUNICADOS = "ROLE_COMUNICADOS"
export const ROLE_COMUNICADOS_D = "ROLE_COMUNICADOS_D"
export const ROLE_COMUNICADOS_W = "ROLE_COMUNICADOS_W"
export const ROLE_COMUNICADOS_R = "ROLE_COMUNICADOS_R"

