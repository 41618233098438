import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import Toolbar from '../../components/Toolbar'
import APIInvoke from '../../utils/APIInvoke';
import { DateTime } from 'luxon';
import { numberFormat } from '../../utils/NumberUtils';
import { DataGrid, GridToolbar, GridRow } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '../../components/Pagination';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import millify from "millify";
import DateGridlocaleTextConstantsES from '../../utils/DateGridlocaleTextConstantsES'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DalalaDialog from '../../components/DalalaDialog';
import ConsultaPoliza from '../ConsultaPoliza';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const ReporteRecibos = (props) => {

    const [data, setData] = useState({
        items: [],
        totalItems: 1,
        currentPage: 1,
        totalPages: 1,
        pageSize: 1
    })
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        page: 1,
        size: 10,
        vigenciaDe: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).plus({ year: -1 }),
        vigenciaA: DateTime.now().set({ hour: 0, minute: 0, second: 0 }),
        status: "",
        ramo: "",
        formaPago: "",
        moneda: ""
    })

    const [status, setStatus] = useState([])
    const [ramos, setRamos] = useState([])
    const [formaPago, setFormaPago] = useState([])
    const [monedas, setMonedas] = useState([])
    const [views, setViews] = useState({
        polizasCountXAseguradora: [], polizasSumXAseguradora: [],
        polizasCountXStatus: [], polizasSumXStatus: [],
        polizasCountXRamo: [], polizasSumXRamo: []
    })
    const [tab, setTab] = useState(0)

    const [openPolizaDialog, setOpenPolizaDialog] = useState({ show: false, id: null })
    //'#0088FE', '#00C49F', '#FFBB28', '#FF8042', 
    const COLORS = ['#FED140', '#6EC81D', '#006FD3', '#B40983', '#E73131', '#FF9420', '#FF9420', '#BEEE62', '#F4743B', '#FF69EB', '#83B5D1'];


    const columns = [
        {
            field: 'polizaId',
            headerName: ' ',
            type: 'string',
            width: 50,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => (<IconButton onClick={e => setOpenPolizaDialog({ show: true, id: params.row.polizaId, reciboId: params.row.reciboId })} color="primary" className="flex items-center" size="small">
                <VisibilityIcon className="text-yellow" size="small" />
            </IconButton>)
        }, {
            field: 'noPoliza',
            headerName: 'No Póliza',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: (params) => `${params.row.noPoliza.toUpperCase()}`
        }, {
            field: 'serie',
            headerName: 'Serie',
            type: 'int',
            width: 75,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {`${params.row.serie}/${params.row.totalSerie}`}
                    </p>
                </div>
            )
        }, {
            field: 'Contratante',
            headerName: 'Contratante',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.nombres} ${params.row.apPat} ${params.row.apMat}`
        }, {
            field: 'concepto',
            headerName: 'Concepto',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.concepto.toLowerCase()}`
        }, {
            field: 'paquete',
            headerName: 'Paquete',
            type: 'string',
            width: 200,
            editable: false,
            renderCell: (params) => (<p className="text-sm">{params.row.paquete.toLowerCase()}</p>)
        }, {
            field: 'fecha',
            headerName: 'Fecha Creación',
            type: 'dateTime',
            width: 200,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecha.toFormat("dd/MM/yyyy HH:mm:ss")}
                    </p>
                </div>
            )
        }, {
            field: 'fecVigDe',
            headerName: 'Vigencia De',
            type: 'date',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecVigDe.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'fecVigA',
            headerName: 'Vigencia A',
            type: 'date',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecVigA.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'fecVence',
            headerName: 'F. Vencimiento',
            type: 'date',
            width: 120,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecVence.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'prima',
            headerName: 'Prima Neta',
            type: 'string',
            width: 120,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="text-right">
                        {`${numberFormat(params.row.prima)} ${params.row.moneda}`}
                    </p>
                </div>
            )
        }, {
            field: 'total',
            headerName: 'Prima Total',
            type: 'string',
            width: 120,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="text-right">
                        {`${numberFormat(params.row.total)} ${params.row.moneda}`}
                    </p>
                </div>
            )
        }, {
            field: 'formaPago',
            headerName: 'Forma de Pago',
            type: 'string',
            width: 120,
            editable: false,
            valueGetter: (params) => `${params.row.formaPago.toUpperCase()}`
        }, {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            width: 120,
            editable: false,
            valueGetter: (params) => `${params.row.status.toUpperCase()}`
        }, {
            field: 'ramo',
            headerName: 'Ramo',
            type: 'string',
            width: 100,
            editable: false,
            valueGetter: (params) => `${params.row.ramo.toUpperCase()}`
        }, {
            field: 'aseguradora',
            headerName: 'Aseguradora',
            type: 'string',
            width: 120,
            editable: false,
            valueGetter: (params) => `${params.row.aseguradora}`
        }, {
            field: 'clave',
            headerName: 'Clave',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: (params) => `${params.row.clave}`
        }, {
            field: 'esRobot',
            type: 'boolean',
            headerName: 'Robot',
            width: 80,
            editable: false,
            renderCell: (params) => (<Checkbox disabled checked={params.row.esRobot} />)

        }
    ]

    useEffect(() => {
        const loadStatus = async () => {
            const response = await (await APIInvoke.invokeGET(`/catalogos/recibos/status`)).json()
            setStatus(response.body)
        }

        const loadRamos = async () => {
            const response = await (await APIInvoke.invokeGET(`/ramos`)).json()
            setRamos(response.body)
        }

        const loadFormaPago = async () => {
            const response = await (await APIInvoke.invokeGET(`/catalogos/forma-pago`)).json()
            setFormaPago(response.body)
        }
        const loadMonedas = async () => {
            const response = await (await APIInvoke.invokeGET(`/monedas`)).json()
            setMonedas(response.body)
        }
        loadStatus()
        loadRamos()
        loadFormaPago()
        loadMonedas()
    }, [])

    useEffect(async () => {
        fetchDate()
    }, [state])

    const fetchDate = async () => {
        try {
            setLoading(true)

            const vigenciaDe = state.vigenciaDe !== "" ? `fecVigDe[ge]=${state.vigenciaDe.toFormat("yyyy-MM-dd")}` : ""
            const vigenciaA = state.vigenciaA !== "" ? `&fecVigDe[le]=${state.vigenciaA.toFormat("yyyy-MM-dd")}` : ""
            const status = state.status !== "" ? `&status=${state.status}` : ""
            const ramo = state.ramo !== "" ? `&ramo=${state.ramo}` : ""
            const formaPago = state.formaPago !== "" ? `&formaPago=${state.formaPago}` : ""
            const moneda = state.moneda !== "" ? `&moneda=${state.moneda}` : ""

            const response = await (await APIInvoke.invokeGET(`/reportes/recibos?${vigenciaDe}${vigenciaA}${status}${ramo}${formaPago}${moneda}`)).json()
            setData({
                ...response.body,
                items: response.body.items.map(x => {
                    return {
                        ...x,
                        fecha: DateTime.fromFormat(x.fecha, "yyyy-MM-dd HH:mm:ss"),
                        fecVigDe: DateTime.fromFormat(x.fecVigDe, "yyyy-MM-dd").set({ hour: 0, minute: 0, second: 0 }),
                        fecVigA: DateTime.fromFormat(x.fecVigA, "yyyy-MM-dd").set({ hour: 0, minute: 0, second: 0 }),
                        fecVence: DateTime.fromFormat(x.fecVence, "yyyy-MM-dd").set({ hour: 0, minute: 0, second: 0 })
                    }
                })
            })

            const views = response.body.items.reduce((previes, current) => {
                previes.primas += current.total

                if (previes.status[current.status]) {
                    previes.status[current.status].count = previes.status[current.status].count + 1
                    previes.status[current.status].sum = previes.status[current.status].sum + current.total
                } else {
                    previes.status[current.status] = {
                        name: current.status,
                        count: 1,
                        sum: current.total
                    }
                }

                if (previes.ramos[current.ramo]) {
                    previes.ramos[current.ramo].count = previes.ramos[current.ramo].count + 1
                    previes.ramos[current.ramo].sum = previes.ramos[current.ramo].sum + current.total
                } else {
                    previes.ramos[current.ramo] = {
                        name: current.ramo,
                        count: 1,
                        sum: current.total
                    }
                }

                if (previes.aseguradoras[current.aseguradora]) {
                    previes.aseguradoras[current.aseguradora].count = previes.aseguradoras[current.aseguradora].count + 1
                    previes.aseguradoras[current.aseguradora].sum = previes.aseguradoras[current.aseguradora].sum + current.total
                } else {
                    previes.aseguradoras[current.aseguradora] = {
                        name: current.aseguradora,
                        count: 1,
                        sum: current.total
                    }
                }

                return previes
            }, { primas: 0, polizas: response.body.items.length, status: {}, ramos: {}, aseguradoras: {} })

            setViews({
                polizas: views.polizas,
                primas: views.primas,
                polizasCountXStatus: Object.keys(views.status).map(x => {
                    return {
                        name: views.status[x].name,
                        value: views.status[x].count
                    }
                }),
                polizasSumXStatus: Object.keys(views.status).map(x => {
                    return {
                        name: views.status[x].name,
                        value: views.status[x].sum
                    }
                }),
                polizasCountXRamo: Object.keys(views.ramos).map(x => {
                    return {
                        name: views.ramos[x].name,
                        value: views.ramos[x].count
                    }
                }),
                polizasSumXRamo: Object.keys(views.ramos).map(x => {
                    return {
                        name: views.ramos[x].name,
                        value: views.ramos[x].sum
                    }
                }),
                polizasCountXAseguradora: Object.keys(views.aseguradoras).map(x => {
                    return {
                        name: views.aseguradoras[x].name,
                        value: views.aseguradoras[x].count
                    }
                }),
                polizasSumXAseguradora: Object.keys(views.aseguradoras).map(x => {
                    return {
                        name: views.aseguradoras[x].name,
                        value: views.aseguradoras[x].sum
                    }
                })
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        const pieProps = {
            height: 200,
            innerRadius: 40,
            outerRadius: 50,
            fontSize: 12
        }

        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Reporte de Recibo" />

                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={openPolizaDialog.show}
                    handleClose={() => setOpenPolizaDialog({ show: false, id: null, reciboId: null })}>
                    <ConsultaPoliza
                        modal={true}
                        polizaId={openPolizaDialog.id}
                        reciboId={openPolizaDialog.reciboId}
                        onCompleted={() => setOpenPolizaDialog({ show: false, id: null, reciboId: null })}
                    />
                </DalalaDialog>

                <div className="main">
                    <div className="mx-4 lg:mx-8">
                        <div className="grid grid-cols-1 gap-4 ">
                            <div className=" bg-dark-400 p-4 rounded-md">
                                <div className="grid grid-cols-1 gap-4">
                                    <p className="font-bold"><FilterAltIcon /> Filtros:</p>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <DatePicker
                                                label="Inicio de Vigencia De"
                                                value={state.vigenciaDe}
                                                onChange={(newValue) => {
                                                    setState({ ...state, vigenciaDe: DateTime.fromJSDate(newValue) })
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>

                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <DatePicker
                                                label="Inicio de Vigencia A"
                                                minDate={state.vigenciaDe}
                                                value={state.vigenciaA}
                                                onChange={(newValue) => {
                                                    setState({ ...state, vigenciaA: DateTime.fromJSDate(newValue) })
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>

                                        <FormControl variant="outlined" size="medium" >
                                            <InputLabel id="status" >Estatus Recibo</InputLabel>
                                            <Select
                                                labelId="status"
                                                label="Estatus"
                                                size="medium"
                                                name="status"
                                                value={state.status}
                                                onChange={e => setState({ ...state, status: e.target.value })}
                                            >
                                                <MenuItem value="" ><em>Todos</em></MenuItem>
                                                {status.map(x => <MenuItem key={x.key} value={x.key} >{x.value}</MenuItem>)}
                                            </Select>
                                        </FormControl>

                                        <FormControl variant="outlined" size="medium" >
                                            <InputLabel id="ramo" >Ramo</InputLabel>
                                            <Select
                                                labelId="ramo"
                                                label="Ramo"
                                                size="medium"
                                                name="ramo"
                                                value={state.ramo}
                                                onChange={e => setState({ ...state, ramo: e.target.value })}
                                            >
                                                <MenuItem value="" ><em>Todos</em></MenuItem>
                                                {ramos.map(x => <MenuItem key={x.id} value={x.ramo} >{x.ramo}</MenuItem>)}
                                            </Select>
                                        </FormControl>

                                        <FormControl variant="outlined" size="medium" >
                                            <InputLabel id="formaPago" >Forma de Pago</InputLabel>
                                            <Select
                                                labelId="formaPago"
                                                label="Forma de Pago"
                                                size="medium"
                                                name="formaPago"
                                                value={state.formaPago}
                                                onChange={e => setState({ ...state, formaPago: e.target.value })}
                                            >
                                                <MenuItem value="" ><em>Todos</em></MenuItem>
                                                {formaPago.map(x => <MenuItem key={x.key} value={x.key} >{x.value}</MenuItem>)}
                                            </Select>
                                        </FormControl>

                                        <FormControl variant="outlined" size="medium" >
                                            <InputLabel id="formaPago" >Moneda</InputLabel>
                                            <Select
                                                labelId="moneda"
                                                label="Moneda"
                                                size="medium"
                                                name="moneda"
                                                value={state.moneda}
                                                onChange={e => setState({ ...state, moneda: e.target.value })}
                                            >
                                                <MenuItem value="" ><em>Todos</em></MenuItem>
                                                {monedas.map(x => <MenuItem key={x.id} value={x.abreviacion} >{`${x.abreviacion} - ${x.moneda}`}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>

                            <div className="border-b-2 border-dark-300" />

                            <div className="grid grid-cols-1 gap-4" >
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mb-4">
                                        <Tabs value={tab} aria-label="basic tabs example" onChange={(e, index) => setTab(index)}>
                                            <Tab className={tab === 0 ? "text-yellow" : "text-gray-400"} label="Ramos" />
                                            <Tab className={tab === 1 ? "text-yellow" : "text-gray-400"} label="Estatus" />
                                            <Tab className={tab === 2 ? "text-yellow" : "text-gray-400"} label="Aseguradoras" />
                                        </Tabs>
                                    </Box>

                                    <div style={{ display: tab === 0 ? "block" : "none" }}>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4" >
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center" >
                                                    <p className="font-bold">Conteo de pólizas por Ramo</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart  >
                                                            <Pie
                                                                data={views.polizasCountXRamo}
                                                                label={(e) => `${e.payload.name} - ${e.payload.value}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {views.polizasCountXRamo.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasCountXRamo.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${entry.value})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Total de pólizas: {views.polizas}</p>
                                            </div>
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Acumulados de primas por Ramo</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart >
                                                            <Pie
                                                                data={views.polizasSumXRamo}
                                                                label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {views.polizasSumXRamo.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasSumXRamo.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${millify(entry.value, { precision: 2 })})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Primas acumuladas {numberFormat(views.primas)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: tab === 1 ? "block" : "none" }}>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Conteo de pólizas por Estatus</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart >
                                                            <Pie
                                                                data={views.polizasCountXStatus}
                                                                label={(e) => `${e.payload.name} - ${e.payload.value}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {Object.keys(views.polizasCountXStatus).map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasCountXStatus.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${entry.value})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Total de pólizas: {views.polizas}</p>
                                            </div>
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Acumulados de primas por Estatus</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart  >
                                                            <Pie
                                                                data={views.polizasSumXStatus}
                                                                label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {Object.keys(views.polizasSumXStatus).map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasSumXStatus.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${millify(entry.value, { precision: 2 })})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Primas acumuladas {numberFormat(views.primas)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: tab === 2 ? "block" : "none" }}>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Conteo de pólizas por Aseguradora</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart  >
                                                            <Pie
                                                                data={views.polizasCountXAseguradora}
                                                                label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {Object.keys(views.polizasCountXAseguradora).map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasCountXAseguradora.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${entry.value})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Total de pólizas: {views.polizas}</p>
                                            </div>
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Acumulados de primas por Aseguradora</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart>
                                                            <Pie
                                                                data={views.polizasSumXAseguradora}
                                                                label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {Object.keys(views.polizasSumXAseguradora).map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasSumXAseguradora.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${millify(entry.value, { precision: 2 })})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Primas acumuladas {numberFormat(views.primas)}</p>
                                            </div>
                                        </div>
                                    </div>

                                </Box>
                            </div>





                            <div className=" bg-dark-400 p-4 rounded-md">
                                <div className="grid grid-cols-1 bg-gray-100 rounded-md" style={{ height: "700px" }} >
                                    <DataGrid
                                        className="bg-dark-300 text-gray-400 border-dark-600"
                                        rows={data.items}
                                        columns={columns}
                                        getRowId={e => e.reciboId}
                                        pageSize={data.pageSize}
                                        rowsPerPageOptions={[10, 50, 100]}
                                        onPageSizeChange={(pageSize, details) => setData({ ...data, pageSize: pageSize })}
                                        rowCount={data.totalItems}
                                        paginationMode="client"
                                        onFilterModelChange={(model, details) => {
                                            console.log("model", model)
                                            console.log("details", details)
                                        }}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        localeText={DateGridlocaleTextConstantsES}
                                        components={{
                                            Toolbar: GridToolbar,
                                            Row: GridRow, // Default value
                                            //Footer: GridFooter
                                            //Footer: (params) => (<div className="p-2"><Pagination totalItems={data.totalItems} pages={data.totalPages} page={data.currentPage} onPageChange={newPage => setState({ ...state, page: newPage })} /></div>)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default ReporteRecibos