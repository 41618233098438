import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";

// Reducers
import poliza from "./reducers/poliza-reducer";
import cxc from "./reducers/cxc-reducer";
import claves from "./reducers/claves-reducer";
import cobranza from "./reducers/cobranza-reducer";
import renovaciones from "./reducers/renovaciones-reducer";
import prospectoKanban from "./reducers/prospecto-kanban-reducer";
import nuevoSiniestro from "./reducers/nuevo-siniestro-reducer";
import nuevaCotizacion from "./reducers/nueva-cotizacion-reduce";
import nuevaCotizacionTemplate from "./reducers/nueva-cotizacion-template-reduce";


const middleware = [];
if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

const pages = combineReducers({
  poliza,
  cxc,
  claves,
  cobranza,
  renovaciones,
  prospectoKanban,
  nuevoSiniestro,
  nuevaCotizacion,
  nuevaCotizacionTemplate
});

const reducers = combineReducers({
  pages,
});

const store = createStore(
  reducers,
  composeWithDevTools({
    name: `Dalala.app`,
    hostname: 'localhost',
    port: 4000,
  })(applyMiddleware(...middleware))
);

export default store;
