import store from '../../redux/store'
import {
    NUEVO_SINIESTRO_RESET,
    NUEVO_SINIESTRO_SET,
} from '../../redux/redux-const'
import APIInvoke from '../../utils/APIInvoke';
import { DateTime } from 'luxon';
import { parse } from '../../utils/DateUtils'

const getState = () => {
    return store.getState().pages.nuevoSiniestro
}

export const reset = () => {
    store.dispatch({ type: NUEVO_SINIESTRO_RESET })
}

export const setLoading = (loading) => {
    store.dispatch({
        type: NUEVO_SINIESTRO_SET,
        payload: { loading: loading }
    })
}


export const onChange = (e) => {
    let { name, value, type } = e.target
    if (type === "checkbox") name = e.target.checked
    store.dispatch({
        type: NUEVO_SINIESTRO_SET,
        payload: { [name]: value }
    })
}

export const setState = (payload) => {
    store.dispatch({
        type: NUEVO_SINIESTRO_SET,
        payload
    })
}

export const fetchSiniestro = async (siniestroId) => {
    try {
        setLoading(true)
        const state = getState()

        const fetchSiniestroResponse = await (await APIInvoke.invokeGET(`/siniestros/${siniestroId}`)).json()
        const poliza = fetchSiniestroResponse.body.poliza
        const cliente = poliza.cliente

        setState({
            ...state,
            descripcion: fetchSiniestroResponse.body.descripcion,
            fechaEstimadaResolucion: parse(fetchSiniestroResponse.body.fechaEstimadaResolucion, "yyyy-MM-dd"),
            fechaSiniestro: parse(fetchSiniestroResponse.body.fechaSiniestro, "yyyy-MM-dd"),
            id: fetchSiniestroResponse.body.id,
            noSiniestro: fetchSiniestroResponse.body.noSiniestro,
            polizaId: poliza.id,
            poliza: {
                ...poliza,
                ramo: poliza.ramo.ramo,
                aseguradora: poliza.agenteClave.aseguradora.abreviacion
            },
            polizas: [{
                ...poliza,
                ramo: poliza.ramo.ramo,
                aseguradora: poliza.agenteClave.aseguradora.abreviacion
            }],
            status: fetchSiniestroResponse.body.status,
            clienteId: cliente.id,
            cliente: cliente,
            clientes: [cliente],
            asegurados: fetchSiniestroResponse.body.asegurados.map(x => {
                return {
                    selected: x.selected,
                    parentesco: x.parentesco,
                    ...x.asegurado
                }
            }) || [],
        })
    } catch (error) {
        console.error("Error al cargar el siniestro", error)
    } finally {
        setLoading(false)
    }
}

export const fetchClientes = async (e) => {
    try {
        const nomCliente = e.target.value
        if (nomCliente.length < 3) return;
        setState({ clienteAutocompleteLoading: true })
        const clientesResponse = await (await APIInvoke.invokeGET(`/clientes?page=1&nombre=${nomCliente}&order=nombres%20asc`)).json()
        setState({ clientes: clientesResponse.body.items })
    } catch (e) {
        console.error(e)
    } finally {
        setState({ clienteAutocompleteLoading: false })
    }
}

const getPolizasByCliente = async (clienteId) => {
    const state = getState()
    return await (await APIInvoke.invokeGET(`/polizas?size=50&status=Vigente&order=fechaVigenciaA%20ASC&clienteId=${clienteId}`)).json()
}

export const fetchPolizas = async () => {
    try {
        setLoading(true)
        const state = getState()
        const polizasResponse = await getPolizasByCliente(state.clienteId)
        setState({
            polizas: polizasResponse.body.items.map(pol => {
                return {
                    id: pol.polizaId,
                    noPoliza: pol.noPoliza,
                    aseguradora: pol.aseguradora,
                    ramo: pol.ramo
                }
            }),
            asegurados: []
        })
    } catch (e) {
        console.log("error al cargar las polizas", e)
    } finally {
        setLoading(false)
    }
}

export const fetchAndSetPoliza = async (polizaId) => {
    const poliza = await fetchPolizaById(polizaId)

    try {
        setLoading(true)

    } catch (error) {
        console.error("Error al cargar la póliza", error)
    } finally {
        setLoading(false)
    }
}

export const fetchPolizaById = async (polizaId) => {
    try {
        setLoading(true)
        const polizaResponse = await (await APIInvoke.invokeGET(`/polizas/${polizaId}`)).json()
        setState({
            polizaId: polizaId,
            poliza: {
                ...polizaResponse.body,
                ramo: polizaResponse.body.ramo.ramo
            },
            asegurados: polizaResponse.body.asegurados.map(asegurado => {
                return {
                    ...asegurado,
                    selected: false
                }
            })
        })
    } catch (e) {
        console.log("error al cargar el detalle de la póliza polizas", e)
    } finally {
        setLoading(false)
    }
}


export const onClienteChange = async (cliente, cascade) => {
    try {
        setLoading(true)
        const state = getState()

        const polizas = await getPolizasByCliente(cliente.id)

        setState({
            clienteId: cliente.id,
            cliente: cliente,
            polizas: polizas.body.items.map(pol => {
                return {
                    ...pol,
                    id: pol.polizaId
                }
            }),
            polizaId: cascade ? null : state.polizaId,
            poliza: cascade ? null : state.poliza,
            asegurados: cascade ? [] : state.asegurados,
        })
    } catch (error) {
        console.error("Error al cargar las polizas", error)
    } finally {
        setLoading(false)
    }
}