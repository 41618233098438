import React, { useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import AdminTemplate from '../../template/AdminTemplate'
import { Breadcrumbs, Link as BreadLink, Stack, Typography } from '@mui/material'
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import Toolbar from '../../components/Toolbar'
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router';
import ArticleIcon from '@mui/icons-material/Article';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FolderIcon from '@mui/icons-material/Folder';



const ConsultaPolizaV2 = (props) => {

    const params = useParams()
    const polizaId = props.polizaId || params.polizaId
    const [loading, setLoading] = useState(true)
    const [tab, setTab] = useState(0)
    const [poliza, setPoliza] = useState(null)


    useEffect(() => {
        fetchPolizaById(polizaId)
    }, [])

    const fetchPolizaById = async (fetchPolizaId) => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/polizas/${fetchPolizaId}`)).json();
            setPoliza(response.body);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return <AdminTemplate loading={loading} modal={props.modal} />
    }

    return (
        <AdminTemplate loading={loading} modal={props.modal} >
            <Toolbar title="Detalle de Póliza" backUrl={`/polizas`} modal={props.modal}>
                <div className="layout">
                    <div className="flex justify-end items-center">
                        <div className="flex items-center">
                            <Link className="no-underline p-1" to={`/polizas/${poliza.id}`}>
                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                    <EditIcon className="text-dark-600 ml-2"></EditIcon>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Toolbar >


            <div className="">
                <div className="m-4">
                    <div className=" rounded-md overflow-hidden">
                        <div className="flex hidden">
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" className="text-gray-300" />}>
                                <BreadLink underline="hover" key="1" href="/" className="text-gray-300 text-sm" ><HomeIcon className="text-gray-500 text-lg" /> Inicio</BreadLink>
                                <BreadLink underline="hover" key="2" href="/material-ui/getting-started/installation/" className="text-gray-500 text-sm">Pólizas</BreadLink>
                                <Typography key="3" className="text-gray-800 text-sm">Breadcrumb</Typography>
                            </Breadcrumbs>
                        </div>

                        <div className="grid bg-dark-500">

                            <Tabs
                                className="bg-dark-400 text-gray-500"
                                value={tab}
                                onChange={(e, newValue) => setTab(newValue)}
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Generales" icon={<ArticleIcon />} iconPosition="start" className="text-gray-500 text-sm" />
                                <Tab label="Recibos" icon={<MonetizationOnIcon />} iconPosition="start" className="text-gray-500 text-sm" />
                                <Tab label="Adjuntos" icon={<FolderIcon />} iconPosition="start" className="text-gray-500 text-sm" />
                                <Tab label="Bitácora" icon={<ArticleIcon />} iconPosition="start" className="text-gray-500 text-sm" />
                                <Tab label="Asegurados" icon={<ArticleIcon />} iconPosition="start" className="text-gray-500 text-sm" />
                                <Tab label="Comisiones" icon={<ArticleIcon />} iconPosition="start" className="text-gray-500 text-sm" />
                            </Tabs>

                            <div className="h-96">

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </AdminTemplate>
    )
}

export default ConsultaPolizaV2;