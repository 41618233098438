import React, { useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { useHistory, Link } from "react-router-dom";
import AdminTemplate from '../../template/AdminTemplate';
import Toolbar from '../../components/Toolbar'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ClavesNuevo = (props) => {

    const [loading, setLoading] = useState(false)
    const [claves, setClaves] = useState([])

    useEffect(async () => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/aseguradoras/claves`)).json()
            const newState = response.body.reduce((previousValue, currentValue) => {
                return [
                    ...previousValue,
                    ...currentValue.claves.map(clave => {
                        return {
                            ...clave,
                            aseguradora: currentValue.aseguradora
                        }
                    })
                ]
            }, [])

            setClaves(newState)

            console.log("newState", newState)
        } catch (error) {
            console.error("Error al cargar las claves de agente ", error.message)
        } finally {
            setLoading(false)
        }
    }

    const renderClaves = async () => {
        return (
            null
        )
    }

    const render = () => {

        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Claves de agente" backUrl="/configuracion">
                </Toolbar>
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-2 bg-dark-400 p-2 rounded-md">
                            {
                                claves.map((clave, i) => (
                                    <Link className="no-underline" key={i} to="#" >
                                        <article className="bg-dark-300 rounded-md p-2 lg:p-4 ">
                                            <div className="flex items-center">
                                                <div className="flex-1">
                                                    <p><span className="text-white">{clave.aseguradora.abreviacion}</span> <span className="text-yellow"> • </span> <span className="font-thin">{clave.clave}</span></p>
                                                </div>
                                                <div className="inline-block h-6 max-w-6">
                                                </div>
                                            </div>

                                        </article>
                                    </Link>
                                ))
                            }
                            {claves.length === 0 && (
                                <p className="text-yellow text-center mb-8">Sin resultados</p>
                            )}
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default ClavesNuevo