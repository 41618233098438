import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate';
import { useDispatch, useSelector } from 'react-redux';
import APIInvoke from '../../utils/APIInvoke';
import { DateTime } from 'luxon';
import Toolbar from '../../components/Toolbar';
import GridViewIcon from '@mui/icons-material/GridView';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ForumIcon from '@mui/icons-material/Forum';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import UndoIcon from '@mui/icons-material/Undo';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { addDays, format, parse } from '../../utils/DateUtils';
import { numberFormat, numberFormatNoDecimal } from '../../utils/NumberUtils';
import { onlySimpleCharacters } from '../../utils/FormUtils'
import { ConstructionOutlined } from '@mui/icons-material';
import { Button, ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import FlagCircleSharpIcon from '@mui/icons-material/FlagCircleSharp';
import DalalaDialog from '../../components/DalalaDialog';
import ConsultaPoliza from '../ConsultaPoliza';
import PagoRecibo from '../PagoRecibo';
import Bitacora from '../Bitacora';
import Adjuntos from '../Adjuntos';
import Correo from '../Correo';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    COBRANZA_RESET,
    COBRANZA_UPDATE_STATE,
    COBRANZA_UPDATE_DIALOG,
    COBRANZA_UPDATE_RECIBO,
    COBRANZA_UPDATE_FILTER
} from "../../redux/redux-const";
const CobranzaPage = (props) => {

    const dispatch = useDispatch()
    const state = useSelector(state => state.pages.cobranza)

    useEffect(() => {
        return () => {
            dispatch({ type: COBRANZA_RESET })
        }
    }, [])

    useEffect(() => {
        fetchRecibos()
    }, [state.filter.breakpoint])

    const fetchRecibos = async () => {
        try {
            dispatch({ type: COBRANZA_UPDATE_STATE, payload: { loading: true, recibos: [] } })
            const currentDateTime = DateTime.now()
            const pageSize = 200
            const startDate = state.filter.breakpoint === -1
                ? currentDateTime.plus({ days: -60 })
                : currentDateTime.plus({ days: state.breakpoints[state.filter.breakpoint].range[0] })
            const endDate = state.filter.breakpoint === -1
                ? currentDateTime.plus({ days: 60 })
                : currentDateTime.plus({ days: state.breakpoints[state.filter.breakpoint].range[1] })
            let rawResponse = await APIInvoke.invokeGET(`/recibos?fecVence[bw]=${startDate.toFormat("yyyy-MM-dd")},${endDate.toFormat("yyyy-MM-dd")}&status=PENDIENTE&size=${pageSize}&order=fecVence asc`)
            let response = await rawResponse.json()
            dispatch({ type: COBRANZA_UPDATE_STATE, payload: { loading: false, recibos: response.body.items } })
        } catch (e) {
            console.log(e)
        } finally {
        }
    }

    const renderRecibo = (recibo, index) => {
        const poliza = recibo.poliza
        const cliente = poliza.cliente
        const { ramo, moneda, agenteClave } = poliza
        const { aseguradora } = agenteClave

        const fecVence = DateTime.fromFormat(recibo.fecVence, "yyyy-MM-dd")
        const diff = Math.ceil(fecVence.diffNow("days").days)

        const breakpoint = recibo.status === "pagado" ? "white"
            : state.breakpoints.find(x => diff >= x.range[0] && diff <= x.range[1])
        const color = breakpoint.color

        const articleStyle = `flex flex-col lg:flex-row bg-${color}-500 rounded-md h-full`
        const divStyle = `block lg:flex flex-none items-center p-2 lg:p-4 bg-${color}-500`

        return (
            <article key={recibo.id} className={`border-2 border-${color}-500 rounded-md`}>
                <div className={articleStyle}>
                    <div className={divStyle}>
                        {recibo.status === "pagado"
                            ? (<div className="text-white">
                                <p className="inline-block lg:block text-base md:text-4xl text-center font-mono font-bold"><CheckCircleIcon fontSize="large" /></p>
                            </div>)
                            : (<div className="text-dark-600 flex justify-between lg:block">
                                <p className="inline-block lg:block text-base lg:text-xs text-center pr-2 lg:pr-0">Vence en</p>
                                <p className="inline-block lg:block text-base md:text-4xl text-center font-mono font-bold">{diff < 0 ? "-" : "+"}{`${Math.abs(diff)}`.padStart(2, '0')}</p>
                            </div>)
                        }
                    </div>
                    <div className="bg-dark-400 rounded-md p-2 lg:p-4 flex-auto">
                        <div className="grid grid-cols-1 gap-2">
                            <div className="flex gap-2 items-center justify-start truncate">
                                <div className="bg-dark-600 capitalize p-1 rounded-md" >
                                    <div className="pr-1  flex items-center text-sm">
                                        <span className="pr-2 flex items-center">{getIcon(ramo.ramo)}</span>
                                        <span className="text-sm truncate inline-block">{`${cliente.nombres} ${cliente.apPat} ${cliente.apMat} - ${aseguradora.abreviacion}`}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 mb-2">
                                <div className=" border-r  border-gray-800 lg:col-span-2 ">
                                    <p className="text-gray-800 text-sm">Póliza:</p>
                                    <p className="text-sm ">{poliza.noPoliza}</p>
                                </div>
                                <div className="text-sm lg:border-r border-gray-800">
                                    <p className="text-gray-800 ">Total:</p>
                                    <p className="text-sm">$ {numberFormatNoDecimal(poliza.total)} {moneda.abreviacion}</p>
                                </div>
                                <div className="text-sm border-r border-gray-800 lg:border-0">
                                    <p className="text-gray-800 ">Recibo:</p>
                                    <p className="text-sm">{`${recibo.serie}/${recibo.totalSerie}`}</p>
                                </div>
                                <div className="text-sm lg:border-r border-gray-800 lg:col-span-2">
                                    <p className="text-gray-800 ">Vigencia:</p>
                                    <p className="text-sm">{`${format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yy")}-${format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yy")}`}</p>
                                </div>
                                <div className="text-sm lg:border-r border-gray-800">
                                    <p className="text-gray-800 col-span-2">
                                        Comisiones:
                                        <br className="hidden lg:flex" />
                                        <span className="text-sm">$ {numberFormatNoDecimal(recibo.comision)}</span></p>
                                </div>
                                <div className="text-sm ">
                                    <p className="text-gray-800 col-span-2">
                                        Vence:
                                        <Tooltip title={"La fecha de vencimiento es la fecha de inicio de vigencia del recibo +/- los días de gracias configurados en la sección de 'Fechas de Vencimiento' de la sección de 'Configuración'"} className="ml-2">
                                            <HelpOutlineIcon fontSize="small" className="text-gray-500" />
                                        </Tooltip>
                                        <br className="hidden lg:flex" />
                                        <span className="text-sm">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yy")}</span></p>
                                </div>
                            </div>
                            <div className="">
                                <p className="text-sm mb-2">Opciones</p>
                                <div className="flex gap-2">
                                    <Tooltip title="Ver póliza">
                                        <IconButton className="btn btn-yellow h-full" onClick={e => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "poliza", payload: { show: true, id: poliza.id, reciboId: recibo.id } })}>
                                            <VisibilityIcon className="text-dark-600" />
                                        </IconButton>
                                    </Tooltip>
                                    {recibo.status === "pendiente" && (
                                        <Tooltip title="Pager recibo">
                                            <IconButton className="btn btn-yellow h-full" onClick={e => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "pago", payload: { show: true, id: recibo.id } })}>
                                                <AttachMoneyIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {recibo.status === "pagado" && (
                                        <Tooltip title="Recibo pagado">
                                            <IconButton className="btn btn-yellow h-full" disabled={true} onClick={e => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "pago", payload: { show: true, id: recibo.id } })}>
                                                <UndoIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Enviar comunicados">
                                        <IconButton className="btn btn-yellow h-full" onClick={e => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "correo", payload: { show: true, id: recibo.id, correoPara: poliza?.cliente?.correos?.map(x => x.correo).join(", ") || "" } })}>
                                            <MailIcon className="text-dark-600" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Bitácora">
                                        <IconButton className="btn btn-yellow h-full" onClick={e => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "bitacora", payload: { show: true, id: recibo.id } })}>
                                            <ForumIcon className="text-dark-600" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Adjuntos">
                                        <IconButton className="btn btn-yellow h-full" onClick={e => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "adjuntos", payload: { show: true, id: poliza.id } })}>
                                            <AttachFileIcon className="text-dark-600" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        )
    }

    const getIcon = (tipo) => {
        switch (tipo) {
            case "Vida":
                return <PersonIcon />
            case "Auto":
                return <DirectionsCarIcon />
            case "Salud":
                return <FavoriteIcon />
            case "Diversos":
                return <HomeIcon />
        }
    }

    const onCompletedPagoRecibo = (recibo) => {
        dispatch({
            type: COBRANZA_UPDATE_RECIBO,
            payload: recibo
        })
        dispatch({
            type: COBRANZA_UPDATE_DIALOG, dialog: "pago",
            payload: { show: false, id: null }
        })
    }

    const render = () => {
        const layoutStyle = state.layout === "list"
            ? "grid grid-cols-1 gap-4"
            : "grid grid-cols-1 xl:grid-cols-2 gap-4"
        return (
            <AdminTemplate loading={state.loading}>
                <div className="bg-red-500 bg-orange-500 bg-yellow bg-green-500 bg-blue-500 border-red-500 border-orange-500 border-yellow border-green-500 border-blue-500 text-red-500 text-orange-500 text-yellow text-green-500 text-blue-500" />
                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={state.dialogs.poliza.show}
                    handleClose={() => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "poliza", payload: { show: false, id: null, reciboId: null } })}
                >
                    <ConsultaPoliza modal={true} polizaId={state.dialogs.poliza.id} reciboId={state.dialogs.poliza.reciboId} onCompleted={() => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "poliza", payload: { show: false, id: null, reciboId: null } })} />
                </DalalaDialog>

                {/* Envio Correo */}
                <DalalaDialog
                    title={"Correo Electrónico"}
                    open={state.dialogs.correo.show}
                    handleClose={() => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "correo", payload: { show: false, id: null } })}>
                    <Correo
                        modal={true}
                        entidad={"recibo"}
                        entidadId={state.dialogs.correo.id}
                        correoPara={state.dialogs.correo.correoPara}
                        onCompleted={() => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "correo", payload: { show: false, id: null } })} />
                </DalalaDialog>

                <DalalaDialog
                    title={"Pagar Recibo"}
                    open={state.dialogs.pago.show}
                    handleClose={() => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "pago", payload: { show: false, id: null } })}
                >
                    <PagoRecibo
                        modal={true}
                        reciboId={state.dialogs.pago.id}
                        onCompleted={onCompletedPagoRecibo}
                    />
                </DalalaDialog>

                <DalalaDialog
                    title={"Bitácora"}
                    open={state.dialogs.bitacora.show}
                    handleClose={() => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "bitacora", payload: { show: false, id: null } })}
                >
                    <Bitacora modal={true} entidad={"recibos"} entidadId={state.dialogs.bitacora.id} onCompleted={() => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "bitacora", payload: { show: false, id: null } })} />
                </DalalaDialog>

                <DalalaDialog
                    title={"Adjuntos"}
                    open={state.dialogs.adjuntos.show}
                    handleClose={() => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "adjuntos", payload: { show: false, id: null } })}
                >
                    <Adjuntos modal={true} entidad={"polizas"} entidadId={state.dialogs.adjuntos.id} onCompleted={() => dispatch({ type: COBRANZA_UPDATE_DIALOG, dialog: "adjuntos", payload: { show: false, id: null } })} />
                </DalalaDialog>
                <Toolbar title="Cobranza" />
                <div className="main center">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-4">
                            <div className="grid grid-cols-1 gap-4">
                                <p>Mostrar:</p>
                                <div className="flex justify-between">
                                    <div className="flex-1">
                                        <ToggleButtonGroup
                                            exclusive
                                            aria-label="text alignment"
                                        //value={type}
                                        //onChange={(event, newValue) => setType(newValue)}
                                        >
                                            <ToggleButton
                                                onClick={e => dispatch({ type: COBRANZA_UPDATE_FILTER, payload: { breakpoint: -1 } })}
                                                className={state.filter.breakpoint === -1 ? `pill pill-border rounded-none rounded-l-md p-2 pl-4 mr-1 bg-dark-100` : "pill pill-border rounded-none rounded-l-md p-2 pl-4 mr-1"}
                                                value="all"
                                                aria-label="left aligned"
                                            >
                                                <div className={state.filter.breakpoint === -1 ? "text-yellow text-xs lg:text-base border-yellow-500" : "text-xs lg:text-base"}><LensBlurIcon className="text-inherit" /> Todos</div>
                                            </ToggleButton>
                                            {state.breakpoints.map((bpoin, index) => (
                                                <ToggleButton
                                                    key={index}
                                                    onClick={e => dispatch({ type: COBRANZA_UPDATE_FILTER, payload: { breakpoint: index } })}
                                                    className={state.filter.breakpoint === index ? "pill pill-border rounded-none p-2 mr-1 bg-dark-100" : "pill pill-border rounded-none p-2 mr-1"}
                                                    value="collection"
                                                    aria-label="aligned"
                                                >
                                                    <p className={state.filter.breakpoint === index ? "text-yellow-500 text-xs lg:text-base" : "text-xs lg:text-base"}><FlagCircleSharpIcon className={`text-${bpoin.color}-500`} />
                                                        {state.filter.breakpoint === index && (<span className="hidden lg:inline-block ml-2 text-xs">{`${bpoin.range[0]} a ${bpoin.range[1]} días`}</span>)}
                                                    </p>
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </div>
                                    <div className="hidden xl:inline-block">
                                        <Tooltip title="Cambiar diseño">
                                            <IconButton
                                                size="large"
                                                className="text-yellow"
                                                onClick={() => dispatch({ type: COBRANZA_UPDATE_STATE, payload: { layout: state.layout === "list" ? "grid-2" : "list" } })}>
                                                {state.layout === "list" ? <GridViewIcon /> : <TableRowsIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    {state.recibos.length === 0 ? (
                                        <p className="text-center text-lg">Sin resultados</p>
                                    ) : (<p className="text-sm">Total de registros {state.recibos.length}</p>)}
                                </div>
                                <div className={layoutStyle}>
                                    {state.recibos.map((recibo, index) => renderRecibo(recibo, index))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render();
}

export default CobranzaPage