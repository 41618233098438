import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import ForumIcon from '@material-ui/icons/Forum';
import { Link, useHistory } from 'react-router-dom';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminTemplate from '../../template/AdminTemplate';
import APIInvoke from '../../utils/APIInvoke'
import { parse, format } from "../../utils/DateUtils";
import Toolbar from '../../components/Toolbar'
import DalalaDialog from '../../components/DalalaDialog';
import Bitacora from './../Bitacora';
import Adjuntos from './../Adjuntos';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import { DialogTitle, IconButton, Tooltip } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { DateTime } from 'luxon';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HomeIcon from '@material-ui/icons/Home';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AttachmentIcon from '@mui/icons-material/Attachment';
import PersonIcon from '@mui/icons-material/Person';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SubjectIcon from '@mui/icons-material/Subject';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { getSiniestroStatusById } from '../../utils/EnumUtils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GrantContent from '../../components/GrantContent'
import * as Permisos from '../../utils/Permisos'


const ConsultaSiniestro = (props) => {

    const params = useParams()
    const history = useHistory()
    const siniestroId = props.siniestroId || params.siniestroId
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState(null)

    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })
    const [showOkDialog, setShowOkDialog] = useState({ show: false, message: "" })


    useEffect(async () => {
        try {
            setLoading(true)
            const siniestroResponse = await (await APIInvoke.invokeGET(`/siniestros/${siniestroId}`)).json();
            setState({
                ...siniestroResponse.body,
                fechaSiniestro: parse(siniestroResponse.body.fechaSiniestro, "yyyy-MM-dd"),
                fechaEstimadaResolucion: parse(siniestroResponse.body.fechaEstimadaResolucion, "yyyy-MM-dd")
            })
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }
    }, [])

    const deleteSiniestro = async () => {
        try {
            setOpenDeleteDialog({ show: false })
            setLoading(true)
            const deleteResponse = await (await APIInvoke.invokeDELETE(`/siniestros/${siniestroId}`)).json()
            if (deleteResponse.ok) {
                history.push("/siniestros")
                props.onCompleted(true)
            } else {
                setShowOkDialog({ show: true, message: deleteResponse.message })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const getRamoIcon = (ramo) => {
        switch (ramo.toLowerCase()) {
            case 'salud': return <FavoriteIcon className="text-2xl text-inherit" />
            case 'vida': return <PersonIcon className="text-2xl text-inherit" />
            case 'auto': return <DriveEtaIcon className="text-2xl text-inherit" />
            case 'diversos': return <HomeIcon className="text-2xl text-inherit" />
            default: return null
        }
    }



    const render = () => {
        //if (!state) return <AdminTemplate loading={loading} modal={props.modal} title={"Consulta de siniestros"} />

        const poliza = state?.poliza
        const ramo = state?.poliza?.ramo?.ramo
        const vehiculo = state?.poliza?.vehiculo
        const cliente = state?.poliza?.cliente
        const clave = state?.poliza?.agenteClave
        const aseguradora = state?.poliza?.agenteClave?.aseguradora
        const status = getSiniestroStatusById(state?.status)
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Dialog
                    open={openDeleteDialog.show}
                    onClose={e => setOpenDeleteDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la póliza?, si procede perderá toda la información de la póliza, botácora y adjuntos.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={deleteSiniestro} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>
                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitacoraDialog.show}
                    handleClose={() => setOpenBitacoraDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"siniestros"} entidadId={openBitacoraDialog.id} onCompleted={() => setOpenBitacoraDialog({ show: false, id: null })} />
                </DalalaDialog>
                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosDialog.show}
                    handleClose={() => setOpenAdjuntosDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"siniestros"} entidadId={openAdjuntosDialog.id} onCompleted={() => setOpenAdjuntosDialog({ show: false, id: null })} />
                </DalalaDialog>
                <Dialog
                    open={openDeleteDialog.show}
                    onClose={e => setOpenDeleteDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar el siniestro?, si procede perderá toda la información relacionada con la botácora y adjuntos.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={deleteSiniestro} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showOkDialog.show}
                    onClose={e => setShowOkDialog({ show: false, message: "" })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">{showOkDialog.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn btn-yellow p-2 text-sm" onClick={e => setShowOkDialog({ show: false, message: "" })} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>
                <Toolbar title="Detalle del Siniestro" backUrl={`/siniestros`} modal={props.modal}>
                    <div className="layout">
                        <div className="flex gap-2 justify-end">
                            <GrantContent permisos={[Permisos.ADJUNTOS_R]}>
                                <Tooltip title="Adjuntos">
                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenAdjuntosDialog({ show: true, id: state.id })}>
                                        <AttachmentIcon className="text-dark-600" />
                                    </IconButton>
                                </Tooltip>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.BITACORA_R]}>
                                <Tooltip title="Bitácora">
                                    <IconButton className="btn btn-yellow" onClick={e => setOpenBitacoraDialog({ show: true, id: state.id })}>
                                        <ForumIcon className="text-dark-600" />
                                    </IconButton>
                                </Tooltip>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.SINIESTROS_W]}>
                                <Tooltip title="Editar">
                                    <Link className="no-underline" to={{ pathname: `/siniestros/${siniestroId}`, state: { backUrl: `/siniestro/${siniestroId}` } }}>
                                        <div className="bg-yellow hover:bg-yellow-700 h-10 w-10 text-center flex items-center rounded-md">
                                            <EditIcon className="text-dark-600 ml-2"></EditIcon>
                                        </div>
                                    </Link>
                                </Tooltip>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.SINIESTROS_D]}>
                                <Tooltip title="Eliminar">
                                    <IconButton className="btn btn-yellow" onClick={e => setOpenDeleteDialog({ show: true, id: state.id })}>
                                        <DeleteIcon className="text-dark-600" />
                                    </IconButton>
                                </Tooltip>
                            </GrantContent>
                        </div>
                    </div>
                </Toolbar>
                <div className="main">
                    {state && (
                        <div className="layout-compact">
                            <div className="grid gap-8">
                                <p className={`text-right font-bold ${status.color}`}>{status.name}</p>
                                <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                    <p className="text-gray-400 text-base font-semibold"><NewReleasesIcon fontSize="large" /> DATOS GENERALES</p>
                                    <div className="grid gap-4 bg-dark-500 rounded-md p-4">
                                        <div>
                                            <p className="text-yellow">Número de siniestro</p>
                                            <p className="text-sm uppercase">{state.noSiniestro}</p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Número de póliza</p>
                                            <p className="text-sm uppercase">
                                                {getRamoIcon(poliza.ramo.ramo)} {`${poliza.noPoliza} (${aseguradora.abreviacion}) `}
                                                <Link to={{ pathname: `/poliza/${poliza.id}`, state: { backUrl: `/siniestro/${state.id}` } }}>
                                                    <OpenInNewIcon fontSize="small" />
                                                </Link>
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Cliente</p>
                                            <p className="text-sm">{`${cliente.nombres} ${cliente.apPat} ${cliente.apMat}`}</p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Fecha del siniestro</p>
                                            <p className="text-sm">{`${format(state.fechaSiniestro, "dd/MM/yyyy")}`}</p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Fecha estimada de resolución</p>
                                            <p className="text-sm">{`${format(state.fechaEstimadaResolucion, "dd/MM/yyyy")}`}</p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Estatus</p>
                                            <p className="text-sm uppercase">{state.status}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                    <p className="text-gray-400 text-base font-semibold"><VerifiedUserIcon fontSize="large" /> ENTIDAD ASEGURADA</p>
                                    <div className="grid gap-4 bg-dark-500 rounded-md p-4">
                                        {(ramo === "Vida" || ramo === "Salud") && (
                                            <FormControl component="fieldset" variant="standard">
                                                <FormGroup>
                                                    {state.asegurados.map(asegurado => (
                                                        <FormControlLabel
                                                            key={asegurado.asegurado.id}
                                                            label={<p className="text-sm capitalize">{`${asegurado.asegurado.nombres} ${asegurado.asegurado.apPat} ${asegurado.asegurado.apMat}`} ({asegurado.parentesco})</p>}
                                                            checked={asegurado.selected}
                                                            control={
                                                                <Checkbox name="terminos" color="primary" disabled />
                                                            }
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        )}
                                        {ramo === "Diversos" && (
                                            <p className="text-sm">{poliza.texto}</p>
                                        )}
                                        {(ramo === "Auto" && vehiculo) && (
                                            <>
                                                <p className="text-sm">{`${vehiculo.marca} ${vehiculo.tipo} ${vehiculo.modelo}`}</p>
                                                <p className="text-sm">{vehiculo.descripcion}</p>
                                            </>
                                        )}

                                    </div>
                                </div>

                                <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                    <p className="text-gray-400 text-base font-semibold"><SubjectIcon fontSize="large" /> DESCRIPCIÓN</p>
                                    <div className="grid gap-4 bg-dark-500 rounded-md p-4">
                                        <div>
                                            <p className="text-sm uppercase">{state.descripcion}</p>
                                        </div>
                                    </div>
                                </div>

                                <AdjuntosSection siniestroId={state.id} />
                                <BitacoraSection siniestroId={state.id} />
                            </div>
                        </div>
                    )}
                </div>
            </AdminTemplate>
        )

    }


    return render()

}

const AdjuntosSection = ({ siniestroId }) => {

    const [loading, setLoading] = useState(true)
    const [state, setState] = useState([])

    useEffect(() => {
        fetchAdjuntos()
    }, [])

    const fetchAdjuntos = async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/adjuntos/siniestros/${siniestroId}`)).json()
            setState(response.body)
        } catch (error) {
            console.error("Error al cargar los adjuntos", error)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                <p className="text-gray-400 text-base font-semibold"><AttachmentIcon fontSize="large" /> ADJUNTOS</p>
                <div className="grid gap-4 bg-dark-500 rounded-md p-4">
                    {loading && (<CircularProgress />)}
                    {state.length === 0 && (<p className="text-sm"><em>No hay adjuntos</em></p>)}
                    {state.map(adjunto => (
                        <a key={adjunto.id} href={adjunto.path} download className="text-sm uppercase no-underline text-gray-500"><FileDownloadIcon className="text-gray-500 mr-2" /> {adjunto.archivo}</a>
                    ))}
                </div>
            </div>
        )
    }

    return render()
}

const BitacoraSection = ({ siniestroId }) => {

    const [loading, setLoading] = useState(true)
    const [state, setState] = useState([])

    useEffect(() => {
        fetchBitacora()
    }, [])

    const fetchBitacora = async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/crm?siniestroId=${siniestroId}`)).json()
            setState(response.body)
        } catch (error) {
            console.error("Error al cargar la bitácora", error)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <div className="grid gap-2 bg-dark-400 p-4 rounded-md">
                <p className="text-gray-400 text-base font-semibold"><ForumIcon fontSize="large" /> BITÁCORA</p>
                {loading && (<CircularProgress size="sm" />)}
                {state.length === 0 && (
                    <div className="grid gap-4 bg-dark-500 rounded-md p-4">
                        <p className="text-sm"><em>Sin registros en bitácora</em></p>
                    </div>
                )}
                {state.map(bitacora => bitacora.textos.map(texto => (
                    <div key={texto.id} className="grid gap-2 bg-dark-500 rounded-md p-4">
                        <p className="text-sm">{texto.texto}</p>
                        <p className="text-sm text-dark-100 text-right">{texto.fecha}</p>
                    </div>
                )))}
            </div>
        )
    }

    return render()
}

export default ConsultaSiniestro