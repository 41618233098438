import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router';
import AdminTemplate from '../template/AdminTemplate';
import APIInvoke from '../utils/APIInvoke';
import { format, parse } from '../utils/DateUtils'
import { numberFormat } from '../utils/NumberUtils'
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@material-ui/core';
import { maxFieldLength, onlySimpleCharacters } from '../utils/FormUtils'
import { Button, InputAdornment } from '@mui/material';
import Alert from '../components/Alert';
import Toolbar from '../components/Toolbar'
import { useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format';

const PagoRecibo = (props) => {

    const params = useParams()
    const reciboId = props.reciboId || params.reciboId
    const history = useHistory()

    const [state, setState] = useState(null)
    const [reciboState, setReciboState] = useState(null)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)


    useEffect(async () => {
        try {
            setLoading(true)
            const rawResponse = await APIInvoke.invokeGET(`/recibos?id=${reciboId}`)
            const response = await rawResponse.json()

            const recibo = response.body.items[0]
            setState({
                fecPago: recibo.fecPago ? parse(recibo.fecPago, "yyyy-MM-dd") : new Date(),
                folioPago: recibo.folioPago,
                tipoCambio: recibo.tipoCambio ? recibo.tipoCambio : recibo.poliza.moneda.tipoCambio,
                observacion: recibo.observacion
            })
            setReciboState(recibo)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [])

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)

            const request = {
                fechaPago: format(state.fecPago, "yyyy-MM-dd"),
                folioPago: state.folioPago,
                tipoCambio: state.tipoCambio,
                observacion: state.observacion
            }

            const rawResponse = await APIInvoke.invokePUT(`/recibos/${reciboId}/pagar`, request)
            const response = await rawResponse.json()
            setMessage({ type: response.ok ? "success" : "error", message: response.message })
            if (response.ok) {
                if (props.onCompleted) {
                    props.onCompleted(response.body)
                } else {
                    history.push(`/cxc`)
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        if (!reciboState) return <AdminTemplate loading={true} modal={props.modal}/>;

        const recibo = reciboState
        const poliza = reciboState.poliza
        const cliente = poliza.cliente
        const moneda = poliza.moneda
        return (
            <AdminTemplate loading={loading} modal={props.modal} >
                <Toolbar title="Pagar Recibo" backUrl={`/cxc`} modal={props.modal} />
                <form onSubmit={onSubmit}>
                    <div className="main center">
                        <div className="layout-compact">
                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <p className="text-yellow">No. de Póliza</p>
                                    <p>{poliza.noPoliza}</p>
                                </div>
                                <div>
                                    <p className="text-yellow">Concepto</p>
                                    <p>{poliza.concepto}</p>
                                </div>
                                <div>
                                    <p className="text-yellow">Contratante</p>
                                    <p>{`${cliente.nombres} ${cliente.apPat} ${cliente.apMat}`.trim()}</p>
                                </div>
                                <div className="flex flex-row gap-8 mt-4 mb-8">
                                    <div>
                                        <p className="text-yellow h-8">Recibo</p>
                                        <p className="text-yellow h-8">Vigencia</p>
                                        <p className="text-yellow h-8">Vencimiento</p>
                                        <p className="text-yellow h-8">Total</p>
                                    </div>
                                    <div>
                                        <p className="h-8">{`${recibo.serie}/${recibo.totalSerie}`}</p>
                                        <p className="h-8">{`${format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yy")} - ${format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yy")}`}</p>
                                        <p className="h-8">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yy")}</p>
                                        <p className="h-8">{numberFormat(recibo.total)}</p>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <TextField
                                        required
                                        name="folioPago"
                                        label="Folio de Pago"
                                        value={state.folioPago}
                                        onChange={onChange}
                                        onInput={event => onlySimpleCharacters(event, 50)}
                                        className="col-span-2"
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            name="fecPago"
                                            label="Fecha de Pago"
                                            inputFormat="dd/MM/yyyy"
                                            value={state.fecPago}
                                            required
                                            onChange={newDate => setState({ ...state, fecPago: newDate })}
                                            renderInput={(params) => <TextField required {...params} />}
                                        />
                                    </LocalizationProvider>

                                    <NumberFormat
                                        disabled={moneda?.moneda === "Peso"}
                                        name="tipoCambio"
                                        value={state.tipoCambio}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Tipo de Cambio"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                        onInput={(e) => maxFieldLength(e, 6)}
                                        type="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={","}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        isNumericString={true}
                                        required
                                    />
                                    <div className="col-span-2 grid grid-cols-1">
                                        <TextField
                                            label="Observaciones"
                                            color="primary"
                                            inputProps={{ className: "text-white" }}
                                            className="w-full"
                                            name="observacion"
                                            value={state.observacion}
                                            onChange={onChange}
                                            multiline
                                            rows={4}
                                        />
                                    </div>


                                </div>
                                <Button type="submit" className="btn btn-yellow font-bold ">PAGAR</Button>

                                {message && <Alert {...message} />}
                            </div>
                        </div>
                    </div>
                </form>
            </AdminTemplate>
        )
    }

    return render()

}
export default PagoRecibo