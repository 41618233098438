import {
    PROSPECTO_KANBAN_SET_FILTERS,
    PROSPECTO_KANBAN_ADD_COLUMN,
    PROSPECTO_KANBAN_SET_KANBAN,
    PROSPECTO_KANBAN_SET_LOADING,
    PROSPECTO_KANBAN_UPDATE_COLUMN,
    PROSPECTO_KANBAN_DELETE_COLUMN,
    PROSPECTO_KANBAN_UPDATE_CARD,
    PROSPECTO_KANBAN_DELETE_CARD,
    PROSPECTO_KANBAN_ADD_CARD,
    PROSPECTO_KANBAN_SAVE_CARD
} from "../../../redux/redux-const";
import APIInvoke from "../../../utils/APIInvoke";
import store from "../../../redux/store"
import { batch } from "react-redux";

export const fetchKanban = async () => {
    try {
        store.dispatch(setLoading(true))
        const getAllKanbanResponse = await (await APIInvoke.invokeGET(`/prospectos/kanbans`)).json()
        let kanbanId = null
        if (getAllKanbanResponse.body.length === 0) {
            const createKanbanRequest = {
                nombre: "Funal de ventas"
            }
            const createKanbanResponse = await (await APIInvoke.invokePOST(`/prospectos/kanbans`, createKanbanRequest)).json()
            kanbanId = createKanbanResponse.body.id
        } else {
            const kanban = getAllKanbanResponse.body[0]
            kanbanId = kanban.id
        }
        const filters = store.getState().pages.prospectoKanban.filters
        const status = filters.showClosed ? "ABIERTA,EXITOSA,NO_EXITOSA" : "ABIERTA"
        const startDate = filters.startDate.toFormat("yyyy-MM-dd")
        const endDate = filters.endDate.toFormat("yyyy-MM-dd")

        const response = await (await APIInvoke.invokeGET(`/prospectos/kanbans/${kanbanId}?status=${status}&startDate=${startDate}&endDate=${endDate}`)).json()
        store.dispatch(setKanban(response.body))
    } catch (e) {
        console.error("Error al cargar el Kanban", e)
    } finally {
        store.dispatch(setLoading(false))
    }

}

export const setFilters = async (filters) => {
    try {
        store.dispatch({
            type: PROSPECTO_KANBAN_SET_FILTERS,
            payload: filters
        })
    } catch (e) {
        console.log("Error al crear la columna", e)
    } finally {
    }
}

export const addNewColumn = async (name) => {
    try {
        store.dispatch(setLoading(true))
        const kanban = store.getState().pages.prospectoKanban.kanban
        const kanbanId = kanban.id

        const createColumnRequest = {
            kanbanId: kanbanId,
            nombre: name
        }
        const createColumnResponse = await (await APIInvoke.invokePOST(`/prospectos/kanbans/columnas`, createColumnRequest)).json()

        store.dispatch({
            type: PROSPECTO_KANBAN_ADD_COLUMN,
            payload: createColumnResponse.body
        })
    } catch (e) {
        console.log("Error al crear la columna", e)
    } finally {
        store.dispatch(setLoading(false))
    }
}

export const setKanban = (kanban) => {
    return {
        type: PROSPECTO_KANBAN_SET_KANBAN,
        payload: {
            kanban
        }
    }
}

export const setLoading = (loading) => {
    return {
        type: PROSPECTO_KANBAN_SET_LOADING,
        payload: {
            loading
        }
    }
}

export const updateColummn = async (column) => {
    try {
        store.dispatch(setLoading(true))

        const kanban = store.getState().pages.prospectoKanban.kanban
        const stateColumn = kanban.columns.find(x => x.id === column.id)
        kanban.columns.splice(column.from, 1)
        kanban.columns.splice(column.to, 0, stateColumn)

        store.dispatch({
            type: PROSPECTO_KANBAN_UPDATE_COLUMN,
            payload: {
                columns: [
                    ...kanban.columns
                ]
            }
        })

        const updateColumnRequest = {
            kanbanId: kanban.id,
            nombre: column.nombre,
            orden: column.to
        }
        const updateColumnResponse = await (await APIInvoke.invokePUT(`/prospectos/kanbans/columnas/${column.id}`, updateColumnRequest)).json()
        if (!updateColumnResponse.ok) return
    } catch (e) {
        console.error("Error al actualizar la columna", e)
    } finally {
        store.dispatch(setLoading(false))
    }
}

export const deleteColummn = async (columnId) => {
    try {
        store.dispatch(setLoading(true))
        const currentState = store.getState().pages.prospectoKanban
        const indexIf = currentState.kanban.columns.findIndex(x => x.id === columnId)
        currentState.kanban.columns.splice(indexIf, 1)

        store.dispatch({
            type: PROSPECTO_KANBAN_DELETE_COLUMN,
            payload: {
                columns: [
                    ...currentState.kanban.columns
                ]
            }
        })

        const deleteColumnResponse = await (await APIInvoke.invokeDELETE(`/prospectos/kanbans/columnas/${columnId}`)).json()
        if (!deleteColumnResponse.ok) return
    } catch (e) {
        console.error("Error al eliminar la columna", e)
    } finally {
        store.dispatch(setLoading(false))
    }
}

export const updateCard = async (payload) => {
    const { card, fromColumn, fromPosition, toColumn, toPosition, to } = payload
    try {
        store.dispatch(setLoading(true))
        const kanban = store.getState().pages.prospectoKanban.kanban
        const fromCardIndex = kanban.columns.findIndex(x => x.id === fromColumn)
        const toCardIndex = kanban.columns.findIndex(x => x.id === toColumn)
        const removeCard = kanban.columns[fromCardIndex].cards.splice(fromPosition, 1)[0]
        kanban.columns[toCardIndex].cards.splice(toPosition, 0, removeCard)

        store.dispatch({
            type: PROSPECTO_KANBAN_UPDATE_CARD,
            payload: {
                columns: [
                    ...kanban.columns
                ]
            }
        })
        const payload = {
            columnId: toColumn,
            nombre: card.nombre,
            prospectoId: card.prospecto.id,
            descripcion: card.descripcion,
            primaTotal: card.primaTotal,
            ramoId: card.ramo.id,
            status: card.status,
            moneda: card.moneda.abreviacion,
            orden: toPosition
        }

        const updateColumnResponse = await (await APIInvoke.invokePUT(`/prospectos/kanbans/cards/${card.id}`, payload)).json()
    } catch (e) {
        console.error("Error al actualizar la columna", e)
    } finally {
        store.dispatch(setLoading(false))
    }
}

export const deleteCard = async (cardId) => {
    try {
        store.dispatch(setLoading(true))
        const kanban = store.getState().pages.prospectoKanban.kanban

        const cardMeta = kanban.columns.reduce((previousValue, currentValue, columnIndex) => {
            if (previousValue !== null) return previousValue
            const cardIndex = currentValue.cards.findIndex(xCard => xCard.id === cardId)
            return cardIndex === -1 ? null : { columnIndex, cardIndex }
        }, null)

        store.dispatch({
            type: PROSPECTO_KANBAN_DELETE_CARD,
            payload: cardMeta
        })

        const deleteColumnResponse = await (await APIInvoke.invokeDELETE(`/prospectos/kanbans/cards/${cardId}`)).json()
    } catch (e) {
        console.error("Error al eliminar la columna", e)
    } finally {
        store.dispatch(setLoading(false))
    }
}



export const addNewCard = async (card, columnId) => {
    try {
        store.dispatch(setLoading(true))

        const createCardRequest = {
            columnId: columnId,
            descripcion: card.descripcion,
            prospectoId: card.prospecto.id,
            nombre: card.nombre,
            primaTotal: card.primaTotal,
            moneda: card.moneda,
            ramoId: card.ramo,
            status: "abierta"
        }
        const createCardResponse = await (await APIInvoke.invokePOST(`/prospectos/kanbans/cards`, createCardRequest)).json()

        store.dispatch({
            type: PROSPECTO_KANBAN_ADD_CARD,
            payload: {
                columnId,
                card: createCardResponse.body
            }
        })
        return createCardResponse
    } catch (e) {
        console.error("Error al eliminar la columna", e)
    } finally {
        store.dispatch(setLoading(false))
    }
}

export const saveCard = async (card) => {
    try {
        store.dispatch(setLoading(true))
        const updateCardRequest = {
            columnId: card.columnId,
            prospectoId: card.prospecto.id,
            descripcion: card.descripcion,
            nombre: card.nombre,
            primaTotal: card.primaTotal,
            moneda: card.moneda,
            ramoId: card.ramo,
            orden: null,
            status: card.status
        }
        const updateCardResponse = await (await APIInvoke.invokePUT(`/prospectos/kanbans/cards/${card.id}`, updateCardRequest)).json()

        store.dispatch({
            type: PROSPECTO_KANBAN_SAVE_CARD,
            payload: updateCardResponse.body
        })

        return updateCardResponse
    } catch (e) {
        console.error("Error al eliminar la columna", e)
    } finally {
        store.dispatch(setLoading(false))
    }
} 