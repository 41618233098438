import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate';
import APIInvoke from '../../utils/APIInvoke';
import Pagination from '../../components/Pagination';
import Toolbar from '../../components/Toolbar'
import { hasText } from '../../utils/StringUtils';
import FilterBuilder2 from '../../components/FilterBuilder/FilterBuilder2';
import TextFilter from '../../components/FilterBuilder/TextFilter';
import BooleanFilter from '../../components/FilterBuilder/BooleanFilter';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';


const filters = [
    {
        id: "aseguradora",
        name: "aseguradora",
        placeholder: "Nombre Aseguradora",
        filter: "aseguradora[cn]",
        type: TextFilter,
        defaultValue: ""
    }, {
        id: "abreviacion",
        name: "abreviacion",
        placeholder: "Abreviación",
        filter: "abreviacion[cn]",
        type: TextFilter,
        defaultValue: ""
    }, {
        id: "estandar",
        name: "estandar",
        placeholder: "Ocultar predefinidas",
        filter: "estandar",
        type: BooleanFilter,
        defaultValue: null,
        processInput: (value) => {
            return value === true ? false : null
        },
        getOptionLabel: (option) => {
            return option === true ? "SÍ" : "NO"
        },
    }
]

export default function Aseguradoras(params) {

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        queryBuilder: {
            queryString: `?size=10&order=aseguradora asc`
        },
        aseguradoras: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0,
    });
    const [openAseguradoraDialog, setOpenAseguradoraDialog] = useState({ show: false, id: null })

    useEffect(() => {
        filter(state)
    }, [])

    const onFilterProcess = async (queryBuilder) => {
        const newState = {
            ...state,
            queryBuilder: queryBuilder,
            aseguradoras: [],
            totalItems: 0,
            currentPage: 1,
            totalPages: 0,
            pageSize: 0,
        }
        setState(newState)
        filter(newState)
    }

    const filter = async (state) => {
        try {
            setLoading(true)
            const query = state.queryBuilder.queryString
            const response = await (await APIInvoke.invokeGET(`/aseguradoras${state.queryBuilder.queryString}${hasText(query) ? "&" : "?"}page=${state.currentPage}&order=aseguradora asc`)).json();
            setState({
                ...state,
                aseguradoras: response.body.items,
                totalItems: response.body.totalItems,
                currentPage: response.body.currentPage,
                totalPages: response.body.totalPages,
                pageSize: response.body.pageSize
            });
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <AdminTemplate loading={loading}>
            <Toolbar title="Aseguradoras" >
                <div className="layout py-4">
                    <div className="text-left">
                        <Link className="no-underline" to={{ pathname: "/aseguradoras/", state: { backUrl: "/aseguradoras" } }} >
                            <Button className="bg-yellow"><AddBoxIcon className="text-dark-600"></AddBoxIcon><p className="text-dark-600 text-base capitalize">Añadir</p></Button>
                        </Link>
                    </div>
                </div>
            </Toolbar>

            <div className="main">
                <div className="layout mb-8 grid gap-8">
                    <FilterBuilder2 filters={filters} onProcess={onFilterProcess} />
                    <div className="grid gap-2 lg:gap-4 grid-cols-1 bg-dark-400 p-2 lg:p-4 rounded-md">
                        {state.aseguradoras.map((aseguradora, i) => (
                            <div key={aseguradora.id} className="bg-dark-300 p-2 rounded-md px-4">
                                <div className="flex items-center gap-4">
                                    <p className="text-sm flex-1">
                                        <Tooltip title={aseguradora.status.toUpperCase()}>
                                            <span className={aseguradora.status === "activo" ? "inline-block rounded-full bg-green w-3 h-3 mr-2" : "inline-block rounded-full bg-red w-3 h-3 mr-2"} />
                                        </Tooltip>
                                        {aseguradora.aseguradora} ({aseguradora.abreviacion})
                                    </p>
                                    <Tooltip title={"Editar"}>
                                        <IconButton disabled={aseguradora.estandar === true} className="no-underline p-1 btn btn-yellow h-8 w-8" to={`/aseguradoras/${aseguradora.id}`} LinkComponent={Link}>
                                            <EditIcon className="text-dark-600 text-base"></EditIcon>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        ))}

                        <div className="" >
                            <Pagination
                                totalItems={state.totalItems}
                                pages={state.totalPages}
                                page={state.currentPage}
                                onPageChange={newPage => {
                                    const newState = {
                                        ...state,
                                        currentPage: newPage,
                                    }
                                    setState(newState)
                                    filter(newState)
                                }} />
                        </div>
                    </div>

                </div>
            </div>
        </AdminTemplate >
    )

}