import { React, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import AdminTemplate from '../../template/AdminTemplate'
import Pagination from '../../components/Pagination'
import { Link } from 'react-router-dom'
import { Accordion, AccordionSummary, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox';
import Toolbar from '../../components/Toolbar'
import ConsultaCotizacion from './ConsultaCotizacion';
import DalalaDialog from '../../components/DalalaDialog';
import { useDispatch } from 'react-redux'
import { DateTime } from 'luxon'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { getCotizacionStatuById, getCotizacionStatus, getRamoById } from '../../utils/EnumUtils'
import { isEmpty } from '../../utils/StringUtils'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import * as Permisos from '../../utils/Permisos'
import GrantContent from '../../components/GrantContent'
import { Tooltip } from '@mui/material'


const Cotizaciones = (props) => {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const [state, setState] = useState({
        cotizaciones: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0
    })

    const [filter, setFilter] = useState({
        showTerminated: false,
        noPoliza: "",
        noCotizacion: "",
        cliente: ""
    })

    const [openDetalleDialog, setOpenDetalleDialog] = useState({ show: false, id: null })

    useEffect(async () => {
        fetchCotizaciones()
    }, [state.currentPage, state.nombre, filter.showTerminated])

    const fetchCotizaciones = async () => {
        setLoading(true)
        try {
            const filterNoPoliza = filter.noPoliza.trim().length > 0 ? `&noPoliza[cn]=${filter.noPoliza.trim()}` : ""
            const filterCliente = filter.cliente.trim().length > 0 ? `&nombre[cn]=${filter.cliente.trim()}` : ""
            const filterNoCotizacion = filter.noCotizacion.trim().length > 0 ? `&noCotizacion[eq]=${filter.noCotizacion.trim()}` : ""
            const filterStatus = isEmpty(filter.status) ? "" : `&status[eq]=${filter.status}`
            const filterValues = `${filterNoPoliza}${filterCliente}${filterNoCotizacion}`

            const response = await (await APIInvoke.invokeGET(`/cotizaciones?page=${state.currentPage}${filterValues}${filterStatus}&order=fecVencimiento asc`)).json()
            setState({
                ...state,
                cotizaciones: response.body.items.map(cotizacion => {
                    return {
                        ...cotizacion,
                        fecVencimiento: DateTime.fromFormat(cotizacion.fecVencimiento, "yyyy-MM-dd"),
                    }
                }),
                totalItems: response.body.totalItems,
                currentPage: response.body.currentPage,
                totalPages: response.body.totalPages,
                pageSize: response.body.pageSize
            })
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const renderCotizacion = (cotizacion) => {
        const status = getCotizacionStatuById(cotizacion.status)
        const ramo = getRamoById(cotizacion.ramo)
        return (
            <Link className="no-underline" key={cotizacion.id} to="#" onClick={e => setOpenDetalleDialog({ show: true, id: cotizacion.id })}>
                <article className="bg-dark-300 rounded-md p-2 lg:p-4 ">
                    <div className="grid gap-1 grid-cols-1 lg:grid-cols-2">
                        <div className="flex flex-row gap-2 justify-between">
                            <p className="text-sm text-gray-400">No Cotizacion: {cotizacion.noCotizacion}</p>
                            <span className="text-white inline-block md:hidden text-2xl">{ramo.icon}</span>
                        </div>
                        <div className="flex flex-row gap-2 justify-between">
                            <p className="text-sm text-gray-400">F. Vencimiento: {cotizacion.fecVencimiento.toFormat("dd/MM/yyyy")}</p>
                            <span className="text-white hidden md:inline-block text-2xl">{ramo.icon}</span>
                        </div>
                        <p className="text-sm text-gray-400">Contratante: {cotizacion.cliente ? `${cotizacion.cliente}` : `${cotizacion.prospecto}`}</p>
                        <p className="text-sm text-gray-400">Estatus: <span className={`${status.color} uppercase`}>{status.name}</span></p>
                    </div>
                </article>
            </Link>
        )
    }

    const onChangeFilters = (e) => {
        let { value, name } = e.target
        if (e.target.type === "checkbox") value = e.target.checked
        setFilter({
            ...filter,
            [e.target.name]: value
        })
    }

    const renderFilters = () => {
        return (

            <Accordion className="bg-dark-400 rounded-md">
                <AccordionSummary expandIcon={<ExpandMoreIcon className="text-gray-400" />} aria-controls="panel1a-content" id="panel1a-header">
                    <p className="font-bold"><FilterAltIcon /> Filtros:</p>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="grid grid-cols-1  gap-4 bg-dark-400">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <TextField
                                label="Número de Cotizacion"
                                type="text"
                                variant="outlined"
                                required
                                name="noCotizacion"
                                value={state.noCotizacion}
                                onChange={onChangeFilters}
                            />
                            <TextField
                                label="Cliente"
                                type="text"
                                variant="outlined"
                                required
                                name="cliente"
                                value={state.cliente}
                                onChange={onChangeFilters}
                            />
                            <FormControl >
                                <InputLabel id="status" required>Estatus</InputLabel>
                                <Select
                                    required
                                    labelId="status"
                                    name="status"
                                    value={state.status}
                                    onChange={onChangeFilters}
                                >
                                    <MenuItem value=""><em>Todos</em></MenuItem>
                                    {getCotizacionStatus().map(status => (
                                        <MenuItem key={status.id} value={status.enum}>{status.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <Button onClick={fetchCotizaciones} className="btn btn-yellow">FILTRAR</Button>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }

    const onDeleteCompleted = (result) => {
        setOpenDetalleDialog({ show: false })

        if (!result) return

        setState({
            ...state,
            cotizaciones: state.cotizaciones.filter(x => x.id !== openDetalleDialog.id)
        })
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} title="Dalala - Cotizaciones">
                <DalalaDialog
                    title={"Detalle del la cotización"}
                    open={openDetalleDialog.show}
                    handleClose={() => setOpenDetalleDialog({ show: false, id: null })}
                >
                    <ConsultaCotizacion modal={true} cotizacionId={openDetalleDialog.id} onCompleted={onDeleteCompleted} />
                </DalalaDialog>
                <Toolbar title="Cotizaciones">
                    <GrantContent permisos={[Permisos.COTIZACIONES_W]}>
                        <div className="layout">
                            <div className="flex flex-row gap-4">
                                <Link to={{ pathname: "/cotizaciones/", state: { backUrl: "/cotizaciones" } }} className="btn btn-yellow px-4 py-2" size="small" ><AddBoxIcon /> Añadir</Link>
                            </div>
                        </div>
                    </GrantContent>
                </Toolbar>
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-4">
                            <div className="text-right">
                                <GrantContent permisos={[Permisos.COTIZACIONES_TEMP_R]} >
                                    <Tooltip title="Ver los templates" >
                                        <Link className="btn btn-yellow inline-block w-auto p-2" to={{ pathname: "/cotizaciones/templates", state: { backUrl: "/cotizaciones" } }}><DocumentScannerIcon className="mr-2" />Templates</Link>
                                    </Tooltip>
                                </GrantContent>
                            </div>
                            {renderFilters()}
                            <div className="grid grid-cols-1 gap-2 bg-dark-400 p-2 rounded-md">
                                {state.cotizaciones && state.cotizaciones.map((cotizacion, i) => renderCotizacion(cotizacion))}
                                <div className="">
                                    <Pagination totalItems={state.totalItems} pages={state.totalPages} page={state.currentPage} onPageChange={newPage => setState({ ...state, currentPage: newPage })} />
                                </div>

                                {state.cotizaciones.length === 0 && (
                                    <p className="text-yellow text-center mb-8">Sin resultados</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}
export default Cotizaciones