import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import AppContext from '../state/AppContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import APIInvoke from '../utils/APIInvoke';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button, Checkbox, Tooltip } from '@mui/material';
import { fromToFormat } from '../utils/DateUtils';
import TodayIcon from '@mui/icons-material/Today';
import { getTareaPrioridadById, getTareaTipoById } from '../utils/EnumUtils';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import DalalaDialog from '../components/DalalaDialog';
import NuevaTareaPage from '../pages/tareas/NuevaTarea';
import AddTaskIcon from '@mui/icons-material/AddTask';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const Taskbar = () => {

    const { appContext, setAppContext } = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [state, setState] = useState([])
    const [openNuevaTareaDialog, setOpenNuevaTareaDialog] = useState({ show: false, tarea: null })

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const response = await (await APIInvoke.invokeGET(`/tareas?status=abierta&size=-1&order=fecha asc, vencimiento asc`)).json()
                if (response.ok) {
                    setError(null)
                    setState(response.body.items)
                } else {
                    new Error()
                }
            } catch (e) {
                setError("Error al cargar las tareas")
            } finally {
                setLoading(false)
            }
        }
        init()
    }, [])

    const toogleTaskbar = () => {
        setAppContext({
            ...appContext,
            taskbar: {
                open: !appContext.taskbar.open
            }
        })
    }


    const updateStatus = async (tarea) => {
        const newStatus = tarea.status.name === "cerrada" ? {
            name: "abierta",
            displayName: "Abierta"
        } : {
            name: "cerrada",
            displayName: "Cerrada"
        }
        try {
            tarea.status = newStatus
            setState([...state])

            const body = {
                ...tarea,
                status: newStatus.name,
                prioridad: tarea.prioridad.name,
                tipo: tarea.tipo.name,
            }
            const response = await (await APIInvoke.invokePUT(`/tareas/${tarea.id}`, body)).json()
        } catch (error) {
            console.error("Error al actualizar la tarea", error)
        }
    }

    const onCreateNewTask = (tarea) => {
        const index = state.findIndex(item => item.id === tarea.id)
        if (index !== -1) {
            state[index] = tarea
            setState([...state])
        } else {
            setState([tarea, ...state])
        }
        setOpenNuevaTareaDialog({ show: false, tarea: null })
    }


    return (
        <Drawer
            anchor="right"
            open={appContext.taskbar.open}
            onClose={toogleTaskbar}
            sx={{
                flexShrink: 0,
            }}
        >

            <DalalaDialog
                title={openNuevaTareaDialog.tarea ? "Editar tarea" : "Nueva tarea"}
                open={openNuevaTareaDialog.show}
                handleClose={() => setOpenNuevaTareaDialog({ show: false, tarea: null })}
            >
                <NuevaTareaPage modal={true} tarea={openNuevaTareaDialog.tarea} onCompleted={onCreateNewTask} />
            </DalalaDialog>

            <div className="bg-dark-500 border-l border-dark-200">
                <div className="min-h-screen" style={{ height: "auto", width: "500px", maxWidth: "85vw" }}>
                    <div className="bg-dark-600 flex justify-center h-10">
                        <p className="m-0 flex items-center gap-2"><LibraryAddCheckIcon className="text-yellow" /> Tareas</p>
                    </div>
                    {error && <p className="m-4 text-sm text-center">{error}</p>}

                    <div className="grid gap-2 m-4 text-sm text-center">
                        <Button onClick={() => setOpenNuevaTareaDialog({ tarea: null, show: true })} to="/tareas" className="no-underline" ><AddTaskIcon className="mr-2" fontSize="small" /> Crear una tarea</Button>
                    </div>
                    <div className="grid gap-2 m-4 ">
                        {state.map(tarea => {
                            const tipo = getTareaTipoById(tarea.tipo.name)
                            const prioridad = getTareaPrioridadById(tarea.prioridad.name)
                            return (
                                <article key={tarea.id} className="flex border-dark-400 border rounded-md bg-dark-600 p-2 text-gray-100 text-sm">
                                    <div>
                                        <Checkbox
                                            className="m-0"
                                            onClick={e => updateStatus(tarea)}
                                            checked={tarea.status.name === "cerrada"}
                                            icon={<RadioButtonUncheckedIcon />}
                                            checkedIcon={<TaskAltIcon />}
                                        />
                                    </div>
                                    <Link to="#" className="no-underline block w-full" onClick={() => setOpenNuevaTareaDialog({ tarea: tarea, show: true })} >
                                        <div className="flex flex-col flex-1 gap-1">
                                            <div className="flex justify-between">
                                                <p className="font-bold">{tarea.nombre}</p>
                                                <p className="p-1 bg-dark-300 rounded">
                                                    <FlagCircleIcon className={`text-lg mr-1 ${prioridad.color}`} />
                                                    <span>{tarea.prioridad.displayName}</span>
                                                </p>
                                            </div>
                                            <p className="text-xs font-thin">{tarea.descripcion}</p>
                                            <div className="flex items-center gap-2">
                                                <span className="text-xs mr-1 text-gray-800">{<tipo.icon className="text-sm" />} {tarea.tipo.name}</span>
                                                <span className="text-xs text-gray-800"><TodayIcon className="text-sm" /> {tarea.vencimiento ? fromToFormat(tarea.vencimiento, "yyyy-MM-dd HH:mm:ss", "dd MMMM HH:mm") : "Sin definir"}</span>
                                            </div>
                                            <div>
                                                <span className="text-xs mr-1 text-gray-800"><SupportAgentIcon className="text-sm" /> {tarea.vendedorId ? `${tarea.vendedorNombres || ''} ${tarea.vendedorApPat || ''} ${tarea.vendedorApMat || ''}` : "Sin vendedor"}</span>
                                            </div>
                                        </div>
                                    </Link>
                                </article>
                            )
                        })}
                    </div>



                    {loading && (
                        <div className="flex gap-2 justify-center items-center m-4 text-center">
                            <CircularProgress size={20} color="primary" />
                            <span className="text-sm">Cargando...</span>
                        </div>
                    )}
                </div>
            </div>
        </Drawer>
    )
}

export default Taskbar