import React, { useState } from 'react'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { IconButton, Button } from '@material-ui/core';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { DateTime } from 'luxon';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitle } from '@mui/material';
import { deleteCard } from './kaban-prospecto-actions'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DalalaDialog from '../../../components/DalalaDialog';
import KanbanNewCard from './KanbanNewCard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { numberFormat, numberFormatNoDecimal } from '../../../utils/NumberUtils';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import BusinessIcon from '@mui/icons-material/Business';
import GrantContent from '../../../components/GrantContent';
import * as Permisos from '../../../utils/Permisos'

const KanbanCard = (props) => {

    const [state, setState] = useState({
        showDeleteDialog: false,
        showNewCardDialog: false,
        expand: false
    })

    const toggleExpand = () => {
        setState({
            ...state,
            expand: !state.expand
        })
    }

    const showDeleteDialog = () => {
        setState({ ...state, showDeleteDialog: true })
    }

    const hiddenDeleteDialog = () => {
        setState({ ...state, showDeleteDialog: false })
    }

    const showNewCardDialog = () => {
        setState({ ...state, showNewCardDialog: true })
    }

    const hiddenNewCardDialog = () => {
        setState({ ...state, showNewCardDialog: false })
    }

    const callDeleteCard = async () => {
        await deleteCard(props.payload.id)
        hiddenDeleteDialog()
    }

    const getRamoIcon = (ramoId) => {
        switch (ramoId) {
            case 1: return (<DriveEtaIcon fontSize="small" className="mr-1" />)  // Auto
            case 3: return (<FavoriteIcon fontSize="small" className="mr-1" />)  // Salud
            case 5: return (<PersonIcon fontSize="small" className="mr-1" />)  // Vida
            case 7: return (<HomeIcon fontSize="small" className="mr-1" />)  // Diversos
        }
    }

    const getStatusIcon = () => {
        switch (props.payload.status) {
            case "exitosa": return <ThumbUpAltIcon className="text-green text-base" />
            case "no_exitosa": return <ThumbDownAltIcon className="text-red text-base" />
            default: return null
            // case "abierta": return <MeetingRoomIcon className="text-gray-500 text-base" />
        }
    }

    const render = () => {
        const { payload, dragging, removeCard } = props
        const prospecto = payload.prospecto
        const fecha = DateTime.fromFormat(payload.fecRegistro, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")

        const cardStyles = dragging
            ? "m-1 w-64 border border-yellow-300 bg-dark-500 rounded-md transition duration-150 transform rotate-6"
            : "m-1 w-64 border border-dark-200   bg-dark-500 rounded-md transition duration-150"

        return (
            <>
                <Dialog
                    open={state.showDeleteDialog}
                    onClose={hiddenDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la tarjeta?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={hiddenDeleteDialog}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={callDeleteCard} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>
                <DalalaDialog
                    title={"Actualizar tarjeta"}
                    open={state.showNewCardDialog}
                    handleClose={hiddenNewCardDialog}
                >
                    <KanbanNewCard
                        modal={true}
                        columnId={payload.columnId}
                        cardId={payload.id}
                        onCompleted={hiddenNewCardDialog}
                    />
                </DalalaDialog>
                <article key={payload.id} className={cardStyles}>
                    <div className="grid grid-cols-1 gap-1 p-2">
                        <div className="flex items-center text-sm transition">

                            {prospecto.fiscal === "moral"
                                ? (<BusinessIcon className="mr-1 text-white" fontSize="small" />)
                                : (<PersonIcon className="mr-1 text-white" fontSize="small" />)}
                            <Tooltip title={prospecto.nombres}>
                                <span className="truncate flex-1">{prospecto.nombres}</span>
                            </Tooltip>
                            <div className="flex">
                                <Tooltip title="Ver/ocultar detalle">
                                    {state.expand
                                        ? (<IconButton onClick={toggleExpand} color="primary" className="flex items-center" size="small">
                                            <KeyboardArrowUpIcon className="text-sm text-gray-500" />
                                        </IconButton>)
                                        : (<IconButton onClick={toggleExpand} color="primary" className="flex items-center" size="small">
                                            <KeyboardArrowDownIcon className="text-sm text-gray-500" />
                                        </IconButton>)
                                    }
                                </Tooltip>
                                <Tooltip title="Ver oportunidad">
                                    <IconButton onClick={showNewCardDialog} color="primary" className="flex items-center" size="small">
                                        <VisibilityIcon className="text-sm text-gray-500" />
                                    </IconButton>
                                </Tooltip>
                                <GrantContent permisos={[Permisos.PROSPECCION_D]}>
                                    <Tooltip title="Eliminar oportunidad">
                                        <IconButton onClick={showDeleteDialog} color="primary" className="flex items-center" size="small">
                                            <CloseIcon className="text-sm text-gray-500" />
                                        </IconButton>
                                    </Tooltip>
                                </GrantContent>
                            </div>
                        </div>
                        <div>
                            <span className="text-xs font-thin">{payload.nombre}</span>

                        </div>
                    </div>
                    {state.expand && (
                        <div className="bg-dark-200 p-2">
                            <p className="text-xs font-thin">{payload.descripcion}</p>
                        </div>
                    )}
                    <div className="px-2 py-1 flex justify-between">
                        <div>
                            {getStatusIcon()}
                        </div>
                        <p className="flex items-center gap-2 text-xs bg-dark-200 rounded-md px-1">{numberFormat(payload.primaTotal)}</p>
                    </div>
                    <div className="flex items-center text-sm justify-between p-2 bg-dark-600 rounded-b-md">
                        <div className="px-1 bg-yellow rounded-md">
                            <Tooltip title={`El prospecto tiene ${payload.dias} días en el dashboard`}>
                                <span className="text-dark-600 text-xs">{getRamoIcon(payload.ramo.id)}{payload.dias} días</span>
                            </Tooltip>
                        </div>
                        <div className="">
                            <ScheduleIcon className="text-gray-500 mr-1 text-sm" />
                            <span className="text-gray-500 text-xs">{fecha.setLocale("es").toFormat("dd/MM/yyyy")}</span>
                        </div>
                    </div>
                </article>
            </>
        )
    }

    return render()
}
export default KanbanCard