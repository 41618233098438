import EditIcon from '@mui/icons-material/Edit';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminTemplate from '../../template/AdminTemplate';
import APIInvoke from '../../utils/APIInvoke'
import { parse, format, fromToFormat } from "../../utils/DateUtils";
import Toolbar from '../../components/Toolbar'
import DalalaDialog from '../../components/DalalaDialog';
import Bitacora from './../Bitacora';
import Adjuntos from './../Adjuntos';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@material-ui/core';
import { DialogTitle, IconButton, Tooltip } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { getCotizacionStatuById, getRamoById, getRamos } from '../../utils/EnumUtils';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import CategoryIcon from '@mui/icons-material/Category';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { numberFormat } from '../../utils/NumberUtils';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LanguageIcon from '@mui/icons-material/Language';
import SendIcon from '@mui/icons-material/Send';
import * as Permisos from '../../utils/Permisos'
import GrantContent from '../../components/GrantContent';

const ConsultaCotizacion = (props) => {

    const params = useParams()
    const history = useHistory()
    const cotizacionId = props.cotizacionId || params.cotizacionId

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(null)
    const [hasError, setHasError] = useState(false)
    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })
    const [openSendEmailDialog, setOpenSendEmailDialog] = useState({ show: false })
    const [showOkDialog, setShowOkDialog] = useState({ show: false, message: "" })


    useEffect(async () => {

        try {
            setLoading(true)
            const responseConCotizacion = await (await APIInvoke.invokeGET(`/cotizaciones/${cotizacionId}`)).json();
            const responseEmpresa = await (await APIInvoke.invokeGET(`/empresas/default`)).json();
            setResult({
                ...responseConCotizacion.body,
                fecVencimiento: parse(responseConCotizacion.body.fecVencimiento, "yyyy-MM-dd"),
                asegurados: responseConCotizacion.body.asegurados.map(asegurado => {
                    return {
                        ...asegurado,
                        fecNacimiento: parse(asegurado.fecNacimiento, "yyyy-MM-dd"),
                    }
                }),
                empresa: responseEmpresa.body
            })
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }

    }, [])

    const deleteCotizacion = async () => {
        try {
            setOpenDeleteDialog({ show: false })
            setLoading(true)
            const deleteResponse = await (await APIInvoke.invokeDELETE(`/cotizaciones/${cotizacionId}`)).json()
            if (deleteResponse.ok) {
                history.push("/cotizaciones")
                props.onCompleted({ id: cotizacionId })
            } else {
                setShowOkDialog({ show: true, message: deleteResponse.message })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const sendCotizacion = async () => {
        try {
            setLoading(true)
            const request = {
                correo: result.correo
            }
            const responde = await (await APIInvoke.invokePOST(`/cotizaciones/${result.id}/send`, request)).json()
        } catch (error) {
            console.error("Error al enviar el email", error)
        } finally {
            setLoading(false)
            setOpenSendEmailDialog({ show: false })
        }

    }

    const render = () => {
        const status = getCotizacionStatuById(result?.status) || {}
        const ramo = getRamoById(result?.ramo) || {}


        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitacoraDialog.show}
                    handleClose={() => setOpenBitacoraDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"cotizaciones"} entidadId={openBitacoraDialog.id} onCompleted={() => setOpenBitacoraDialog({ show: false, id: null })} />
                </DalalaDialog>
                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosDialog.show}
                    handleClose={() => setOpenAdjuntosDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"cotizaciones"} entidadId={openAdjuntosDialog.id} onCompleted={() => setOpenAdjuntosDialog({ show: false, id: null })} />
                </DalalaDialog>

                <Dialog
                    open={openDeleteDialog.show}
                    onClose={e => setOpenDeleteDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la cotización?, si procede perderá toda la información de la cotización, botácora y adjuntos.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={deleteCotizacion} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showOkDialog.show}
                    onClose={e => setShowOkDialog({ show: false, message: "" })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">{showOkDialog.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn btn-yellow p-2 text-sm" onClick={e => setShowOkDialog({ show: false, message: "" })} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openSendEmailDialog.show}
                    onClose={e => setOpenSendEmailDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Enviar cotización</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">Esta acción enviará la cotización al cliente por email ({result?.correo}), ¿desea enviarla?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setOpenSendEmailDialog({ show: false })}>CANCELAR</Button>
                        <Button className="bg-yellow text-dark-600" onClick={sendCotizacion} autoFocus>ENVIAR</Button>
                    </DialogActions>
                </Dialog>

                <Toolbar title="Detalle de la Cotización" backUrl={`/cotizaciones`} modal={props.modal}>
                    {result && (
                        <div className="layout">
                            <div className="flex justify-end">
                                <GrantContent permisos={[Permisos.COTIZACIONES_W]}>
                                    <Tooltip title={"Editar"}>
                                        <Link className="no-underline p-1" to={{ pathname: `/cotizaciones/${cotizacionId}`, state: { backUrl: `/cotizacion/${cotizacionId}` } }}>
                                            <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md hover:bg-yellow-700 transition-colors">
                                                <EditIcon className="text-dark-600 ml-2"></EditIcon>
                                            </div>
                                        </Link>
                                    </Tooltip>
                                </GrantContent>
                                <Tooltip title={"Enviar cotización al cliente"}>
                                    <IconButton className="btn btn-yellow h-10 w-10 m-1" onClick={e => setOpenSendEmailDialog({ show: true })}>
                                        <SendIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Ver enlace de cliente"}>
                                    <a className="no-underline p-1" target="_blank" href={`/cotizaciones/visor/${result.uuid}`}>
                                        <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md hover:bg-yellow-700 transition-colors">
                                            <LanguageIcon className="text-dark-600 ml-2"></LanguageIcon>
                                        </div>
                                    </a>
                                </Tooltip>
                                <GrantContent permisos={[Permisos.COTIZACIONES_D]}>
                                    <Tooltip title={"Eliminar cotización"}>
                                        <IconButton className="btn btn-yellow h-10 w-10 m-1" onClick={e => setOpenDeleteDialog({ show: true, id: result.id })}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </GrantContent>
                            </div>
                        </div>
                    )}
                </Toolbar>

                <div className="main">
                    <div className="layout-compact">
                        {result && (
                            <div className="grid gap-8">
                                {!result.empresa && (
                                    <div className="flex gap-4 bg-yellow p-4 rounded-md mb-8">
                                        <WarningRoundedIcon className='text-dark-600' />
                                        <p className='flex-1 text-dark-600 text-sm'>Antes de enviar una cotización al cliente, complete los datos de su empresa para que sea más formal la cotización <Link to={{ pathname: `/configuracion/empresa`, state: { backUrl: `/cotizaciones` } }} className="text-dark-600">Actualizar los datos de mi empresa</Link> </p>
                                    </div>
                                )}
                                {renderViewStatus()}

                                <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                    <div className="flex justify-between">
                                        <p className="text-gray-400 text-base font-semibold"><RequestPageIcon fontSize="medium" /> DATOS GENERALES</p>
                                    </div>

                                    <div className="grid gap-4 bg-dark-500 rounded-md p-4">
                                        <div>
                                            <p className="text-yellow">Ramo</p>
                                            <div className="flex items-center">
                                                <span className="text-lg mr-2">{ramo.icon}</span>
                                                <span className="text-sm ">{ramo.name}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <p className="text-yellow">Número de cotización</p>
                                            <p className="text-sm uppercase">{result.noCotizacion}</p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Cliente</p>
                                            <p className="text-sm">{result.cliente ? `${result.cliente.nombres} ${result.cliente.apPat} ${result.cliente.apMat}` : `${result.prospecto.nombres} ${result.prospecto.apPat} ${result.prospecto.apMat}`}</p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Correo</p>
                                            <p className="text-sm">{result.correo}</p>
                                        </div>


                                        <div>
                                            <p className="text-yellow">Fecha de Vencimiento</p>
                                            <p className="text-sm">{`${format(result.fecVencimiento, "dd/MM/yyyy")}`}</p>
                                        </div>

                                        <div>
                                            <p className="text-yellow">Estatus</p>
                                            <p className={`text-sm ${status?.color}`}>{status?.name}</p>
                                        </div>


                                    </div>
                                </div>



                                {result.ramo === "Auto" && (
                                    <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                        <p className="text-gray-400 text-base font-semibold"><DriveEtaIcon fontSize="medium" /> Vehiculos ({result.vehiculos.length})</p>
                                        <div className="grid gap-2">
                                            {result.vehiculos.map(vehiculo => (
                                                <div key={vehiculo.id} className="items-center gap-2 p-2 px-4 bg-dark-500 rounded-md">
                                                    <p className="text-yellow">{`${vehiculo.descripcion}`}</p>
                                                    <p className="text-sm ">{`Modelo: ${vehiculo.modelo}`}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {result.ramo === "Diversos" && (
                                    <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                        <p className="text-gray-400 text-base font-semibold"><HomeIcon fontSize="medium" /> Bien Asegurado</p>
                                        <div className="grid gap-2">
                                            <div className="items-center gap-2 p-2 px-4 bg-dark-500 rounded-md">
                                                <p className="text-sm">{result.texto}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {(result.ramo === "Salud" || result.ramo === "Vida") && (
                                    <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                        <p className="text-gray-400 text-base font-semibold"><PersonIcon fontSize="medium" /> Asegurados ({result.asegurados.length})</p>
                                        <div className="grid gap-2">
                                            {result.asegurados.map(asegurado => (
                                                <div key={asegurado.id} className="items-center gap-2 p-2 px-4 bg-dark-500 rounded-md">
                                                    <p className="text-yellow">{`${asegurado.nombre} (${asegurado.parentesco})`}</p>
                                                    <p className="text-sm">{`F. Nacimiento: ${asegurado.fecNacimiento instanceof Date ? format(asegurado.fecNacimiento, "dd/MM/yyyy") : "Sin definir"}, Edad: ${asegurado.edad}`}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {result.items.map((item, itemIndex) => (
                                    <div key={item.id} className="grid gap-4 bg-dark-400 rounded-md p-2 md:p-4">
                                        <p className="text-gray-400 text-base font-semibold"><PriceChangeIcon fontSize="medium" /> COTIZACIÓN {itemIndex + 1}</p>
                                        <div className="grid gap-4 p-4 bg-dark-500 rounded-md">
                                            <div>
                                                <p className="text-yellow">Aseguradora</p>
                                                <p className="text-sm">{item.aseguradora.abreviacion}</p>
                                            </div>
                                            <div>
                                                <p className="text-yellow">Plan</p>
                                                <p className="text-sm">{item.plan}</p>
                                            </div>
                                        </div>

                                        <div className=" grid gap-4 bg-dark-500 p-4 rounded-md">
                                            <p className="text-gray-400 text-base font-semibold"><PriceChangeIcon fontSize="medium" /> CARACTERÍSTICAS DEL PLAN</p>
                                            <div className="grid col-span-1 md:grid-cols-2 gap-4 bg-dark-400 rounded-md p-4">
                                                <div>
                                                    <p className="text-yellow">Suma Asegurada</p>
                                                    <p className="text-sm uppercase">{item.sumaAsegurada}</p>
                                                </div>
                                                {result.ramo !== "Vida" && (
                                                    <div>
                                                        <p className="text-yellow">Deducible</p>
                                                        <p className="text-sm">{item.deducible}</p>
                                                    </div>
                                                )}
                                                {result.ramo === "Salud" && (
                                                    <>
                                                        <div>
                                                            <p className="text-yellow">Coaseguro</p>
                                                            <p className="text-sm">{item.coaseguro}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-yellow">Tope Coaseguro</p>
                                                            <p className="text-sm">{item.topeCoaseguro}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-yellow">Copago</p>
                                                            <p className="text-sm">{item.copago}</p>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className=" grid gap-4 bg-dark-500 p-4 rounded-md">
                                            <p className="text-gray-400 text-base font-semibold"><CategoryIcon fontSize="medium" /> Características Adicionales ({item.caracteristicas.length})</p>
                                            {item.caracteristicas.length === 0 && (<em className="text-sm text-gray-800">Sin resultados</em>)}
                                            <div className="grid gap-2">
                                                {item.caracteristicas.map(caracteritica => (
                                                    <div key={caracteritica.id} className="items-center gap-2 p-2 px-4 bg-dark-400 rounded-md">
                                                        <p className="text-yellow">{caracteritica.descripcion}</p>
                                                        <p className="text-sm ">{caracteritica.valor}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>


                                        <div className=" grid gap-4 bg-dark-500 p-4 rounded-md">
                                            <p className="text-gray-400 text-base font-semibold"><HealthAndSafetyIcon fontSize="medium" /> COBERTURAS ({item.coberturas.length})</p>
                                            {item.coberturas.length === 0 && (<em className="text-sm text-gray-800">Sin resultados</em>)}
                                            <div className="grid gap-2">
                                                {item.coberturas.map(cobertura => (
                                                    <div key={cobertura.id} className="items-center gap-2 p-2 px-4 bg-dark-400 rounded-md">
                                                        <p className="text-yellow">{cobertura.descripcion}</p>
                                                        <p className="text-sm ">{cobertura.valor}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>


                                        <div className=" grid gap-2 bg-dark-500 p-4 rounded-md">
                                            <p className="text-gray-400 text-base font-semibold pb-2"><MonetizationOnIcon fontSize="medium" /> PLANES DE PAGO ({item.planesPago.length})</p>
                                            {item.planesPago.length === 0 && (<em className="text-sm text-gray-800">Sin resultados</em>)}
                                            {item.planesPago.map(planPago => (
                                                <div key={planPago.id} className="grid gap-4 bg-dark-400 rounded-md p-2 px-4 ">
                                                    <div>
                                                        <p className="text-yellow">{`${planPago.formaPago.toUpperCase()} - ${numberFormat(planPago.total)}`}</p>
                                                        <p className="text-sm uppercase">{planPago.formaPago === "contado" ? `Pago único de contado` : `${numberFormat(planPago.primaPrimerRecibo)} / ${numberFormat(planPago.primaReciboSub)}`}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </AdminTemplate>
        )

    }

    const renderViewStatus = () => {

        if (result.fechaVista != null) {
            return (
                <div className="text-right ">
                    <span className="text-green-500 mr-2">
                        <HowToRegIcon />
                    </span>
                    <span className="mr-4">Visto el {fromToFormat(result.fechaVista, "yyyy-MM-dd'T'HH:mm:ss'Z'", "dd/MM/yyyy hh:mm")}</span>
                    <Tooltip title="Esta marca indica que el cliente ya ha abierto el link de la cotización y que la ha visualizado en la fecha y hora marcada">
                        <HelpOutlineIcon className="text-white" />
                    </Tooltip>
                </div>
            )
        } else if (result.fechaEnvio != null) {
            return (
                <div className="text-right ">
                    <span className="text-green-500 mr-2">
                        <PersonIcon />
                    </span>
                    <span className="mr-4">Enviada el {fromToFormat(result.fechaEnvio, "yyyy-MM-dd'T'HH:mm:ss'Z'", "dd/MM/yyyy hh:mm")}</span>
                    <Tooltip title="Esta marca indica que la cotización fue enviada al correo del cliente en la fecha indicada">
                        <HelpOutlineIcon className="text-white" />
                    </Tooltip>
                </div>
            )
        } else {
            return (
                <div className="text-right">
                    <span className="mr-2">El cliente no ha visto la cotización</span>
                    <Tooltip title="Dalala puede detectar cuando el cliente ha abierto la cotización por medio del link que se envía a su correo">
                        <HelpOutlineIcon className="text-white" />
                    </Tooltip>
                </div>
            )
        }
    }

    return render()

}

export default ConsultaCotizacion