import EditIcon from '@mui/icons-material/Edit';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminTemplate from '../../template/AdminTemplate';
import APIInvoke from '../../utils/APIInvoke'
import { format } from "../../utils/DateUtils";
import Toolbar from '../../components/Toolbar'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@material-ui/core';
import { DialogTitle, IconButton, Tooltip } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GrantContent from '../../components/GrantContent'
import * as Permisos from '../../utils/Permisos'

const ConsultaVendedor = (props) => {

    const params = useParams()
    const history = useHistory()
    const vendedorId = props.vendedorId || params.vendedorId

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState()
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })
    const [showOkDialog, setShowOkDialog] = useState({ show: false, message: "" })


    useEffect(async () => {

        try {
            setLoading(true)
            const responseConVendedor = await (await APIInvoke.invokeGET(`/vendedores/${vendedorId}`)).json();
            setResult({
                ...responseConVendedor.body,
            })
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }

    }, [])

    const deleteVendedor = async () => {
        try {
            setOpenDeleteDialog({ show: false })
            setLoading(true)
            const deleteResponse = await (await APIInvoke.invokeDELETE(`/vendedores/${vendedorId}`)).json()
            if (deleteResponse.ok) {
                history.push("/vendedores")
                props.onCompleted(deleteResponse.body)
            } else {
                setShowOkDialog({ show: true, message: deleteResponse.message })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Dialog
                    open={openDeleteDialog.show}
                    onClose={e => setOpenDeleteDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar al vendedor?, si procede perderá toda la información del vendedor.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={deleteVendedor} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showOkDialog.show}
                    onClose={e => setShowOkDialog({ show: false, message: "" })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">{showOkDialog.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn btn-yellow p-2 text-sm" onClick={e => setShowOkDialog({ show: false, message: "" })} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Toolbar title="Detalle del vendedor" backUrl={`/vendedores`} modal={props.modal}>
                    {result && (
                        <div className="layout">
                            <div className="flex justify-end gap-2">
                                <GrantContent permisos={[Permisos.VENDEDORES_W]}>
                                    {result.status === "activo" && (
                                        <Tooltip title="Editar" >
                                            <IconButton className="btn btn-yellow h-full" onClick={e => history.push(`/vendedores/${vendedorId}/edit`)}>
                                                <EditIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </GrantContent>

                                <GrantContent permisos={[Permisos.VENDEDORES_D]}>
                                    {result.status === "activo" && (
                                        <Tooltip title="Eliminar" >
                                            <IconButton className="btn btn-yellow h-full" onClick={e => setOpenDeleteDialog({ show: true, id: result.id })}>
                                                <DeleteIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </GrantContent>
                            </div>
                        </div>
                    )}
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        {result && (
                            <div className="grid grid-cols-1 gap-y-8 mb-8">
                                <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                    <p className="text-gray-400 text-base font-semibold"><SupportAgentIcon fontSize="large" /> DATOS GENERALES</p>
                                    <section className="rounded-md bg-dark-300 p-4 grid grid-cols-1 gap-4 ">
                                        <div className="flex justify-between">
                                            <p className={result.status === "activo" ? "text-green-500 mt-4 mb-2 text-sm font-semibold uppercase" : "text-red mt-4 mb-2 text-sm font-semibold uppercase"}>{result.status}</p>
                                        </div>
                                        <section className="grid">
                                            <p className="text-yellow text-sm">Nombre</p>
                                            <p className="text-white text-sm capitalize">{result.nombres + " " + result.apPat + " " + result.apMat}</p>
                                        </section>
                                        <section className="grid">
                                            <p className="text-yellow text-sm">RFC</p>
                                            <p className="text-white text-sm capitalize">{result.rfc}</p>
                                        </section>
                                        <section className="grid ">
                                            <p className="text-yellow text-sm">Sexo</p>
                                            <p className="text-white text-sm capitalize">{result.sexo}</p>
                                        </section>
                                        <section className="grid ">
                                            <p className="text-yellow text-sm">Correo Electrónico</p>
                                            <p className="text-white text-sm capitalize">{result.correo}</p>
                                        </section>
                                        <section className="grid ">
                                            <p className="text-yellow text-sm">Teléfono</p>
                                            <p className="text-white text-sm capitalize">{result.telefono}</p>
                                        </section>
                                    </section>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default ConsultaVendedor