import React, { useEffect } from 'react'
import APIInvoke from '../utils/APIInvoke'
import { useHistory, Link } from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import AdminTemplate from '../template/AdminTemplate';
import Toolbar from '../components/Toolbar'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '../components/Alert';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { maxFieldLength, onlyNumbers, rangeNumber } from "../utils/FormUtils";
import Visibility from '@material-ui/icons/Visibility';
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';

import {
    CLAVES_UPDATE_ROOT,
    CLAVES_UPDATE_CLAVE,
    CLAVES_ADD_CLAVE,
    CLAVES_DELETE_CLAVE
} from '../redux/redux-const'

const Claves = (props) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const state = useSelector(state => state.pages.claves)

    useEffect(async () => {

        try {
            dispatch({ type: CLAVES_UPDATE_ROOT, payload: { loading: true } })

            const response = await (await APIInvoke.invokeGET(`/aseguradoras/claves`)).json()
            const newState = response.body.reduce((previousValue, currentValue) => {
                return [
                    ...previousValue,
                    ...currentValue.claves
                ]
            }, [])

            newState.map(x => {
                x.tipos = [x.vida ? "vida" : "x", x.autos ? "autos" : "x", x.salud ? "salud" : "x", x.diversos ? "diversos" : "x"].filter(x => x !== "x")
                x.expanded = false
                x.sincronizacion = x.sincronizacionDalala
                x.portalPassword = ""
                x.portalClave = x.portalClave
                x.showPassword = false
                x.terminos = false
                x.aseguradoraId = x.aseguradora.id
                x.comisionVida = x.comisionVida || 0
                x.comisionAutos = x.comisionAutos || 0
                x.comisionSalud = x.comisionSalud || 0
                x.comisionDiversos = x.comisionDiversos || 0
                return x
            })

            const aseguradorasResponse = await (await APIInvoke.invokeGET(`/aseguradoras?size=99999&status=activo&order=aseguradora asc`)).json()
            dispatch({ type: CLAVES_UPDATE_ROOT, payload: { claves: newState, aseguradoras: aseguradorasResponse.body.items } })
            if (newState.length === 0) {
                dispatch({ type: CLAVES_ADD_CLAVE })
            }
        } catch (error) {
            console.error(error)
        } finally {
            dispatch({ type: CLAVES_UPDATE_ROOT, payload: { loading: false } })
        }

    }, [])

    const onChange = (e, index) => {
        let value = e.target.value
        const name = e.target.name
        if (e.target.type === "checkbox") {
            value = e.target.checked
        }
        if (name === "primas") {
            value = parseFloat(value.replaceAll(",", ""))
        }

        if (name === "comisionAutos" || name === "comisionDiversos" || name === "comisionSalud" || name === "comisionVida") {
            const preValue = value.replaceAll("-", "").trim()
            if (preValue === null || preValue.length === 0 || isNaN(preValue)) value = 0
            else value = parseInt(preValue)
        }

        dispatch({ type: CLAVES_UPDATE_CLAVE, index, payload: { [name]: value } })
    }

    const onChangeAccordion = (panel) => (e, isExpanded) => {
        dispatch({ type: CLAVES_UPDATE_ROOT, payload: { expanded: isExpanded ? panel : false } })
    }

    const addClave = () => {
        dispatch({ type: CLAVES_ADD_CLAVE })
    }

    const onSubmit = async (e, index) => {
        e.preventDefault()

        try {
            dispatch({ type: CLAVES_UPDATE_ROOT, payload: { loading: true } })

            const clave = state.claves[index]

            const request = {
                "aseguradora": clave.aseguradoraId,
                "clave": clave.clave,
                "vida": clave.tipos.includes("vida"),
                "autos": clave.tipos.includes("autos"),
                "salud": clave.tipos.includes("salud"),
                "diversos": clave.tipos.includes("diversos"),
                "portalClave": clave.portalClave,
                "portalPassword": clave.portalPassword,
                "sincronizacionDalala": clave.sincronizacion,
                "comisionVida": clave.comisionVida,
                "comisionAutos": clave.comisionAutos,
                "comisionSalud": clave.comisionSalud,
                "comisionDiversos": clave.comisionDiversos
            }
            const response = clave.id
                ? await (await APIInvoke.invokePUT(`/aseguradoras/accounts/${clave.id}`, request)).json()
                : await (await APIInvoke.invokePOST(`/aseguradoras/accounts`, request)).json()

            const message = {
                message: response.message,
                type: response.ok ? "success" : "error"
            }

            if (response.ok) {
                if (clave.id == undefined) {
                    dispatch({ type: CLAVES_UPDATE_ROOT, payload: { afterSaved: 1 } })
                }
                dispatch({ type: CLAVES_UPDATE_CLAVE, index, payload: { id: response.body.id, message } })
            } else {
                dispatch({ type: CLAVES_UPDATE_CLAVE, index, payload: { message } })
            }
        } catch (e) {
            console.error(e)
        } finally {
            dispatch({ type: CLAVES_UPDATE_ROOT, payload: { loading: false } })
        }
    }

    const setTipos = (newValues, index) => {
        dispatch({ type: CLAVES_UPDATE_CLAVE, index, payload: { tipos: newValues } })
    }

    const deleteClave = async () => {
        try {
            const clave = state.claves[state.deleteIndex]

            if (clave.id) {
                dispatch({ type: CLAVES_UPDATE_ROOT, payload: { loading: true } })


                const response = await (await APIInvoke.invokeDELETE(`/aseguradoras/accounts/${clave.id}`)).json()
                const message = {
                    message: response.message,
                    type: response.ok ? "success" : "error"
                }

                if (response.ok) {
                    dispatch({ type: CLAVES_DELETE_CLAVE, index: state.deleteIndex })
                } else {
                    dispatch({ type: CLAVES_UPDATE_ROOT, payload: { deleteIndex: null, message } })
                    dispatch({ type: CLAVES_UPDATE_CLAVE, index: state.deleteIndex, payload: { message } })
                }
            } else {
                dispatch({ type: CLAVES_DELETE_CLAVE, index: state.deleteIndex })
            }
        } catch (e) {
            console.error(e)
        } finally {
            dispatch({ type: CLAVES_UPDATE_ROOT, payload: { loading: false } })
        }
    }

    const render = () => {
        return (
            <AdminTemplate loading={state.loading}>
                <Toolbar title="Clave de agente" backUrl="/configuracion">
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        <p className="mb-4">Registra tus claves de agente</p>

                        <div className="grid grid-cols-1 gap-0">
                            {state.claves.map((clave, index) => {
                                const aseguradora = state.aseguradoras.find(x => x.id === clave.aseguradoraId)
                                return (
                                    <Accordion
                                        key={index}
                                        expanded={state.expanded === `panel-${index}`}
                                        onChange={onChangeAccordion(`panel-${index}`)}
                                        className="bg-dark-400">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className="text-white" />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header">
                                            <Typography >
                                                {aseguradora &&
                                                    <span className=" text-white">
                                                        <span className="pr-2">
                                                            <SelfImprovementIcon fontSize="medium" className={clave.sincronizacionDalala ? "text-yellow" : "text-gray-500"} />
                                                        </span>
                                                        {aseguradora.abreviacion}
                                                        <span className="text-yellow"> • </span>
                                                        <span className="font-thin ">{clave.clave}</span>
                                                    </span>}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <form onSubmit={e => onSubmit(e, index)}>
                                                <div className="grid grid-cols-1 gap-y-8">
                                                    <FormControl variant="outlined" size="medium" >
                                                        <InputLabel id="aseguradoraId" required>Aseguradora</InputLabel>
                                                        <Select
                                                            labelId="aseguradoraId"
                                                            label="Aseguradora"
                                                            size="medium"
                                                            name="aseguradoraId"
                                                            value={clave.aseguradoraId}
                                                            onChange={e => onChange(e, index)}
                                                        >
                                                            {state.aseguradoras.map(x => <MenuItem key={x.id} value={x.id} >{x.aseguradora}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                    {aseguradora && (
                                                        <div className="grid gap-4 grid-cols-1 ">
                                                            <p className="text-sm">
                                                                <PictureAsPdfIcon className={aseguradora.pdf ? "text-yellow mr-2" : "text-gray-200 mr-2"} /><span>{aseguradora.pdf ? <span className='text-green'>Cuenta con traducción de PDFs</span> : <span className='text-red'>No cuenta con traducción de PDFs</span>}</span>
                                                            </p>
                                                            <p className="text-sm">
                                                                <img src={aseguradora.rpa ? "/brand/dalala-icon.svg" : "/brand/dalala-icon-gray.svg"} className={aseguradora.rpa ? "inline-block text-yellow mr-2 h-5" : "inline-block text-red-800 mr-2 h-5"} />
                                                                {aseguradora.rpa ? (<span className='text-green'>Cuenta con sincronización <span className="text-yellow">dalala</span></span>) : (<span className='text-red' >No cuenta con sincronización <span className="text-yellow">dalala</span></span>)}
                                                            </p>
                                                        </div>
                                                    )}

                                                    <div className="grid gap-8 grid-cols-1 lg:grid-cols-2">
                                                        <TextField
                                                            name="clave"
                                                            label="Clave agente"
                                                            type="clave"
                                                            variant="outlined"
                                                            required
                                                            disabled={clave.id ? true : false}
                                                            value={clave.clave}
                                                            onChange={e => onChange(e, index)}
                                                            onInput={(e) => maxFieldLength(e, 15)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div >
                                                            <ToggleButtonGroup value={clave.tipos} name="tipos" onChange={(event, newValues) => setTipos(newValues, index)} aria-label="device" className="flex justify-around lg:justify-start">
                                                                <ToggleButton value="vida" aria-label="Vida" className="pill pill-fill lg:mr-4 rounded-full">Vida</ToggleButton>
                                                                <ToggleButton value="autos" aria-label="Autos" className="pill pill-fill lg:mr-4 rounded-full">Autos</ToggleButton>
                                                                <ToggleButton value="salud" aria-label="Salud" className="pill pill-fill lg:mr-4 rounded-full">Salud</ToggleButton>
                                                                <ToggleButton value="diversos" aria-label="Daños" className="pill pill-fill rounded-full">Daños</ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {aseguradora && (
                                                            <FormControlLabel
                                                                label={<p className={aseguradora.rpa ? " " : " text-gray-800"}> Habilitar Sincronización Automática <span className="text-yellow">dalala</span></p>}
                                                                required
                                                                control={
                                                                    <Checkbox disabled={!aseguradora.rpa} name="sincronizacion" onChange={e => onChange(e, index)} checked={clave.sincronizacion} color="primary" />
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    {clave.sincronizacion && (
                                                        <>
                                                            <div className="grid grid-cols-1 gap-8">
                                                                <TextField
                                                                    name="portalClave"
                                                                    label="Usuario del Portal de la Aseguradora"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    autoComplete="false"
                                                                    required
                                                                    value={clave.portalClave}
                                                                    onChange={e => onChange(e, index)}
                                                                    onInput={(e) => maxFieldLength(e, 100)}
                                                                />
                                                                <FormControl variant="outlined">
                                                                    <InputLabel htmlFor={`portalPassword-${index}`} required>Contraseña del Portal</InputLabel>
                                                                    <OutlinedInput
                                                                        id={`portalPassword-${index}`}
                                                                        name="portalPassword"
                                                                        variant="outlined"
                                                                        autoComplete="false"
                                                                        type={clave.showPassword ? 'text' : 'password'}
                                                                        value={clave.portalPassword}
                                                                        onChange={e => onChange(e, index)}
                                                                        onInput={(e) => maxFieldLength(e, 100)}
                                                                        required
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    name="showPassword"
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={e => dispatch({ type: CLAVES_UPDATE_CLAVE, index, payload: { showPassword: !clave.showPassword } })}
                                                                                    edge="end"
                                                                                >
                                                                                    {clave.showPassword ? <Visibility className="text-gray-400" /> : <VisibilityOff className="text-gray-400" />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </div>

                                                            <div className="grid grid-cols-1 gap-4">
                                                                <p className="text-center text-yellow">Reglas de Sincronización</p>
                                                                <p className="text-sm">La primer sincronización se realizará <strong className="text-yellow">15 minutos</strong> después de salvar los datos de acceso al portal de la aseguradora</p>
                                                                <p className="text-sm">Posterior a este evento se repetirá en base a los días indicados en el plan que tengas contratado</p>
                                                                <p className="text-yellow">IMPORTANTE</p>
                                                                <p className="text-sm">Dalala se compromete ha realizar el mayor esfuerzo posible por completar cada evento de manera satisfactoria, sin embargo, el servicio de sincronización
                                                                    se ofrece <span className="underline text-yellow">sin garantías de ningún tipo</span> al depender 100% de la <span className=" underline text-yellow ">disponibilidad y respuesta oportuna</span> de las plataformas de las aseguradoras,
                                                                    por esta razón, el costo del servicio no esta relacionado de forma directa con el éxito de cada evento de sincronización al ser un servicio opcional.</p>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="grid grid-cols-1 gap-4">
                                                        <div>
                                                            <span className="mr-2">Comisiones:</span>
                                                            <Tooltip title="Indica el porcentaje default de comisiones en cada ramo, el valor de cada póliza puede ser actualizado durante su captura.">
                                                                <HelpOutlineIcon className="text-gray-600" />
                                                            </Tooltip>
                                                        </div>

                                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                                            <TextField
                                                                name="comisionVida"
                                                                label="Vida"
                                                                type="text"
                                                                variant="outlined"
                                                                autoComplete="false"
                                                                required
                                                                inputProps={{ style: { textAlign: 'right' } }}
                                                                value={clave.comisionVida}
                                                                onChange={e => onChange(e, index)}
                                                                onInput={(e) => { onlyNumbers(e, 3); rangeNumber(e, 0, 100); }}
                                                                onFocus={e => e.target.select()}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                }}
                                                            />
                                                            <TextField
                                                                name="comisionAutos"
                                                                label="Autos"
                                                                type="text"
                                                                variant="outlined"
                                                                autoComplete="false"
                                                                required
                                                                inputProps={{ style: { textAlign: 'right' } }}
                                                                value={clave.comisionAutos}
                                                                onChange={e => onChange(e, index)}
                                                                onInput={(e) => { onlyNumbers(e, 3); rangeNumber(e, 0, 100); }}
                                                                onFocus={e => e.target.select()}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                }}
                                                            />
                                                            <TextField
                                                                name="comisionSalud"
                                                                label="Salud"
                                                                type="text"
                                                                variant="outlined"
                                                                autoComplete="false"
                                                                required
                                                                inputProps={{ style: { textAlign: 'right' } }}
                                                                value={clave.comisionSalud}
                                                                onChange={e => onChange(e, index)}
                                                                onInput={(e) => { onlyNumbers(e, 3); rangeNumber(e, 0, 100); }}
                                                                onFocus={e => e.target.select()}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                }}
                                                            />
                                                            <TextField
                                                                name="comisionDiversos"
                                                                label="Daños"
                                                                type="text"
                                                                variant="outlined"
                                                                autoComplete="false"
                                                                required
                                                                inputProps={{ style: { textAlign: 'right' } }}
                                                                value={clave.comisionDiversos}
                                                                onChange={e => onChange(e, index)}
                                                                onInput={(e) => { onlyNumbers(e, 3); rangeNumber(e, 0, 100); }}
                                                                onFocus={e => e.target.select()}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <FormControlLabel
                                                            label={<p>Acepto <a href="/terminos-condiciones" target="_blank" className="text-yellow no-underline">Términos y Condiciones</a></p>}
                                                            required
                                                            control={
                                                                <Checkbox name="terminos" onChange={e => onChange(e, index)} checked={clave.terminos} color="primary" />
                                                            }
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-4">
                                                        <Button onClick={e => dispatch({ type: CLAVES_UPDATE_ROOT, payload: { deleteIndex: index } })} className="btn btn-yellow bg-red-500 text-white font-bold">ELIMINAR</Button>
                                                        <Button type="submit" disabled={!clave.terminos} className="btn btn-yellow" >GUARDAR</Button>
                                                    </div>
                                                    {clave.message && <Alert type={clave.message.type} message={clave.message.message} />}
                                                </div>
                                            </form>

                                            {
                                                state.deleteIndex !== null && (
                                                    <Dialog
                                                        open={state.deleteIndex !== null}
                                                        onClose={e => dispatch({ type: CLAVES_UPDATE_ROOT, payload: { deleteIndex: null } })}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                    >
                                                        <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la Clave de Agente?</DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button className="mr-4" onClick={e => dispatch({ type: CLAVES_UPDATE_ROOT, payload: { deleteIndex: null } })}>CANCELAR</Button>
                                                            <Button className="bg-red-500 text-white" onClick={e => deleteClave(e)} autoFocus>ACEPTAR</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                )
                                            }
                                            {
                                                state.afterSaved !== null && state.afterSaved > 0 && (
                                                    <Dialog
                                                        open={state.afterSaved !== null}
                                                        onClose={e => dispatch({ type: CLAVES_UPDATE_ROOT, payload: { afterSaved: null } })}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                    >
                                                        <DialogContent>
                                                            <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Deseas registrar otra Clave de Agente?</DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions className="justify-center">
                                                            <Button className="btn-yellow font-bold w-32" onClick={e => { dispatch({ type: CLAVES_UPDATE_ROOT, payload: { afterSaved: null } }); addClave() }}>Si</Button>
                                                            <Button className="btn-yellow font-bold w-32" onClick={e => dispatch({ type: CLAVES_UPDATE_ROOT, index, payload: { isSynchronization: clave.sincronizacion } })} autoFocus>No</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                )
                                            }
                                            {
                                                (state.isSynchronization == true || state.isSynchronization == false) && (
                                                    <Dialog
                                                        open={state.isSynchronization !== null}
                                                        onClose={e => dispatch({ type: CLAVES_UPDATE_ROOT, payload: { isSynchronization: null } })}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                    >
                                                        <DialogContent>
                                                            <div className="flex justify-center items-center text-center pt-4">
                                                                <img src="/brand/dalala-logo-sm.svg" alt="dalala" className="inline-block lg:hidden mt-2 mb-5 " />
                                                                <img src="/brand/dalala-logo-lg.svg" alt="dalala" className="hidden lg:inline-block" />
                                                            </div>
                                                            {state.isSynchronization === true ? <DialogContentText className="text-gray-400 pt-4 w-72 text-center" id="alert-dialog-description">Activaste la <span className="text-yellow font-bold">sincronización automática</span>, en unos minutos recibirás por correo el resultado del primer evento, te recomendamos esperar antes de iniciar la captura</DialogContentText> : <DialogContentText className="text-gray-400 pt-4 w-72 text-center" id="alert-dialog-description">No activaste la <span className="text-yellow font-bold">sincronización automática</span>, no hay problema, puedes integrar tus pólizas importando los PDFs originales o capturándolas manualmente, cualquiera que sea el caso iniciarás en la opción de Pólizas del menú de la Aplicación</DialogContentText>}
                                                        </DialogContent>
                                                        <DialogActions className="justify-center pb-6">
                                                            <Button className="btn-yellow font-bold w-32" onClick={e => dispatch({ type: CLAVES_UPDATE_ROOT, payload: { isSynchronization: null, afterSaved: null } })} component={Link} to="/resumen">ACEPTAR</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                )
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                            <Button onClick={addClave} type="button" className="btn btn-yellow mt-8 font-bold"><AddBoxIcon className="mr-2" /> Agregar Clave de Agente</Button>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default Claves