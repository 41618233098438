import {
    NUEVO_SINIESTRO_RESET,
    NUEVO_SINIESTRO_SET,
} from "../redux-const"

const initialState = {
    loading: false,
    noSiniestro: "PENDIENTE",
    clienteId: "",
    polizaId: "",
    fechaSiniestro: null,
    fechaEstimadaResolucion: null,
    descripcion: "",
    status: "captura",
    asegurados: [],
    poliza: null,
    polizas: [],
    detallePoliza: null,
    cliente: null,
    clientes: [],
    clienteAutocompleteLoading: false,
    openAutocompleteCliente: false,
}

export const reducer = (state = initialState, action) => {
    const { payload } = action

    switch (action.type) {
        case NUEVO_SINIESTRO_RESET: {
            return initialState
        }
        case NUEVO_SINIESTRO_SET: {
            return { ...state, ...payload }
        }
    }

    return state
}

export default reducer