import React from 'react'
import { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import AdminTemplate from '../../template/AdminTemplate';
import Toolbar from '../../components/Toolbar'
import toast from 'react-hot-toast';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import APIInvoke from '../../utils/APIInvoke';
import { hasValue } from '../../utils/StringUtils';
import DeleteIcon from '@mui/icons-material/Delete';
import NumberFormat from 'react-number-format';


export default function NuevaMoneda(props) {

    const history = useHistory()
    const { monedaId } = useParams()
    const [loading, setLoading] = useState(false)
    const [monedas, setMonedas] = useState([])
    const [moneda, setMoneda] = useState({
        moneda: "",
        tipoCambio: "0.00",
        formato: "###,##0.00"
    })
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })


    useEffect(() => {
        fetchMonedas()
    }, [])

    const fetchMonedas = async (id) => {
        try {
            setLoading(true)
            const rawResponse = await (await APIInvoke.invokeGET(`/monedas/all`)).json()
            setMonedas(rawResponse.body)

            if (monedaId) {
                const monedaResponse = await (await APIInvoke.invokeGET(`/monedas/${monedaId}`)).json()
                setMoneda({
                    id: monedaId,
                    moneda: monedaResponse.body.abreviacion,
                    tipoCambio: monedaResponse.body.tipoCambio,
                    formato: monedaResponse.body.formato
                })
            }
        } catch (error) {
            toast.error("Error al cargar la página")
            console.error("Error al inicializar la página", error)
        } finally {
            setLoading(false)
        }
    }

    const onChange = (e) => {
        setMoneda({
            ...moneda,
            [e.target.name]: e.target.value
        })
    }

    const save = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            const request = {
                id: moneda.id,
                moneda: moneda.moneda,
                tipoCambio: moneda.tipoCambio,
                formato: moneda.formato
            }

            const rawResponse = monedaId
                ? await (await APIInvoke.invokePUT(`/monedas`, request)).json()
                : await (await APIInvoke.invokePOST(`/monedas`, request)).json()
            if (!rawResponse.ok) {
                toast.error(rawResponse.message)
                return
            }

            setMoneda({
                ...moneda,
                id: rawResponse.body.id
            })

            toast.success("Guardado exitosamente")
            history.push(`/monedas`)
        } catch (error) {
            console.error("Error al guardar ", error)
            toast.success("Error al guardar")
        } finally {
            setLoading(false)
        }
    }

    const deleteMoneda = async () => {
        try {
            setOpenDeleteDialog({ id: null, show: false })
            setLoading(true)

            const rawResponse = await (await APIInvoke.invokeDELETE(`/monedas/${moneda.id}`)).json()
            if (!rawResponse.ok) {
                toast.error(rawResponse.message)
                return
            }

            toast.success("Moneda eliminada exitosamente")
            history.push("/monedas")

        } catch (e) {
            toast.error("Error al eliminar la moneda")
            console.error("Error al eliminar la moneda", e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <AdminTemplate loading={loading} modal={props.modal}>
            <Dialog
                open={openDeleteDialog.show}
                onClose={e => setOpenDeleteDialog({ show: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                <DialogContent>
                    <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la moneda?, esta acción es irreversible.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                    <Button className="bg-red-500 text-white" onClick={deleteMoneda} autoFocus>ACEPTAR</Button>
                </DialogActions>
            </Dialog>
            <Toolbar title="Monedas" backUrl={`/monedas`} modal={props.modal}>
                <div className="layout">
                    <div className="flex justify-end">
                        <Tooltip title="Eliminar">
                            <IconButton className="btn btn-yellow" onClick={e => setOpenDeleteDialog({ show: true, id: moneda.id })}>
                                <DeleteIcon className="text-dark-600" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>

            <div className="main center">
                <div className="layout-compact">
                    <form onSubmit={save}>
                        <div className="grid gap-4">
                            <FormControl variant="outlined" size="medium" >
                                <InputLabel id="moneda" required>Moneda</InputLabel>
                                <Select
                                    disabled={hasValue(monedaId)}
                                    labelId="moneda"
                                    label="Moneda"
                                    size="medium"
                                    name="moneda"
                                    value={moneda.moneda}
                                    onChange={onChange}
                                >
                                    {monedas.map(moneda => (
                                        <MenuItem key={moneda.id} value={moneda.abreviacion}>{moneda.abreviacion} - {moneda.moneda}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <div className="grid md:grid-cols-2 gap-4">
                                <NumberFormat
                                    name="tipoCambio"
                                    value={moneda.tipoCambio}
                                    onChange={onChange}
                                    customInput={TextField}
                                    onFocus={e => e.target.select()}
                                    label="Tipo de cambio"
                                    variant="outlined"
                                    size="medium"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    allowNegative={false}
                                    isNumericString={true}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <FormControl variant="outlined" size="medium" >
                                    <InputLabel id="formato" required>Formato</InputLabel>
                                    <Select
                                        labelId="formato"
                                        label="Formato"
                                        size="medium"
                                        name="formato"
                                        value={moneda.formato}
                                        onChange={onChange}
                                    >
                                        <MenuItem value="###,##0.00">#,##0.00 (Punto para decimales)</MenuItem>
                                        <MenuItem value="###.##0,00">#.##0,00 (Coma para decimales)</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <Button type="submit" className="btn btn-yellow">GUARDAR MONEDA</Button>
                        </div>
                    </form>
                </div>
            </div>
        </AdminTemplate>
    )
}