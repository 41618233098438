import React, { useContext } from 'react'

import UserContext from '../state/UserContext';
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom'

import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SettingsIcon from '@mui/icons-material/Settings';

const Landing = (props) => {

    const { user } = useContext(UserContext);

    const render = () => {
        return (
            <div >
                <Navbar showBackButton={false} />

                <div className="mt-8 container m-auto grid grid-cols-1 lg:grid-cols-3 lg:mt-32">
                    <div className="lg:col-start-2 px-8 lg:px-0">
                        <section className="mb-4 pb-4">
                            <p className="text-gray-400 mb-5 grid justify-items-center">Bienvenido {user.nombres}</p>
                            <p className="text-gray-400 text-center">Hay 3 formas de integrar la información de tu cartera a<a className="no-underline mt-1 ml-1">dalala</a> </p>
                        </section>

                        <section>
                            <p className="text-lg text-gray-100 mb-5 "> 1. Captura tus pólizas manualmente</p>
                        </section>

                        <section className="flex flex-row gap-2 lg:items-center mb-2 pb-2">
                            <div className='flex items-center pb-6'>
                                <EditIcon className="text-yellow"></EditIcon>
                            </div>
                            <p className="text-lg text-gray-700 mb-5 pl-2 pr-2 "> Proceso sencillo que solicita lo indispensable para operar de manera profesional</p>
                        </section>

                        <section>
                            <p className="text-lg text-gray-100 mb-5 "> 2. Procesa los PDFs* originales de las carátulas</p>
                        </section>

                        <section className="flex flex-row gap-2 lg:items-center mb-2 pb-2">
                            <div className='flex items-center pb-6'>
                                <PictureAsPdfIcon className="text-yellow"></PictureAsPdfIcon>
                            </div>
                            <p className="text-lg text-gray-700 mb-5 pl-2 pr-2 "> Contamos con más de 60 formatos traducidos de las aseguradoras mas importantes de México</p>
                        </section>

                        <section>
                            <p className="text-lg text-yellow mb-5 "> 3. Automatiza la captura conectando dalala a tu portal de la aseguradora*</p>
                        </section>

                        <section className="flex flex-row gap-2 lg:items-center">
                            <div className='flex items-center pb-6'>
                                <SettingsIcon className="text-yellow"></SettingsIcon>
                            </div>
                            <p className="text-lg text-gray-700 mb-5 pl-2 pr-2 "> Puedes confirmar la calidad de la información sincronizada por el proceso y actualizarla si así lo deseas</p>
                        </section>

                        <section className="flex flex-row gap-2 lg:items-center">
                            <p className="text-md pl-10 pr-2 pb-8">* Disponible solo con algunas aseguradoras</p>
                        </section>

                        <div className="flex justify-center items-center text-center ">
                            <img src="/works/69.png" />
                        </div>
                            
                        <div>
                            <section className="grid grid-cols-1 gap-8 px-8 lg:px-0 mb-8">
                            <p className="text-yellow font-bold pt-4 mb-5 ml-4 mr-4 lg:top-2 lg:text-xl grid text-center">¿ Listo para Empezar ?</p>
                                <Link to={"/configuracion/claves"} className="btn btn-yellow btn-block no-underline  text-center" color="primary">Iniciar Prueba</Link>
                            </section>
                        </div>
                    </div>
                </div>


                <div className="mt-8 m-auto grid grid-cols-1 lg:grid-cols-3 lg:mt-32 bg-white">
                </div>
                <Footer />
            </div >
        )
    }

    return render()
}

export default Landing