import React, { useEffect, useState } from 'react'
import APIInvoke from '../utils/APIInvoke'

const EnvWarning = (props) => {

    const [meta, setMeta] = useState(null)

    useEffect(() => {
        const init = async () => {
            const meta = await (await APIInvoke.invokeGET(`/api-info`)).json()
            setMeta(meta.body)
        }
        init()
    }, [])

    const render = () => {

        const style = {
            background: "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
            animation: "env-gradient 15s ease infinite",
            backgroundSize: "400% 400%"
        }

        return meta && meta.env !== "prod" ? (
            <div className="p-2 text-center font-bold " style={style}>{`Env=${meta.env} APIVersion=${meta.version}`}</div>)
            : null
    }

    return render()
}

export default EnvWarning