import { addYear, parse } from "../../utils/DateUtils";
import {
  NUEVA_POLIZA_RESET_STATE,
  NUEVA_POLIZA_ONCHANGE,
  NUEVA_POLIZA_ONCHANGE_TYPE,
  NUEVA_POLIZA_ONCHANGE_GENERALES,
  NUEVA_POLIZA_ONCHANGE_VEHICULO,
  NUEVA_POLIZA_ONCHANGE_PRIMAS,
  NUEVA_POLIZA_ONCHANGE_ASEGURADO,
  NUEVA_POLIZA_ONCHANGE_RECIBOS,
  NUEVA_POLIZA_ONCHANGE_DETALLES,
  NUEVA_POLIZA_ONCHANGE_STEPPER,
  NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT,
  NUEVA_POLIZA_LOAD_CARATULA,
  NUEVA_POLIZA_AGREGAR_ASEGURADO,
  NUEVA_POLIZA_REMOVER_ASEGURADO,
  NUEVA_POLIZA_APLICAR_TODOS_RECIBOS,
  NUEVA_POLIZA_APLICAR_TODOS_REC,
  NUEVA_POLIZA_ONCHANGE_COMISIONES,
  EDITAR_POLIZA,
  RENOVAR_POLIZA,
  POLIZA_UPDATE_STATUS,
  NUEVA_POLIZA_ONCHANGE_CLIENTE_ES_ASEGURADO
} from "../redux-const";

import AppsIcon from "@mui/icons-material/Apps";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MoneyIcon from "@mui/icons-material/Money";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@material-ui/icons/Home";
import { DateTime } from "luxon";

const vehiculoJourney = [
  {
    id: "generales",
    label: "Datos Generales",
    active: true,
    completed: false,
    icon: <AppsIcon fontSize="large" />,
    first: true,
    last: false,
    path: "",
  },
  {
    id: "vehiculo",
    label: "Vehículo",
    active: false,
    completed: false,
    icon: <DriveEtaIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/vehiculo",
  },
  {
    id: "primas",
    label: "Primas",
    active: false,
    completed: false,
    icon: <MonetizationOnIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/primas",
  },
  {
    id: "recibos",
    label: "Recibos",
    active: false,
    completed: false,
    icon: <MoneyIcon fontSize="large" />,
    first: false,
    last: true,
    path: "/recibos",
  },
];

const vidaJourney = [
  {
    id: "generales",
    label: "Datos Generales",
    active: true,
    completed: false,
    icon: <AppsIcon fontSize="large" />,
    first: true,
    last: false,
    path: "",
  },
  {
    id: "asegurado",
    label: "Asegurado",
    active: false,
    completed: false,
    icon: <PersonIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/asegurado",
  },
  {
    id: "primas",
    label: "Primas",
    active: false,
    completed: false,
    icon: <MonetizationOnIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/primas",
  },
  {
    id: "recibos",
    label: "Recibos",
    active: false,
    completed: false,
    icon: <MoneyIcon fontSize="large" />,
    first: false,
    last: true,
    path: "/recibos",
  },
];

const saludJourney = [
  {
    id: "generales",
    label: "Datos Generales",
    active: true,
    completed: false,
    icon: <AppsIcon fontSize="large" />,
    first: true,
    last: false,
    path: "",
  },
  {
    id: "asegurados",
    label: "Asegurados",
    active: false,
    completed: false,
    icon: <PersonIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/asegurados",
  },
  {
    id: "primas",
    label: "Primas",
    active: false,
    completed: false,
    icon: <MonetizationOnIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/primas",
  },
  {
    id: "recibos",
    label: "Recibos",
    active: false,
    completed: false,
    icon: <MoneyIcon fontSize="large" />,
    first: false,
    last: true,
    path: "/recibos",
  },
];

const diversosJourney = [
  {
    id: "generales",
    label: "Datos Generales",
    active: true,
    completed: false,
    icon: <AppsIcon fontSize="large" />,
    first: true,
    last: false,
    path: "",
  },
  {
    id: "diversos",
    label: "Diversos",
    active: false,
    completed: false,
    icon: <HomeIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/diversos",
  },
  {
    id: "primas",
    label: "Primas",
    active: false,
    completed: false,
    icon: <MonetizationOnIcon fontSize="large" />,
    first: false,
    last: false,
    path: "/primas",
  },
  {
    id: "recibos",
    label: "Recibos",
    active: false,
    completed: false,
    icon: <MoneyIcon fontSize="large" />,
    first: false,
    last: true,
    path: "/recibos",
  },
];

const ramos = {
  1: "autos" || "auto",
  3: "salud",
  5: "vida",
  7: "diversos",
};

const initialState = {
  newType: "vida",
  ramoBlocked: false,
  smartReciboCalculate: true,
  clienteEsAsegurado: false,
  currentRec: 0,
  currency: [],
  aseguradoras: [],
  contratantes: [],
  vendedores: [],
  conductos: [],
  currentAseg: null,
  vehiculos: [],
  formaPago: [
    { key: "contado", value: "Contado", parcialidades: 1 },
    { key: "semestral", value: "Semestral", parcialidades: 2 },
    { key: "trimestral", value: "Trimestral", parcialidades: 4 },
    { key: "mensual", value: "Mensual", parcialidades: 12 },
  ],
  genero: [
    { key: "hombre", value: "Hombre" },
    { key: "mujer", value: "Mujer" },
  ],
  parentesco: [
    { key: "titular", value: "Titular" },
    { key: "conyuge", value: "Cónyuge" },
    { key: "hijo", value: "Hijo(a)" },
    { key: "otro", value: "Otro" },
  ],

  stepper: {
    activeStep: 0,
    lastStep: 0,
    steps: vidaJourney,
  },
  generales: {
    contratante: null,
    aseguradora: "",
    contratanteId: "",
    agente: "",
    vendedor: "",
    conducto: "CXC",
    concepto: "",
    fecFinal: DateTime.now().plus({ year: 1 }).toJSDate(),
    fecInicial: new Date(),
    moneda: "",
    plan: "",
    poliza: "",
    formaPago: "",
    recibos: 0,
    primerRecibo: 0.0,
    reciboSubsecuente: 0.0,
  },
  vehiculo: {
    modelo: new Date().getFullYear(),
    descripcion: null,
    noSerie: "",
    placas: "",
  },
  primas: {
    primaNeta: 0.0,
    derecho: 0.0,
    financiamiento: 0.0,
    iva: 0.0,
    primaTotal: 0.0,
  },
  recibos: [],
  asegurados: [
    {
      parentesco: "titular",
      name: "",
      apPat: "",
      apMat: "",
      nacimiento: null,
      rfc: "",
      genero: "",
      expanded: true
    },
  ],
  detalles: {
    texto: "",
  },
  texto: "",
  pages: {},
  comisiones: {
    porcentajeComision: 0,
    comisiones: 0
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NUEVA_POLIZA_RESET_STATE:
      initialState.asegurados[0].name = "";
      initialState.asegurados[0].apPat = "";
      initialState.asegurados[0].apMat = "";
      initialState.asegurados[0].nacimiento = null;
      initialState.asegurados[0].rfc = "";
      initialState.asegurados[0].genero = "";
      return initialState;
    case NUEVA_POLIZA_ONCHANGE:
      return {
        ...state,
        ...action.payload,
      };

    case NUEVA_POLIZA_ONCHANGE_TYPE:
      let journey = null;
      if (action.payload.newType === "vida") {
        journey = vidaJourney;
      } else if (action.payload.newType === "autos") {
        journey = vehiculoJourney;
      } else if (action.payload.newType === "salud") {
        journey = saludJourney;
      } else if (action.payload.newType === "diversos") {
        journey = diversosJourney;
      }

      return {
        ...state,
        newType: action.payload.newType,
        stepper: {
          ...state.stepper,
          steps: journey,
        },
      };

    case NUEVA_POLIZA_ONCHANGE_GENERALES:
      return {
        ...state,
        generales: {
          ...state.generales,
          ...action.payload,
        }
      };

    case NUEVA_POLIZA_ONCHANGE_VEHICULO:
      return {
        ...state,
        vehiculo: {
          ...state.vehiculo,
          ...action.payload,
        },
      };

    case NUEVA_POLIZA_ONCHANGE_COMISIONES: {
      const payload = action.payload || {}
      let newState = state
      if (state.generales.agente && (!state.comisiones || payload.force)) {
        const claves = state.aseguradoras.find((item) => item.aseguradora.id === state.generales.aseguradora)?.claves || []
        const claveAgente = claves.find(clave => clave.id === state.generales.agente)
        const porcentajeComision = state.newType === 'vida' ? claveAgente.comisionVida
          : state.newType === 'autos' ? claveAgente.comisionAutos
            : state.newType === 'salud' ? claveAgente.comisionSalud
              : state.newType === 'diversos' ? claveAgente.comisionDiversos
                : 0

        newState = {
          ...newState,
          comisiones: {
            porcentajeComision: porcentajeComision,
            comisiones: Number.parseFloat(((porcentajeComision * state.primas.primaNeta) / 100.0).toFixed(2))
          }
        }
      }

      if (payload.porcentajeComision) {
        newState = {
          ...newState,
          comisiones: {
            porcentajeComision: payload.porcentajeComision,
            comisiones: Number.parseFloat(((payload.porcentajeComision * state.primas.primaNeta / 100.0)).toFixed(2))
          }
        }
      }

      if (payload.comisiones) {
        newState = {
          ...newState,
          comisiones: {
            porcentajeComision: Number.parseFloat(((payload.comisiones * 100.0) / state.primas.primaNeta).toFixed(2)),
            comisiones: payload.comisiones
          }
        }
      }

      return newState
    }

    case NUEVA_POLIZA_ONCHANGE_PRIMAS: {
      return {
        ...state,
        primas: {
          ...state.primas,
          ...action.payload
        },
        comisiones: {
          ...state.comisiones,
          comisiones: Number.parseFloat(((state.comisiones.porcentajeComision * state.primas.primaNeta / 100.0)).toFixed(2))
        }
      }
    }
    case NUEVA_POLIZA_ONCHANGE_ASEGURADO:
      const aseguradoIndex = action.payload.index;
      let currentAsegurado = state.asegurados[aseguradoIndex];

      currentAsegurado = {
        ...currentAsegurado,
        ...action.payload,
        index: undefined,
      };

      state.asegurados[aseguradoIndex] = currentAsegurado;

      return {
        ...state,
        asegurados: [...state.asegurados],
      };

    case NUEVA_POLIZA_ONCHANGE_RECIBOS: {
      const payload = action.payload
      const currentIndex = state.recibos.findIndex((x) => x.serie === payload.serie)
      const currentRecibo = state.recibos[currentIndex];

      const totalSerie = currentRecibo.totalSerie - 1;
      const primaNeta = payload.hasOwnProperty("primaNeta") ? payload.primaNeta : currentRecibo.primaNeta;
      const derecho = payload.hasOwnProperty("derecho") ? payload.derecho : currentRecibo.derecho;
      const financiamiento = payload.hasOwnProperty("financiamiento") ? payload.financiamiento : currentRecibo.financiamiento;
      const primaTotal = primaNeta + derecho + financiamiento;
      const iva = payload.hasOwnProperty("iva") ? payload.iva : primaTotal * 0.16;

      if (state.smartReciboCalculate === true) {
        Object.assign(currentRecibo, {
          ...payload,
          iva,
          primaTotal: primaTotal + iva,
        });

        const newRecibos = [
          state.recibos[0],
          ...state.recibos
            .filter((item, index) => index > 0)
            .map((r) => {
              const pn = (state.primas.primaNeta - primaNeta) / totalSerie;
              const d = (state.primas.derecho - derecho) / totalSerie;
              const f =
                (state.primas.financiamiento - financiamiento) / totalSerie;
              const pt = pn + d + f;
              const iv = pt * 0.16;

              return {
                ...r,
                primaNeta: pn,
                derecho: d,
                financiamiento: f,
                iva: iv,
                primaTotal: pt + iv,
              };
            }),
        ];

        return {
          ...state,
          recibos: newRecibos,
        };
      } else {
        Object.assign(currentRecibo, {
          ...action.payload,
          primaNeta,
          derecho,
          financiamiento,
          iva,
          primaTotal: primaTotal + iva,
        });

        return {
          ...state,
          recibos: [...state.recibos],
        }
      }
    }
    case NUEVA_POLIZA_ONCHANGE_DETALLES:
      return {
        ...state,
        ...action.payload,
        /* detalles: {
          ...state.detalles,
          ...action.payload,
        }, */
      };

    case NUEVA_POLIZA_ONCHANGE_STEPPER:
      return {
        ...state,
        stepper: {
          ...state.stepper,
          ...action.payload,
        },
      };

    case NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT:
      let activeStep = state.stepper.activeStep;
      let currentStep = state.stepper.steps[activeStep];
      currentStep.completed = true;
      currentStep.active = false;

      if (!currentStep.last) {
        const nextStep = state.stepper.steps[activeStep + 1];
        nextStep.active = true;
      }

      return {
        ...state,
        stepper: {
          ...state.stepper,
          activeStep: activeStep + 1,
          lastStep:
            activeStep + 1 > state.stepper.lastStep
              ? activeStep + 1
              : state.stepper.lastStep,
          steps: [...state.stepper.steps],
        },
      };

    case NUEVA_POLIZA_LOAD_CARATULA:
      const payload = action.payload;
      const { cliente, vehiculo, recibos } = action.payload;
      const ramo = ramos[payload.ramo];

      let journeys = null;
      if (ramo === "vida") {
        journeys = vidaJourney;
      } else if (ramo === "autos") {
        journeys = vehiculoJourney;
      } else if (ramo === "salud") {
        journeys = saludJourney;
      } else if (ramo === "diversos") {
        journeys = diversosJourney;
      }

      const asegurados = payload.asegurados
        ? payload.asegurados.map(x => {
          return {
            parentesco: x.parentesco,
            name: x.nombres,
            apPat: x.apPat,
            apMat: x.apMat,
            nacimiento: x.fecNacimiento ? parse(x.fecNacimiento, "yyyy-MM-dd") : null,
            rfc: x.rfc,
            genero: x.genero,
            expanded: true
          }
        })
        : null

      return {
        ...state,
        ramoBlocked: true,
        smartReciboCalculate: true,
        prorratearRecargo: payload.prorratearRecargo,
        prorratearDerecho: payload.prorratearDerecho,
        newType: ramo,
        stepper: {
          ...state.stepper,
          steps: journeys,
        },
        contratantes: [
          {
            id: cliente.id || "",
            nombres: cliente.nombres || "",
            apPat: cliente.apPat || "",
            apMat: cliente.apMat || "",
            fecNacimiento: parse(cliente.fecNacimiento, "yyyy-MM-dd"),
            //fecRegistro: cliente.fecRegistro,
            fiscal: cliente.fiscal,
            rfc: cliente.rfc,
            edoCivil: cliente.edoCivil,
            sexo: cliente.sexo,
            celular: cliente.celular,
            correo: cliente.correo,
            direccion: cliente.direccion?.direccion,
            cp: cliente.direccion?.cp,
            tipoDireccion: cliente.direccion?.tipo,
            esRobot: true
          },
        ],
        vehiculos: ramo === "autos" ? [
          {
            id: vehiculo.id,
            descripcion: vehiculo.descripcion,
            marca: vehiculo.marca,
            tipo: vehiculo.tipo,
            modelo: vehiculo.modelo,
            amis: vehiculo.amis,
          },
        ] : undefined,
        generales: {
          agente: payload.claveAgente,
          contratante: {
            id: cliente.id || "",
            nombres: cliente.nombres,
            apPat: cliente.apPat,
            apMat: cliente.apMat,
            fecNacimiento: parse(cliente.fecNacimiento, "yyyy-MM-dd"),
            //fecRegistro: cliente.fecRegistro,
            fiscal: cliente.fiscal,
            rfc: cliente.rfc,
            edoCivil: cliente.edoCivil,
            sexo: cliente.sexo,
            direccion: cliente.direccion?.direccion,
            cp: cliente.direccion?.cp,
            tipoDireccion: cliente.direccion?.tipo,
            esRobot: cliente.esRobot
          },
          concepto: payload.concepto,
          fecFinal: parse(payload.fechaVigenciaA, "yyyy-MM-dd"),
          fecInicial: parse(payload.fechaVigenciaDe, "yyyy-MM-dd"), //new Date(payload.fechaVigenciaDe),
          moneda: payload.moneda,
          plan: `${payload.paquete}`.substring(0, 50),
          poliza: payload.noPoliza,
          formaPago: payload.formaPago,
          recibos: payload.recibos ? payload.recibos.length : 0,
          primerRecibo: 0,
          reciboSubsecuente: 0,
          contratanteId: cliente.id || "",
          aseguradora: payload.aseguradora,
          esRobot: true
        },
        vehiculo: ramo === "autos" ? {
          id: vehiculo.id,
          modelo: vehiculo.modelo,
          descripcion: {
            descripcion: vehiculo.descripcion,
            marca: vehiculo.marca,
            tipo: vehiculo.tipo,
            modelo: vehiculo.modelo,
            amis: vehiculo.amis,
          },
          noSerie: vehiculo.noSerie,
          placas: vehiculo.placas,
        } : undefined,
        primas: {
          primaNeta: payload.prima,
          derecho: payload.derecho,
          financiamiento: payload.recargo,
          iva: payload.iva,
          primaTotal: payload.total,
        },
        //recibos: [],
        /*recibos: recibos ? recibos.map((recibo, index) => {
          return {
            serie: recibo.serie,
            primaNeta: recibo.prima,
            derecho: recibo.derecho,
            financiamiento: recibo.recargo,
            iva: recibo.iva,
            primaTotal: recibo.total,
            status: recibo.status,
            fecInicial: parse(recibo.fecVigDe, "yyyy-MM-dd"),
            fecFinal: parse(recibo.fecVigA, "yyyy-MM-dd"),
            vencimiento: parse(recibo.fecVence, "yyyy-MM-dd"),
          };
        }) : [],*/
        asegurados,
        caratula: payload.caratula,
        comisiones: {
          porcentajeComision: payload.porcentajeComision,
          comisiones: payload.comisiones
        }
      };

    case NUEVA_POLIZA_AGREGAR_ASEGURADO:
      const nuevoAsegurado = {
        parentesco: "",
        nombres: "",
        apPat: "",
        apMat: "",
        nacimiento: null,
        rfc: "",
        genero: "",
        expanded: true
      };
      return {
        ...state,
        asegurados: [...state.asegurados, nuevoAsegurado],
      };

    case NUEVA_POLIZA_REMOVER_ASEGURADO:
      if (action.payload.index !== 0) {
        return {
          ...state,
          asegurados: state.asegurados.filter(
            (asegurado, index) => index !== action.payload.index
          ),
        };
      }

    case EDITAR_POLIZA: {
      const pay = action.payload;
      const ram = pay.ramo.ramo.toLowerCase();

      let jour = null;
      if (ram === "vida") {
        jour = vidaJourney;
      } else if (ram === "autos" || ram === "auto") {
        jour = vehiculoJourney;
      } else if (ram === "salud") {
        jour = saludJourney;
      } else if (ram === "diversos") {
        jour = diversosJourney;
      }

      const newState = {
        ...state,
        ramoBlocked: true,
        smartReciboCalculate: false,
        newType: ram,
        stepper: {
          ...state.stepper,
          steps: jour,
        },
        vehiculos: [
          {
            id: pay.vehiculo?.id,
            descripcion: pay.vehiculo?.descripcion,
            marca: pay.vehiculo?.marca,
            tipo: pay.vehiculo?.tipo,
            modelo: pay.vehiculo?.modelo,
            amis: pay.vehiculo?.amis,
          },
        ],
        generales: {
          polizaId: pay.id,
          agente: pay.agenteClave.id,
          aseguradora: pay.agenteClave.aseguradora.id,
          concepto: pay.concepto,
          fecFinal: parse(pay.fechaVigenciaA, "yyyy-MM-dd"),
          fecInicial: parse(pay.fechaVigenciaDe, "yyyy-MM-dd"),
          vendedor: pay.vendedor?.id,
          vendedorEntity: pay.vendedor,
          conducto: pay.conducto,
          moneda: pay.moneda.id,
          plan: pay.paquete,
          poliza: pay.noPoliza,
          formaPago: pay.formaPago,
          recibos: pay.recibos.length,
          esRobot: pay.cliente.esRobot,
          contratante: {
            id: pay.cliente.id,
            nombres: pay.cliente.nombres,
            apPat: pay.cliente.apPat,
            apMat: pay.cliente.apMat,
            fiscal: pay.cliente.fiscal,
            rfc: pay.cliente.rfc,
            edoCivil: pay.cliente.edoCivil,
            genero: pay.cliente.genero,
            esRobot: pay.cliente.esRobot,
          },
        },
        vehiculo: pay.vehiculo ? {
          id: pay.vehiculo?.id,
          modelo: pay.vehiculo?.modelo,
          descripcion: {
            descripcion: pay.vehiculo?.descripcion,
            marca: pay.vehiculo?.marca,
            tipo: pay.vehiculo?.tipo,
            modelo: pay.vehiculo?.modelo,
            amis: pay.vehiculo?.amis,
          },
          noSerie: pay.vehiculo?.noSerie,
          placas: pay.vehiculo?.placas,
        } : {
          modelo: new Date().getFullYear(),
          descripcion: {
            descripcion: "",
            marca: "",
            tipo: "",
            modelo: "",
            amis: "",
          },
          noSerie: "",
          placas: "",
        },
        asegurados: pay.asegurados.map((asegurado, index) => {
          return {
            apPat: asegurado.apPat,
            apMat: asegurado.apMat,
            genero: asegurado.genero,
            id: asegurado.id,
            name: asegurado.nombres,
            parentesco: asegurado.parentesco,
            rfc: asegurado.rfc,
            nacimiento: parse(asegurado.fecNacimiento, "yyyy-MM-dd"),
            expanded: true
          };
        }),
        primas: {
          primaNeta: pay.prima,
          derecho: pay.derecho,
          financiamiento: pay.recargo,
          iva: pay.iva,
          primaTotal: pay.total,
        },
        recibos: pay.recibos.map((recibo, index) => {
          return {
            id: recibo.id,
            serie: recibo.serie,
            primaNeta: recibo.prima,
            derecho: recibo.derecho,
            financiamiento: 0,
            iva: recibo.iva,
            primaTotal: recibo.total,
            fecInicial: parse(recibo.fecVigDe, "yyyy-MM-dd"),
            fecFinal: parse(recibo.fecVigA, "yyyy-MM-dd"),
            vencimiento: parse(recibo.fecVence, "yyyy-MM-dd"),
            totalSerie: recibo.totalSerie,
            status: recibo.status,
            fecPago: recibo.fecPago
              ? parse(recibo.fecPago, "yyyy-MM-dd")
              : null,
            folioPago: recibo.folioPago,
          };
        }),
        texto: pay.texto,
        comisiones: {
          porcentajeComision: pay.porcentajeComision,
          comisiones: pay.comisiones
        }
      };
      return newState;
    }
    case RENOVAR_POLIZA:
      const load = action.payload;
      const r = load.ramo.ramo.toLowerCase();

      let j = null;
      if (r === "vida") {
        j = vidaJourney;
      } else if (r === "autos" || r === "auto") {
        j = vehiculoJourney;
      } else if (r === "salud") {
        j = saludJourney;
      } else if (r === "diversos") {
        j = diversosJourney;
      }

      const newStateReno = {
        ...state,
        ramoBlocked: true,
        smartReciboCalculate: true,
        newType: r,
        stepper: {
          ...state.stepper,
          steps: j,
        },
        vehiculos: [
          {
            id: load?.id,
            descripcion: load?.descripcion,
            marca: load?.marca,
            tipo: load?.tipo,
            modelo: load?.modelo,
            amis: load?.amis,
          },
        ],
        generales: {
          polizaId: load.id,
          polizaIDRenovo: load.id,
          agente: load.agenteClave.id,
          aseguradora: load.agenteClave.aseguradora.id,
          concepto: load.concepto,
          fecFinal: addYear(parse(load.fechaVigenciaA, "yyyy-MM-dd"), +1),
          fecInicial: addYear(parse(load.fechaVigenciaDe, "yyyy-MM-dd"), +1),
          moneda: load.moneda.id,
          plan: load.paquete,
          poliza: load.noPoliza,
          formaPago: load.formaPago,
          recibos: load.recibos.length,
          contratante: {
            id: load.cliente.id,
            nombres: load.cliente.nombres,
            apPat: load.cliente.apPat,
            apMat: load.cliente.apMat,
            fiscal: load.cliente.fiscal,
            rfc: load.cliente.rfc,
            edoCivil: load.cliente.edoCivil,
            genero: load.cliente.genero,
          },
        },
        vehiculo: {
          id: load.vehiculo?.id,
          modelo: load.vehiculo?.modelo,
          descripcion: {
            descripcion: load.vehiculo?.descripcion,
            marca: load.vehiculo?.marca,
            tipo: load.vehiculo?.tipo,
            modelo: load.vehiculo?.modelo,
            amis: load.vehiculo?.amis,
          },
          noSerie: load.vehiculo?.noSerie,
          placas: load.vehiculo?.placas,
        },
        asegurados: load.asegurados.map((asegurado, index) => {
          return {
            apPat: asegurado.apPat,
            apMat: asegurado.apMat,
            genero: asegurado.genero,
            // id: asegurado.id,
            name: asegurado.nombres,
            parentesco: asegurado.parentesco,
            rfc: asegurado.rfc,
            nacimiento: parse(asegurado.fecNacimiento, "yyyy-MM-dd"),
            expanded: true
          };
        }),
        primas: {
          primaNeta: load.prima,
          derecho: load.derecho,
          financiamiento: load.recargo,
          iva: load.iva,
          primaTotal: load.total,
        },
        recibos: [],
        texto: load.texto,
      };
      return newStateReno;

    case POLIZA_UPDATE_STATUS: {
      const { id, status } = action.payload;

      let recibo = state.recibos.find((x) => x.recibo.id === id);

      recibo.recibo.status = status;

      return {
        ...state,
        recibos: [...state.recibos],
      };
    }
    case NUEVA_POLIZA_APLICAR_TODOS_RECIBOS: {
      const { reciboIndex } = action.payload;
      const recibo = state.recibos.find((x, index) => index === reciboIndex);

      const newRecibos = state.recibos.filter((x, index) => index > 0).map(r => {
        return {
          ...r,
          primaNeta: recibo.primaNeta,
          derecho: recibo.derecho,
          financiamiento: recibo.financiamiento,
          iva: recibo.iva,
          primaTotal: recibo.primaTotal,
        }
      })

      return {
        ...state,
        recibos: [state.recibos[0], ...newRecibos],
      };
    }
    case NUEVA_POLIZA_ONCHANGE_CLIENTE_ES_ASEGURADO: {
      const payload = action.payload
      const contratante = state.generales.contratante

      const titular = payload.clienteEsAsegurado
        ? {
          ...state.asegurados[0],
          name: contratante.nombres,
          apPat: contratante.apPat,
          apMat: contratante.apMat,
          nacimiento: contratante.fecNacimiento,
          rfc: contratante.rfc,
          genero: contratante.sexo,
        }
        : {
          parentesco: "titular",
          name: "",
          apPat: "",
          apMat: "",
          nacimiento: null,
          rfc: "",
          genero: "",
          expanded: true
        }

      state.asegurados[0] = titular

      return {
        ...state,
        clienteEsAsegurado: payload.clienteEsAsegurado,
        asegurados: [...state.asegurados]
      }


    }
  }
  return state;
};

export default reducer;
