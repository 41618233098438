import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import Toolbar from '../../components/Toolbar'
import APIInvoke from '../../utils/APIInvoke';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom';
import { numberFormat, numberFormatNoDecimal } from '../../utils/NumberUtils';
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getRamoById } from '../../utils/EnumUtils';
import PersonIcon from '@mui/icons-material/Person';
import EventNoteIcon from '@mui/icons-material/EventNote';


const ReporteSemanal = (props) => {

    const params = useParams()
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState(null);

    useEffect(() => {
        fetchData()
    }, [params.fecha])

    const fetchData = async (state) => {
        try {
            const response = await (await APIInvoke.invokeGET(`/reportes/weekly/${params.fecha}`)).json()
            setState(response.body)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {


        if (loading) return <AdminTemplate loading={true} />

        const { summary = {}, renovaciones = [], cobranza = [], cumpleanos = [] } = state

        return (
            <AdminTemplate loading={loading} >
                <Toolbar title="Reporte Semanal" />


                <div className="main">
                    <div className="layout">
                        <p className="text-xl font-bold mb-2 text-center py-4">Resumen de la semana pasada</p>
                        <div className="grid md:grid-cols-2 gap-4 md:gap-8">
                            <div className="rounded-md p-2 md:p-8 bg-yellow">
                                <p className="text-center text-4xl font-bold text-dark-600">{summary.polizas}</p>
                                <p className="text-center text-xl text-dark-600">Pólizas</p>
                                <p className="text-center text-sm text-dark-600">Nuevas</p>
                            </div>
                            <div className="rounded-md p-2 md:p-8 bg-yellow">
                                <p className="text-center text-4xl font-bold text-dark-600">{summary.recibos}</p>
                                <p className="text-center text-xl text-dark-600">Recibos</p>
                                <p className="text-center text-sm text-dark-600">Cobrados</p>
                            </div>

                            <div className="rounded-md p-2 md:p-8 bg-yellow">
                                <p className="text-center text-2xl font-bold text-dark-600">{numberFormatNoDecimal(summary.polizasTotal)}</p>
                                <p className="text-center text-sm text-dark-600">Prima acumulada</p>
                            </div>
                            <div className="rounded-md p-2 md:p-8 bg-yellow">
                                <p className="text-center text-2xl font-bold text-dark-600">{numberFormatNoDecimal(summary.recibosComiciones)}</p>
                                <p className="text-center text-sm text-dark-600">Comisiones ganadas</p>
                            </div>
                        </div>

                        <p className="text-xl font-bold mb-2 text-center py-8">Resumen de esta semana</p>

                        <p className="text-lg font-bold mb-2 text-center py-4">Renovaciones</p>

                        <div className="grid gap-4 p-2 md:p-4 bg-dark-400 rounded-md ">
                            {renovaciones.length === 0 && (
                                <p className="text-center text-dark-100">Sin registros</p>
                            )}
                            {renovaciones.map((poliza, index) => (
                                <Link className="no-underline" to={{ pathname: `/poliza/${poliza.polizaId}`, state: { backUrl: `/reportes/semanal/${params.fecha}` } }}>
                                    <div className="grid gap-2 bg-dark-300 p-2 md:p-4 rounded-md text-sm">
                                        <div className="flex flex-row justify-between items-center">
                                            <div>
                                                <span className="mr-2 text-xl">{getRamoById(poliza.ramo).icon}</span>
                                                <span>{poliza.noPoliza}</span>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <span>{poliza.vencimiento}</span>
                                                <EventNoteIcon className="text-gray-100 text-xl" />
                                            </div>
                                        </div>
                                        <div>
                                            <span>{`${poliza.clienteNombres} ${poliza.clienteApPat} ${poliza.clienteApMat}`}</span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>

                        <p className="text-lg font-bold mb-2 text-center py-4">Cobranza</p>

                        <div className="grid gap-4 p-2 md:p-4 bg-dark-400 rounded-md ">
                            {cobranza.length === 0 && (
                                <p className="text-center text-dark-100">Sin registros</p>
                            )}
                            {cobranza.map((recibo, index) => (
                                <Link className="no-underline" to={{ pathname: `/poliza/${recibo.polizaId}`, state: { backUrl: `/reportes/semanal/${params.fecha}` } }}>
                                    <div className="grid gap-2 bg-dark-300 p-2 md:p-4 rounded-md text-sm">
                                        <div className="flex flex-row justify-between items-center">
                                            <div>
                                                <span className="mr-2 text-xl">{getRamoById(recibo.ramo).icon}</span>
                                                <span>{recibo.noPoliza} - Recibo ({recibo.serie}/{recibo.totalSerie})</span>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <span>{recibo.vencimiento}</span>
                                                <EventNoteIcon className="text-gray-100 text-xl" />
                                            </div>
                                        </div>
                                        <div>
                                            <span>{`${recibo.clienteNombres} ${recibo.clienteApPat} ${recibo.clienteApMat}`}</span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>

                        <p className="text-lg font-bold mb-2 text-center py-4">Cumpleaños</p>
                        <div className="grid gap-4 p-2 md:p-4 bg-dark-400 rounded-md ">
                            {cumpleanos.length === 0 && (
                                <p className="text-center text-dark-100">Sin registros</p>
                            )}
                            {cumpleanos.map((cumple, index) => (
                                <Link className="no-underline" to={{ pathname: `/clientes/${cumple.clienteId}`, state: { backUrl: `/reportes/semanal/${params.fecha}` } }}>
                                    <div className="grid gap-2 bg-dark-300 p-2 md:p-4 rounded-md text-sm">
                                        <div className="flex flex-row justify-between items-center">
                                            <div>
                                                <span>{`${cumple.clienteNombres} ${cumple.clienteApPat} ${cumple.clienteApMat}`}</span>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <span>{cumple.fecNacimiento}</span>
                                                <EventNoteIcon className="text-gray-100 text-xl" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default ReporteSemanal