
import { State } from '@egjs/react-flicking'
import { aseguradoInitalState, caracteristicaInitialState, coberturaInitialState, itemInitialState, planPagoInitalState } from '../../redux/reducers/nueva-cotizacion-reduce'
import { NUEVA_COTIZACION_RESET, NUEVA_COTIZACION_SET } from '../../redux/redux-const'
import store from '../../redux/store'
import APIInvoke from '../../utils/APIInvoke'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from 'luxon'
import { getFormaPagoById } from '../../utils/EnumUtils'
import { SatelliteAlt } from '@mui/icons-material'
import { isEmpty } from '../../utils/StringUtils'
import { format, parse } from '../../utils/DateUtils'
import { useHistory } from 'react-router-dom'




export const getState = () => {
    return store.getState().pages.nuevaCotizacion
}

export const reset = () => {
    store.dispatch({ type: NUEVA_COTIZACION_RESET })
}

export const setLoading = (loading) => {
    store.dispatch({
        type: NUEVA_COTIZACION_SET,
        payload: {
            loading
        }
    })
}

export const setState = (payload) => {
    store.dispatch({
        type: NUEVA_COTIZACION_SET,
        payload
    })
}

export const onChange = (e) => {
    const state = getState()

    let { name, value, type } = e.target
    if (type === "checkbox") name = e.target.checked

    setState({
        [name]: value
    })
}


export const onChangeItem = (e, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]

    let { name, value, type } = e.target
    if (type === "checkbox") name = e.target.checked

    item[name] = value
    /*store.dispatch({
        type: NUEVA_COTIZACION_SET,
        payload: { [name]: value }
    })*/
    setState({ ...state })
}

export const onChangeRamo = async (e) => {
    const { name, value, type } = e.target
    const coberturas = await fetchCoberturas(value)
    setState({
        ramo: value,
        coberturasOptions: coberturas,
        coberturas: [],
        asegurados: [],
        items: [itemInitialState()]
    })
}


export const onChangeAsegurado = (e, index) => {
    const state = getState()
    const { name, value } = e.target

    state.asegurados[index][name] = value
    setState({
        asegurados: [
            ...state.asegurados
        ]
    })
}

export const onChangeCobertura = (e, index, itemIndex) => {

    const state = getState()
    const item = state.items[itemIndex]
    const { name, value } = e.target

    item.coberturas[index][name] = value
    setState({ ...state })
}

export const onChangeCoberturaDescripcion = (event, value, index, itemIndex) => {
    const state = getState()
    const newValue = value ? value : ""

    const item = state.items[itemIndex]

    const finIndex = state.coberturasOptions.findIndex(x => x.descripcion === value)

    if (finIndex === -1) {
        state.coberturasOptions.unshift({ id: uuidv4(), descripcion: value })
    }

    item.coberturas[index].descripcion = newValue
    setState({ ...state })
}

export const onChangeAseguradoNacimiento = (date, index) => {

    const state = getState()

    if (date === null) {
        state.asegurados[index].fecNacimiento = ""
        state.asegurados[index].edad = ""
        setState({
            asegurados: [
                ...state.asegurados
            ]
        })
    }

    if (!date instanceof Date || isNaN(date)) {
        return
    }

    const diff = date !== null ? DateTime.now().diff(DateTime.fromJSDate(date), ["years"]) : ""

    state.asegurados[index].fecNacimiento = date
    state.asegurados[index].edad = parseInt(diff.years)
    setState({
        asegurados: [
            ...state.asegurados
        ]
    })
}

export const onChangeFechaVencimiento = (date) => {
    if (date instanceof Date && !isNaN(date)) {
        setState({
            fecVencimiento: date
        })
    } else {
        setState({
            fecVencimiento: null
        })
    }
}

export const fetchTemplates = async (ramo) => {
    const templatesResponse = await (await APIInvoke.invokeGET(`/cotizaciones/templates?ramo=${ramo}`)).json()

    const state = getState()
    setState({
        ...state,
        templates: templatesResponse.body.items,
    })
}

export const initPage = async (cotizacionId) => {
    try {
        setLoading(true)
        const aseguradorasResponse = await (await APIInvoke.invokeGET(`/aseguradoras`)).json()
        const monedasResponse = await (await APIInvoke.invokeGET(`/monedas`)).json()

        if (cotizacionId) {
            const cotizacionResponse = await (await APIInvoke.invokeGET(`/cotizaciones/${cotizacionId}`)).json()
            setState({
                ...cotizacionResponse.body,
                vehiculos: undefined,
                cliente: cotizacionResponse.body.cliente ? {
                    id: cotizacionResponse.body.cliente.id,
                    nombre: `${cotizacionResponse.body.cliente.nombres} ${cotizacionResponse.body.cliente.apPat} ${cotizacionResponse.body.cliente.apMat}`,
                    type: "clientes"
                } : {
                    id: cotizacionResponse.body.prospecto.id,
                    nombre: `${cotizacionResponse.body.prospecto.nombres} ${cotizacionResponse.body.prospecto.apPat} ${cotizacionResponse.body.prospecto.apMat}`,
                    type: "prospectos"
                },
                vehiculo: cotizacionResponse.body.ramo === "Auto" ? {
                    id: cotizacionResponse.body.vehiculos[0].id,
                    descripcion: cotizacionResponse.body.vehiculos[0].descripcion,
                    modelo: cotizacionResponse.body.vehiculos[0].modelo,
                    orden: cotizacionResponse.body.vehiculos[0].orden
                } : null,
                fecVencimiento: cotizacionResponse.body.fecVencimiento ? parse(cotizacionResponse.body.fecVencimiento, "yyyy-MM-dd") : null,
                asegurados: cotizacionResponse.body.asegurados.map(asegurado => {
                    return {
                        ...asegurado,
                        fecNacimiento: asegurado.fecNacimiento ? parse(asegurado.fecNacimiento, "yyyy-MM-dd") : null,
                    }
                }),
                items: cotizacionResponse.body.items.map(item => {
                    return {
                        ...item,
                        planesPago: item.planesPago.map(planPago => {
                            const formaPago = getFormaPagoById(planPago.formaPago)
                            return {
                                ...planPago,
                                parcialidades: formaPago.parcialidades
                            }
                        })
                    }
                }),
                aseguradoras: aseguradorasResponse.body.items,
                monedas: monedasResponse.body
            })
        } else {
            setState({
                aseguradoras: aseguradorasResponse.body.items,
                monedas: monedasResponse.body
            })
        }
    } catch (error) {
        console.error("Error al inicializar la página", error)
    } finally {
        setLoading(false)
    }
}

export const onChangeAseguradora = (event, newValue, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]

    if (newValue) {
        item.aseguradoraId = newValue.id
        item.aseguradora = newValue
    } else {
        item.aseguradoraId = null
        item.aseguradora = null
    }
    setState({ ...state })
}

export const addInsured = (e) => {
    const state = getState()

    state.asegurados.push({
        ...aseguradoInitalState,
        parentesco: state.asegurados.length === 0 ? "titular" : ""
    })

    setState({ ...state })
}

export const addCoverage = (itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.coberturas.push({ ...coberturaInitialState, uuid: uuidv4() })
    setState({ ...state })
}

export const removeInsured = (index) => {
    const state = getState()
    state.asegurados.splice(index, 1)
    setState({
        asegurados: [...state.asegurados]
    })
}

export const removeCoverage = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.coberturas.splice(index, 1)
    setState({ ...state })
}

export const fetchCoberturas = async (ramo) => {
    try {
        setLoading(true)
        const response = await (await APIInvoke.invokeGET(`/coberturas?ramo=${ramo}`)).json()
        if (response.ok) {
            return response.body.items
        } else {
            return []
        }
    } catch (error) {
        console.error("Error al cargar las coberturas", error)
    } finally {
        setLoading(false)
    }
}

export const downCobertura = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]

    if (index >= (item.coberturas.length - 1)) return
    const removed = item.coberturas.splice(index, 1)[0]
    item.coberturas.splice(index + 1, 0, { ...removed })
    setState({ ...state })
}

export const upCobertura = (index, itemIndex) => {
    const state = getState()
    if (index <= 0) return

    const item = state.items[itemIndex]
    const removed = item.coberturas.splice(index, 1)[0]
    item.coberturas.splice(index - 1, 0, { ...removed })

    setState({ ...state })
}

export const downAsegurados = (index) => {
    const state = getState()
    if (index >= (state.asegurados.length - 1)) return

    const removed = state.asegurados.splice(index, 1)[0]
    state.asegurados.splice(index + 1, 0, { ...removed })

    setState({
        asegurados: [...state.asegurados]
    })
}

export const upAsegurados = (index) => {
    const state = getState()
    if (index <= 0) return

    const removed = state.asegurados.splice(index, 1)[0]
    state.asegurados.splice(index - 1, 0, { ...removed })

    setState({
        asegurados: [...state.asegurados]
    })
}

export const save = async (e, history) => {
    e.preventDefault()
    if (e.keyCode === 13) return; // Press Enter

    const state = getState()

    try {
        setLoading(true)

        let body = {
            ramo: state.ramo,
            clienteId: state.cliente.type === "clientes" ? state.cliente.id : null,
            prospectoId: state.cliente.type === "prospectos" ? state.cliente.id : null,
            correo: state.correo,
            status: state.status,
            fecVencimiento: format(state.fecVencimiento, "yyyy-MM-dd"),
            texto: state.ramo === "Diversos" ? state.texto : null,
            vehiculos: state.ramo === "Auto" ? [{
                descripcion: state.vehiculo.descripcion,
                modelo: parseInt(state.vehiculo.modelo)
            }] : null,
            asegurados: state.asegurados.map(asegurado => {
                return {
                    id: asegurado.id,
                    nombre: asegurado.nombre,
                    parentesco: asegurado.parentesco,
                    genero: asegurado.genero,
                    fecNacimiento: typeof asegurado.fecNacimiento instanceof Date ? format(asegurado.fecNacimiento, "yyyy-MM-dd") : null,
                    edad: asegurado.edad
                }
            }),
            items: state.items.map(item => {
                return {
                    id: item.id,
                    aseguradora: item.aseguradora.id,
                    plan: item.plan,
                    formaPago: item.formaPago,
                    moneda: item.moneda,
                    deducible: item.deducible,
                    sumaAsegurada: item.sumaAsegurada,
                    topeCoaseguro: item.topeCoaseguro,
                    copago: item.copago,
                    coaseguro: item.coaseguro,
                    caracteristicas: item.caracteristicas.map(caracteristica => {
                        return {
                            id: caracteristica.id,
                            descripcion: caracteristica.descripcion,
                            valor: caracteristica.valor
                        }
                    }),
                    coberturas: item.coberturas.map(cobertura => {
                        return {
                            id: cobertura.id,
                            descripcion: cobertura.descripcion,
                            valor: cobertura.valor
                        }
                    }),
                    planesPago: item.planesPago.map(plan => {
                        return {
                            formaPago: plan.formaPago,
                            total: plan.total,
                            primaPrimerRecibo: plan.formaPago === "contado" ? undefined : plan.primaPrimerRecibo,
                            primaReciboSub: plan.formaPago === "contado" ? undefined : plan.primaReciboSub
                        }
                    }),
                }
            })
        }

        const response = state.id
            ? await (await APIInvoke.invokePUT(`/cotizaciones/${state.id}`, body)).json()
            : await (await APIInvoke.invokePOST(`/cotizaciones`, body)).json()

        setState({
            id: state.id || response.body?.id,
            message: {
                type: response.ok ? "success" : "error",
                message: response.message
            }
        })

        if (response.ok) {
            history.push(`/cotizacion/${response.body.id}`)
        }
    } catch (error) {
        console.error("Error al guardar la cotización", error)
    } finally {
        setLoading(false)
    }

}

export const editInsured = (index) => {
    const state = getState()
    state.asegurados[index].edit = true
    setState({
        asegurados: [
            ...state.asegurados
        ]
    })
}

export const saveAsegurado = (index) => {
    const state = getState()
    state.asegurados[index].edit = false
    setState({
        asegurados: [
            ...state.asegurados
        ]
    })
}

export const saveCoverage = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.coberturas[index].edit = false
    setState({ ...state })
}

export const editCoverage = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.coberturas[index].edit = true
    setState({ ...state })
}

export const onChangeVehiculo = (e) => {
    const { name, value, type } = e.target
    const state = getState()

    state.vehiculo[name] = value

    console.log("name", name, "value", value)

    setState({
        ...state,
        vehiculo: {
            ...state.vehiculo
        }
    })
}

export const onChangeCaracteristica = (e, index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    const { name, value } = e.target

    item.caracteristicas[index][name] = value
    setState({ ...state })
}

export const removeCaracteristica = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.caracteristicas.splice(index, 1)
    setState({ ...state })
}

export const saveCaracteristica = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.caracteristicas[index].edit = false
    setState({ ...state })
}


export const downCaracteristica = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]

    if (index >= (item.caracteristicas.length - 1)) return

    const removed = item.caracteristicas.splice(index, 1)[0]
    item.caracteristicas.splice(index + 1, 0, { ...removed })

    setState({ ...state })
}

export const upCaracteristica = (index, itemIndex) => {
    const state = getState()
    if (index <= 0) return

    const item = state.items[itemIndex]

    const removed = item.caracteristicas.splice(index, 1)[0]
    item.caracteristicas.splice(index - 1, 0, { ...removed })

    setState({ ...state })
}

export const editCaracteristica = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.caracteristicas[index].edit = true
    setState({ ...state })
}

export const addCaracteristica = (itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    console.log("item", item)
    item.caracteristicas.push({ ...caracteristicaInitialState, uuid: uuidv4() })

    setState({ ...state })
}

export const onChangePlanPagoPrimas = (e, index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    const { name, value, type } = e.target

    const planPago = item.planesPago[index]

    if (name === "formaPago") {
        const formaPagoEnum = getFormaPagoById(value)
        const parcialidades = formaPagoEnum.parcialidades
        planPago.total = 0
        planPago.primaPrimerRecibo = 0
        planPago.primaReciboSub = 0
        planPago.formaPago = value
        planPago.parcialidades = parcialidades
    } else {
        planPago[name] = isEmpty(value) ? 0 : parseFloat(value.replaceAll(",", ""))
    }

    if (name === "primaPrimerRecibo" || name === "primaReciboSub") {
        const formaPago = getFormaPagoById(planPago.formaPago)
        const total = ((formaPago.parcialidades - 1) * planPago.primaReciboSub) + planPago.primaPrimerRecibo
        planPago.total = total
    }

    setState({ ...state })

}



export const prepareOpenClienteDialog = (e) => {

}

export const openNewCliente = (e) => {
    setState({
        clienteAnchor: e.currentTarget,
        openNewCliente: true
    })
}

export const closeNewCliente = (e) => {
    setState({
        clienteAnchor: null,
        openNewCliente: false
    })
}

export const toggleClienteList = (open) => {
    setState({
        openClienteList: open
    })
}


export const onClienteChange = async (event, cliente) => {
    const clienteEntity = (await fetchCliente(cliente.id)).body
    clienteEntity.correos = clienteEntity.correos || []
    const correo = clienteEntity.correos.length > 0 ? clienteEntity.correos[0].correo : ""

    setState({
        cliente: cliente,
        clienteId: cliente,
        correo: correo,
        clienteEntity: clienteEntity
    })
}

export const fetchCliente = async (clienteId) => {
    const response = await (await APIInvoke.invokeGET(`/clientes/${clienteId}`)).json()
    return response
}

export const fetchClientes = async (e) => {
    try {
        const value = e.target.value
        if (value.length < 3) return
        setState({ contratantesLoading: true })
        const clientesProm = await (await APIInvoke.invokeGET(`/clientes?nombre=${value}`)).json()
        const prospectosProm = await (await APIInvoke.invokeGET(`/prospectos?nombres[cn]=${value}`)).json()

        setState({
            clientesOptions: [
                ...clientesProm.body.items.map(cliente => {
                    return {
                        id: cliente.id,
                        nombre: `${cliente.nombres} ${cliente.apPat} ${cliente.apMat}`,
                        type: "clientes"
                    }
                }),
                ...prospectosProm.body.items.map(prospecto => {
                    return {
                        id: prospecto.id,
                        nombre: `${prospecto.nombres} ${prospecto.apPat} ${prospecto.apMat}`,
                        type: "prospectos"
                    }
                }),
            ]
        })


    } catch (error) {
        console.error("Error al consultar los clientes", error)
    } finally {
        setState({ contratantesLoading: false })
    }

}


export const addPlanPago = (itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]

    item.planesPago.push({ ...planPagoInitalState(), uuid: uuidv4() })
    setState({ ...state })
}

export const removePlanPago = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.planesPago.splice(index, 1)
    setState({ ...state })
}

export const savePlanPago = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.planesPago[index].edit = false
    setState({ ...state })
}

export const downPlanPago = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    if (index >= (item.planesPago.length - 1)) return

    const removed = item.planesPago.splice(index, 1)[0]
    item.planesPago.splice(index + 1, 0, { ...removed })

    setState({ ...state })
}

export const upPlanPago = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    if (index <= 0) return

    const removed = item.planesPago.splice(index, 1)[0]
    item.planesPago.splice(index - 1, 0, { ...removed })

    setState({ ...state })
}

export const editPlanPago = (index, itemIndex) => {
    const state = getState()
    const item = state.items[itemIndex]
    item.planesPago[index].edit = true
    setState({ ...state })
}

export const openNewClienteDialog = () => {
    setState({
        clienteAnchor: null,
        openNewCliente: false,
        openNewClienteDialog: true
    })
}

export const closeNewClienteDialog = () => {
    setState({
        openNewClienteDialog: false
    })
}

export const openNewProspectoDialog = () => {
    setState({
        clienteAnchor: null,
        openNewCliente: false,
        openNewProspectoDialog: true
    })
}

export const closeNewProspectoDialog = () => {
    setState({
        openNewProspectoDialog: false
    })
}

export const onCompletedCliente = (cliente) => {
    console.log("cliente", cliente)
    const state = getState()
    const clienteOpt = {
        id: cliente.id,
        nombre: `${cliente.nombres} ${cliente.apPat} ${cliente.apMat}`,
        type: "clientes"
    }
    setState({
        cliente: clienteOpt,
        clienteId: clienteOpt,
        openNewClienteDialog: false,
        clientesOptions: [
            clienteOpt,
            ...state.clientesOptions
        ]
    })
}



export const onCompletedProspecto = (prospecto) => {
    console.log("prospecto", prospecto)
    const state = getState()
    const prospectoOpt = {
        id: prospecto.id,
        nombre: `${prospecto.nombres} ${prospecto.apPat} ${prospecto.apMat}`,
        type: "prospectos"
    }
    setState({
        cliente: prospectoOpt,
        clienteId: prospectoOpt,
        openNewProspectoDialog: false,
        clientesOptions: [
            prospectoOpt,
            ...state.clientesOptions
        ]
    })
}

export const onChanteTemplate = async (e, itemIndex) => {
    try {
        setLoading(true)

        const state = getState()
        const value = e.target.value

        const templateResponse = await (await APIInvoke.invokeGET(`/cotizaciones/templates/${value}`)).json()

        const item = state.items[itemIndex]
        item.templateId = value
        item.template = templateResponse.body
        item.ramo = templateResponse.body.ramo
        item.moneda = templateResponse.body.moneda.abreviacion
        item.plan = templateResponse.body.plan
        item.aseguradoraId = templateResponse.body.aseguradora.id
        item.aseguradora = templateResponse.body.aseguradora
        item.sumaAsegurada = templateResponse.body.sumaAsegurada
        item.deducible = templateResponse.body.deducible
        item.coaseguro = templateResponse.body.coaseguro
        item.topeCoaseguro = templateResponse.body.topeCoaseguro
        item.copago = templateResponse.body.copago


        item.coberturas = templateResponse.body.coberturas.map(cobertura => {
            cobertura.id = null
            return cobertura
        })
        item.caracteristicas = templateResponse.body.caracteristicas.map(x => { return { ...x, id: undefined } })

        setState({
            items: [...state.items]
        })
    } catch (error) {
        console.error("Error al cargar el template", error)
    } finally {
        setLoading(false)
    }
}

export const addCotizacion = () => {
    const state = getState()
    state.items.push(itemInitialState())
    console.log("piush", state.items)
    setState({ ...state })
}

export const toggleShowDeleteItemDialog = (show, itemIndex) => {

    const state = getState()
    state.showDeleteItemDialog = show
    state.prepareDeleteIndex = itemIndex
    setState({ ...state })
}

export const removeCotizacion = () => {
    const state = getState()
    state.showDeleteItemDialog = false
    state.items.splice(state.prepareDeleteIndex, 1)
    state.prepareDeleteIndex = null
    setState({})
}

