import React, { useState, useCallback, useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import debounce from "lodash.debounce";
import { CircularProgress, TextField } from '@mui/material';

const SearchFilter = ({
    id,
    name,
    filter,
    placeholder,
    values,
    value,
    processFilter,
    updateState,
    required,
    getOptionLabel,
    isOptionEqualToValue,
    clearText = "Limpiar",
    closeText = "Cerrar",
    noOptionsText = "Sin resultados",
    loadingText = "Cargando...",
    openText = "Abrir"
}) => {

    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        switchOpen(options.length !== 0)
    }, [options])

    const _fetch = async (e) => {
        try {
            setLoading(true)
            const fetchOptions = await values({
                id,
                name,
                filter,
                placeholder,
                values,
                value: e.target.value,
                processFilter,
                updateState,
                required,
                getOptionLabel,
                isOptionEqualToValue
            })
            setOptions(fetchOptions)

        } catch (error) {
            console.error("Error al cargar los datos", error)
        } finally {
            setLoading(false)
        }
    }

    const onChange = (event, newValue) => {
        updateState(id, newValue, true)
    }

    const _getOptionLabel = (option) => {
        return getOptionLabel ? getOptionLabel(option) : option
    }

    const _isOptionEqualToValue = (option, value) => {
        return isOptionEqualToValue ? isOptionEqualToValue(option, value) : option === value
    }

    const switchOpen = () => {
        setOpen(options.length !== 0 ? true : false)
    }

    const fetchDebounce = useCallback(debounce(_fetch, 500), []);

    const render = () => {
        return (
            <Autocomplete
                id={id}
                name={name}
                fullWidth={true}
                required={required}
                clearText={clearText}
                closeText={closeText}
                noOptionsText={noOptionsText}
                loadingText={loadingText}
                openText={openText}
                open={open}
                value={value}
                onOpen={switchOpen}
                onClose={() => setOpen(false)}
                isOptionEqualToValue={_isOptionEqualToValue}
                getOptionLabel={_getOptionLabel}
                options={options}
                onInput={fetchDebounce}
                loading={loading}
                onChange={onChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={placeholder}
                        variant="outlined"
                        size="small"
                        required={required}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        )
    }

    return render()
}



export default SearchFilter