import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { Provider } from 'react-redux'
import "./App.css";
import Login from "./pages/Login";
import Registro from "./pages/Registro";
import ScreenDebug from "./components/ScreenDebug";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import UserContext from "./state/UserContext";
import AppContext from "./state/AppContext";
import { useState, useEffect } from "react";
import Landing from "./pages/Landing";
import APIInvoke from "./utils/APIInvoke";
import Loading from "./components/Loading";
import RegistroContra from "./pages/RegistroContra"
import ConsultaPoliza from "./pages/ConsultaPoliza";
import ConsultaCliente from "./pages/ConsultaCliente";
import Polizas from "./pages/Polizas";
import { useHistory, Redirect } from "react-router-dom";
import Clientes from "./pages/Clientes";
import NuevoCliente from "./pages/NuevoCliente";
import Bitacora from "./pages/Bitacora";
import NuevaPoliza from "././pages/NuevaPoliza";
import CxC from "././pages/CxC"
import Resumen from "./pages/Resumen"
import PagoRecibo from "././pages/PagoRecibo"
import store from './redux/store'
import Renovaciones from "./pages/Renovaciones";
import Adjuntos from "./pages/Adjuntos";
import Configuracion from "./pages/Configuracion";
import Vencimientos from "./pages/Vencimientos";
import Metas from "./pages/Metas";
import Planes from "./pages/Planes";
import CerrarPoliza from "./pages/CerrarPoliza";
import Modelos from "./pages/Modelos";
import NuevoModelo from "./pages/NuevoModelo";
import Claves from "./pages/Claves";
import Automatizaciones from "./pages/Automatizaciones";
import Automatizacione from "./pages/Automatizacione";
import Page404 from "./pages/404";
import Page500 from "./pages/500";
import ReactivarPoliza from './pages/ReactivarPoliza'
import PaymentComplete from "./pages/PaymentComplete";
import Correo from "./pages/Correo";
import Usuarios from "./pages/Usuarios";
import MiPlan from "./pages/MiPlan";
import Todoist from "./pages/integraciones/Todoist";
import Integraciones from "./pages/integraciones/Integraciones";
import MiCuenta from "./pages/MiCuenta";
import RestaurarPassword from "./pages/RestaurarPassword";
import SetNewPassword from "./pages/SetNewPassword";
import EnvWarning from "./components/EnvWarning";
import SuscripcionVencida from './pages/SuscripcionVencida'
import AvisoPrivacidad from './pages/AvisoPrivacidad'
import TerminosCondiciones from './pages/TerminosCondiciones'
import ImportarPolizas from "./pages/ImportarPolizas";
import Calendar from "./pages/Calendar";
import ReportePolizas from "./pages/reportes/ReportePolizas"
import ReporteRecibos from "./pages/reportes/ReporteRecibos"
import ReporteClientes from "./pages/reportes/ReporteClientes";
import { Toaster } from 'react-hot-toast';
import CobranzaPage from "./pages/cobranza/CobranzaPage";
import RenovacionesPage from "./pages/renovaciones/RenovacionesPage";
import ClavesNuevo from "./pages/claves/ClavesNuevo";
import ReporteComisiones from "./pages/reportes/ReporteComisiones";
import Prospectos from "./pages/prospectos/Prospectos";
import NuevoProspecto from "./pages/prospectos/NuevoProspecto";
import ConsultaProspecto from "./pages/prospectos/ConsultaProspecto";
import KanbanProspectos from "./pages/prospectos/kanban/KanbanProspectos";
import UX from "./pages/UX";
import ImportarPolizasCaratula from "./pages/ImportarPolizasCaratula";
import ImportarPolizasExcel from "./pages/ImportarPolizasExcel";
import ImportarPolizasExcelResult from "./pages/ImportarPolizasExcelResult";
import Siniestros from "./pages/siniestros/Siniestros";
import NuevoSiniestro from "./pages/siniestros/NuevoSiniestro";
import ConsultaSiniestro from "./pages/siniestros/ConsultaSiniestro";
import AppTips from "./components/AppTips";
import NuevaCotizacion from "./pages/cotizaciones/NuevaCotizacion";
import ConsultaCotizacion from "./pages/cotizaciones/ConsultaCotizacion";
import Cotizaciones from "./pages/cotizaciones/Cotizaciones";
import ConsultaCotizacionTemplate from "./pages/cotizacionesTemplates/ConsultaCotizacionTemplate";
import CotizacionesTemplate from "./pages/cotizacionesTemplates/CotizacionesTemplate";
import NuevaCotizacionTemplate from "./pages/cotizacionesTemplates/NuevaCotizacionTemplate";
import VisorCotizaciones from "./pages/cotizaciones/visor/VisorCotizaciones";
import Vendedores from "./pages/vendedores/Vendedores";
import NuevoVendedor from "./pages/vendedores/NuevoVendedor";
import ConsultaVendedor from "./pages/vendedores/ConsultaVendedor";
import Empresa from "./pages/empresas/Empresa";
import ReporteSemanal from "./pages/reportes/ReporteSemanal";
import TareasPage from "./pages/tareas/Tareas";
import ConsultaTereaPage from "./pages/tareas/ConsultaTarea";
import NuevaTareaPage from "./pages/tareas/NuevaTarea";
import ConsultaPolizaV2 from "./pages/polizas/ConsultaPolizaV2";
import NuevaAseguradora from "./pages/aseguradoras/NuevaAseguradoras";
import Aseguradoras from "./pages/aseguradoras/Aseguradoras";
import NuevoUsuario from "./pages/usuarios/NuevoUsuario";
import Monedas from './pages/monedas/Monedas'
import * as Permisos from './utils/Permisos'
import * as Roles from './utils/Roles'
import NuevaMoneda from "./pages/monedas/NuevaMoneda";


function UsePageViews() {
  const location = useLocation();
  useEffect(() => {
    //ga.send(["pageview", location.pathname]);

    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: location.pathname,
        page_path: location.pathname,
      })
    }

    if (window.fbq) {
      try {
        window.fbq('track', 'PageView')
      } catch (e) {
        console.error("facebook pixel not found")
      }

    }
  }, [location]);
  return null
}

function App() {
  const history = useHistory()
  const [user, setUser] = useState(null);
  const [appContext, setAppContext] = useState({
    navbar: {
      open: window.innerWidth >= 1024 ? true : false,
    },
    taskbar: {
      open: false
    },
  });

  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("token")
    if (token) isTokenExpired(token)
    if (token) {
      APIInvoke.invokePOST(`/usuarios/login`)
        .then((res) => res.json())
        .catch((err) => setReady(true))
        .then((res) => {
          if (res.ok) {
            window.localStorage.setItem("token", res.body.token);
            setUser({
              ...res.body.usuario,
              agenteId: res.body.agenteId,
              suscripcion: res.body.suscripcion,
              rol: res.body.role,
              permisos: res.body.permisos
            });

            setTimeout(() => {
              const tawk = window.Tawk_API || {}
              const tawkRequest = {
                name: `${res.body.usuario.nombres} ${res.body.usuario.apPat} ${res.body.usuario.apMat}`,
                email: res.body.usuario.correo,
                hash: res.body.tawktoToken
              }
              if (tawk.setAttributes) tawk.setAttributes(tawkRequest)
            }, 5000)

            setReady(true);
          } else {
            window.localStorage.removeItem("token")
            history.push("/login")
          }
        })
        .catch((err) => setReady(true));
    } else {
      setReady(true);
    }
  }, []);

  const isTokenExpired = (token) => {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    const isExpity = (Math.floor((new Date()).getTime() / 1000)) >= expiry;
    if (isExpity) {
      window.localStorage.removeItem("token")
      history.push("/login")
    }
  }

  const canAccess = (permiso) => {
    return user.rol.includes(Roles.ROLE_ADMIN) || user.permisos.includes(permiso)
  }

  const getRoutes = () => {
    if (!user) {
      return (
        <Switch>
          <Route path="/" component={SuscripcionVencida} exact />
          <Route path="/ux" component={UX} exact />
          <Route path="/404" component={Page404} exact />
          <Route path="/500" component={Page500} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/registro" component={Registro} exact />
          <Route path="/activar" component={RegistroContra} exact />
          <Route path="/planes" component={Planes} exact />
          <Route path="/restaurar-pass" component={RestaurarPassword} exact />
          <Route path="/establecer-pass" component={SetNewPassword} exact />
          <Route path="/aviso-privacidad" component={AvisoPrivacidad} exact />
          <Route path="/terminos-condiciones" component={TerminosCondiciones} exact />
          <Route path="/cotizaciones/visor/:uuid" component={VisorCotizaciones} exact strict />
          <Route component={Page404} exact />
        </Switch>
      )
    } else if (user.rol.includes(Roles.ROLE_UNSUBSCRIBE_USER)) {
      return (
        <Switch>
          <Redirect from="/resumen" to="/" exact strict />
          <Route path="/" component={SuscripcionVencida} exact />
          <Route path="/404" component={Page404} exact />
          <Route path="/500" component={Page500} exact />
          <Route path="/payments/stripe/completed" component={PaymentComplete} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/planes" component={Planes} exact />
          <Route path="/restaurar-pass" component={RestaurarPassword} exact />
          <Route path="/establecer-pass" component={SetNewPassword} exact />
          <Route path="/aviso-privacidad" component={AvisoPrivacidad} exact />
          <Route path="/terminos-condiciones" component={TerminosCondiciones} exact />
          <Route path="/configuracion/mi-cuenta" component={MiCuenta} exact />
          <Route path="/configuracion/mi-plan" component={MiPlan} exact />
          <Route path="/cotizaciones/visor/:uuid" component={VisorCotizaciones} exact strict />
          <Route component={Page404} exact />
        </Switch>
      )
    } else if (user.rol.includes(Roles.ROLE_USER)) {
      return (
        <Switch>
          <Redirect from="/" to="/resumen" exact strict />
          <Route path="/ux" component={UX} exact />
          <Route path="/404" component={Page404} exact />
          <Route path="/500" component={Page500} exact />
          <Route path="/payments/stripe/completed" component={PaymentComplete} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/resumen" component={Resumen} exact />
          <Route path="/restaurar-pass" component={RestaurarPassword} exact />
          <Route path="/establecer-pass" component={SetNewPassword} exact />
          <Route path="/aviso-privacidad" component={AvisoPrivacidad} exact />
          <Route path="/terminos-condiciones" component={TerminosCondiciones} exact />
          <Route path="/registro" component={Registro} exact />
          <Route path="/activar" component={RegistroContra} exact />
          <Route path="/comofunciona" component={Landing} exact />
          {canAccess(Permisos.COMUNICADOS_R) && <Route path="/configuracion/modelos" component={Modelos} exact strict />}
          {canAccess(Permisos.COMUNICADOS_W) && <Route path="/configuracion/modelos/" component={NuevoModelo} exact strict />}
          {canAccess(Permisos.COMUNICADOS_W) && <Route path="/configuracion/modelos/:modeloId" component={NuevoModelo} exact strict />}
          {canAccess(Permisos.REPORTES_AUTOMATIZACIONES) && <Route path="/Automatizaciones" component={Automatizaciones} exact strict />}
          {canAccess(Permisos.REPORTES_AUTOMATIZACIONES) && <Route path="/Automatizaciones/:logId" component={Automatizacione} exact strict />}
          <Route path="/configuracion" component={Configuracion} exact />
          {canAccess(Permisos.INTEGRACIONES) && <Route path="/configuracion/integraciones" component={Integraciones} exact />}
          {canAccess(Permisos.INTEGRACIONES) && <Route path="/configuracion/integraciones/todoist" component={Todoist} exact />}
          {canAccess(Permisos.CONFIG) && <Route path="/configuracion/vencimientos" component={Vencimientos} exact />}
          {canAccess(Permisos.METAS) && <Route path="/configuracion/metas" component={Metas} exact />}
          {canAccess(Permisos.FACTURACION) && <Route path="/planes" component={Planes} exact />}
          {canAccess(Permisos.POLIZAS_COBRANZA) && <Route path="/cxc" component={CobranzaPage} exact />}
          {canAccess(Permisos.POLIZAS_COBRANZA) && <Route path="/cxc-clasic" component={CxC} exact />}
          {canAccess(Permisos.POLIZAS_RENOVACIONES) && <Route path="/renovaciones" component={RenovacionesPage} exact />}
          {canAccess(Permisos.POLIZAS_RENOVACIONES) && <Route path="/renovaciones-clasic" component={Renovaciones} exact />}
          {canAccess(Permisos.CLAVES_W) && <Route path="/configuracion/claves" component={Claves} exact />}
          {canAccess(Permisos.CLAVES_W) && <Route path="/claves" component={Claves} exact />}
          {canAccess(Permisos.CLAVES_W) && <Route path="/claves-clasic" component={ClavesNuevo} exact />}
          {canAccess(Permisos.PROSPECTOS_R) && <Route path="/prospectos" component={Prospectos} exact strict />}
          {canAccess(Permisos.PROSPECCION_R) && <Route path="/prospeccion" component={KanbanProspectos} exact strict />}
          {canAccess(Permisos.PROSPECTOS_W) && <Route path="/prospectos/" component={NuevoProspecto} exact strict />}
          {canAccess(Permisos.PROSPECTOS_R) && <Route path="/prospectos/:prospectoId" component={ConsultaProspecto} exact />}
          {canAccess(Permisos.PROSPECTOS_W) && <Route path="/prospectos/:prospectoId/edit" component={NuevoProspecto} exact />}
          {canAccess(Permisos.VENDEDORES_R) && <Route path="/vendedores" component={Vendedores} exact strict />}
          {canAccess(Permisos.VENDEDORES_W) && <Route path="/vendedores/" component={NuevoVendedor} exact strict />}
          {canAccess(Permisos.VENDEDORES_R) && <Route path="/vendedores/:vendedorId" component={ConsultaVendedor} exact />}
          {canAccess(Permisos.VENDEDORES_W) && <Route path="/vendedores/:vendedorId/edit" component={NuevoVendedor} exact />}
          {canAccess(Permisos.CLIENTES_R) && <Route path="/clientes" component={Clientes} exact strict />}
          {canAccess(Permisos.CLIENTES_W) && <Route path="/clientes/" component={NuevoCliente} exact strict />}
          {canAccess(Permisos.CLIENTES_R) && <Route path="/clientes/:clienteId" component={ConsultaCliente} exact />}
          {canAccess(Permisos.CLIENTES_W) && <Route path="/clientes/:clienteId/edit" component={NuevoCliente} exact />}
          {canAccess(Permisos.ADJUNTOS_R) && <Route path="/clientes/:entidadId/adjuntos" render={() => <Adjuntos entidad="clientes" />} exact strict />}
          {canAccess(Permisos.POLIZAS_R) && <Route path="/polizav2/:polizaId" component={ConsultaPolizaV2} exact strict />}
          {canAccess(Permisos.POLIZAS_R) && <Route path="/poliza/:polizaId" component={ConsultaPoliza} exact strict />}
          {canAccess(Permisos.POLIZAS_R) && <Route path="/polizas" component={Polizas} exact strict />}
          {canAccess(Permisos.POLIZAS_W) && <Route path="/polizas/:polizaId" component={NuevaPoliza} exact strict />}
          {canAccess(Permisos.POLIZAS_W) && <Route path="/polizas/:polizaId/cerrar" component={CerrarPoliza} exact strict />}
          {canAccess(Permisos.POLIZAS_W) && <Route path="/polizas/:polizaId/reactivar" component={ReactivarPoliza} exact strict />}
          {canAccess(Permisos.BITACORA_R) && <Route path="/:entidad/:entidadId/bitacora" component={Bitacora} exact strict />}
          {canAccess(Permisos.ADJUNTOS_R) && <Route path="/polizas/:entidadId/adjuntos" render={() => <Adjuntos entidad="polizas" />} exact strict />}
          {canAccess(Permisos.POLIZAS_W) && <Route path="/polizas/:polizaId/:section" component={NuevaPoliza} exact strict />}
          {canAccess(Permisos.POLIZAS_W) && <Route path="/recibos/:reciboId/pagar" component={PagoRecibo} exact strict />}
          {canAccess(Permisos.ADJUNTOS_R) && <Route path="/recibos/:entidadId/adjuntos" render={() => <Adjuntos entidad="recibos" />} exact strict />}
          {canAccess(Permisos.COMUNICADOS_R) && <Route path="/correo/:objectId" component={Correo} exact />}
          {canAccess(Permisos.USUARIOS_R) && <Route path="/configuracion/usuarios" component={Usuarios} exact strict />}
          {canAccess(Permisos.USUARIOS_W) && <Route path="/configuracion/usuarios/" component={NuevoUsuario} exact strict />}
          {canAccess(Permisos.USUARIOS_W) && <Route path="/configuracion/usuarios/:usuarioId" component={NuevoUsuario} exact strict />}
          <Route path="/configuracion/mi-cuenta" component={MiCuenta} exact />
          {canAccess(Permisos.FACTURACION) && <Route path="/configuracion/mi-plan" component={MiPlan} exact />}
          {canAccess(Permisos.EMPRESA_W) && <Route path="/configuracion/empresa" component={Empresa} exact />}
          {canAccess(Permisos.POLIZAS_IMPORT) && <Route path="/importar-polizas" component={ImportarPolizas} exact />}
          {canAccess(Permisos.POLIZAS_IMPORT) && <Route path="/importar-polizas/pdf" component={ImportarPolizasCaratula} exact />}
          {canAccess(Permisos.POLIZAS_IMPORT) && <Route path="/importar-polizas/excel" component={ImportarPolizasExcel} exact />}
          {canAccess(Permisos.POLIZAS_IMPORT) && <Route path="/importar-polizas/excel-result" component={ImportarPolizasExcelResult} exact />}
          {canAccess(Permisos.CALENDARIO_R) && <Route path="/calendario" component={Calendar} exact />}
          {canAccess(Permisos.TAREAS_R) && <Route path="/tareas" component={TareasPage} exact strict />}
          {canAccess(Permisos.TAREAS_W) && <Route path="/tareas/" component={NuevaTareaPage} exact strict />}
          {canAccess(Permisos.TAREAS_R) && <Route path="/tareas/:tareaId" component={ConsultaTereaPage} exact />}
          {canAccess(Permisos.TAREAS_W) && <Route path="/tareas/:tareaId/edit" component={NuevaTareaPage} exact />}
          {canAccess(Permisos.REPORTES_POLIZAS) && <Route path="/reportes/polizas" component={ReportePolizas} exact />}
          {canAccess(Permisos.REPORTES_RECIBOS) && <Route path="/reportes/recibos" component={ReporteRecibos} exact />}
          {canAccess(Permisos.REPORTES_CLIENTES) && <Route path="/reportes/clientes" component={ReporteClientes} exact />}
          {canAccess(Permisos.REPORTES_COMISIONES) && <Route path="/reportes/comisiones" component={ReporteComisiones} exact />}
          {canAccess(Permisos.REPORTES_SEMANAL) && <Route path="/reportes/semanal/:fecha" component={ReporteSemanal} exact strict />}
          {canAccess(Permisos.SINIESTROS_R) && <Route path="/siniestro/:siniestroId" component={ConsultaSiniestro} exact strict />}
          {canAccess(Permisos.SINIESTROS_R) && <Route path="/siniestros" component={Siniestros} exact strict />}
          {canAccess(Permisos.SINIESTROS_W) && <Route path="/siniestros/" component={NuevoSiniestro} exact strict />}
          {canAccess(Permisos.SINIESTROS_W) && <Route path="/siniestros/:siniestroId" component={NuevoSiniestro} exact strict />}
          <Route path="/cotizaciones/visor/:uuid" component={VisorCotizaciones} exact strict />
          {canAccess(Permisos.COTIZACIONES_TEMP_R) && <Route path="/cotizacion/templates/:cotizacionId" component={ConsultaCotizacionTemplate} exact strict />}
          {canAccess(Permisos.COTIZACIONES_TEMP_R) && <Route path="/cotizaciones/templates" component={CotizacionesTemplate} exact strict />}
          {canAccess(Permisos.COTIZACIONES_TEMP_W) && <Route path="/cotizaciones/templates/" component={NuevaCotizacionTemplate} exact strict />}
          {canAccess(Permisos.COTIZACIONES_TEMP_W) && <Route path="/cotizaciones/templates/:cotizacionId" component={NuevaCotizacionTemplate} exact strict />}
          {canAccess(Permisos.COTIZACIONES_R) && <Route path="/cotizacion/:cotizacionId" component={ConsultaCotizacion} exact strict />}
          {canAccess(Permisos.COTIZACIONES_R) && <Route path="/cotizaciones" component={Cotizaciones} exact strict />}
          {canAccess(Permisos.COTIZACIONES_W) && <Route path="/cotizaciones/" component={NuevaCotizacion} exact strict />}
          {canAccess(Permisos.COTIZACIONES_W) && <Route path="/cotizaciones/:cotizacionId" component={NuevaCotizacion} exact strict />}
          {canAccess(Permisos.ASEGURADORAS_R) && <Route path="/aseguradoras" component={Aseguradoras} exact strict />}
          {canAccess(Permisos.ASEGURADORAS_W) && <Route path="/aseguradoras/" component={NuevaAseguradora} exact strict />}
          {canAccess(Permisos.ASEGURADORAS_W) && <Route path="/aseguradoras/:aseguradoraId" component={NuevaAseguradora} exact strict />}
          {canAccess(Permisos.MONEDA_W) && <Route path="/monedas" component={Monedas} exact strict />}
          {canAccess(Permisos.MONEDA_W) && <Route path="/monedas/" component={NuevaMoneda} exact strict />}
          {canAccess(Permisos.MONEDA_W) && <Route path="/monedas/:monedaId" component={NuevaMoneda} exact strict />}
          <Route component={Page404} />
        </Switch>
      )
    } else {
      return null
    }
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <AppContext.Provider value={{ appContext, setAppContext }}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ScreenDebug />
            <Toaster position="bottom-center" reverseOrder={false} toastOptions={{ duration: 5000, sticky: true }} />
            <Loading show={!isReady} />
            <EnvWarning />
            {isReady && (
              <Router onUpdate={() => window.scrollTo(0, 0)}>
                <UsePageViews />
                <AppTips />
                {getRoutes()}
              </Router>
            )}
          </ThemeProvider>
        </Provider>
      </AppContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
