const config = {
    api: {
        baseUrl: "https://dalala-gateway-cko2rscy.uc.gateway.dev" 
    },
    uploader: {
        baseUrl: "https://dalala-storage-gcp-api-ldawljzoxq-uc.a.run.app", 
        token: "xxx"
    },
    bucket: {
        name: "dalala-app-qa"
    },
    props: {
        taza: 0.16
    },
    contacts: {
        emailContacto: "info@dalala.mx",
        emailSoporte: "info@dalala.mx"
    }
}

module.exports = config