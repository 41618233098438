import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Toolbar from '../../components/Toolbar'
import APIInvoke from '../../utils/APIInvoke'
import Alert from '../../components/Alert'

const Todoist = (props) => {

    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        token: "",
        sensible: false,
        ok: null
    })

    useEffect(async () => {
        try {
            setLoading(true)
            const response = await (await (APIInvoke.invokeGET(`/integraciones?aplicacion=TODOIST`))).json()
            if (!response.ok) throw new Error("Error al consultar los parámetros")
            const param = response.body.find(x => x.parametro === "token")
            setState({
                token: param.valor,
                sensible: true
            })
        } catch (error) {
            console.log("Error al cargar el token", error)
        } finally {
            setLoading(false)
        }
    }, [])

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const body = {
                aplicacion: "todoist",
                parametros: [
                    {
                        parametro: "token",
                        valor: state.token,
                        sensible: true
                    }
                ]
            }
            const response = await (await APIInvoke.invokePOST(`/integraciones`, body)).json()
            setState({
                ...state,
                sensible: true,
                ok: response.ok,
                message: response.message
            })
        } catch (error) {
            console.log("Error al guardar el token", error)
        } finally {
            setLoading(false)
        }
    }

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
            sensible: false
        })
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} >
                <Toolbar title="Integración con Todoist" helpLink="https://appdalala.blogspot.com/2022/05/integracion-todoist.html"/>
                <div className="main">
                    <div className="layout-compact mb-8">
                        <form onSubmit={onSubmit}>
                            <div className="grid grid-cols-1 gap-8">
                                <div className="text-center">
                                    <img src="/integrations/todoist/todoist-icon.png" alt="Todoist" className="inline-block text-center" />
                                </div>
                                <p className=""><strong>todoist*</strong> es una de las aplicaciones para la administración de tareas mas sencillas y de facil implementación del mercado</p>
                                <p className="">Por esta razón <span className="text-yellow">dalala</span> permite integrar esta herramienta de forma sencilla siguiendo los siguientes pasos:</p>
                                <ul className=" ">
                                    <li>
                                        <p>1. Si aún no eres usuario registrate en <a href="https://todoist.com/users/showregister" className="no-underline" target="_blank">https://todoist.com/users/showregister</a></p>
                                    </li>
                                    <li>
                                        <p>2. En la pantalla principal da clic en el icono de tu usuario en la esquina superior derecha</p>
                                    </li>
                                </ul>
                                <div className="text-center">
                                    <img src="/integrations/todoist/todoist-step1.png" alt="Todoist step 1" className="inline-block text-center" />
                                </div>
                                <ul>
                                    <li>
                                        <p>3. Del menú que se desplega selecciona la opción Integraciones</p>
                                    </li>
                                </ul>
                                <div className="text-center">
                                    <img src="/integrations/todoist/todoist-step2.png" alt="Todoist step 2" className="inline-block text-center" />
                                </div>
                                <ul>
                                    <li>
                                        <p>4. En la pantalla de integraciones damos click en la sección "Desarrolladores", al final en la sección <strong>Token de API</strong> y da clic en la opción <span className="underline">Copiar a Portapapeles</span></p>
                                    </li>
                                </ul>
                                <div className="text-center">
                                    <img src="/integrations/todoist/todoist-step3.png" alt="Todoist step 3" className="inline-block text-center" />
                                </div>
                                <ul>
                                    <li>
                                        <p>5. Captura el valor copiado en el siguiente campo y da clic en validar</p>
                                    </li>
                                </ul>
                                <TextField
                                    label="API Token"
                                    type="text"
                                    variant="outlined"
                                    name="token"
                                    required
                                    onFocus={e => e.target.select()}
                                    value={state.sensible ? "**********" : state.token}
                                    onChange={onChange}
                                />
                                <Button type="submit" className="btn btn-yellow btn-block">GUARDAR</Button>
                                {state.ok != null && (
                                    <Alert type={state.ok ? "success" : "error"} message={state.message} />
                                )}
                                <p className="text-xs font-thin">* Derenchos reservados <strong>© Doist Inc.</strong></p>
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}
export default Todoist