import React, { useContext, useState } from 'react'
import Hamburger from 'hamburger-react'
import AppContext from '../state/AppContext';
import UserContext from '../state/UserContext'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from "react-router-dom";
import { ClickAwayListener, Divider, Grow, IconButton, Link, ListItemIcon, MenuItem, MenuList, Paper, Popper, Tooltip } from '@mui/material';
import { hasValue } from '../utils/StringUtils';
import * as Permisos from '../utils/Permisos'
import GrantContent from '../components/GrantContent';

const Toolbar = () => {

    const history = useHistory()
    const userContext = useContext(UserContext)
    const { appContext, setAppContext } = useContext(AppContext)
    const [openMenu, setOpenMenu] = useState(false)
    const openMenuRef = React.useRef(null);

    const toogleNavbar = () => {
        setAppContext({
            ...appContext,
            navbar: {
                open: !appContext.navbar.open
            }
        })
    }

    const toogleTaskbar = () => {
        setAppContext({
            ...appContext,
            taskbar: {
                open: !appContext.taskbar.open
            }
        })
    }

    const logout = () => {
        window.localStorage.removeItem("token")
        window.location = "/login"
        setOpenMenu(false)
    }

    const goto = (url) => {
        history.push(url)
        setOpenMenu(false)
    }

    const render = () => {
        const navbar = appContext.navbar
        let username = ""
        let userFullname = ""
        let rol = null
        const logged = hasValue(userContext.user)
        if (logged) {
            username = `${userContext.user.nombres.charAt(0)}${userContext.user.apPat.charAt(0)}`.toUpperCase()
            userFullname = `${userContext.user.nombres} ${userContext.user.apPat} ${userContext.user.apMat}`

            // ROLE_USER, ROLE_UNSUBSCRIBE_USER
            rol = userContext.user.rol
        }

        return (
            <nav className="bg-dark-400 p-2 px-2 h-12 lg:h-16 flex flex-row justify-between items-center border-b border-dark-100">
                <span className="inline-block lg:hidden">
                    <Hamburger className=" text-white bg-white" size={18} color="#FFF" toggled={navbar.open} toggle={toogleNavbar} />
                </span>
                <img src="/brand/dalala-logo-lg.svg" alt="dalala" className="h-full lg:h-8 " />

                {logged && (
                    <div className="flex justify-center items-center gap-2">
                        <GrantContent permisos={[Permisos.TAREAS_R]}>
                            <span className="inline-block">
                                <Tooltip title={"Ver tareas"}>
                                    <IconButton className="h-10 w-10 m-1" onClick={toogleTaskbar}>
                                        <LibraryAddCheckIcon className="text-2xl text-yellow" />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        </GrantContent>


                        <Tooltip title={userFullname}>
                            <IconButton ref={openMenuRef} className="btn btn-yellow h-7 w-7 rounded-full text-xs" onClick={() => setOpenMenu(true)}>{username}</IconButton>
                        </Tooltip>
                        <Popper
                            sx={{ zIndex: 1 }}
                            open={openMenu}
                            anchorEl={openMenuRef.current}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}

                                >
                                    <Paper className="mr-2 mt-2 bg-dark-500 border border-dark-600 rounded-md">
                                        <ClickAwayListener onClickAway={() => { setOpenMenu(false) }}>
                                            <MenuList id="split-button-menu" autoFocusItem className="w-60" >
                                                <MenuItem className="hover:bg-dark-200" onClick={e => goto("/configuracion/mi-cuenta")}>
                                                    <ListItemIcon>
                                                        <AccountCircleIcon className="mr-2 text-gray-400" fontSize="small" />
                                                    </ListItemIcon>
                                                    <p className="">Mi perfil</p>
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem onClick={logout} className="hover:bg-dark-200" >
                                                    <ListItemIcon>
                                                        <ExitToAppIcon className="mr-2 text-gray-400" fontSize="small" />
                                                    </ListItemIcon>
                                                    <p className="text-gray-400">Salir</p>
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                )}
            </nav>
        )
    }

    return render()
}
export default Toolbar