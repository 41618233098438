import React, { useEffect, useState, useRef } from 'react'
import { addDays, format, parse } from '../utils/DateUtils';
import { FormControl, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke';
import Toolbar from '../components/Toolbar';
import { Link, useLocation } from 'react-router-dom'
import Pagination from '../components/Pagination'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { numberFormat } from '../utils/NumberUtils';
import { onlySimpleCharacters } from '../utils/FormUtils'
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import Flicking from "@egjs/react-flicking";
import { Button, Fade, Menu } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ForumIcon from '@mui/icons-material/Forum';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import UndoIcon from '@mui/icons-material/Undo';
import { useHistory } from "react-router-dom";

import DalalaDialog from '../components/DalalaDialog';
import ConsultaPoliza from './ConsultaPoliza';
import PagoRecibo from './PagoRecibo';
import Bitacora from './Bitacora';
import Adjuntos from './Adjuntos';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Correo from './Correo';
import {
    CXC_RESET,
    CXC_UPDATE_ROOT,
    CXC_UPDATE_STATUS
} from "../redux/redux-const";


//import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
//import 'swiper/swiper.min.css'
//import 'swiper/modules/pagination/pagination.min.css'

const CxC = (props) => {

    const dispatch = useDispatch()

    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [openPolizaDialog, setOpenPolizaDialog] = useState({ show: false, id: null, reciboId: null })
    const [openPagoDialog, setOpenPagoDialog] = useState({ show: false, id: null })
    const [openCorreoDialog, setOpenCorreoDialog] = useState({ show: false, id: null })

    const {
        loading,
        loadingListado,
        filters,
        listado,
        recibos,
        filtersListado
    } = useSelector(state => state.pages.cxc)

    const fieldList = [
        { id: "cliente_nombre[cn]", value: "Cliente", type: "text", show: true },
        { id: "poliza_noPoliza[cn]", value: "Número de Póliza", type: "text", show: true },
        { id: "aseguradora_abreviacion[cn]", value: "Aseguradora", type: "text", show: true },
    ]

    const dateRangeScrollRef = useRef()

    const ranges = [
        [-60, -1],     // -1
        [0, 3],        // 0
        [4, 9],         // 5
        [10, 24],        // 15
        [25, 45],       // 30
        [46, 60]        // 45
    ]

    const getDateRange = () => {
        return ranges.map(x => {
            const start = addDays(new Date(), x[0])
            const end = addDays(new Date(), x[1])
            return [`${start.getDate()} ${start.toLocaleDateString("es-ES", { month: "short" })}`, `${end.getDate()} ${end.toLocaleDateString("es-ES", { month: "short" })}`]
        })
    }

    useEffect(() => {
        return () => { dispatch({ type: CXC_RESET }) }
    }, [])

    useEffect(() => {
        fetchRecibos()
    }, [filters.types, filters.range])

    useEffect(() => {
        fetchListado(false)
    }, [listado.currentPage])

    useEffect(() => {
        if (filters.clickRange !== undefined) {
            fetchListado();
        }

    }, [filters.types, filters.range, filters.clickRange])

    const fetchRecibos = async (e) => {
        try {
            dispatch({ type: CXC_UPDATE_ROOT, payload: { loading: true } })

            let [start, end] = ranges[filters.range]
            start = format(addDays(new Date(), start), "yyyy-MM-dd")
            end = format(addDays(new Date(), end), "yyyy-MM-dd")

            let rawResponse = await APIInvoke.invokeGET(`/recibos?fecVence[bw]=${start},${end}&status=PENDIENTE&size=100`)
            let response = await rawResponse.json()
            dispatch({ type: CXC_UPDATE_ROOT, payload: { recibos: response.body } })
            dateRangeScrollRef.current?.moveTo(filters.range)
        } catch (e) {
            console.log(e)
        } finally {
            dispatch({ type: CXC_UPDATE_ROOT, payload: { loading: false } })
        }
    }

    const fetchListado = async (changeCritery) => {
        try {
            dispatch({ type: CXC_UPDATE_ROOT, payload: { loadingListado: true } })

            const field = fieldList.find(x => x.id === filtersListado.field)

            let [start, end] = ranges[filters.range]
            start = format(addDays(new Date(), start), "yyyy-MM-dd")
            end = format(addDays(new Date(), end), "yyyy-MM-dd")

            let query = ""
            let orderBy = ""
            if ((field.type === "text") && filtersListado.value.trim().length !== 0) {
                query = `&${filtersListado.field}=${filtersListado.value}`

                if (field.id === "cliente") {
                    orderBy = `&order=CONCAT(poliza.cliente.nombres, ' ', poliza.cliente.apPat, ' ', poliza.cliente.apMat) ${filtersListado.orderBy}`
                } else if (field.id === "noPoliza") {
                    orderBy = `&order=poliza.noPoliza ${filtersListado.orderBy}`
                }
            } else if (field.type === "date" || field.type === "numeric") {
                if (field.id === "vigencia") {
                    orderBy = `&order=fecVence ${filtersListado.orderBy}`
                } else if (field.id === "total") {
                    orderBy = `&order=poliza.total ${filtersListado.orderBy}`
                }
            }

            let rawResponse = null;
            let response = null;
            if ((field.type === "text") && filtersListado.value.trim().length !== 0) {
                rawResponse = await APIInvoke.invokeGET(`/recibos?status=PENDIENTE${query}&page=${listado.currentPage}&size=10${orderBy}`)
                response = await rawResponse.json()
            } else {
                rawResponse = await APIInvoke.invokeGET(`/recibos?fecVence[bw]=${start},${end}&status=PENDIENTE${query}&page=${listado.currentPage}&size=10${orderBy}`)
                response = await rawResponse.json()
            }

            if (changeCritery) {
                dispatch({ type: CXC_UPDATE_ROOT, payload: { listado: { ...response.body, currentPage: 1 } } })
            } else {
                dispatch({ type: CXC_UPDATE_ROOT, payload: { listado: response.body } })
            }

        } catch (e) {
            console.log(e)
        } finally {
            dispatch({ type: CXC_UPDATE_ROOT, payload: { loadingListado: false } })
        }
    }

    const onTypeChange = (e, newType) => {
        if (newType.length) {
            dispatch({
                type: CXC_UPDATE_ROOT, payload: {
                    filters: {
                        ...filters,
                        types: newType,
                        clickRange: filters?.clickRange ? false : true
                    }
                }
            })
        }
        console.log("onTypeChange", newType)
    }

    const onRangeChange = (e, newRange) => {
        if (newRange || newRange === 0) {
            dispatch({
                type: CXC_UPDATE_ROOT, payload: {
                    filters: {
                        ...filters,
                        range: newRange,
                        clickRange: filters?.clickRange ? false : true
                    },
                    filtersListado: {
                        ...filtersListado,
                        value: ""
                    }
                }
            })
        }
    }

    const getIcon = (tipo) => {
        switch (tipo) {
            case "Vida":
                return <PersonIcon />
            case "Auto":
                return <DirectionsCarIcon />
            case "Salud":
                return <FavoriteIcon />
            case "Diversos":
                return <HomeIcon />
        }
    }

    const renderTypeField = () => {

        const field = fieldList.find(x => x.id === filtersListado.field)

        if (field?.show === false) return null

        if (field.type === "text") {
            return (
                <TextField
                    className="flex-1"
                    label="Buscar..."
                    type='text'
                    size="medium"
                    variant="outlined"
                    onInput={onlySimpleCharacters}
                    value={filtersListado.value}
                    onKeyPress={e => {
                        if (e.key == "Enter") fetchListado(true)
                    }}
                    onChange={e => {
                        dispatch({
                            type: CXC_UPDATE_ROOT, payload: {
                                filtersListado: {
                                    ...filtersListado,
                                    value: e.target.value
                                }
                            }
                        })
                    }}
                />
            )
        } else if (field.type === "numeric") {
            return (
                <TextField
                    className="flex-1"
                    label="Buscar..."
                    type='number'
                    size="medium"
                    variant="outlined"
                    value={filtersListado.value}
                    onKeyPress={e => {
                        if (e.key == "Enter") fetchListado(true)
                    }}
                    onChange={e => {
                        dispatch({
                            type: CXC_UPDATE_ROOT, payload: {
                                filtersListado: {
                                    ...filtersListado,
                                    value: e.target.value
                                }
                            }
                        })
                    }}
                />
            )
        }
        return null;
    }

    const onCompletedPagoRecibo = (recibo) => {
        const reciboId = recibo.id
        const reciboStatus = recibo.status

        dispatch({
            type: CXC_UPDATE_STATUS, payload: {
                id: reciboId,
                status: reciboStatus
            }
        })
        setOpenPagoDialog({ show: false, id: null })
    }

    const render = () => {

        return (
            <AdminTemplate loading={loading || loadingListado}>
                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={openPolizaDialog.show}
                    handleClose={() => setOpenPolizaDialog({ show: false, id: null, reciboId: null })}
                >
                    <ConsultaPoliza modal={true} polizaId={openPolizaDialog.id} reciboId={openPolizaDialog.reciboId} onCompleted={() => setOpenPolizaDialog({ show: false, id: null, reciboId: null })} />
                </DalalaDialog>

                {/* Envio Correo */}
                <DalalaDialog
                    title={"Correo Electrónico"}
                    open={openCorreoDialog.show}
                    handleClose={() => setOpenCorreoDialog({ show: false, id: null })}
                >
                    <Correo modal={true} entidad={"recibo"} entidadId={openCorreoDialog.id} correoPara={openCorreoDialog.correoPara} onCompleted={() => setOpenCorreoDialog({ show: false, id: null })} />
                </DalalaDialog>

                <DalalaDialog
                    title={"Pagar Recibo"}
                    open={openPagoDialog.show}
                    handleClose={() => setOpenPagoDialog({ show: false, id: null })}
                >
                    <PagoRecibo modal={true} reciboId={openPagoDialog.id} onCompleted={onCompletedPagoRecibo} />
                </DalalaDialog>

                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitacoraDialog.show}
                    handleClose={() => setOpenBitacoraDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"recibos"} entidadId={openBitacoraDialog.id} onCompleted={() => setOpenBitacoraDialog({ show: false, id: null })} />
                </DalalaDialog>

                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosDialog.show}
                    handleClose={() => setOpenAdjuntosDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"polizas"} entidadId={openAdjuntosDialog.id} onCompleted={() => setOpenAdjuntosDialog({ show: false, id: null })} />
                </DalalaDialog>

                <Toolbar title="Cobranza" >
                </Toolbar>

                <div className="main center">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-2">

                            <div className="mb-8 text-center">
                                <p className="mb-4 text-lg">Días por vencer</p>
                                <div className="bg-gradient-to-r from-red via-yellow to-blue block lg:inline-block rounded-md mb-2 " style={{ padding: "2px" }}>
                                    <div className="bg-dark-600 rounded-md p-2">
                                        <ToggleButtonGroup
                                            value={filters.range}
                                            size="medium"
                                            exclusive
                                            onChange={onRangeChange}
                                            aria-label="text alignment"
                                            className="gradiant-range-group"
                                        >
                                            <ToggleButton value={0} aria-label="left aligned" className="w-12 h-8">
                                                <p>&lt;</p>
                                            </ToggleButton>
                                            <ToggleButton value={1} aria-label="centered" className="w-12 h-8">
                                                <p>0</p>
                                            </ToggleButton>
                                            <ToggleButton value={2} aria-label="right aligned" className="w-12 h-8">
                                                <p>5</p>
                                            </ToggleButton>
                                            <ToggleButton value={3} aria-label="justified" className="w-12 h-8">
                                                <p>15</p>
                                            </ToggleButton>
                                            <ToggleButton value={4} aria-label="justified" className="w-12 h-8">
                                                <p>30</p>
                                            </ToggleButton>
                                            <ToggleButton value={5} aria-label="justified" className="w-12 h-8" >
                                                <p>45</p>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>

                                </div>

                                <div>
                                    <div className="inline-block " style={{ width: "370px", maxWidth: "100%" }}>
                                        <Flicking
                                            ref={dateRangeScrollRef}
                                            align="center"
                                            adaptive={true}
                                            disableOnInit={true}
                                            panelsPerView={3}
                                            index={filters.range}
                                            currentPanel={filters.range}>
                                            {getDateRange().map((dates, index) => {
                                                const styles = filters.range === index ? "py-0 text-sm mx-2 lg:mx-4 text-yellow panel flicking-panel" : "py-0 text-sm mx-2 lg:mx-4 text-gray-600 panel flicking-panel"
                                                return <p key={index} className={styles}>{`${dates[0]} - ${dates[1]}`}</p>
                                            })}
                                        </Flicking>
                                    </div>
                                </div>
                            </div>

                            <div className="relative mb-8">
                                <div className="hidden lg:block lg:absolute left-0 top-0 bottom-0 w-16 bg-gradient-to-r from-dark-600 to-transparent z-10" />
                                <div className="hidden lg:block lg:absolute right-0 top-0 bottom-0 w-16 bg-gradient-to-r from-transparent to-dark-600  z-10" />
                                {recibos.items.length > 0 && (
                                    <Flicking align="center" adaptive={true}>
                                        {
                                            recibos.items.map((recibo, index) => (
                                                <article key={index} className="bg-dark-400 rounded-md p-4 panel mr-4 grid grid-cols-1 gap-1 w-full lg:w-3/5 flicking-panel" >
                                                    <div className="flex flex-row ">
                                                        <div className="flex-1 flex items-center justify-start truncate">
                                                            <p className="text-base truncate ...">{`${recibo.poliza.cliente.nombres} ${recibo.poliza.cliente.apPat} ${recibo.poliza.cliente.apMat}`}</p>
                                                        </div>
                                                        <div>
                                                            <div className="bg-dark-600 capitalize p-1 rounded-md" >
                                                                <p className="pr-1  flex items-center text-sm">
                                                                    <span className="pl-1 flex items-center">{getIcon(recibo.poliza.ramo.ramo)}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 mb-2">
                                                        <div className=" border-r  border-gray-800 lg:col-span-2 ">
                                                            <p className="text-gray-800 text-sm">Poliza:</p>
                                                            <p className="text-base ">{recibo.poliza.noPoliza}</p>
                                                        </div>
                                                        <div className="text-sm lg:border-r border-gray-800">
                                                            <p className="text-gray-800 ">Total:</p>
                                                            <p className="text-base">{numberFormat(recibo.poliza.total)}</p>
                                                        </div>
                                                        <div className="text-sm border-r border-gray-800 lg:border-0">
                                                            <p className="text-gray-800 ">Recibo:</p>
                                                            <p className="text-base">{`${recibo.serie}/${recibo.totalSerie}`}</p>
                                                        </div>
                                                        <div className="text-sm lg:border-r border-gray-800 lg:col-span-2">
                                                            <p className="text-gray-800 ">Vigencia:</p>
                                                            <p className="text-base">{`${format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yy")} - ${format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yy")}`}</p>
                                                        </div>
                                                        <div className="text-sm ">
                                                            <p className="text-gray-800 col-span-2">Vence:<br className="hidden lg:flex" /> <span className="text-base">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yy")}</span></p>
                                                        </div>
                                                    </div>

                                                    <div className="">
                                                        <p className="text-md mb-2">Opciones</p>

                                                        <div className="flex gap-0">

                                                            <Link className="no-underline p-1" to="#" onClick={e => {
                                                                setOpenPolizaDialog({ show: true, id: recibo.poliza.id, reciboId: recibo.id })
                                                            }}>
                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                    <VisibilityIcon className="text-dark-600 ml-2"></VisibilityIcon>
                                                                </div>
                                                            </Link>

                                                            {recibo.status === "pendiente" && (
                                                                <Link className="no-underline p-1" to="#" onClick={e => setOpenPagoDialog({ show: true, id: recibo.id })}>
                                                                    <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                        <AttachMoneyIcon className="text-dark-600 ml-2"></AttachMoneyIcon>
                                                                    </div>
                                                                </Link>
                                                            )}

                                                            {recibo.status === "pagado" && (
                                                                <Link className="no-underline p-1" to={``}>
                                                                    <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                        <UndoIcon className="text-dark-600 ml-2"></UndoIcon>
                                                                    </div>
                                                                </Link>
                                                            )}

                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenCorreoDialog({ show: true, id: recibo.id, correoPara: recibo.poliza?.cliente?.correos?.map(x => x.correo).join(", ") || "" })}>
                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                    <MailIcon className="text-dark-600 ml-2"></MailIcon>
                                                                </div>
                                                            </Link>

                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenBitacoraDialog({ show: true, id: recibo.id })}>
                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                    <ForumIcon className="text-dark-600 ml-2"></ForumIcon>
                                                                </div>
                                                            </Link>

                                                            <Link className="no-underline p-1" to="#" onClick={e => setOpenAdjuntosDialog({ show: true, id: recibo.poliza.id })}>
                                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                                    <AttachFileIcon className="text-dark-600 ml-2"></AttachFileIcon>
                                                                </div>
                                                            </Link>


                                                        </div>

                                                    </div>
                                                </article>
                                            ))
                                        }
                                    </Flicking>
                                )}
                            </div>

                            {recibos.items.length === 0 && <p className="text-center mb-8">Sin resultados</p>}

                            <p className="mb-4 text-lg">Recibos • {listado.totalItems}</p>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                                <div className="flex gap-2">
                                    <FormControl variant="outlined" size="medium" required className="flex-1">
                                        <InputLabel id="field" required>Buscar por</InputLabel>
                                        <Select
                                            onChange={e => {
                                                dispatch({
                                                    type: CXC_UPDATE_ROOT, payload: {
                                                        filtersListado: {
                                                            ...filtersListado,
                                                            field: e.target.value,
                                                            value: "",
                                                            orderBy: "desc"
                                                        }
                                                    }
                                                })
                                            }}
                                            value={filtersListado.field}
                                            labelId="field"
                                            label="Buscar por"
                                            size="medium"
                                            name="field"
                                            required
                                        >
                                            {fieldList.map((item, index) => <MenuItem key={index} value={item.id}>{item.value}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        className=" p-0 m-0 border-2 border-yellow border-solid"
                                        onClick={e => fetchListado(true)}
                                        onClick={e => {
                                            dispatch({
                                                type: CXC_UPDATE_ROOT, payload: {
                                                    filtersListado: {
                                                        ...filtersListado,
                                                        orderBy: filtersListado.orderBy === "desc" ? "asc" : "desc"
                                                    }
                                                }
                                            })
                                        }}>
                                        {filtersListado.orderBy === "desc" ? <ArrowDownwardIcon className="text-yellow" /> : <ArrowUpwardIcon className="text-yellow" />}
                                    </Button>
                                </div>


                                <div className="flex gap-2">
                                    {renderTypeField()}

                                    <Button className=" p-0 btn btn-yellow m-0" onClick={e => fetchListado(true)}>
                                        <SearchIcon className="text-dark-600 "></SearchIcon>
                                    </Button>
                                </div>
                            </div>

                            <div className="bg-dark-400 p-2 md:p-4 grid grid-cols-1 gap-2 md:gap-4 rounded-md">
                                {
                                    listado.items && listado.items.map((reciboItem, key) => {
                                        const polizaId = reciboItem.poliza.id
                                        return (
                                            <ul key={key} className="shadow-lg shadow-yellow">
                                                <Link className="no-underline" to="#" onClick={e => setOpenPolizaDialog({ show: true, id: reciboItem.poliza.id, reciboId: reciboItem.id })}>
                                                    <article className="bg-dark-300 rounded-md p-3 ">
                                                        <div className="flex flex-row mb-2">
                                                            <div className="flex-1 flex items-center">
                                                                <p>{`${reciboItem.poliza.cliente.nombres} ${reciboItem.poliza.cliente.apPat} ${reciboItem.poliza.cliente.apMat}`}</p>
                                                            </div>
                                                            <div>
                                                                <div className="bg-dark-600 capitalize p-1 lg:p-2 rounded-md" >
                                                                    <p className="pr-1 ml-1 flex items-center text-sm">
                                                                        <span className="flex items-center">{getIcon(reciboItem.poliza.ramo.ramo)}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row">
                                                            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 flex-1 items-center">
                                                                <p className="text-sm">Poliza:{reciboItem.poliza.noPoliza}</p>
                                                                <p className="text-sm">Recibo: {`${reciboItem.serie}/${reciboItem.totalSerie}`}
                                                                    <span className="text-sm text-right">&nbsp;&nbsp;&nbsp;{reciboItem.poliza.agenteClave.aseguradora.abreviacion}</span></p>
                                                                <p className="text-sm">Total: {numberFormat(reciboItem.poliza.total)}</p>
                                                                <p className="text-sm">Vence: {format(new Date(reciboItem.fecVence), "dd MMM yy")}</p>
                                                                <p className="text-sm col-span-2 lg:col-span-4">Concepto: {reciboItem.poliza.concepto}</p>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </Link>
                                            </ul>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="mt-4">
                            <Pagination totalItems={listado.totalItems} pages={listado.totalPages} page={listado.currentPage} onPageChange={newPage => dispatch({ type: CXC_UPDATE_ROOT, payload: { listado: { ...listado, currentPage: newPage } } })} />
                        </div>
                    </div>
                </div>

            </AdminTemplate>
        )
    }



    return render()
}

export default CxC