import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, TextField } from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import APIInvoke from '../../utils/APIInvoke';
import { useParams, useHistory } from "react-router-dom";
import { format, parse } from '../../utils/DateUtils'
import Alert from '../../components/Alert';
import Toolbar from '../../components/Toolbar'
import { maxFieldLength, onlyLetter, onlyNumbers, onlySimpleCharacters } from '../../utils/FormUtils'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import GrantContent from '../../components/GrantContent'
import * as Permisos from '../../utils/Permisos'

const NuevoProspecto = (props) => {

    const history = useHistory()
    const params = useParams()
    const { prospectoId } = useParams();

    const prospecto = props.prospecto

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        personalidad: "fisica",
        nombres: "",
        sexo: "",
        fecNacimiento: null,
        edoCivil: "",
        telefono: "",
        email: ""
    })
    const [message, setMessage] = useState(null)
    const [codigos, setCodigos] = useState([])



    useEffect(async () => {
        const parametroProspectoId = prospectoId || props.prospecto?.id
        if (parametroProspectoId) {
            try {
                setLoading(true)
                const response = await (await APIInvoke.invokeGET(`/prospectos/${parametroProspectoId}`)).json()
                const body = response.body

                setState({
                    id: body.id,
                    personalidad: body.fiscal,
                    nombres: body.nombres,
                    sexo: body.sexo,
                    fecNacimiento: body.fecNacimiento ? parse(body.fecNacimiento, "yyyy-MM-dd") : null,
                    edoCivil: body.edoCivil,
                    email: body.email,
                    telefono: body.telefono
                })
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        } else if (prospecto) {
            setState(prospecto)
        }

    }, [])

    const onChange = (e) => {
        let value = e.target.value
        const name = e.target.name
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }

        setState({
            ...state,
            [name]: value
        })
    }

    const save = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const colonia = codigos.find(x => x.id == state.colonia) || { municipio: "" }

            let request = null
            if (state.personalidad === "fisica") {
                request = {
                    id: state.id,
                    nombres: state.nombres,
                    fecNacimiento: state.fecNacimiento ? format(state.fecNacimiento, "yyyy-MM-dd") : null,
                    fiscal: state.personalidad,
                    edoCivil: state.edoCivil ? state.edoCivil : null,
                    sexo: state.sexo ? state.sexo : null,
                    telefono: state.telefono,
                    email: state.email
                }
            } else {
                request = {
                    id: state.id,
                    nombres: state.nombres,
                    fecRegistro: state.fecRegistro ? format(state.fecRegistro, "yyyy-MM-dd") : null,
                    fiscal: state.personalidad,
                    telefono: state.telefono,
                    email: state.email
                }
            }

            const response = state.id
                ? await (await APIInvoke.invokePUT(`/prospectos/${prospectoId || state.id}`, request)).json()
                : await (await APIInvoke.invokePOST(`/prospectos`, request)).json()
            if (response.ok) {
                setState({
                    ...state,
                    id: response.body.id
                })
                setMessage({
                    type: "success",
                    message: "Prospecto guardado correctamente"
                })
                if (props.onCompleted) {
                    props.onCompleted(response.body)
                } else {
                    history.push({ pathname: `/prospectos/${response.body.id}`, state: { backUrl: `/prospectos` } })
                }
            } else {
                setMessage({
                    type: "error",
                    message: response.message
                })
            }
        } catch (e) {
            console.error("error al guardar el prospecto", e)
            setMessage({
                type: "error",
                message: "Error al guardar el prospecto"
            })
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Toolbar title="Captura de Prospecto" backUrl={`/prospectos/${prospectoId}`} modal={props.modal}>
                </Toolbar>
                <div className="main center">
                    <div className="layout-compact">
                        <form onSubmit={save}>
                            <div className="grid grid-cols-1  gap-4">
                                <FormControl variant="outlined" size="medium" >
                                    <InputLabel id="personalidad" required>Personalidad</InputLabel>
                                    <Select
                                        labelId="personalidad"
                                        label="Personalidad"
                                        size="medium"
                                        name="personalidad"
                                        value={state.personalidad}
                                        onChange={onChange}
                                    >
                                        <MenuItem value="fisica" >Física</MenuItem>
                                        <MenuItem value="moral">Moral</MenuItem>
                                    </Select>
                                </FormControl>
                                {state.personalidad === "fisica" && renderFisica()}
                                {state.personalidad === "moral" && renderMoral()}
                                {renderLocalizacion()}
                                <Button type="submit" className="btn btn-yellow">GUARDAR PROSPECTO</Button>
                                {message && <Alert {...message} />}
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    const renderFisica = () => {
        return (
            <>
                <TextField
                    label="Nombre"
                    type="text"
                    variant="outlined"
                    required
                    name="nombres"
                    onInput={event => onlyLetter(event, 200)}
                    value={state.nombres}
                    onChange={onChange}
                />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            name="fecNacimiento"
                            label="Nacimiento"
                            inputFormat="dd/MM/yyyy"
                            value={state.fecNacimiento}
                            onChange={(newValue) => {
                                setState({ ...state, fecNacimiento: newValue })
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    <FormControl variant="outlined" size="medium" >
                        <InputLabel id="sexo" >Sexo</InputLabel>
                        <Select
                            labelId="sexo"
                            label="Sexo"
                            size="medium"
                            name="sexo"
                            value={state.sexo}
                            onChange={onChange}
                        >
                            <MenuItem value="" ><em>Seleccione</em></MenuItem>
                            <MenuItem value="hombre" >Hombre</MenuItem>
                            <MenuItem value="mujer">Mujer</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <FormControl variant="outlined" size="medium" >
                    <InputLabel id="edoCivil">Estado Civil</InputLabel>
                    <Select
                        labelId="edoCivil"
                        label="Estado Civil"
                        size="medium"
                        name="edoCivil"
                        value={state.edoCivil}
                        onChange={onChange}
                    >
                        <MenuItem value="" ><em>Seleccione</em></MenuItem>
                        <MenuItem value="soltero" >Soltero</MenuItem>
                        <MenuItem value="casado">Casado</MenuItem>
                        <MenuItem value="viudo" >Viudo</MenuItem>
                        <MenuItem value="divorciado">Divorciado</MenuItem>
                        <MenuItem value="concubinato">Concubinato</MenuItem>
                    </Select>
                </FormControl>

            </>
        )
    }

    const renderMoral = () => {
        return (
            <>
                <TextField
                    label="Razón Social"
                    type="text"
                    variant="outlined"
                    required
                    name="nombres"
                    onInput={event => onlySimpleCharacters(event, 200)}
                    value={state.nombres}
                    onChange={onChange}
                />
            </>
        )
    }

    const renderLocalizacion = () => {
        return (
            <>
                <p className="mt-8">LOCALIZACIÓN</p>
                <TextField
                    label="Correo"
                    type="email"
                    variant="outlined"
                    name="email"
                    onInput={event => maxFieldLength(event, 100)}
                    value={state.email}
                    onChange={onChange}
                />

                <TextField
                    label="Teléfono"
                    type="text"
                    variant="outlined"
                    name="telefono"
                    onInput={event => onlyNumbers(event, 10)}
                    value={state.telefono}
                    onChange={onChange}
                />

            </>
        )
    }

    return render()

}

export default NuevoProspecto