import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import AdminTemplate from '../template/AdminTemplate';
import APIInvoke from '../utils/APIInvoke';
import { format } from '../utils/DateUtils'
import PolizaItem from '../components/PolizaItem';
import Pagination from '../components/Pagination';
import Toolbar from '../components/Toolbar'
import DalalaDialog from '../components/DalalaDialog';
import ConsultaPoliza from './ConsultaPoliza';
import { useDispatch } from 'react-redux';
import { hasText, hasValue } from '../utils/StringUtils';
import { getRamoById } from '../utils/EnumUtils';
import { DateTime } from 'luxon';
import FilterBuilder2 from '../components/FilterBuilder/FilterBuilder2';
import TextFilter from '../components/FilterBuilder/TextFilter';
import SelectFilter from '../components/FilterBuilder/SelectFilter';
import BooleanFilter from '../components/FilterBuilder/BooleanFilter';
import SearchFilter from '../components/FilterBuilder/SearchFilter';
import GrantContent from '../components/GrantContent'
import * as Permisos from '../utils/Permisos'

const anios = (() => {
    const anio = parseInt(new Date().getFullYear())
    const max = anio + 1
    const min = anio - 10
    var list = [];
    for (var year = max; year > min; year--) {
        list.push({
            key: year,
            value: year
        });
    }
    return list
})()

const filters = [
    {
        id: "nombreId",
        name: "nombreId",
        placeholder: "Cliente",
        colSpan: 1,
        filter: "clienteId",
        type: SearchFilter,
        values: async (filter) => {
            if (filter.value.length < 3) return []
            const response = await (await APIInvoke.invokeGET(`/clientes?nombre=${filter.value}&size=20&order=nombres%20asc`)).json()
            return response.body.items
        },
        getOptionLabel: (option) => {
            return hasValue(option) ? `${option.nombres} ${option.apPat} ${option.apMat}` : ""
        },
        isOptionEqualToValue: (option, value) => {
            return option.id === value.id
        },
        processInput: (value) => {
            return hasValue(value) ? value.id : ""
        },
        defaultValue: ""
    }, {
        id: "vendedorId",
        name: "vendedorId",
        placeholder: "Vendedor",
        filter: "vendedorId",
        type: SearchFilter,
        values: async (filter) => {
            if (filter.value.length < 3) return []
            const response = await (await APIInvoke.invokeGET(`/vendedores?nombre[cn]=${filter.value}&size=20&order=nombres%20asc`)).json()
            return response.body.items
        },
        getOptionLabel: (option) => {
            return hasValue(option) ? `${option.nombres} ${option.apPat} ${option.apMat}` : ""
        },
        isOptionEqualToValue: (option, value) => {
            return option.id === value.id
        },
        processInput: (value) => {
            return hasValue(value) ? value.id : ""
        },
        defaultValue: ""
    }, {
        id: "noPoliza",
        name: "noPoliza",
        placeholder: "Número de póliza",
        filter: "noPoliza[cn]",
        type: TextFilter,
        defaultValue: ""
    }, {
        id: "concepto",
        name: "concepto",
        placeholder: "Concepto",
        filter: "concepto[cn]",
        type: TextFilter,
        defaultValue: ""
    }, {
        id: "abreviacion",
        name: "abreviacion",
        placeholder: "Aseguradora",
        filter: "abreviacion[cn]",
        type: TextFilter,
        defaultValue: ""
    }, {
        id: "fechaVigenciaDe",
        name: "fechaVigenciaDe",
        placeholder: "Vigencia",
        filter: "fechaVigenciaDe[bw]",
        type: SelectFilter,
        values: anios,
        defaultValue: anios.find(option => option.key === DateTime.now().year),
        getOptionLabel: (option) => option.value,
        getOptionKey: (option) => option.key,
        processInput: (value) => {
            if (!hasValue(value)) return null
            const fechaInicial = new Date(value.key, 0, 1);
            const fechaFinal = new Date(value.key, 11, 31);
            return `${format(fechaInicial, "yyyy-MM-dd")},${format(fechaFinal, "yyyy-MM-dd")}`;
        }
    }, {
        id: "conducto",
        name: "conducto",
        placeholder: "Método de pago",
        filter: "conducto",
        type: SelectFilter,
        values: (async () => {
            const response = await (await APIInvoke.invokeGET("/catalogos/metodo-pago")).json()
            return response.body
        })(),
        defaultValue: ""
    }, {
        id: "ramo",
        name: "ramo",
        placeholder: "Ramo",
        filter: "ramo",
        type: SelectFilter,
        values: ["Vida", "Auto", "Salud", "Diversos"].map(ramo => {
            return {
                key: ramo,
                value: ramo,
                icon: getRamoById(ramo).icon
            }
        }),
        defaultValue: ""
    }, {
        id: "esRobot",
        name: "esRobot",
        placeholder: "Pendientes de validar",
        filter: "esRobot",
        type: BooleanFilter,
        defaultValue: null,
        processInput: (value) => {
            return value === true ? true : null
        }
    }
]

const Polizas = (props) => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [openPolizaDialog, setOpenPolizaDialog] = useState({ show: false, id: null })
    const [state, setState] = useState({
        queryBuilder: {
            queryString: `?fechaVigenciaDe[bw]=${DateTime.local().startOf("year").toFormat("yyyy-MM-dd")},${DateTime.local().endOf("year").toFormat("yyyy-MM-dd")}`
        },
        poliza: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0,
    });

    useEffect(() => {
        filter(state)
    }, [])

    const filter = async (state) => {
        try {
            setLoading(true)
            const query = state.queryBuilder.queryString
            const response = await (await APIInvoke.invokeGET(`/polizas${state.queryBuilder.queryString}${hasText(query) ? "&" : "?"}page=${state.currentPage}`)).json();
            setState({
                ...state,
                poliza: response.body.items,
                totalItems: response.body.totalItems,
                currentPage: response.body.currentPage,
                totalPages: response.body.totalPages,
                pageSize: response.body.pageSize
            });
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onFilterProcess = async (queryBuilder) => {
        const newState = {
            ...state,
            queryBuilder: queryBuilder,
            poliza: [],
            totalItems: 0,
            currentPage: 1,
            totalPages: 0,
            pageSize: 0,
        }
        setState(newState)
        filter(newState)
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading}>
                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={openPolizaDialog.show}
                    handleClose={() => setOpenPolizaDialog({ show: false, id: null })}
                >
                    <ConsultaPoliza modal={true} polizaId={openPolizaDialog.id} onCompleted={() => setOpenPolizaDialog({ show: false, id: null })} />
                </DalalaDialog>
                <Toolbar title="Polizas" >
                    <div className="layout py-4">
                        <div className="text-left">
                            <Link className="no-underline" to={{ pathname: "/polizas/nuevo", state: { backUrl: "/polizas" } }} >
                                <GrantContent permisos={[Permisos.POLIZAS_W]}>
                                    <Button className="bg-yellow"><AddBoxIcon className="text-dark-600"></AddBoxIcon><p className="text-dark-600 text-base capitalize">Añadir</p></Button>
                                </GrantContent>
                            </Link>
                        </div>
                    </div>
                </Toolbar>

                <div className="main">
                    <div className="layout mb-8 grid gap-8">
                        <FilterBuilder2 filters={filters} onProcess={onFilterProcess} />
                        <div className="grid gap-2 lg:gap-4 grid-cols-1 bg-dark-400 p-2 lg:p-4 rounded-md">
                            {
                                state.poliza.map((data, i) => (
                                    <Link className="no-underline" key={i} to="#" onClick={e => setOpenPolizaDialog({ show: true, id: data.polizaId })}>
                                        <PolizaItem
                                            contratante={`${data.nombres} ${data.apPat} ${data.apMat}`}
                                            tipo={data.ramo}
                                            noPoliza={data.noPoliza}
                                            concepto={data.concepto}
                                            esRobot={data.esRobot}
                                            aseguradora={data.aseguradora}
                                            total={data.total}
                                            status={data.status}
                                            finVigenciaA={data.fechaVigenciaA}
                                            finVigenciaDe={data.fechaVigenciaDe}
                                        ></PolizaItem>
                                    </Link>
                                ))
                            }

                            <div className="" >
                                <Pagination
                                    totalItems={state.totalItems}
                                    pages={state.totalPages}
                                    page={state.currentPage}
                                    onPageChange={newPage => {
                                        const newState = {
                                            ...state,
                                            currentPage: newPage,
                                        }
                                        setState(newState)
                                        filter(newState)
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate >
        )
    }

    return render()
}

export default Polizas