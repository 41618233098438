import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import Toolbar from '../../components/Toolbar';
import { Link } from 'react-router-dom'

const Integraciones = (props) => {

    const render = () => {
        return (
            <AdminTemplate loading={false} >
                <Toolbar title="Integraciones" />
                <div className="main">
                    <div className="layout-compact mb-8">
                        <div className="grid grid-cols-1 gap-8">
                            <Link to={{ pathname: "/configuracion/integraciones/todoist", state: { backUrl: "/configuracion/integraciones" } }} className="no-underline block">
                                <article className="bg-dark-400 p-4 rounded-md">
                                    <div className="text-center">
                                        <img src="/integrations/todoist/todoist-icon.png" alt="Todoist" className="inline-block text-center" />
                                    </div>
                                </article>
                            </Link>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}
export default Integraciones