import { React, useEffect, useState } from 'react'
import APIInvoke from '../utils/APIInvoke'
import AdminTemplate from '../template/AdminTemplate'
import Pagination from '../components/Pagination'
import { Link } from 'react-router-dom'
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox';
import Toolbar from '../components/Toolbar'
import SearchIcon from '@mui/icons-material/Search';

const Modelos = (props) => {

    const [loading, setLoading] = useState(true)
    const [state, setState] = useState([])

    useEffect(async () => {
        setLoading(true)
        try {
            const response = await (await APIInvoke.invokeGET(`/correo-modelo`)).json()
            setState(response.body)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [])


    const render = () => {
        return (
            <AdminTemplate loading={loading}>

                

                <Toolbar title="Modelos de Correo">
                    <div className="layout">
                        <div className="flex flex-row gap-4">
                            <Link to={{ pathname: "/configuracion/modelos/", state: { backUrl: "/configuracion/modelos" } }} className="btn btn-yellow px-4 py-2" size="small" ><AddBoxIcon /> Añadir Modelo</Link>
                        </div>
                    </div>
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        <div className="bg-dark-400 p-2 rounded-md">
                            <div className="grid grid-cols-1 gap-2 ">
                                {
                                    state.map(correo => (
                                        <Link key={correo.id} to={`/configuracion/modelos/${correo.id}`} className="no-underline">
                                            <article className="bg-dark-300 rounded-md p-4 ">
                                                <div className="grid gap-4 grid-cols-1">
                                                    <p className="text-sm text-gray-400">{correo.nombre}</p>
                                                    <p className="text-sm text-gray-400">{`Asunto: ${correo.asunto}`}</p>
                                                </div>
                                            </article>
                                        </Link>
                                    ))
                                }

                                {state.length === 0 && (
                                    <p className="text-yellow text-center mb-8">Sin resultados</p>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}
export default Modelos