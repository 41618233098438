import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Button, IconButton } from '@material-ui/core';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import MailIcon from '@mui/icons-material/Mail';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useParams, withRouter } from 'react-router';
import config from '../config'
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke'
import { dateTimeFormat } from '../utils/DateUtils';
import Toolbar from '../components/Toolbar'
import FileUploader from '../components/FileUploader';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Permisos from '../utils/Permisos'
import GrantContent from '../components/GrantContent';
import { hasValue } from '../utils/StringUtils';


const Bitacora = (props) => {

    /* states */
    const [loading, setLoading] = useState(true);
    const [deleteIndex, setDeleteIndex] = useState(null)

    const params = useParams()
    const entidad = props.entidad || params.entidad
    const entidadId = props.entidadId || params.entidadId


    const [data, setData] = useState({
        id: ""
    })
    const [bitacora, setBitacora] = useState({})

    const [todoist, setTodoist] = useState({})

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                if (entidad === "polizas") {
                    const responseCRM = await (await APIInvoke.invokeGET(`/crm?polizaId=${entidadId}`)).json();
                    setBitacora(responseCRM.body[0] || { textos: [] });

                    const polizaResponse = await (await APIInvoke.invokeGET(`/polizas/${entidadId}`)).json();
                    setData(polizaResponse.body)

                    const todoistResponse = await (await APIInvoke.invokeGET(`/todoist/poliza/${entidadId}`)).json();
                    if (todoistResponse.ok && todoistResponse?.body > 0) {
                        setTodoist(
                            { id: todoistResponse.body }
                        )
                    }
                } else if (entidad === "clientes") {
                    const clienteCRMResponse = await (await APIInvoke.invokeGET(`/crm?clienteId=${entidadId}`)).json();
                    setBitacora(clienteCRMResponse.body[0] || { textos: [] });

                    const clienteResponse = await (await APIInvoke.invokeGET(`/clientes/${entidadId}`)).json()
                    setData(clienteResponse.body)
                } else if (entidad === "recibos") {
                    const clienteCRMResponse = await (await APIInvoke.invokeGET(`/crm?reciboId=${entidadId}`)).json();
                    setBitacora(clienteCRMResponse.body[0] || { textos: [] });

                    const reciboResponse = await (await APIInvoke.invokeGET(`/recibos/${entidadId}`)).json()
                    setData(reciboResponse.body)
                    if (reciboResponse.body?.todoistID) {
                        setTodoist(
                            { id: reciboResponse.body.todoistID }
                        )
                    }
                } else if (entidad === "prospectos") {
                    const clienteCRMResponse = await (await APIInvoke.invokeGET(`/crm?prospectoId=${entidadId}`)).json();
                    setBitacora(clienteCRMResponse.body[0] || { textos: [] });

                    const reciboResponse = await (await APIInvoke.invokeGET(`/prospectos/${entidadId}`)).json()
                    setData(reciboResponse.body)
                    if (reciboResponse.body?.todoistID) {
                        setTodoist(
                            { id: reciboResponse.body.todoistID }
                        )
                    }
                } else if (entidad === "siniestros") {
                    const siniestroCRMResponse = await (await APIInvoke.invokeGET(`/crm?siniestroId=${entidadId}`)).json();
                    setBitacora(siniestroCRMResponse.body[0] || { textos: [] });

                    const siniestroResponse = await (await APIInvoke.invokeGET(`/siniestros/${entidadId}`)).json()
                    setData(siniestroResponse.body)
                } else {
                    new Error(`No está implementada ${entidad}`)
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false)
            }
        }
        init()
    }, [])

    const onAddBitacotra = (newItem) => {
        setBitacora({
            ...bitacora,
            textos: [
                newItem,
                ...bitacora.textos
            ]
        })
    }

    const deleteText = async () => {
        try {
            setLoading(true)
            const deleteTextResponse = await (await APIInvoke.invokeDELETE(`/crm/texts/${deleteIndex}`)).json()
            if (deleteTextResponse.ok) {
                setBitacora({
                    ...bitacora,
                    textos: bitacora.textos.filter(x => x.id !== deleteIndex)
                })
                setDeleteIndex(null)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        const cliente = data?.cliente || {}
        const poliza = data || {}
        const textos = bitacora?.textos || {}
        const backUrls = {
            polizas: `/poliza/${entidadId}`,
            clientes: `/clientes/${entidadId}`,
            recibos: `/recibos/${entidadId}`
        }
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Toolbar title="Bitácora" backUrl={props.location.state?.backUrl || backUrls[entidad]} modal={props.modal}>
                </Toolbar>

                <div className="main">
                    <div className="layout-compact">
                        <div className="grid grid-cols-1 gap-y-8 mb-8">
                            <GrantContent permisos={[Permisos.BITACORA_W]}>
                                <NewCRM setLoading={setLoading} data={data} entidad={entidad} onAddBitacotra={onAddBitacotra} todoist={todoist} />
                            </GrantContent>
                            <section >
                                {((!hasValue(bitacora)) || (!hasValue(bitacora.textos)) || (bitacora.textos.length === 0)) && (
                                    <p>No hay registros en la bitácora</p>
                                )}
                                {bitacora?.textos && bitacora?.textos.map((text, idx) => (
                                    <div key={idx}>
                                        {renderBitacoraType(text)}
                                    </div>
                                ))}
                            </section>
                        </div>
                    </div>
                </div>

                {
                    deleteIndex !== null && (
                        <Dialog
                            open={deleteIndex !== null}
                            onClose={e => setDeleteIndex(null)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                            <DialogContent>
                                <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar el registro?</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button className="mr-4" onClick={e => setDeleteIndex(null)}>CANCELAR</Button>
                                <Button className="bg-red-500 text-white" onClick={e => deleteText(e)} autoFocus>ACEPTAR</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </AdminTemplate >
        )
    }

    const renderBitacoraType = (text) => {
        let icons;
        switch (text.tipo) {
            case "nota general":
                icons = <ChatIcon />;
                break;
            case "llamada saliente":
                icons = <PhoneForwardedIcon />;
                break;
            case "llamada entrante":
                icons = <PhoneCallbackIcon />;
                break;
            case "platica con humano":
                icons = <RecordVoiceOverIcon />;
                break;
            case "correo":
                icons = <MailIcon />;
                break;
            case "adjunto":
                icons = <AttachFileIcon />;
                break;
        }

        if (text.tipo === "adjunto") {
            return (
                <section className="flex flex-row gap-2 text-gray-400 border-b text-sm mb-4">
                    <div className="">
                        {icons}
                    </div>
                    <div className="mb-4 grid justify-items-start flex-1">
                        <p className="align-middle text-justify" >{text.adjunto.archivo}</p>
                        <p className="text-xs text-gray-800 mt-2 mb-2"> {dateTimeFormat(text.fecha)}</p>

                        <div className="flex flex-row gap-4 justify-between w-full">
                            <div className="flex flex-row gap-4 ">
                                <a href={text.adjunto.path} target="_blank">
                                    <IconButton color="primary" className="flex items-center" size="medium">
                                        <VisibilityIcon className="text-yellow" fontSize="large" />
                                    </IconButton>
                                </a>
                                {/* <a href={text.adjunto.path} download>
                                    <IconButton color="primary" className="flex items-center" size="medium">
                                        <CloudDownloadIcon className="text-yellow" fontSize="large" />
                                    </IconButton>
                                </a> */}
                            </div>
                            <GrantContent permisos={[Permisos.BITACORA_D]}>
                                <IconButton color="primary" className="flex items-center" size="medium" onClick={e => setDeleteIndex(text.id)}>
                                    <CloseIcon className="text-red" fontSize="large" />
                                </IconButton>
                            </GrantContent>
                        </div>
                    </div>
                </section>
            )
        } else {
            return (
                <section className="flex flex-row gap-2 text-gray-400 border-b text-sm mb-4">
                    <div className="">
                        {icons}
                    </div>
                    <div className="mb-4 grid justify-items-start flex-1">
                        <p dangerouslySetInnerHTML={{ __html: text.texto.replace("\n", "<br/>") }} className="align-middle text-justify" ></p>
                        <p className="text-xs text-gray-800 mt-2 mb-2"> {dateTimeFormat(text.fecha)}</p>
                    </div>
                </section>
            )
        }
    }

    return render()

}

const NewCRM = ({ setLoading, entidad, data, onAddBitacotra, todoist }) => {

    const [errorMessage, setErrorMessage] = useState('');
    const [tiposCRM, setTiposCRM] = useState({
        newType: "0"
    });
    const [textos, setTextos] = useState({
        texto: ""
    });
    const [closeTodoist, setCloseTodoist] = useState({
        value: true
    });

    const [todoistAux, setTodoistAux] = useState({
        value: true
    });

    const postBitacora = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const request = {
                "asunto": "bitacora",
                "tipo": tiposCRM.newType || "0",
                "polizaId": entidad === "polizas" ? data.id : undefined,
                "clienteId": entidad === "clientes" ? data.id : undefined,
                "prospectoId": entidad === "prospectos" ? data.id : undefined,
                "reciboId": entidad === "recibos" ? data.id : undefined,
                "siniestroId": entidad === "siniestros" ? data.id : undefined,
                "textos": [
                    {
                        "tipo": tiposCRM.newType || "0",
                        "texto": textos.texto
                    }
                ]
            }
            const response = await (await APIInvoke.invokePOST(`/crm`, request)).json();
            if (response.ok === true) {
                setTiposCRM({ newType: "" })
                setTextos({ texto: "" })
                onAddBitacotra(response.body.textos[0])
                if (todoist?.id && closeTodoist.value && todoistAux.value) {
                    cierraTodoist();
                }
            } else {
                setErrorMessage(response.message);
            }

        } catch (e) {
            console.error("error al guardar bitacora", e)
        } finally {
            setLoading(false)
        }
    }

    const cierraTodoist = async (e) => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokePUT(`/todoist/${todoist.id}/cerrar/tipo/${entidad === 'polizas' ? 'POLIZA' : 'RECIBO'}`)).json();
            if (response.ok === true) {
                setTodoistAux(
                    { value: false }
                )
            } else {
                setErrorMessage(response.message);
            }
        } catch (e) {
            console.error("error al cerrar todoist", e)
        } finally {
            setLoading(false)
        }
    }


    const handleChangeTipos = (e, newType) => {
        setTiposCRM({
            ...tiposCRM,
            newType: newType
        });
    };

    const handleChangeTextos = (e) => {
        let value = e.target.value
        setTextos({
            ...textos,
            [e.target.name]: value
        });
    };

    const onUpload = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const thisFiles = e.target.files[0]

            const body = new FormData();
            body.append('bucket', config.bucket.name);
            body.append('path', `polizas/${thisFiles.name}`);
            body.append('file', thisFiles)

            const request = {
                method: "POST",
                body
            }

            const uploading = await (await fetch(`${config.uploader.baseUrl}/storage/upload`, request)).json();

            if (uploading.ok !== true) {
                console.error(uploading.message)
                return
            }
            /* -------------------------------------------------------- */

            const adjuntoRequest = {
                "asunto": "bitacora de la poliza",
                "tipo": tiposCRM.newType || "0",
                "polizaId": entidad === "polizas" ? data.id : undefined,
                "clienteId": entidad === "clientes" ? data.id : undefined,
                "siniestroId": entidad === "siniestros" ? data.id : undefined,
                "prospectoId": entidad === "prospectos" ? data.id : undefined,
                "reciboId": entidad === "recibos" ? data.id : undefined,
                "textos": [
                    {
                        "tipo": "adjunto",
                        "texto": "",
                        adjunto: {
                            dominio: "",
                            path: uploading.body.url,
                            archivo: thisFiles.name,
                        }
                    }
                ]
            }
            const adjuntoResponse = await (await APIInvoke.invokePOST(`/crm`, adjuntoRequest)).json();
            if (adjuntoResponse.ok === true) {
                setTiposCRM({ newType: "" })
                setTextos({ texto: "" })
                onAddBitacotra(adjuntoResponse.body.textos[0])
            } else {
                setErrorMessage(adjuntoResponse.message);
            }

        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <>
                <form onSubmit={postBitacora}>
                    <div className="grid grid-cols-1 gap-y-8 mb-8">
                        <section>
                            <TextField
                                inputProps={{ className: "text-white" }}
                                className="w-full"
                                color="primary"
                                id="outlined-multiline-static"
                                label="Comentarios"
                                name="texto"
                                value={textos.texto}
                                onChange={handleChangeTextos}
                                multiline
                                rows={4}
                                required
                            />
                        </section>

                        <section className="text-left mr-1">
                            <ToggleButtonGroup exclusive value={tiposCRM.newType} onChange={handleChangeTipos} aria-label="device" className="flex justify-start h-10">
                                <ToggleButton value="llamada saliente" aria-label="llamada saliente" className="pill pill-fill mr-2 rounded-md"><PhoneForwardedIcon className="text-dark-500"></PhoneForwardedIcon></ToggleButton>
                                <ToggleButton value="llamada entrante" aria-label="llamada entrante" className="pill pill-fill mr-2 rounded-md"><PhoneCallbackIcon className="text-dark-500"></PhoneCallbackIcon></ToggleButton>
                                <ToggleButton value="platica con humano" aria-label="Bitácora Llamada Recibida" className="pill pill-fill mr-2 rounded-md"> <RecordVoiceOverIcon className="text-dark-500"></RecordVoiceOverIcon></ToggleButton>
                            </ToggleButtonGroup>
                            {todoist?.id && todoistAux.value && (
                                <div className="flex align-middle items-center pt-2">
                                    <Switch
                                        name="status"
                                        checked={closeTodoist.value}
                                        onChange={e => setCloseTodoist({ value: closeTodoist.value ? false : true })}
                                    />
                                    <p className="ml-2 capitalize text-white justify-start">Dar por terminada la tarea en Todoist *</p>
                                </div>)}
                            <section className="text-center mt-8 text-dark-400 text-base">
                                <Button type="submit" disabled={!textos.texto} className="btn btn-yellow w-72">
                                    Guardar Bitácora
                                </Button>
                                <p className="text-center text-red">{errorMessage}</p>
                            </section>
                        </section>
                    </div>
                </form>
                <section className="grid grid-cols-1">
                    <FileUploader onUpload={onUpload}>
                        <div className="text-left">
                            <p className="text-lg font-bold normal-case	">Carga archivo</p>
                            <p className="text-sm normal-case	"><span className="underline">Da clic aquí </span>para cargar un anexo</p>
                        </div>
                    </FileUploader>
                </section>
            </>
        )
    }

    return render()
}

const BitacoraRouter = withRouter(Bitacora);
export default BitacoraRouter;