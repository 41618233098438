import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AttachmentIcon from '@mui/icons-material/Attachment';
import EditIcon from '@mui/icons-material/Edit';
import ForumIcon from '@material-ui/icons/Forum';
import { Link, useHistory } from 'react-router-dom';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminTemplate from '../../template/AdminTemplate';
import APIInvoke from '../../utils/APIInvoke'
import { parse, format } from "../../utils/DateUtils";
import Toolbar from '../../components/Toolbar'
import DalalaDialog from '../../components/DalalaDialog';
import Bitacora from './../Bitacora';
import Adjuntos from './../Adjuntos';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@material-ui/core';
import { DialogTitle, IconButton, Tooltip } from '@mui/material';
import GrantContent from '../../components/GrantContent'
import * as Permisos from '../../utils/Permisos'

const ConsultaProspecto = (props) => {

    const params = useParams()
    const history = useHistory()
    const prospectoId = props.prospectoId || params.prospectoId

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState({
        direcciones: []
    })
    const [hasError, setHasError] = useState(false)
    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })
    const [showOkDialog, setShowOkDialog] = useState({ show: false, message: "" })


    useEffect(async () => {

        try {
            setLoading(true)
            const responseConProspecto = await (await APIInvoke.invokeGET(`/prospectos/${prospectoId}`)).json();
            setResult({
                ...responseConProspecto.body,
                fecNacimiento: parse(responseConProspecto.body.fecNacimiento, "yyyy-MM-dd")
            })
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }

    }, [])

    const deleteProspecto = async () => {
        try {
            setOpenDeleteDialog({ show: false })
            setLoading(true)
            const deleteResponse = await (await APIInvoke.invokeDELETE(`/prospectos/${prospectoId}`)).json()
            if (deleteResponse.ok) {
                history.push("/prospectos")
                props.onCompleted(deleteResponse.body)
            } else {
                setShowOkDialog({ show: true, message: deleteResponse.message })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitacoraDialog.show}
                    handleClose={() => setOpenBitacoraDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"prospectos"} entidadId={openBitacoraDialog.id} onCompleted={() => setOpenBitacoraDialog({ show: false, id: null })} />
                </DalalaDialog>
                <Dialog
                    open={openDeleteDialog.show}
                    onClose={e => setOpenDeleteDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar al prospecto?, si procede perderá toda la información del prospecto, botácora y adjuntos.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={deleteProspecto} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showOkDialog.show}
                    onClose={e => setShowOkDialog({ show: false, message: "" })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">{showOkDialog.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn btn-yellow p-2 text-sm" onClick={e => setShowOkDialog({ show: false, message: "" })} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Toolbar title="Detalle de Prospecto" backUrl={`/prospectos`} modal={props.modal}>
                    {result && (
                        <div className="layout">
                            <div className="flex justify-end gap-2">
                                <GrantContent permisos={[Permisos.PROSPECTOS_W]}>
                                    {result.status === "activo" && (
                                        <Tooltip title="Editar" >
                                            <IconButton className="btn btn-yellow h-full" onClick={e => history.push(`/prospectos/${prospectoId}/edit`)}>
                                                <EditIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </GrantContent>
                                <GrantContent permisos={[Permisos.BITACORA_R]}>
                                    <Tooltip title="Bitácora" >
                                        <IconButton className="btn btn-yellow h-full" onClick={e => setOpenBitacoraDialog({ show: true, id: result.id })}>
                                            <ForumIcon className="text-dark-600" />
                                        </IconButton>
                                    </Tooltip>
                                </GrantContent>
                                <GrantContent permisos={[Permisos.PROSPECTOS_D]}>
                                    {result.status === "activo" && (
                                        <Tooltip title="Eliminar" >
                                            <IconButton className="btn btn-yellow h-full" onClick={e => setOpenDeleteDialog({ show: true, id: result.id })}>
                                                <DeleteIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </GrantContent>
                            </div>
                        </div>
                    )}
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        {result && (
                            <div className="grid grid-cols-1 gap-y-8 mb-8">
                                {result?.esRobot && (
                                    <div className="flex gap-4 bg-yellow p-4 rounded-md">
                                        <WarningRoundedIcon className='text-dark-600' />
                                        <p className='flex-1 text-dark-600 text-sm'>Prospecto creado automáticamente con la tecnología de sincronización dalala <Link to={"#"} className="text-dark-600" onClick={e => setResult({
                                            ...result,
                                            verInfo: true
                                        })}>Más información</Link> </p>
                                    </div>
                                )}

                                <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                    <p className="text-gray-400 text-base font-semibold"><AccountCircleIcon fontSize="large" /> DATOS GENERALES</p>
                                    <section className="rounded-md bg-dark-300 p-4 grid grid-cols-1 gap-4 ">
                                        <div className="flex justify-between">
                                            <p className={result.status === "activo" ? "text-green-500 mt-4 mb-2 text-sm font-semibold uppercase" : "text-red mt-4 mb-2 text-sm font-semibold uppercase"}>{result.status}</p>

                                        </div>
                                        <section className="grid">
                                            <p className="text-yellow text-sm">Personalidad</p>
                                            <p className="text-white text-sm capitalize">{result.fiscal}</p>
                                        </section>
                                        <section className="grid">
                                            <p className="text-yellow text-sm">Nombre</p>
                                            <p className="text-white text-sm capitalize">{result.nombres + " " + result.apPat + " " + result.apMat}</p>
                                        </section>
                                        <section className="grid">
                                            <p className="text-yellow text-sm">Fecha de Nacimiento</p>
                                            <p className="text-white text-sm capitalize">{result.fecNacimiento ? format(result.fecNacimiento, 'dd/MM/yyyy') : "NA"}</p>
                                        </section>

                                        {result.fiscal === "fisica" && (
                                            <>
                                                <section className="grid ">
                                                    <p className="text-yellow text-sm">Sexo</p>
                                                    <p className="text-white text-sm capitalize">{result.sexo}</p>
                                                </section>
                                                <section className="grid ">
                                                    <p className="text-yellow text-sm">Estado Civil</p>
                                                    <p className="text-white text-sm capitalize">{result.edoCivil}</p>
                                                </section>
                                            </>
                                        )}
                                    </section>
                                </div>


                                <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                    <p className="text-gray-400 text-base font-semibold"><PersonPinCircleIcon fontSize="large" /> LOCALIZACIÓN</p>
                                    <section className="rounded-sm bg-dark-300 p-4 grid grid-cols-1 gap-4">
                                        <section className="grid">
                                            <p className="text-yellow text-sm">Teléfono</p>
                                            <p className="text-white text-sm capitalize">{result.telefono}</p>
                                        </section>
                                        <section className="grid">
                                            <p className="text-yellow text-sm">Correo</p>
                                            <p className="text-white text-sm">{result.email}</p>
                                        </section>
                                    </section>
                                </div>

                                {result.verInfo === true && (
                                    <Dialog
                                        open={result.verInfo === true}
                                        onClose={e => setResult({
                                            ...result,
                                            verInfo: false
                                        })}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogContent style={{ width: "330px", maxWidth: "100%" }}>
                                            <DialogContentText className="text-gray-400" id="alert-dialog-description">
                                                <p>
                                                    La póliza o prospecto se integró desde la <span className="text-yellow font-bold">sincronización automática</span>, este proceso utiliza el PDF original de la póliza para obtener los datos de la póliza o el prospecto, aunque este proceso se perfecciona día con día es <span className="text-yellow font-bold">indispensable</span> sea validado por el agente de forma puntual para así garantizar la <span className="font-bold">confiabilidad</span> de la información, por esto recomendamos revisar los siguientes puntos:
                                                </p>
                                                <p className="pl-4">
                                                    <ul className="py-6 list-disc">
                                                        <li>Datos del Prospecto</li>
                                                        <li>Primas de la póliza</li>
                                                        <li>Vigencias de la póliza</li>
                                                        <li>No. de Recibos</li>
                                                        <li>Primas de Recibos</li>
                                                        <li>Vigencias de Recibos</li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    También te recomendamos completar el correo y teléfono de tu prospecto para los procesos de comunicación operativos
                                                </p>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions className="justify-center pb-6">
                                            <Button className="btn-yellow font-bold w-32" onClick={e => setResult({
                                                ...result,
                                                verInfo: false
                                            })}>ACEPTAR</Button>
                                        </DialogActions>
                                    </Dialog>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </AdminTemplate>
        )
    }
    return render()

}

export default ConsultaProspecto