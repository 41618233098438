import { NUEVA_COTIZACION_TEMPLATE_RESET, NUEVA_COTIZACION_TEMPLATE_SET } from "../redux-const"

export const planPagoInitalState = {
    id: null,
    edit: true,
    formaPago: "",
    total: 0,
    primaPrimerRecibo: 0,
    primaReciboSub: 0,
    orden: null,
}

export const aseguradoInitalState = {
    id: null,
    edit: true,
    nombre: "",
    parentesco: "",
    nacimiento: null,
    genero: "",
    edad: ""
}

export const coberturaInitialState = {
    id: null,
    edit: true,
    descripcion: "",
    valor: ""
}

export const caracteristicaInitialState = {
    id: null,
    edit: true,
    descripcion: "",
    valor: ""
}

const initialState = {
    loading: false,
    expandAsegurados: false,
    expandCoberturas: true,
    openNewCliente: false,
    openClienteList: false,
    contratantesLoading: false,
    openNewClienteDialog: false,
    openNewProspectoDialog: false,
    nombre: "",
    ramo: "",
    plan: "",
    aseguradoraId: "",
    aseguradora: null,
    aseguradoras: [],
    monedas: [],
    coberturasOptions: [],
    coberturas: [],
    caracteristicas: [],
    moneda: "",
    sumaAsegurada: "",
    deducible: "",
    coaseguro: "",
    topeCoaseguro: "",
    status: "",
}

export const reducer = (state = initialState, action) => {
    const { payload } = action
    switch (action.type) {
        case NUEVA_COTIZACION_TEMPLATE_RESET: {
            return initialState;
        }
        case NUEVA_COTIZACION_TEMPLATE_SET: {
            return {
                ...state,
                ...payload
            }
        }
    }
    return state
}

export default reducer