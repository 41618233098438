import React, { useState } from 'react'
import Toolbar from '../components/Toolbar'
import AdminTemplate from '../template/AdminTemplate'
import { Link } from 'react-router-dom';

const ImportarPolizas = (props) => {

    const render = () => {
        return (
            <AdminTemplate loading={false}>
                <Toolbar title="Importar Pólizas" backUrl="/configuracion" />
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <Link
                                className="no-underline border border-yellow inline-block rounded-md p-4 hover:bg-dark-400 duration-500"
                                to={{ pathname: "/importar-polizas/pdf", state: { backUrl: "/importar-polizas" } }} >
                                <div className="flex flex-col items-center">
                                    <img src="/fileimport/pdf.png" alt="importar desde PDF" />
                                    <p className="text-center text-lg ">Importar carátulas (PDF)</p>
                                </div>
                            </Link>
                            <Link
                                className="no-underline border border-yellow inline-block rounded-md p-4 hover:bg-dark-400 duration-500"
                                to={{ pathname: "/importar-polizas/excel", state: { backUrl: "/importar-polizas" } }} >
                                <div className="flex flex-col items-center">
                                    <img src="/fileimport/excel.png" alt="importar desde excel" />
                                    <p className="text-center text-lg ">Importar desde Excel</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}





export default ImportarPolizas