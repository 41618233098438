import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import APIInvoke from '../../utils/APIInvoke';
import { useParams, useHistory } from "react-router-dom";
import { format, parse } from '../../utils/DateUtils'
import Alert from '../../components/Alert';
import Toolbar from '../../components/Toolbar'
import { maxFieldLength, onlyLetter, onlyNumbers, onlySimpleCharacters } from '../../utils/FormUtils'
import { calcularRFC, calcularRFCMoral } from '../../utils/RFCGenerator'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'


const NuevoVendedor = (props) => {

    const history = useHistory()
    const params = useParams()
    const { vendedorId } = useParams();

    const vendedor = props.vendedor

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        nombres: "",
        apPat: "",
        apMat: "",
        rfc: "",
        sexo: "",
        correo: "",
        telefono: ""
    })
    const [message, setMessage] = useState(null)
    const [codigos, setCodigos] = useState([])



    useEffect(async () => {
        const parametroVendedorId = vendedorId || props.vendedor?.id
        if (parametroVendedorId) {
            try {
                setLoading(true)
                const response = await (await APIInvoke.invokeGET(`/vendedores/${parametroVendedorId}`)).json()
                const body = response.body

                setState({
                    id: body.id,
                    nombres: body.nombres,
                    apPat: body.apPat,
                    apMat: body.apMat,
                    sexo: body.sexo,
                    rfc: body.rfc,
                    correo: body.correo,
                    telefono: body.telefono
                })
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        } else if (vendedor) {
            setState(vendedor)
        }

    }, [])

    const onChange = (e) => {
        let value = e.target.value
        const name = e.target.name
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }

        if (name === "rfc") {
            value = value.toUpperCase()
        }

        setState({
            ...state,
            [name]: value
        })
    }

    const onChangeTelefono = (telefono) => {
        setState({
            ...state,
            telefono: telefono
        })
    }

    const save = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const colonia = codigos.find(x => x.id == state.colonia) || { municipio: "" }

            let request = null
            request = {
                id: state.id,
                nombres: state.nombres,
                apPat: state.apPat,
                apMat: state.apMat,
                sexo: state.sexo,
                rfc: state.rfc,
                telefono: state.telefono,
                correo: state.correo
            }
            console.log(JSON.stringify(request))

            const response = state.id
                ? await (await APIInvoke.invokePUT(`/vendedores/${vendedorId || state.id}`, request)).json()
                : await (await APIInvoke.invokePOST(`/vendedores`, request)).json()
            if (response.ok) {
                setState({
                    ...state,
                    id: response.body.id
                })
                setMessage({
                    type: "success",
                    message: "Vendedor guardado correctamente"
                })
                if (props.onCompleted) {
                    props.onCompleted(response.body)
                } else {
                    history.push({ pathname: `/vendedores/${response.body.id}`, state: { backUrl: `/vendedores` } })
                }
            } else {
                setMessage({
                    type: "error",
                    message: response.message
                })
            }
        } catch (e) {
            console.error("error al guardar el vendedor", e)
            setMessage({
                type: "error",
                message: "Error al guardar el vendedor"
            })
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Toolbar title="Captura de vendedor" backUrl={`/vendedores/${vendedorId}`} modal={props.modal}>
                </Toolbar>
                <div className="main center">
                    <div className="layout-compact">
                        <form onSubmit={save}>
                            <div className="grid grid-cols-1  gap-4">
                                <TextField
                                    label="Nombres"
                                    type="text"
                                    variant="outlined"
                                    required
                                    name="nombres"
                                    onInput={event => onlyLetter(event, 200)}
                                    value={state.nombres}
                                    onChange={onChange}
                                />
                                <TextField
                                    label="Apellido Paterno"
                                    type="text"
                                    variant="outlined"
                                    required
                                    name="apPat"
                                    onInput={event => onlyLetter(event, 60)}
                                    value={state.apPat}
                                    onChange={onChange}
                                />
                                <TextField
                                    label="Apellido Materno"
                                    type="text"
                                    variant="outlined"
                                    required
                                    name="apMat"
                                    onInput={event => onlyLetter(event, 60)}
                                    value={state.apMat}
                                    onChange={onChange}
                                />

                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

                                    <FormControl variant="outlined" size="medium" >
                                        <InputLabel id="sexo" required>Sexo</InputLabel>
                                        <Select
                                            labelId="sexo"
                                            label="Sexo"
                                            size="medium"
                                            name="sexo"
                                            value={state.sexo}
                                            onChange={onChange}
                                        >
                                            <MenuItem value="hombre" >Hombre</MenuItem>
                                            <MenuItem value="mujer">Mujer</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="RFC"
                                        type="text"
                                        variant="outlined"
                                        required
                                        name="rfc"
                                        value={state.rfc}
                                        onInput={event => onlySimpleCharacters(event, 13)}
                                        onChange={onChange}
                                        helperText=""
                                        error={false}
                                    />
                                </div>

                                <TextField
                                    label="Correo"
                                    type="text"
                                    variant="outlined"
                                    required
                                    name="correo"
                                    onInput={event => onlyLetter(event, 100)}
                                    value={state.correo}
                                    onChange={onChange}
                                />


                                <PhoneInput
                                    className="block w-full"
                                    country={'mx'}
                                    name="telefono"
                                    specialLabel="Teléfono"
                                    preferredCountries={["mx", "us"]}
                                    disableDropdown={true}
                                    value={state.telefono}
                                    onChange={onChangeTelefono}
                                    inputProps={{ required: true }}
                                />


                                <Button type="submit" className="btn btn-yellow">GUARDAR VENDEDOR</Button>
                                {message && <Alert {...message} />}
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    const renderFisica = () => {
        return (
            <>


            </>
        )
    }

    return render()

}

export default NuevoVendedor