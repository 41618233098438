// NuevaPoliza
export const NUEVA_POLIZA_RESET_STATE = "NUEVA_POLIZA_RESET_STATE"
export const NUEVA_POLIZA_ONCHANGE = "NUEVA_POLIZA_ONCHANGE"
export const NUEVA_POLIZA_ONCHANGE_TYPE = "NUEVA_POLIZA_ONCHANGE_TYPE"
export const NUEVA_POLIZA_ONCHANGE_STEPPER = "NUEVA_POLIZA_ONCHANGE_STEPPER"
export const NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT = "NUEVA_POLIZA_ONCHANGE_STEPPER_NEXT"
export const NUEVA_POLIZA_ONCHANGE_GENERALES = "NUEVA_POLIZA_ONCHANGE_GENERALES"
export const NUEVA_POLIZA_ONCHANGE_VEHICULO = "NUEVA_POLIZA_ONCHANGE_VEHICULO"
export const NUEVA_POLIZA_ONCHANGE_PRIMAS = "NUEVA_POLIZA_ONCHANGE_PRIMAS"
export const NUEVA_POLIZA_ONCHANGE_RECIBOS = "NUEVA_POLIZA_ONCHANGE_RECIBOS"
export const NUEVA_POLIZA_ONCHANGE_DETALLES = "NUEVA_POLIZA_ONCHANGE_DETALLES"
export const NUEVA_POLIZA_ONCHANGE_CLIENTE_ES_ASEGURADO = "NUEVA_POLIZA_ONCHANGE_CLIENTE_ES_ASEGURADO"
export const NUEVA_POLIZA_ONCHANGE_COMISIONES = "NUEVA_POLIZA_ONCHANGE_COMISIONES"
export const NUEVA_POLIZA_CALCULAR_RECIBOS = "NUEVA_POLIZA_CALCULAR_RECIBOS"
export const NUEVA_POLIZA_LOAD_CARATULA = "NUEVA_POLIZA_LOAD_CARATULA"
export const NUEVA_POLIZA_ONCHANGE_ASEGURADO = "NUEVA_POLIZA_ONCHANGE_ASEGURADO"
export const NUEVA_POLIZA_AGREGAR_ASEGURADO = "NUEVA_POLIZA_AGREGAR_ASEGURADO"
export const NUEVA_POLIZA_REMOVER_ASEGURADO = "NUEVA_POLIZA_REMOVER_ASEGURADO"
export const NUEVA_POLIZA_APLICAR_TODOS_RECIBOS = "NUEVA_POLIZA_APLICAR_TODOS_RECIBOS"
export const EDITAR_POLIZA = "EDITAR_POLIZA"
export const RENOVAR_POLIZA = "RENOVAR_POLIZA"
export const POLIZA_UPDATE_STATUS = "POLIZA_UPDATE_STATUS"

//CxC
export const CXC_RESET = "CXC_RESET"
export const CXC_UPDATE_ROOT = "CXC_UPDATE_ROOT"
export const CXC_UPDATE_SECTION = "CXC_UPDATE_SECTION"
export const CXC_UPDATE_STATUS = "CXC_UPDATE_STATUS"



// Claves
export const CLAVES_UPDATE_ROOT = "CLAVES_UPDATE_ROOT"
export const CLAVES_UPDATE_CLAVE = "CLAVES_UPDATE_CLAVE"
export const CLAVES_DELETE_CLAVE = "CLAVES_DELETE_CLAVE"
export const CLAVES_ADD_CLAVE = "CLAVES_ADD_CLAVE"


export const DUMMY = "DUMMY"


// Cobranza
export const COBRANZA_RESET = "COBRANZA_RESET"
export const COBRANZA_UPDATE_STATE = "COBRANZA_UPDATE_STATE"
export const COBRANZA_UPDATE_RECIBO = "COBRANZA_UPDATE_RECIBO"
export const COBRANZA_UPDATE_DIALOG = "COBRANZA_UPDATE_DIALOG"
export const COBRANZA_UPDATE_FILTER = "COBRANZA_UPDATE_FILTER"

// Renovaciones
export const RENOVACIONES_RESET = "RENOVACIONES_RESET"
export const RENOVACIONES_UPDATE_STATE = "RENOVACIONES_UPDATE_STATE"
export const RENOVACIONES_UPDATE_RECIBO = "RENOVACIONES_UPDATE_RECIBO"
export const RENOVACIONES_UPDATE_DIALOG = "RENOVACIONES_UPDATE_DIALOG"
export const RENOVACIONES_UPDATE_FILTER = "RENOVACIONES_UPDATE_FILTER"


export const PROSPECTO_KANBAN_SET_FILTERS = "PROSPECTO_KANBAN_SET_FILTERS"
export const PROSPECTO_KANBAN_SET_LOADING = "PROSPECTO_KANBAN_SET_LOADING"
export const PROSPECTO_KANBAN_ADD_COLUMN = "PROSPECTO_KANBAN_ADD_COLUMN"
export const PROSPECTO_KANBAN_SET_KANBAN = "PROSPECTO_KANBAN_SET_KANBAN"
export const PROSPECTO_KANBAN_UPDATE_COLUMN = "PROSPECTO_KANBAN_UPDATE_COLUMN"
export const PROSPECTO_KANBAN_DELETE_COLUMN = "PROSPECTO_KANBAN_DELETE_COLUMN"
export const PROSPECTO_KANBAN_DELETE_CARD = "PROSPECTO_KANBAN_DELETE_CARD"
export const PROSPECTO_KANBAN_UPDATE_CARD = "PROSPECTO_KANBAN_UPDATE_CARD"
export const PROSPECTO_KANBAN_ADD_CARD = "PROSPECTO_KANBAN_ADD_CARD"
export const PROSPECTO_KANBAN_SAVE_CARD = "PROSPECTO_KANBAN_SAVE_CARD"


// NUEVO SINIESTRO
export const NUEVO_SINIESTRO_RESET = "NUEVO_SINIESTRO_RESET"
export const NUEVO_SINIESTRO_SET = "NUEVO_SINIESTRO_SET"


// NUEVA COTIZAVION
export const NUEVA_COTIZACION_RESET = "NUEVA_COTIZACION_RESET"
export const NUEVA_COTIZACION_SET = "NUEVA_COTIZACION_SET"

// NUEVA COTIZAVION TEMPLATE
export const NUEVA_COTIZACION_TEMPLATE_RESET = "NUEVA_COTIZACION_TEMPLATE_RESET"
export const NUEVA_COTIZACION_TEMPLATE_SET = "NUEVA_COTIZACION_TEMPLATE_SET"