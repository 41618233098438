import React, { useState } from 'react'
import Toolbar from '../components/Toolbar'
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke';
import config from '../config'
import ReportIcon from '@mui/icons-material/Report';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useContext } from 'react';
import UserContext from '../state/UserContext';
import { DataGrid } from '@mui/x-data-grid';
import FileUploader from '../components/FileUploader';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stepper, Typography } from '@mui/material';
import { useHistory } from "react-router-dom";
import { DateTime } from 'luxon';
import FormHelperText from '@mui/material/FormHelperText';
import ListSubheader from '@mui/material/ListSubheader';


const ImportarPolizasExcel = (props) => {

    const history = useHistory()
    const [data, setData] = useState(null)
    const [state, setState] = useState({
        dateFormat: "dd/MM/yyyy"
    })
    const [fileImport, setFileImport] = useState(null)
    const [resumen, setResumen] = useState({ ok: 0, warning: 0, error: 0 })
    const [loading, setLoading] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const { user } = useContext(UserContext)

    const onUpload = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const thisFiles = e.target.files[0]
            const fileName = `${DateTime.now().toFormat("yyyyMMddhhmmss")}-${thisFiles.name}`
            const body = new FormData();
            body.append('bucket', config.bucket.name);
            body.append('name', fileName);
            body.append('path', `agentes/${user.agenteId}/excel-import`);
            body.append('file', thisFiles)

            const request = {
                method: "POST",
                body
            }

            const uploading = await (await fetch(`${config.uploader.baseUrl}/storage/upload`, request)).json();

            if (uploading.ok !== true) {
                console.error(uploading.message)
                return
            }

            const excelRequest = {
                "fileName": fileName,
                "fileUrl": uploading.body.url,
                "dateFormat": state.dateFormat
            }

            setFileImport(excelRequest)

            const caratulaRawResponse = await APIInvoke.invokePOST(`/excel-importer/process`, excelRequest)
            const caratulaResponse = await caratulaRawResponse.json()
            if (caratulaResponse.ok) {
                setData(caratulaResponse.body)
                const calcResumen = caratulaResponse.body.reduce((acumulador, next) => {
                    acumulador[next.status.status] = acumulador[next.status.status] + 1
                    return acumulador
                }, {
                    ok: 0,
                    warning: 0,
                    error: 0
                })
                setResumen(calcResumen)
                if (calcResumen.warning > 0 || calcResumen.error > 0) {
                    setShowErrorDialog(true)
                }
            } else {
                setData(null)
                alert(caratulaResponse.message)
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
            e.target.value = '';
        }
    }

    const renderCell = (row) => {
        const { value, status, errorMessage = "" } = row.value
        let style = ""
        let errorIcon = null

        if (status === "ok") {
            style = "font-thin text-sm text-white"
        } else if (status === "warning") {
            style = "font-thin text-sm text-yellow"
            errorIcon = <ReportProblemIcon className="text-yellow text-base" />
        } else if (status === "error") {
            style = "font-thin text-sm text-red"
            errorIcon = <ReportIcon className="text-red text-base" />
        }

        return (<div className="flex items-center gap-2">
            {status !== "ok" && (
                <Tooltip title={errorMessage}>
                    <p className={style}>{errorIcon} {value}</p>
                </Tooltip>)}
            {status === "ok" && (<p className={style}>{value}</p>)}
        </div>)
    }

    const importar = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const importarResponse = await (await APIInvoke.invokePOST(`/excel-importer/importar`, fileImport)).json()
            history.push(`/importar-polizas/excel-result`, { polizas: importarResponse.body })
        } catch (error) {
            console.error("Error al importar las pólizas", e.message)
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        { field: "status", headerName: 'STATUS', width: 75, editable: false, sortable: false, type: "boolean", renderCell: (row) => { return row.value.value === "ok" ? <CheckCircleOutlineIcon className="text-green text-base" /> : row.value.value === "error" ? <HighlightOffIcon className="text-red text-base" /> : <ReportProblemOutlinedIcon className="text-yellow text-base" /> } },
        { field: "noPoliza", headerName: 'NO PÓLIZA', width: 150, editable: false, sortable: false, renderCell },
        { field: 'claveAgente', headerName: 'CLAVE AGENTE', width: 150, editable: false, sortable: false, renderCell },
        { field: 'formaPago', headerName: 'FORMA PAGO', width: 150, editable: false, sortable: false, renderCell },
        { field: 'ramo', headerName: 'RAMO', width: 150, editable: false, sortable: false, renderCell },
        { field: 'concepto', headerName: 'CONCEPTO', width: 150, editable: false, sortable: false, renderCell },
        { field: 'paquete', headerName: 'PAQUETE/PLAN', width: 150, editable: false, sortable: false, renderCell },
        { field: 'fechaVigenciaDe', headerName: 'VIGENCIA INICIO', width: 150, editable: false, sortable: false, renderCell },
        { field: 'fechaVigenciaA', headerName: 'VIGRNVIA FIN', width: 150, editable: false, sortable: false, renderCell },
        { field: 'moneda', headerName: 'MONEDA', width: 150, editable: false, sortable: false, renderCell },
        { field: 'prima', headerName: 'PRIMA', width: 150, editable: false, sortable: false, renderCell },
        { field: 'derecho', headerName: 'DERECHO', width: 150, editable: false, sortable: false, renderCell },
        { field: 'recargo', headerName: 'RECARGO', width: 150, editable: false, sortable: false, renderCell },
        { field: 'iva', headerName: 'IVA', width: 150, editable: false, sortable: false, renderCell },
        { field: 'total', headerName: 'TOTAL', width: 150, editable: false, sortable: false, renderCell },
        { field: 'comision', headerName: 'COMISIÓN', width: 150, editable: false, sortable: false, renderCell },
        { field: 'personalidad', headerName: 'PERSONALIDAD', width: 150, editable: false, sortable: false, renderCell },
        { field: 'rfc', headerName: 'RFC', width: 150, editable: false, sortable: false, renderCell },
        { field: 'nombres', headerName: 'NOMBRES', width: 300, editable: false, sortable: false, renderCell },
        { field: 'apPaterno', headerName: 'APELLIDO PATERNO', width: 200, editable: false, sortable: false, renderCell },
        { field: 'apMaterno', headerName: 'APELLIDO MATERNO', width: 200, editable: false, sortable: false, renderCell },
        { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO/REGISTRO', width: 250, editable: false, sortable: false, renderCell }
    ];

    const updateState = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading}>
                <Dialog
                    open={showErrorDialog}
                    onClose={e => setShowErrorDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">
                            <p>Tras analizar el Excel, hemos detectado las siguientes observaciones</p>
                            <ul className="pl-8 list-disc">
                                {resumen.error > 0 && (<li className="mb-4">
                                    <p className="text-sm">Se encontraron {resumen.error} errores (<ReportProblemIcon className="text-red text-base" />), los cuales tendrán que ser resueltos antes de poder continuar con la importación</p>

                                </li>)}
                                {resumen.warning > 0 && (<li>
                                    <p className="text-sm">Se encontraron {resumen.warning} advertencias (<ReportIcon className="text-yellow text-base" />), las advertencias no impiden importar las pólizas, pero se aconseja atenderlas antes de proceder.</p>
                                </li>)}

                            </ul>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn btn-yellow p-2 text-sm" onClick={e => setShowErrorDialog(false)} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>
                <Toolbar title="Importar Excel" backUrl="/configuracion" />
                <div className="main">
                    <div className="mx-4 lg:mx-8">
                        <div className="grid grid-cols-1 gap-8">
                            <div className="text-right">
                                <a className="btn btn-yellow inline-block text-sm md:text-base w-full md:w-auto text-center" href="/downloads/dalala-import-template.xlsx" download >DESCARGAR FORMATO DE EXCEL<SimCardDownloadIcon fontSize="large" /></a>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2">
                                <FormControl variant="outlined" size="medium" required >
                                    <InputLabel id="dateFormat" required>Formato de la fecha</InputLabel>
                                    <Select
                                        labelId="dateFormat"
                                        label="Formato de fecha"
                                        size="medium"
                                        name="dateFormat"
                                        required
                                        value={state.dateFormat}
                                        onChange={updateState}>
                                        <ListSubheader>Formatos sin hora</ListSubheader>
                                        <MenuItem value="dd/MM/yyyy" >dd/MM/aaaa ejemplo 30/12/2023</MenuItem>
                                        <MenuItem value="MM/dd/yyyy" >MM/dd/aaaa ejemplo 12/30/2023</MenuItem>
                                        <MenuItem value="yyyy/MM/dd" >aaaa/MM/dd ejemplo 2023/12/30</MenuItem>
                                        <MenuItem value="yyyy/dd/MM" >aaaa/dd/MM ejemplo 2023/30/12</MenuItem>
                                        <MenuItem value="dd-MM-yyyy" >dd-MM-aaaa ejemplo 30-12-2023</MenuItem>
                                        <MenuItem value="MM-dd-yyyy" >MM-dd-aaaa ejemplo 12-30-2023</MenuItem>
                                        <MenuItem value="yyyy-MM-dd" >aaaa-MM-dd ejemplo 2023-12-30</MenuItem>
                                        <MenuItem value="yyyy-dd-MM" >aaaa-dd-MM ejemplo 2023-30-12</MenuItem>

                                        <ListSubheader>Formatos con hora</ListSubheader>
                                        <MenuItem value="dd/MM/yyyy hh:mm:ss" >dd/MM/aaaa hh:mm:ss ejemplo 30/12/2023 00:00:00</MenuItem>
                                        <MenuItem value="MM/dd/yyyy hh:mm:ss" >MM/dd/aaaa hh:mm:ss ejemplo 12/30/2023 00:00:00</MenuItem>
                                        <MenuItem value="yyyy/MM/dd hh:mm:ss" >aaaa/MM/dd hh:mm:ss ejemplo 2023/12/30 00:00:00</MenuItem>
                                        <MenuItem value="yyyy/dd/MM hh:mm:ss" >aaaa/dd/MM hh:mm:ss ejemplo 2023/30/12 00:00:00</MenuItem>
                                        <MenuItem value="dd-MM-yyyy hh:mm:ss" >dd-MM-aaaa hh:mm:ss ejemplo 30-12-2023 00:00:00</MenuItem>
                                        <MenuItem value="MM-dd-yyyy hh:mm:ss" >MM-dd-aaaa hh:mm:ss ejemplo 12-30-2023 00:00:00</MenuItem>
                                        <MenuItem value="yyyy-MM-dd hh:mm:ss" >aaaa-MM-dd hh:mm:ss ejemplo 2023-12-30 00:00:00</MenuItem>
                                        <MenuItem value="yyyy-dd-MM hh:mm:ss" >aaaa-dd-MM hh:mm:ss ejemplo 2023-30-12 00:00:00</MenuItem>
                                    </Select>
                                    <FormHelperText>Seleccione el formato de fecha utilizando en el Excel (dd=día, MM=més, yyyy=año, hh=hora, mm=minuto, ss=segundo)</FormHelperText>
                                </FormControl>
                            </div>


                            <FileUploader onUpload={onUpload} accept=".xlsx">
                                <div className="text-left">
                                    <p className="text-base lg:text-lg font-bold normal-case">Carga el Excel con las pólizas a importar</p>
                                    <p className="text-xs lg:text-sm normal-case"><span className="underline">Da clic aquí</span> para cargar la carátula de la póliza que deseas procesar</p>
                                </div>
                            </FileUploader>

                            {data && (
                                <>
                                    <div className="grid" style={{ height: 'auto', overflow: "auto" }}>
                                        <DataGrid
                                            disableColumnFilter
                                            disableColumnMenu
                                            disableColumnSelector
                                            className="bg-dark-300 text-gray-400 border-dark-600"
                                            rowHeight={25}
                                            rows={data}
                                            getRowId={(row) => row.rowId.value}
                                            columns={columns}
                                            pageSize={20}
                                            autoHeight={true}
                                            rowsPerPageOptions={[20]}
                                            experimentalFeatures={{ newEditingApi: true }}
                                        />
                                    </div>

                                    {resumen.warning > 0 && (
                                        <p className="text-yellow text-sm"><ReportIcon className="text-yellow text-base" /> ADVERTENCIA: Las advertencias le permitirán proceder con la importanción, pero se aconseja atenderlas</p>
                                    )}
                                    {resumen.error > 0 && (
                                        <p className="text-red text-sm"><ReportProblemIcon className="text-red text-base" /> ERRORES: Antes de continuar, corrija todos los errores encontrados</p>
                                    )}
                                    <Button onClick={importar} disabled={resumen.error > 0} className="btn btn-yellow">IMPORTAR PÓLIZAS</Button>
                                </>
                            )}

                            <div className="">
                                <p>Instrucciones:</p>
                                <ul>
                                    <li className="ml-4">
                                        <p className="text-sm font-thin">1 ) Descargar el formato de importación</p>
                                        <p className="text-sm font-thin">2) Llevar el formato de importación respetando el formato definido</p>
                                        <p className="text-sm font-thin">3) Cargar el archivo de importación</p>
                                        <p className="text-sm font-thin">4) Validar la información antes de realizar la importación definitva</p>
                                        <p className="text-sm font-thin">5) Precionar el botón de "IMPORTAR" para comenzar el proceso de importación</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}
export default ImportarPolizasExcel