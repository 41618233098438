import AddBoxIcon from '@material-ui/icons/AddBox';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import FaceIcon from '@mui/icons-material/Face';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import CallIcon from '@mui/icons-material/Call';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import CachedIcon from '@mui/icons-material/Cached';



const whiteColor = ""
const redColor = ""
const orangeColor = ""
const yellowColor = ""
const greenColor = ""
const blueColor = ""


export const getSiniestroStatus = () => {
    return [
        { id: "captura", name: "Captura", rgbColor: redColor, color: "text-red", icon: (<AddBoxIcon className="text-inherit" />) },
        { id: "faltante", name: "Faltante del cliente", rgbColor: orangeColor, color: "text-orange-500", icon: (<AddBoxIcon className="text-inherit" />) },
        { id: "enviado", name: "Enviado a la aseguradora", rgbColor: yellowColor, color: "text-green", icon: (<AddBoxIcon className="text-inherit" />) },
        { id: "rechazado", name: "Rechazado", rgbColor: greenColor, color: "text-yellow", icon: (<AddBoxIcon className="text-inherit" />) },
        { id: "finalizado", name: "Finalizado", rgbColor: blueColor, color: "text-blue", icon: (<AddBoxIcon className="text-inherit" />) }
    ]
}

export const getSiniestroStatusById = (status) => {
    return getSiniestroStatus().find(x => x.id === status)
}

export const getRamos = () => {
    return [
        { id: "Auto", name: "Autos", rgbColor: whiteColor, color: "text-white", icon: (<DriveEtaIcon className="text-inherit text-size-inherit" />) },
        { id: "Diversos", name: "Diversos", rgbColor: whiteColor, color: "text-white", icon: (<HomeIcon className="text-inherit text-size-inherit" />) },
        { id: "Vida", name: "Vida", rgbColor: whiteColor, color: "text-white", icon: (<PersonIcon className="text-inherit text-size-inherit" />) },
        { id: "Salud", name: "Salud", rgbColor: whiteColor, color: "text-white", icon: (<FavoriteIcon className="text-inherit text-size-inherit" />) }
    ]
}

export const getRamoById = (ramo) => {
    return getRamos().find(x => x.id === ramo)
}


export const getParentescos = () => {
    return [
        { id: "titular", name: "Titular", rgbColor: whiteColor, color: "text-white", icon: (<AccountCircleIcon className="text-inherit" />) },
        { id: "conyuge", name: "Conyuge", rgbColor: whiteColor, color: "text-white", icon: (<SupervisedUserCircleIcon className="text-inherit" />) },
        { id: "hijo", name: "Hijo", rgbColor: whiteColor, color: "text-white", icon: (<FaceIcon className="text-inherit" />) },
        { id: "otro", name: "Otro", rgbColor: whiteColor, color: "text-white", icon: (<PersonPinIcon className="text-inherit" />) }
    ]
}

export const getParentescoById = (ramo) => {
    return getParentescos().find(x => x.id === ramo)
}

export const getGeneros = () => {
    return [
        { id: "hombre", name: "Hombre", rgbColor: whiteColor, color: "text-white", icon: null },
        { id: "mujer", name: "Mujer", rgbColor: whiteColor, color: "text-white", icon: null },
    ]
}

export const getGeneroById = (genero) => {
    return getGeneros().find(x => x.id === genero)
}

export const getFormaPago = () => {
    return [
        { id: "contado", name: "Contado", rgbColor: whiteColor, color: "text-white", icon: null, parcialidades: 1 },
        { id: "semestral", name: "Semestral", rgbColor: whiteColor, color: "text-white", icon: null, parcialidades: 2 },
        { id: "trimestral", name: "Trimestral", rgbColor: whiteColor, color: "text-white", icon: null, parcialidades: 4 },
        { id: "mensual", name: "Mensual", rgbColor: whiteColor, color: "text-white", icon: null, parcialidades: 12 },
    ]
}

export const getFormaPagoById = (formaPago) => {
    return getFormaPago().find(x => x.id === formaPago)
}

export const getCotizacionStatus = () => {
    return [
        { id: "eliminada", name: "Eliminada", enum: "ELIMINADA", rgbColor: whiteColor, color: "text-red", icon: null },
        { id: "captura", name: "Captura", enum: "CAPTURA", rgbColor: whiteColor, color: "text-white", icon: null },
        { id: "enviada", name: "Enviada", enum: "ENVIADA", rgbColor: whiteColor, color: "text-blue", icon: null },
        { id: "revision", name: "Revision", enum: "REVISION", rgbColor: whiteColor, color: "text-blue", icon: null },
        { id: "cancelada", name: "Cancelada", enum: "CANCELADA", rgbColor: whiteColor, color: "text-red", icon: null },
        { id: "perdida", name: "Perdida", enum: "PERDIDA", rgbColor: whiteColor, color: "text-red", icon: null },
        { id: "ganada", name: "Ganada", enum: "GANADA", rgbColor: whiteColor, color: "text-blue", icon: null },
    ]
}

export const getCotizacionStatuById = (status) => {
    return getCotizacionStatus().find(x => x.id === status)
}


export const getTareaTipo = () => {
    return [
        { id: "tarea", name: "Tarea", rgbColor: whiteColor, color: "text-white", icon: LibraryAddCheckIcon },
        { id: "cobranza", name: "Cobranza", rgbColor: whiteColor, color: "text-white", icon: MonetizationOnIcon },
        { id: "renovacion", name: "Renovacion", rgbColor: whiteColor, color: "text-white", icon: CachedIcon },
        { id: "cotizacion", name: "Cotizacion", rgbColor: whiteColor, color: "text-white", icon: RequestPageIcon },
        { id: "siniestro", name: "Siniestro", rgbColor: whiteColor, color: "text-white", icon: NewReleasesIcon },
        { id: "llamada", name: "Llamada", rgbColor: whiteColor, color: "text-white", icon: CallIcon },
        { id: "visita", name: "Visita", rgbColor: whiteColor, color: "text-white", icon: EmojiTransportationIcon },
        { id: "reunion", name: "Reunion", rgbColor: whiteColor, color: "text-white", icon: GroupsIcon },
    ]
}


export const getTareaTipoById = (tipo) => {
    return getTareaTipo().find(x => x.id === tipo)
}

export const getTareaPrioridad = () => {
    return [
        { id: "baja", name: "Baja", rgbColor: whiteColor, color: "text-green", icon: CallIcon },
        { id: "media", name: "Media", rgbColor: whiteColor, color: "text-white", icon: EmojiTransportationIcon },
        { id: "alta", name: "Alta", rgbColor: whiteColor, color: "text-red", icon: GroupsIcon },
    ]
}


export const getTareaPrioridadById = (prioridad) => {
    return getTareaPrioridad().find(x => x.id === prioridad)
}