import { Button, TextField } from '@material-ui/core';
import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onlyLetter } from '../../../utils/FormUtils';
import { addNewColumn, setLoading } from './kaban-prospecto-actions'

const KanbanColumnAdder = (props) => {

    const dispatch = useDispatch()
    const state = useSelector(state => state.pages.prospectoKanban)

    const [viewAdd, setViewAdd] = useState(false)
    const [nombre, setNombre] = useState("")

    const addCard = (nombre) => {
        try {
            dispatch(setLoading(true))
            setViewAdd(false)
            setNombre("")
            addNewColumn(nombre)
        } catch (e) {
            console.error("Error al crear la columna", e)
        } finally {
            dispatch(setLoading(false))
        }
    }

    const cancelCard = () => {
        setViewAdd(false)
        setNombre("")
    }

    const render = () => {
        return (
            <section className="m-1 grid col-span-1 gap-4 w-64 bg-dark-500 p-2 rounded-md border border-dark-100" style={{ minWidth: "300px" }}>
                {!viewAdd && (
                    <Button
                        className="btn btn-yellow btn-sm py-2"
                        onClick={e => setViewAdd(true)}>
                        Agregar Columna
                    </Button>
                )}
                {viewAdd && (
                    <div className="grid grid-cols-1 gap-4">
                        <TextField
                            required
                            label="Nombre"
                            size="small"
                            name="nombre"
                            type="text"
                            variant="outlined"
                            onInput={event => onlyLetter(event, 20)}
                            value={nombre}
                            onChange={e => setNombre(e.target.value)}
                        />
                        <div className="grid grid-cols-2 gap-4">
                            <Button className="btn btn-gray py-2" onClick={cancelCard}>Cancelar</Button>
                            <Button className="btn btn-yellow py-2" onClick={e => addCard(nombre)} >Agregar</Button>
                        </div>
                    </div>
                )}
            </section>
        )
    }

    return render()
}
export default KanbanColumnAdder