export const ADMIN = "ADMIN"
export const POLIZAS_R = "POLIZAS_R"
export const POLIZAS_W = "POLIZAS_W"
export const POLIZAS_D = "POLIZAS_D"
export const POLIZAS_IMPORT = "POLIZAS_IMPORT"
export const POLIZAS_COBRANZA = "POLIZAS_COBRANZA"
export const POLIZAS_RENOVACIONES = "POLIZAS_RENOVACIONES"
export const CALENDARIO_R = "CALENDARIO_R"
export const CALENDARIO_W = "CALENDARIO_W"
export const CALENDARIO_D = "CALENDARIO_D"
export const TAREAS_R = "TAREAS_R"
export const TAREAS_W = "TAREAS_W"
export const TAREAS_D = "TAREAS_D"
export const SINIESTROS_R = "SINIESTROS_R"
export const SINIESTROS_W = "SINIESTROS_W"
export const SINIESTROS_D  = "SINIESTROS_D"
export const COTIZACIONES_R = "COTIZACIONES_R"
export const COTIZACIONES_W = "COTIZACIONES_W"
export const COTIZACIONES_D = "COTIZACIONES_D"
export const COTIZACIONES_TEMP = "COTIZACIONES_TEMP"
export const COTIZACIONES_TEMP_R = "COTIZACIONES_TEMP_R"
export const COTIZACIONES_TEMP_W = "COTIZACIONES_TEMP_W"
export const COTIZACIONES_TEMP_D = "COTIZACIONES_TEMP_D"
export const PROSPECCION_R = "PROSPECCION_R"
export const PROSPECCION_W = "PROSPECCION_W"
export const PROSPECCION_D = "PROSPECCION_D"
export const PROSPECTOS_R = "PROSPECTOS_R"
export const PROSPECTOS_W = "PROSPECTOS_W"
export const PROSPECTOS_D = "PROSPECTOS_D"
export const CLIENTES_R = "CLIENTES_R"
export const CLIENTES_W = "CLIENTES_W"
export const CLIENTES_D = "CLIENTES_D"
export const VENDEDORES_R = "VENDEDORES_R"
export const VENDEDORES_W = "VENDEDORES_W"
export const VENDEDORES_D = "VENDEDORES_D"
export const ASEGURADORAS_R = "ASEGURADORAS_R"
export const ASEGURADORAS_W = "ASEGURADORAS_W"
export const ASEGURADORAS_D = "ASEGURADORAS_D"
export const USUARIOS_R = "USUARIOS_R"
export const USUARIOS_W = "USUARIOS_W"
export const USUARIOS_D = "USUARIOS_D"
export const CLAVES_R = "CLAVES_R"
export const CLAVES_W = "CLAVES_W"
export const CLAVES_D = "CLAVES_D"
export const REPORTES = "REPORTES"
export const REPORTES_POLIZAS = "REPORTES_POLIZAS"
export const REPORTES_RECIBOS = "REPORTES_RECIBOS"
export const REPORTES_CLIENTES = "REPORTES_CLIENTES"
export const REPORTES_COMISIONES = "REPORTES_COMISIONES"
export const REPORTES_AUTOMATIZACIONES = "REPORTES_AUTOMATIZACIONES"
export const REPORTES_SEMANAL = "REPORTES_SEMANAL"
export const CONFIG = "CONFIG"
export const EMPRESA_R = "EMPRESA_R"
export const EMPRESA_W = "EMPRESA_W"
export const METAS = "METAS"
export const COMUNICADOS_R = "COMUNICADOS_R"
export const COMUNICADOS_W = "COMUNICADOS_W"
export const COMUNICADOS_D = "COMUNICADOS_D"
export const COMUNICADOS_ENVIAR = "COMUNICADOS_ENVIAR"
export const INTEGRACIONES = "INTEGRACIONES"
export const FACTURACION = "FACTURACION"
export const BITACORA_R = "BITACORA_R"
export const BITACORA_W = "BITACORA_W"
export const BITACORA_D = "BITACORA_D"
export const ADJUNTOS_R = "ADJUNTOS_R"
export const ADJUNTOS_W = "ADJUNTOS_W"
export const ADJUNTOS_D = "ADJUNTOS_D"
export const MONEDA_R = "MONEDA_R"
export const MONEDA_W = "MONEDA_W"
export const MONEDA_D = "MONEDA_D"
export const ASEGURADO_R = "ASEGURADO_R"
export const ASEGURADO_W = "ASEGURADO_W"
export const ASEGURADO_D = "ASEGURADO_D"
export const COBERTURAS_R = "COBERTURAS_R"
export const COBERTURAS_W = "COBERTURAS_W"
export const COBERTURAS_D = "COBERTURAS_D"
export const DIRECCIONES_R = "DIRECCIONES_R"
export const DIRECCIONES_W = "DIRECCIONES_W"
export const DIRECCIONES_D = "DIRECCIONES_D"
