import React, { useState, useContext } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import APIInvoke from '../utils/APIInvoke';
import UserContext from '../state/UserContext';

const AppTips = (props) => {

    const userContext = useContext(UserContext)
    const [open, setOpen] = useState(false)
    const [comunicado, setComunicado] = useState(null)

    useEffect(() => {
        setTimeout(() => {
            if (userContext.user) {
                fetchComunicados()
            }
        }, 2000)
    }, [userContext.user])

    const fetchComunicados = async () => {
        try {
            const response = await (await APIInvoke.invokeGET(`/comunicados`)).json()
            if (response.body.length === 0) return
            const newComunicado = response.body[0]
            const isClosed = localStorage.getItem(`dalala-comunicado-${newComunicado.id}-closed`);
            if (isClosed) return
            setComunicado(newComunicado)
            setOpen(true)
        } catch (error) {
            console.error("error al cargar los comunicados", error)
        }
    }

    const closed = () => {
        localStorage.setItem(`dalala-comunicado-${comunicado.id}-closed`, "true");
        setOpen(false)
    }

    const render = () => {
        if (!open) return null
        return (
            <Dialog open={open} className="dialog-apptip" style={{ overflow: "visible !important" }}   >
                <DialogTitle className="text-yellow" >Dalala News!!</DialogTitle>
                <DialogContent className="overflow-visible pb-1">
                    <img src="/apptips/dalila-lap.png" alt="dalila" style={{ transform: "rotateY(180deg)" }} className="z-50 w-36 absolute right-0 -top-24" />
                    <DialogContentText dangerouslySetInnerHTML={{ __html: comunicado.texto }} className="text-white text-sm" >
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="mr-4" onClick={closed}>CERRAR</Button>
                    {comunicado.actionUrl && (<Link className="btn btn-yellow p-2 px-2 text-sm" autoFocus to={comunicado.actionUrl} onClick={closed}>VER MÁS</Link>)}
                </DialogActions>
            </Dialog>
        )
    }

    return render()
}
export default AppTips