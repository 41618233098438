import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField } from '@material-ui/core'
import React from 'react'
import NumberFormat from 'react-number-format'
import Toolbar from '../components/Toolbar'
import AdminTemplate from '../template/AdminTemplate'
import ForumIcon from '@material-ui/icons/Forum';

const UX = (props) => {

    const render = () => {
        return (
            <AdminTemplate loged={false} loading={false}>
                <Toolbar title="UX Kit" />
                <div className="main">

                    <div className="layout-compact">
                        <div className="grid grid-cols-1 gap-8">
                            <div className="grid grid-cols-2 gap-4">
                                <TextField label="TextField" required />
                                <TextField disabled label="TextField Disabled" required />
                                <TextField label="TextField" defaultValue="Hola mundo" required />
                                <TextField disabled label="TextField Disabled" defaultValue="Hola mundo" required />
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <NumberFormat
                                    customInput={TextField}
                                    label="NumberFormat"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <NumberFormat
                                    disabled={true}
                                    customInput={TextField}
                                    label="NumberFormat Disabled"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />

                                <NumberFormat
                                    customInput={TextField}
                                    label="NumberFormat"
                                    defaultValue={1000000}
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <NumberFormat
                                    disabled={true}
                                    customInput={TextField}
                                    defaultValue={1000000}
                                    label="NumberFormat Disabled"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <FormControlLabel
                                    label={<p className="text-sm">Checkbox</p>}
                                    required
                                    checked={false}
                                    control={
                                        <Checkbox name="terminos" color="primary" />
                                    }
                                />
                                <FormControlLabel
                                    disabled={true}
                                    label={<p className="text-sm">Checkbox Disabled</p>}
                                    required
                                    checked={false}
                                    control={
                                        <Checkbox name="terminos" color="primary" />
                                    }
                                />
                                <FormControlLabel
                                    label={<p className="text-sm">Checkbox</p>}
                                    required
                                    checked={true}
                                    control={
                                        <Checkbox name="terminos" color="primary" />
                                    }
                                />
                                <FormControlLabel
                                    disabled={true}
                                    label={<p className="text-sm">Checkbox Disabled</p>}
                                    required
                                    checked={true}
                                    control={
                                        <Checkbox name="terminos" color="primary" />
                                    }
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    inputProps={{ className: "text-white" }}
                                    className="w-full"
                                    color="primary"
                                    id="outlined-multiline-static"
                                    label="TextField Multiline"
                                    name="texto"
                                    multiline
                                    rows={5}
                                    required
                                />
                                <TextField
                                    disabled={true}
                                    inputProps={{ className: "text-white" }}
                                    className="w-full"
                                    color="primary"
                                    id="outlined-multiline-static"
                                    label="TextField Multiline"
                                    name="texto"
                                    multiline
                                    rows={5}
                                    required
                                />
                                <TextField
                                    inputProps={{ className: "text-white" }}
                                    className="w-full"
                                    color="primary"
                                    id="outlined-multiline-static"
                                    label="TextField Multiline"
                                    defaultValue="Hola Mundo"
                                    name="texto"
                                    multiline
                                    rows={5}
                                    required
                                />
                                <TextField
                                    disabled={true}
                                    inputProps={{ className: "text-white" }}
                                    className="w-full"
                                    color="primary"
                                    id="outlined-multiline-static"
                                    label="TextField Multiline"
                                    defaultValue="Hola Mundo"
                                    name="texto"
                                    multiline
                                    rows={5}
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <Button className="btn btn-yellow">Button</Button>
                                <Button disabled className="btn btn-yellow">Button Disabled</Button>
                            </div>
                            <div className="">
                                <IconButton className="btn btn-yellow">
                                    <ForumIcon className="text-dark-600" />
                                </IconButton>
                                <IconButton disabled className="btn btn-yellow">
                                    <ForumIcon className="text-dark-600" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate >
        )
    }

    return render()
}
export default UX