import React, { useContext, useEffect, useState } from 'react'
import CachedIcon from '@material-ui/icons/Cached';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Link, NavLink } from 'react-router-dom'
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@mui/material';
import Hamburger from 'hamburger-react'
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AppContext from '../state/AppContext';
import Drawer from '@material-ui/core/Drawer';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import UserContext from '../state/UserContext';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';
import MoneyIcon from '@mui/icons-material/Money';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ExtensionIcon from '@mui/icons-material/Extension';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import TuneIcon from '@mui/icons-material/Tune';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GrantContent from '../components/GrantContent';
import * as Roles from '../utils/Roles'
import * as Permisos from '../utils/Permisos'
import PaidIcon from '@mui/icons-material/Paid';

const Aside = (props) => {

    const routeMap = {
        "/clientes": "catalogs",
        "/prospectos": "catalogs",
        "/vendedores": "catalogs",
        "/resumen": "modules",
        "/polizas": "modules",
        "/prospeccion": "modules",
        "/siniestros": "modules",
        "/cotizaciones": "modules",
        "/cxc": "modules",
        "/renovaciones": "modules",
        "/calendario": "modules",
        "/tareas": "modules",
        "/reportes/polizas": "reports",
        "/reportes/recibos": "reports",
        "/reportes/clientes": "reports",
        "/reportes/comisiones": "reports",
        "/automatizaciones": "reports",
        "/importar-polizas": "management",
        "/configuracion": "management",
        "/aseguradoras": "management",
        "/monedas": "management",
        "/claves": "management"
    }

    const matchSection = () => {
        for (let key in routeMap) {
            if (location.pathname.startsWith(key)) {
                return routeMap[key]
            }
        }
    }

    const { appContext, setAppContext } = useContext(AppContext)
    const { user, setUser } = useContext(UserContext)
    const location = useLocation();
    const [expandedPanel, setExpandedPanel] = useState(matchSection());

    const handleChange = (panel) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? panel : false);
    };

    useEffect(() => {
    }, [])

    const toogleNavbar = () => {
        setAppContext({
            ...appContext,
            navbar: {
                open: !appContext.navbar.open
            }
        })
    }

    const logout = () => {
        window.localStorage.removeItem("token")
        window.location = "/login"
    }

    const render = () => {
        const open = appContext.navbar.open
        const styles = open ? "hidden lg:block w-72 transition duration-700 ease-in-out border-r border-dark-100 bg-dark-400 scroll-thin rounded-none" : "hidden lg:block w-14 border-r transition duration-700 ease-in-out border-dark-100 bg-dark-400 scroll-thin"
        const labelStyles = open ? "inline-block" : "hidden"

        const ulStyle = open ? " mt-2" : " py-4"
        const liStyle = open ? "mb-2 text-white hover:text-yellow" : "mb-2 text-white hover:text-yellow"

        const aClass = "no-underline rounded-md px-2 py-2 t-0 l-0 text-sm flex items-center transition duration-700 ease-in-out "
        const aActiveClass = "bg-yellow text-dark-500"
        const activeStyle = { backgroundColor: "#F4D144", color: "black" }


        const suscription = user && user.suscripcion
            ?
            <li className="my-4">
                <div className=" mb-4" />
                <p className={`${labelStyles} px-2 text-sm mb-2  text-dark-100`}>Tu suscripción:</p>
                <Link to="/configuracion/mi-plan" className={`${labelStyles} left-0 flex-1 text-left px-2 flex items-center text-gray-200 hover:text-yellow no-underline`}>
                    <BusinessIcon fontSize='small' /> <span className="ml-2 text-sm text-inherit">{user.suscripcion.plan.nombre}</span>
                </Link>
            </li>
            : null

        const menu = (mobile) => (

            <div className="h-12 bg-dark-300">
                <div className="bg-dark-300 text-right flex justify-between lg:justify-end items-center">
                    <img src="/brand/dalala-logo-lg.svg" alt="dalala" className="inline-block lg:hidden h-8 ml-4" />
                    <Hamburger
                        className="text-white bg-white inline-block"
                        size={20}
                        color="#FFF"
                        toggled={open}
                        toggle={toogleNavbar}
                    />
                </div>

                <Paper >
                    <div className="grid grid-cols-1  bg-dark-400">
                        {user && user.rol.includes("ROLE_USER") && (
                            <>
                                <Accordion expanded={expandedPanel === 'modules'} onChange={handleChange('modules')} className="rounded-none bg-dark-600 border-b border-dark-300 m-0" >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className="text-white" />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="flex items-center"><ExtensionIcon className="mr-1" fontSize="medium" /><span className={open ? "inline-block" : "hidden"}>Módulos</span></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="bg-dark-400 p-2">
                                        <ul className={ulStyle} >
                                            <li className={liStyle}>
                                                <NavLink
                                                    to="/resumen"
                                                    className={aClass}
                                                    activeStyle={activeStyle}
                                                    activeClassName={aActiveClass}
                                                    style={{ color: "inherit" }}
                                                    onClick={mobile ? toogleNavbar : null}
                                                >
                                                    <DescriptionIcon className="mr-2" fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Resumen</p>
                                                </NavLink>
                                            </li>

                                            <GrantContent permisos={[Permisos.POLIZAS_R]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/polizas"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <InsertDriveFileIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Pólizas</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>

                                            <GrantContent permisos={[Permisos.POLIZAS_COBRANZA]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/cxc"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <MonetizationOnIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Cobranza</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>

                                            <GrantContent permisos={[Permisos.POLIZAS_RENOVACIONES]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/renovaciones"
                                                        className={aClass}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <CachedIcon className="mr-2" fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Renovaciones</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>

                                            <GrantContent permisos={[Permisos.PROSPECCION_R]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/prospeccion"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <ViewKanbanIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Prospección</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>



                                            <GrantContent permisos={[Permisos.SINIESTROS_R]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/siniestros"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <NewReleasesIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Siniestros</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>

                                            <GrantContent permisos={[Permisos.COTIZACIONES_R]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/cotizaciones"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <RequestPageIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Cotizaciones</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>

                                            
                                            <GrantContent permisos={[Permisos.CALENDARIO_R]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/calendario"
                                                        className={aClass}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <EventAvailableIcon className="mr-2" fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Calendario</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                            <GrantContent permisos={[Permisos.TAREAS_R]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/tareas"
                                                        className={aClass}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <LibraryAddCheckIcon className="mr-2" fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Tareas</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expandedPanel === 'catalogs'} onChange={handleChange('catalogs')} className="rounded-none bg-dark-600 border-b border-dark-300 m-0">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className="text-white" />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="flex items-center"><ListAltIcon className="mr-1" fontSize="medium" /><span className={open ? "inline-block" : "hidden"}>Catálogos</span></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="bg-dark-500 p-3">
                                        <ul className={ulStyle} >

                                            <GrantContent permisos={[Permisos.CLIENTES_R]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/clientes"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <SupervisedUserCircleIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Clientes</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                            <GrantContent permisos={[Permisos.PROSPECTOS_R]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/prospectos"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <RecentActorsIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Prospectos</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                            <GrantContent permisos={[Permisos.VENDEDORES_R]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/vendedores"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <SupportAgentIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Vendedores</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expandedPanel === 'reports'} onChange={handleChange('reports')} className="rounded-none bg-dark-600 border-b border-dark-300 m-0">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className="text-white" />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="flex items-center"><AssessmentIcon className="mr-1" fontSize="medium" /><span className={open ? "inline-block" : "hidden"}>Reportes</span></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="bg-dark-500 p-2">
                                        <ul className={ulStyle} >
                                            <GrantContent permisos={[Permisos.REPORTES_POLIZAS]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/reportes/polizas"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <ArticleIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Pólizas</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                            <GrantContent permisos={[Permisos.REPORTES_RECIBOS]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/reportes/recibos"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <MoneyIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Recibos</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                            <GrantContent permisos={[Permisos.REPORTES_CLIENTES]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/reportes/clientes"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <SupervisedUserCircleIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Clientes</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                            <GrantContent permisos={[Permisos.REPORTES_COMISIONES]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/reportes/comisiones"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <PriceChangeIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Comisiones</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                            <GrantContent permisos={[Permisos.REPORTES_AUTOMATIZACIONES]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/automatizaciones"
                                                        className={aClass}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <PrecisionManufacturingIcon className="mr-2" fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Automatizaciones</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expandedPanel === 'management'} onChange={handleChange('management')} className="rounded-none bg-dark-600 border-b border-dark-300 m-0">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className="text-white" />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="flex items-center"><TuneIcon className="mr-1" fontSize="medium" /><span className={open ? "inline-block" : "hidden"}>Administración</span></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="bg-dark-500 p-2">
                                        <ul className={ulStyle} >
                                            {/*
                                    <li className={liStyle}>
                                        <a href="https://appdalala.blogspot.com/" className={aClass} style={{ color: "inherit" }} target="_blank" >
                                            <SchoolIcon className="mr-2" /> <p className={labelStyles} style={{ color: "inherit" }}>Blog</p>
                                        </a>
                                    </li>
                                    */}
                                            <GrantContent permisos={Permisos.ASEGURADORAS_R}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/aseguradoras"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <ApartmentIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Aseguradoras</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>

                                            <GrantContent permisos={Permisos.MONEDA_W}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/monedas"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <PaidIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Monedas</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>

                                            <GrantContent permisos={Permisos.CLAVES_R}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/claves"
                                                        className={aClass}
                                                        activeStyle={activeStyle}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <AssignmentIndIcon className="mr-2 " fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Claves de agente</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>


                                            <GrantContent permisos={[Permisos.POLIZAS_IMPORT]}>
                                                <li className={liStyle}>
                                                    <NavLink
                                                        to="/importar-polizas"
                                                        className={aClass}
                                                        activeClassName={aActiveClass}
                                                        style={{ color: "inherit" }}
                                                        onClick={mobile ? toogleNavbar : null}
                                                    >
                                                        <DriveFolderUploadIcon className="mr-2" fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Importar Pólizas</p>
                                                    </NavLink>
                                                </li>
                                            </GrantContent>

                                            <li className={liStyle}>
                                                <NavLink
                                                    to="/configuracion"
                                                    className={aClass}
                                                    activeClassName={aActiveClass}
                                                    style={{ color: "inherit" }}
                                                    onClick={mobile ? toogleNavbar : null}
                                                >
                                                    <SettingsIcon className="mr-2" fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Configuración</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )}
                        <div className="">
                            <ul className={ulStyle}>
                                {/* 
                                <li className={liStyle}>
                                    <div className={aClass} style={{ color: "inherit", cursor: "pointer" }} onClick={logout}>
                                        <ExitToAppIcon className="mr-2" fontSize="small" /> <p className={labelStyles} style={{ color: "inherit" }}>Salir</p>
                                    </div>
                                </li>
                                */}
                                {suscription}
                            </ul>
                        </div>
                    </div>
                </Paper>
            </div>
        )

        return (
            <>
                <Paper style={{ overflowY: 'auto', overflowX: "none", minWidth: open ? "200px" : "55px" }} className={styles} >
                    <aside >
                        <div className="hidden lg:block " >
                            {menu(false)}
                        </div>
                    </aside>
                </Paper>
                <Drawer className="block lg:hidden bg-dark-400" anchor={"left"} disableScrollLock={true} open={open} onClose={toogleNavbar} color="primary">
                    <div className="bg-dark-400 h-full" style={{ width: "80vw" }}>
                        {menu(true)}
                    </div>
                </Drawer>
            </>
        )
    }

    return render()

}
export default Aside