import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import { Button, TextField, Checkbox, FormControlLabel, Autocomplete, CircularProgress, Box, FormControl, InputLabel, Select, MenuItem, FormLabel, FormGroup, FormHelperText } from '@material-ui/core';
import Toolbar from '../../components/Toolbar'
import { onlyLetter } from '../../utils/FormUtils'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import APIInvoke from '../../utils/APIInvoke';
import { useCallback } from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HomeIcon from '@material-ui/icons/Home';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ForumIcon from '@material-ui/icons/Forum';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { DateTime } from 'luxon';
import store from '../../redux/store'
import debounce from 'lodash.debounce'
import { useSelector } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import {
    reset,
    onChange,
    setLoading,
    setState,
    onClienteChange,
    fetchClientes,
    fetchPolizas,
    fetchSiniestro,
    fetchPolizaById
} from './nuevo-siniestro-actions'
import { getSiniestroStatus } from '../../utils/EnumUtils';


const NuevoSiniestro = (props) => {

    const history = useHistory()
    const siniestroId = useParams().siniestroId
    const state = useSelector(state => state.pages.nuevoSiniestro)

    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })


    useEffect(() => {
        return reset
    }, [])

    useEffect(() => {
        if (!siniestroId) return
        fetchSiniestro(siniestroId)
    }, [])

    const onChangePoliza = (e) => {
        const polizaId = e.target.value
        fetchPolizaById(polizaId)
    }

    const save = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)

            const request = {
                polizaId: state.poliza.id,
                noSiniestro: state.noSiniestro,
                fechaSiniestro: DateTime.fromJSDate(state.fechaSiniestro).toFormat("yyyy-MM-dd"),
                fechaEstimadaResolucion: DateTime.fromJSDate(state.fechaEstimadaResolucion).toFormat("yyyy-MM-dd"),
                descripcion: state.descripcion,
                status: state.status,
                asegurados: state.asegurados.filter(a => a.selected).map(a => a.id)
            }

            const saveResponse = state.id
                ? await (await APIInvoke.invokePUT(`/siniestros/${state.id}`, request)).json()
                : await (await APIInvoke.invokePOST(`/siniestros`, request)).json()

            setState({ name: "id", value: saveResponse.body.id })

            history.push({ pathname: `/siniestro/${saveResponse.body.id}`, state: { backUrl: `/siniestros` } })
        } catch (error) {
            console.error("Error al guardar el siniestro", error)
        } finally {
            setLoading(false)
        }
    }

    const getRamoIcon = (ramo) => {
        switch (ramo.toLowerCase()) {
            case 'salud': return <FavoriteIcon className="text-2xl text-inherit" />
            case 'vida': return <PersonIcon className="text-2xl text-inherit" />
            case 'auto': return <DriveEtaIcon className="text-2xl text-inherit" />
            case 'diversos': return <HomeIcon className="text-2xl text-inherit" />
            default: return null
        }
    }

    const getPersonalidaIcon = (personalidad) => {
        switch (personalidad) {
            case 'fisica': return <PersonIcon className="text-2xl text-dark-500" />
            case 'moral': return <BusinessIcon className="text-2xl text-dark-500" />
            default: return null
        }
    }

    const onChangeAsegurado = (e, asegurado) => {
        const index = state.asegurados.findIndex(a => a.id === asegurado.id)
        state.asegurados[index].selected = e.target.checked

        setState({
            asegurados: [...state.asegurados]
        })
    }

    const renderBienAsegurado = () => {
        switch (state.poliza.ramo) {
            case 'Salud':
            case 'Vida': return (
                <div className="grid bg-dark-300 p-4 rounded-md gap-2">
                    <p className="text-white">Asegurados</p>
                    <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                            {state.asegurados.length === 0 && (<p className="text-red text-sm italic">La póliza no tiene asegurados registrados</p>)}
                            {state.asegurados.map(asegurado => (
                                <FormControlLabel
                                    key={asegurado.id}
                                    label={<p className="text-sm capitalize">{`${asegurado.nombres} ${asegurado.apPat} ${asegurado.apMat}`.toLowerCase()} ({asegurado.parentesco})</p>}
                                    required
                                    onChange={e => onChangeAsegurado(e, asegurado)}
                                    checked={asegurado.selected}
                                    control={
                                        <Checkbox name="terminos" color="primary" />
                                    }
                                />
                            ))}
                        </FormGroup>
                        <FormHelperText>Seleccione al menos un asegurado</FormHelperText>
                    </FormControl>

                </div>
            )
            case 'Auto':
                const vehiculo = state.poliza.vehiculo
                if (vehiculo) {
                    return (
                        <div className="grid bg-dark-300 p-4 rounded-md">
                            <p className="text-white">Auto</p>
                            {state.poliza.vehiculo && (
                                <FormControlLabel
                                    key={vehiculo.id}
                                    label={<p className="text-sm capitalize">{`${vehiculo.marca} ${vehiculo.tipo} ${vehiculo.modelo} (${vehiculo.placas})`}</p>}
                                    required
                                    checked={true}
                                    control={
                                        <Checkbox name="terminos" color="primary" disabled />
                                    }
                                />
                            )}
                        </div>
                    )
                } else {
                    return (
                        <div className="grid bg-dark-300 p-4 rounded-md">
                            <p className="text-white">Auto</p>
                            <p><em className="text-red text-sm">No se ha registrado un vehículo en la póliza</em></p>
                        </div>
                    )
                }
            case 'Diversos': return (
                <div className="grid bg-dark-300 p-4 gap-2 rounded-md">
                    <p className="text-white">Diversos/Daños</p>
                    <p className="text-sm">{state.poliza?.texto}</p>
                </div>
            )
            default: return null
        }
    }

    const fetchContratanteDebounce = useCallback(debounce(fetchClientes, 500), []);


    const render = () => {
        const { id, poliza } = state

        return (
            <AdminTemplate loading={state.loading} >
                <Toolbar title="Nuevo siniestro" backUrl={`/siniestros`} >
                    {/* 
                    <div className="layout">
                        <section className="flex justify-end items-center">
                            <div className="flex gap-2 items-center">
                                <IconButton disabled={!id} className="btn btn-yellow" onClick={e => setOpenAdjuntosDialog({ show: true, id: state.id })}>
                                    <AttachmentIcon className="text-dark-600"></AttachmentIcon>
                                </IconButton>
                                <IconButton disabled={!id} className="btn btn-yellow" onClick={e => setOpenBitacoraDialog({ show: true, id: state.id })}>
                                    <ForumIcon className="text-dark-600"></ForumIcon>
                                </IconButton>
                                <IconButton disabled={!id} className="btn btn-yellow" onClick={e => setOpenDeleteDialog({ show: true, id: state.id })}>
                                    <DeleteIcon className="text-dark-600" />
                                </IconButton>
                            </div>
                        </section>
                    </div>
                    */}
                </Toolbar>

                <div className="main center">
                    <div className="layout-compact">
                        <form onSubmit={save}>
                            <div className="grid grid-cols-1 gap-4">
                                <TextField
                                    label="No de siniestro"
                                    type="text"
                                    variant="outlined"
                                    required
                                    name="noSiniestro"
                                    onInput={event => onlyLetter(event, 200)}
                                    value={state.noSiniestro}
                                    onChange={onChange}
                                />

                                <Autocomplete
                                    id="cliente"
                                    name="cliente"
                                    className="flex-1"
                                    required
                                    clearText="clearText"
                                    closeText="closeText"
                                    noOptionsText="Sin resultados"
                                    loadingText="Cargando..."
                                    openText="openText"
                                    open={state.openAutocompleteCliente}
                                    value={state.cliente}
                                    onOpen={() => setState({ openAutocompleteCliente: true })}
                                    onClose={() => setState({ openAutocompleteCliente: false })}
                                    isOptionEqualToValue={(option, value) => option.id === value.clienteId}
                                    getOptionLabel={(option) => `${option.nombres} ${option.apPat} ${option.apMat} (${option.rfc})`}
                                    options={state.clientes}
                                    onInput={fetchContratanteDebounce}
                                    loading={state.clienteAutocompleteLoading}
                                    onChange={(event, cliente) => onClienteChange(cliente, true)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cliente"
                                            required
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {state.clienteAutocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            <p className="text-dark-600">{getPersonalidaIcon(option.fiscal)} {`${option.nombres} ${option.apPat} ${option.apMat}`}</p>
                                        </Box>
                                    )}
                                />

                                <FormControl variant="outlined" size="medium" >
                                    <InputLabel id="cliente" required>Póliza</InputLabel>
                                    <Select
                                        labelId="poliza"
                                        label="Póliza"
                                        size="medium"
                                        name="poliza"
                                        value={state.polizaId}
                                        onChange={onChangePoliza}
                                    >
                                        <MenuItem value=""><em>Seleccione</em></MenuItem>
                                        {state.polizas.map(poliza => (
                                            <MenuItem key={poliza.id} value={poliza.id} ><div className="text-drak-500">{getRamoIcon(poliza.ramo)} {poliza.noPoliza} ({poliza.aseguradora})</div></MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} required>
                                        <DesktopDatePicker
                                            name="fechaSiniestro"
                                            required
                                            label="Fecha del siniestro"
                                            inputFormat="dd/MM/yyyy"
                                            value={state.fechaSiniestro}
                                            onChange={(newValue) => {
                                                setState({ ...state, fechaSiniestro: newValue })
                                            }}
                                            renderInput={(params) => <TextField {...params} required />}
                                        />
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            name="fechaEstimadaResolucion"
                                            label="F. estimada resolución"
                                            inputFormat="dd/MM/yyyy"
                                            minDate={state.fechaSiniestro}
                                            value={state.fechaEstimadaResolucion}
                                            onChange={(newValue) => {
                                                setState({ ...state, fechaEstimadaResolucion: newValue })
                                            }}
                                            renderInput={(params) => <TextField {...params} required />}
                                        />
                                    </LocalizationProvider>
                                </div>

                                {poliza && (
                                    <>
                                        <div className="grid gap-2 bg-dark-300 p-4 rounded-md">
                                            <p className="text-white">Datos generales de la póliza</p>
                                            <p className="text-sm">Contratante: {`${poliza.cliente.nombres} ${poliza.cliente.apPat} ${poliza.cliente.apMat}`.toLowerCase()}</p>
                                            <p className="text-sm capitalize">Concepto: {poliza.concepto.toLowerCase()}</p>
                                            <p className="text-sm capitalize">Aseguradora: {poliza.agenteClave.aseguradora.abreviacion} ({poliza.agenteClave.clave})</p>
                                        </div>
                                        {renderBienAsegurado()}
                                    </>
                                )}

                                <TextField
                                    name="descripcion"
                                    inputProps={{ className: "text-white" }}
                                    className="w-full"
                                    color="primary"
                                    id="outlined-multiline-static"
                                    label="Descripción"
                                    multiline
                                    onChange={onChange}
                                    value={state.descripcion}
                                    rows={5}
                                    required
                                />

                                <FormControl variant="outlined" size="medium" >
                                    <InputLabel id="status" required>Estatus</InputLabel>
                                    <Select
                                        labelId="status"
                                        label="Estatus"
                                        size="medium"
                                        name="status"
                                        value={state.status}
                                        onChange={onChange}
                                    >
                                        {getSiniestroStatus().map(status => (
                                            <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Button type="submit" className="btn btn-yellow">GUARDAR</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default NuevoSiniestro