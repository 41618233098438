import React, { useState } from 'react'
import Toolbar from '../components/Toolbar'
import AdminTemplate from '../template/AdminTemplate'
import { useDropzone } from 'react-dropzone'
import CircularProgress from '@material-ui/core/CircularProgress';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Button } from '@material-ui/core';
import APIInvoke from '../utils/APIInvoke';
import config from '../config'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReportIcon from '@mui/icons-material/Report';
import { Link, Prompt } from 'react-router-dom';

const ImportarPolizasCaratula = (props) => {

    const [files, setFiles] = useState([])
    const [working, setWorking] = useState(false)


    const onDrop = (file) => {
        setFiles([...files, ...file.map(file => { return { file: file, state: "hold" } })])
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop: onDrop,
        accept: "application/pdf"
    })

    const renderFile = () => {

        return files.map((file, index) => {

            let icon = null
            switch (file.state) {
                case "hold":
                    icon = (<FilePresentIcon color="primary" />)
                    break
                case "working":
                    icon = (<CircularProgress size={20} color="primary" />)
                    break
                case "done":
                    icon = (<CheckCircleIcon size={20} color="primary" />)
                    break
                case "error":
                    icon = (<ReportIcon size={20} className="text-red" />)
                    break
            }

            return (
                <li key={index} id={`file-${index}`}>
                    <div className="grid grid-cols-1 gap-4 text-gray-400 bg-dark-400 rounded-md p-4">
                        <div className="flex items-center">
                            {icon}
                            <p className="ml-2">{file.file.path}</p>
                        </div>
                        {file.message && <p className="ml-8 text-sm">{file.message}</p>}
                        {file.poliza && <p className="ml-8 text-sm">Se ha creado la póliza no {file.poliza.noPoliza} de {file.poliza.agenteClave.aseguradora.abreviacion}</p>}
                    </div>
                </li>
            )
        })
    }

    const importar = async () => {
        let newState = files
        try {
            setWorking(true)
            document.getElementById("dropzone").scrollIntoView();

            for (let c = 0; c < files.length; c++) {
                try {
                    const thisFiles = files[c]

                    if (thisFiles.state !== "hold") continue

                    newState = newState.map((x, index) => {
                        return index !== c ? x : {
                            ...x,
                            state: "working"
                        }
                    })
                    setFiles(newState)

                    const body = new FormData();
                    body.append('bucket', config.bucket.name);
                    body.append('path', `polizas/${thisFiles.file.path}`);
                    body.append('file', thisFiles.file)

                    const request = {
                        method: "POST",
                        body
                    }

                    const uploading = await (await fetch(`${config.uploader.baseUrl}/storage/upload`, request)).json();

                    if (uploading.ok !== true) {
                        console.error(uploading.message)
                        return
                    }

                    const caratulaRequest = {
                        "fileName": thisFiles.file.name,
                        "caratulaUrl": uploading.body.url
                    }

                    const caratulaRawResponse = await APIInvoke.invokePOST(`/polizas/caratula`, caratulaRequest)
                    const caratulaResponse = await caratulaRawResponse.json()
                    if (caratulaResponse.ok) {
                        newState = newState.map((x, index) => {
                            return index !== c ? x : {
                                ...x,
                                state: "done",
                                message: "Importación exitosa",
                                poliza: caratulaResponse.body
                            }
                        })
                        setFiles(newState)
                    } else {
                        newState = newState.map((x, index) => {
                            return index !== c ? x : {
                                ...x,
                                state: "error",
                                message: caratulaResponse.message
                            }
                        })
                        setFiles(newState)
                    }
                } catch (error) {
                    newState = newState.map((x, index) => {
                        return index !== c ? x : {
                            ...x,
                            state: "error",
                            message: "Se ha producido un error inesperado al importar la póliza"
                        }
                    })
                    setFiles(newState)
                }
            }
        } catch (error) {
            console.log("error", error)
        } finally {
            setWorking(false)
        }
    }

    const reset = () => {
        setFiles([])
    }

    const render = () => {
        return (
            <AdminTemplate loading={false}>
                <Toolbar title="Importar Pólizas" backUrl="/configuracion" />
                {working && <Prompt message="Si sale de la página se cancelará el proceso de importación, ¿quirere salir?" />}
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-8">
                            <div id="dropzone" className="" {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <p className="p-8 lg:p-16 border-2 border-dashed border-yellow text-gray-600 font-bold rounded-md text-md text-center">Suelta aquí todas las carátulas de tus pólizas, o da click para seleccionarlos desde tus archivos</p>
                            </div>
                            <div id="files">
                                <ul className="grid grid-cols-1 gap-4">{renderFile()}</ul>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <Button className="btn bg-dark-400 " onClick={reset} disabled={working}>REINICIAR</Button>
                                <Button className="btn btn-yellow " onClick={importar} disabled={working} >IMPORTAR</Button>
                            </div>
                            <div className="">
                                <p>Posibles errores y soluciones:</p>
                                <ul>
                                    <li className="ml-4">
                                        <p className="text-sm font-thin">* Clave de agente no encontrada: Es necesario dar de alta la <Link to={{ pathname: "/configuracion/claves", state: { backUrl: "/importar-polizas/pdf" } }}>clave de agente</Link> desde la sección de configuración</p>
                                        <p className="text-sm font-thin">* No fue posible determinar el tipo de documento o aseguradora: Es necesario asegurarse de enviar la carátula original en formato PDF, en otro caso, es importante reportar la carátula con soporte para su análisis</p>
                                        <p className="text-sm font-thin">* Algunos de los campos requeridos no fueron encontrados: Será necesario intentar procesar la carátula individualmente desde la sección de pólizas</p>
                                        <p className="text-sm font-thin">* En este momento las pólizas de grupo no pueden ser importadas por este medio</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}





export default ImportarPolizasCaratula