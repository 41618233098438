import { Autocomplete, Badge, Button, CircularProgress, TextField } from '@mui/material'
import React, { useState, useCallback } from 'react'
import Toolbar from '../../../components/Toolbar'
import AdminTemplate from '../../../template/AdminTemplate'
import debounce from "lodash.debounce";
import { maxFieldLength } from '../../../utils/FormUtils';
import APIInvoke from '../../../utils/APIInvoke';
import { useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import { setLoading, addNewCard, saveCard } from './kaban-prospecto-actions';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Checkbox, FormControl, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@material-ui/core';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NumberFormat from 'react-number-format';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DalalaDialog from '../../../components/DalalaDialog';
import NuevoProspecto from '../NuevoProspecto';
import * as Permisos from '../../../utils/Permisos'
import GrantContent from '../../../components/GrantContent';

const KanbanNewCard = (props) => {

    const dispatch = useDispatch()
    const store = useSelector(state => state.pages.prospectoKanban)
    const [open, setOpen] = useState(false)
    const [showNewProspectDialog, setShowNewProspectDialog] = useState(false)
    const [ramos, setRamos] = useState([])
    const [prospectos, setProspectos] = useState([])
    const [prospectosLoading, setProspectosLoading] = useState(false)
    const [state, setState] = useState({
        prospecto: null,
        descripcion: "",
        ramo: "",
        primaTotal: 0,
        moneda: "",
        nombre: "",
        status: "abierta"
    })
    const [newNote, setNewNote] = useState({
        texto: ""
    })
    const [notes, setNotes] = useState([])
    const [currency, setCurrency] = useState([])

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        await fetchRamos()
        await fetchCurrencies()
        await fetchCard()
    }

    const fetchCurrencies = async () => {
        try {
            dispatch(setLoading(true))
            const currencyResponse = await (await APIInvoke.invokeGET(`/monedas`)).json();
            setCurrency(currencyResponse.body)
        } catch (error) {
            console.error("Currency fetch error", error)
        } finally {
            dispatch(setLoading(false))
        }
    }

    const fetchRamos = async () => {
        try {
            dispatch(setLoading(true))
            const fetchRamosResponse = await (await APIInvoke.invokeGET(`/ramos`)).json()
            setRamos(fetchRamosResponse.body)
        } catch (e) {
            console.log("Error al cargar los ramos", e)
        } finally {
            dispatch(setLoading(false))
        }
    }

    const fetchCard = async () => {
        try {
            dispatch(setLoading(true))
            const cardId = props.cardId
            if (!cardId) return;
            const fetchCardResponse = await (await APIInvoke.invokeGET(`/prospectos/kanbans/cards/${cardId}`)).json()
            setState({
                ...fetchCardResponse.body,
                ramo: fetchCardResponse.body.ramo.id,
                moneda: fetchCardResponse.body.moneda.abreviacion
            })
            const fetchNoteResponse = await (await APIInvoke.invokeGET(`/prospectos/kanbans/cards/${cardId}/notes`)).json()
            setNotes(fetchNoteResponse.body)
        } catch (e) {
            console.error("Error al cargar la tarjeta", e)
        } finally {
            dispatch(setLoading(false))
        }
    }

    const fetchProspectos = async (e) => {
        try {
            setProspectosLoading(true)
            const description = e.target.value
            if (description.length <= 3 || null) {
                setProspectos([])
                return
            }
            const fetchProspectosResponse = await (await APIInvoke.invokeGET(`/prospectos?nombres[cn]=${description}&size=20`)).json()
            setProspectos(fetchProspectosResponse.body.items)
        } catch (e) {
            console.error("Error al recuperar los prospectos", e)
        } finally {
            setProspectosLoading(false)
        }
    }

    const fetchProspectoDebounce = useCallback(debounce(fetchProspectos, 500), []);

    const onChangeProspecto = (event, newValue) => {
        setState({
            ...state,
            prospecto: newValue
        })
    }

    const onChange = (e) => {
        const name = e.target.name
        let value = e.target.value

        if (name === "primaTotal") value = value.replaceAll(",", "")

        setState({
            ...state,
            [name]: value
        })
    }


    const submit = async (e) => {
        e.preventDefault()
        try {
            setProspectosLoading(true)
            const response = state.id
                ? await saveCard(state)
                : await addNewCard(state, props.columnId)
            setState(response.body)
            if (response.ok === true) {
                props.onCompleted()
            }
        } catch (e) {
            console.error("Error al crear la tarjeta", e)
        } finally {
            setProspectosLoading(false)
        }

    }

    const createNewNote = async (e) => {
        e.preventDefault()
        try {
            dispatch(setLoading(true))
            const createNewNoteRequest = {
                texto: newNote.texto,
                cardId: state.id
            }
            const createNewNoteResponse = await (await APIInvoke.invokePOST(`/prospectos/kanbans/cards/notes`, createNewNoteRequest)).json()
            setNotes([
                ...notes,
                createNewNoteResponse.body
            ])
            setNewNote({ texto: "" })
        } catch (e) {
            console.error("Error al crear la nota", e)
        } finally {
            dispatch(setLoading(false))
        }
    }

    const getRamoIcon = (ramoId) => {
        switch (ramoId) {
            case 1: return (<DriveEtaIcon className="mr-2" />)  // Auto
            case 3: return (<FavoriteIcon className="mr-2" />)  // Salud
            case 5: return (<PersonIcon className="mr-2" />)  // Vida
            case 7: return (<HomeIcon className="mr-2" />)  // Diversos
        }
    }

    const getStatus = () => {
        switch (state.status) {
            case "exitosa": return (<p className="text-green pb-2"><ThumbUpAltIcon /> Oportunidad cerrar con éxitosa</p>)
            case "no_exitosa": return (<p className="text-red pb-2"><ThumbDownAltIcon /> Oportunidad cerrar sin éxitosa</p>)
            case "abierta": return (<p className="pb-2"><MeetingRoomIcon /> Oportunidad Abierta</p>)
        }
    }

    const close = async (status) => {
        const request = {
            ...state,
            status
        }
        const response = await saveCard(request)
        setState({
            ...state,
            status: response.body.status
        })
    }

    const onNewProspectCompleted = (newProspect) => {
        setShowNewProspectDialog(false)
        setProspectos([
            ...prospectos,
            newProspect
        ])
        setState({
            ...state,
            prospecto: newProspect
        })
    }

    const render = () => {
        return (
            <AdminTemplate loading={store.loading} modal={props.modal}>
                <DalalaDialog
                    title={"Captura de Cliente"}
                    open={showNewProspectDialog}
                    handleClose={(e) => setShowNewProspectDialog(false)}
                >
                    <NuevoProspecto modal={true} onCompleted={onNewProspectCompleted} />
                </DalalaDialog>
                <Toolbar title="Oportunidad" modal={props.modal} >
                    <div className="layout">
                        <section className="flex justify-between items-center">
                            <p>Oportunidad</p>
                            <div className="flex items-center gap-2">
                                <GrantContent permisos={[Permisos.PROSPECCION_W]} >
                                    {(state.id !== null && state.status === "abierta") && (
                                        <Tooltip title={`Cerrar la oportunidad sin éxito`}>
                                            <IconButton className="no-underline p-1 m-0" onClick={e => close("no_exitosa")} >
                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                    <ThumbDownAltIcon className="text-dark-600 ml-2" />
                                                </div>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </GrantContent>

                                <GrantContent permisos={[Permisos.PROSPECCION_W]} >
                                    {(state.id !== null && state.status === "abierta") && (
                                        <Tooltip title={`Cerrar la oportunidad como exitosa`}>
                                            <IconButton className="no-underline p-1 m-0" onClick={e => close("exitosa")}>
                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                    <ThumbUpAltIcon className="text-dark-600 ml-2" />
                                                </div>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </GrantContent>

                                <GrantContent permisos={[Permisos.PROSPECCION_W]} >
                                    {(state.id !== null && state.status !== "abierta") && (
                                        <Tooltip title={`Reabrir la oportunidad`}>
                                            <IconButton className="no-underline p-1 m-0" onClick={e => close("abierta")}>
                                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                                    <MeetingRoomIcon className="text-dark-600 ml-2" />
                                                </div>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </GrantContent>
                            </div>
                        </section>
                    </div>
                </Toolbar >
                <div className="main">
                    <div className="layout-compact">
                        <form onSubmit={submit}>
                            <div className="grid grid-cols-1 gap-4">
                                {getStatus()}
                                <TextField
                                    label="Nombre de oportunidad"
                                    type="text"
                                    variant="outlined"
                                    name="nombre"
                                    value={state.nombre}
                                    onChange={onChange}
                                    onInput={(e) => maxFieldLength(e, 50)}
                                    required
                                />

                                <div className="flex flex-row gap-4">
                                    <Autocomplete
                                        name="prospecto"
                                        className="flex-1"
                                        required
                                        clearText="clearText"
                                        closeText="closeText"
                                        noOptionsText="Sin resultados"
                                        loadingText="Cargando..."
                                        openText="openText"
                                        open={open}
                                        value={state.prospecto}
                                        onOpen={() => setOpen(true)}
                                        onClose={() => setOpen(false)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => `${option.nombres}`}
                                        options={prospectos}
                                        onInput={fetchProspectoDebounce}
                                        loading={prospectosLoading}
                                        onChange={onChangeProspecto}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Prospecto"
                                                required
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {prospectosLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <GrantContent permisos={[Permisos.PROSPECTOS_W]}>
                                        <Tooltip title="Crear nuevo prospecto">
                                            <Button className=" p-0 btn btn-yellow m-0" onClick={(e) => setShowNewProspectDialog(true)} >
                                                <GroupAddIcon className="text-dark-600" fontSize="large" />
                                            </Button>
                                        </Tooltip>
                                    </GrantContent>
                                </div>
                                <FormControl >
                                    <InputLabel id="demo-multiple-checkbox-label" required>Ramo</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-multiple-checkbox-label"
                                        name="ramo"
                                        value={state.ramo}
                                        onChange={onChange}
                                    >
                                        {ramos.map(ramo => (
                                            <MenuItem key={ramo.id} value={ramo.id}>{getRamoIcon(ramo.id)}{ramo.ramo}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormControl variant="outlined" size="medium" required >
                                        <InputLabel id="moneda" required>Moneda</InputLabel>
                                        <Select
                                            labelId="moneda"
                                            label="moneda"
                                            size="medium"
                                            name="moneda"
                                            value={state.moneda}
                                            onChange={onChange}
                                        >
                                            <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                            {currency.map(currency => (
                                                <MenuItem key={currency.id} value={currency.abreviacion}>{currency.abreviacion} - {currency.moneda}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <NumberFormat
                                        name="primaTotal"
                                        value={state.primaTotal}
                                        onChange={onChange}
                                        customInput={TextField}
                                        onFocus={e => e.target.select()}
                                        label="Prima Total"
                                        variant="outlined"
                                        size="medium"
                                        inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                        type="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={","}
                                        allowNegative={false}
                                        isNumericString={true}
                                        required
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />
                                </div>

                                <TextField
                                    inputProps={{ className: "text-white" }}
                                    className="w-full"
                                    color="primary"
                                    label="Descripción"
                                    name="descripcion"
                                    value={state.descripcion}
                                    onChange={onChange}
                                    onInput={(e) => maxFieldLength(e, 1500)}
                                    multiline
                                    rows={5}
                                    required
                                />
                                <GrantContent permisos={[Permisos.PROSPECCION_W]} >
                                    <Button type="submit" className="btn btn-yellow">GUARDAR</Button>
                                </GrantContent>
                            </div>
                        </form>

                        {state.id && (
                            <div className="grid grid-cols-1 gap-4 mt-8 ">
                                <p>Actividad</p>
                                <GrantContent permisos={[Permisos.PROSPECCION_W]} >
                                    <form onSubmit={createNewNote}>
                                        <div className="grid grid-cols-1 gap-4 bg-dark-400 p-2 rounded-md">
                                            <TextField
                                                inputProps={{ className: "text-white" }}
                                                className="w-full"
                                                color="primary"
                                                label="Agregar nueva actividad"
                                                name="texto"
                                                value={newNote.texto}
                                                onChange={e => setNewNote({ ...newNote, texto: e.target.value })}
                                                onInput={(e) => maxFieldLength(e, 1500)}
                                                multiline
                                                rows={2}
                                                required
                                            />
                                            <Button type="submit" className="btn btn-yellow ">AGREGAR ACTIVIDAD</Button>
                                        </div>
                                    </form>
                                </GrantContent>
                                {notes.map((note, index) => (
                                    <Nota key={index} cardId={state.id} note={note} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </AdminTemplate >
        )
    }
    return render()
}

const Nota = (props) => {

    const dispatch = useDispatch()

    const [state, setState] = useState({
        texto: "",
        editable: false
    })

    useEffect(() => {
        setState({ ...props.note })
    }, [])

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setState({
            ...state,
            [name]: value
        })
    }

    const setEditable = () => {
        setState({
            ...state,
            editable: true
        })
    }

    const submitNewNote = async (e) => {
        e.preventDefault()
        try {
            dispatch(setLoading(true))
            const updateNoteRequest = {
                texto: state.texto,
                cardId: props.cardId
            }
            const updateNoteResponse = state.id
                ? await (await APIInvoke.invokePUT(`/prospectos/kanbans/cards/notes/${state.id}`, updateNoteRequest)).json()
                : await (await APIInvoke.invokePOST(`/prospectos/kanbans/cards/notes`, updateNoteRequest)).json()
            setState({
                ...state,
                id: updateNoteResponse.body.id,
                editable: false
            })
        } catch (e) {
            console.log("error al actualizar la nota", e)
        } finally {
            dispatch(setLoading(false))
        }
    }

    const render = () => {
        const { cardId, nota, isNew } = props
        return (
            <form onSubmit={submitNewNote}>
                <div className="bg-dark-500 rounded-md p-4">
                    <div className="grid grid-cols-1 gap-4">
                        <TextField
                            disabled={!state.editable}
                            inputProps={{ className: "text-white" }}
                            className="w-full"
                            color="primary"
                            label="Notas"
                            name="texto"
                            value={state.texto}
                            onChange={onChange}
                            onInput={(e) => maxFieldLength(e, 1500)}
                            multiline
                            rows={2}
                            required
                        />

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                {state.id && (
                                    <div>
                                        <p className="text-xs font-thin text-gray-800">{DateTime.fromFormat(state.fecCreacion, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").setLocale("es").toFormat("dd MMMM yyyy HH:mm")}</p>
                                    </div>
                                )}
                            </div>
                            {state.editable === true
                                ? (
                                    <Button type="submit" className="btn btn-yellow p-2">GUARDAR</Button>
                                )
                                : (
                                    <div className="flex justify-end">
                                        <IconButton onClick={setEditable} color="primary" className="flex items-center" size="medium">
                                            <BorderColorIcon className="text-sm text-gray-500" />
                                        </IconButton>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    return render()
}

export default KanbanNewCard