import React, { useEffect, useState, useCallback } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import Toolbar from '../../components/Toolbar'
import APIInvoke from '../../utils/APIInvoke';
import { DateTime } from 'luxon';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { numberFormat, numberFormatNoDecimal } from '../../utils/NumberUtils';
import millify from "millify";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { TextField } from '@material-ui/core'
import { DataGrid, GridToolbar, GridRow } from '@mui/x-data-grid';
import DateGridlocaleTextConstantsES from '../../utils/DateGridlocaleTextConstantsES'
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Checkbox from '@mui/material/Checkbox';
import DalalaDialog from '../../components/DalalaDialog';
import ConsultaPoliza from '../ConsultaPoliza';


const ReporteComisiones = (props) => {

    const [loading, setLoading] = useState(false)
    const [comisiones1, setComisiones1] = useState({ series: [] })
    const [comisiones2, setComisiones2] = useState({ series: [] })
    const [openPolizaDialog, setOpenPolizaDialog] = useState({ show: false, id: null })
    const [data, setData] = useState({
        items: [],
        totalItems: 1,
        currentPage: 1,
        totalPages: 1,
        pageSize: 1
    })


    const prevDate = DateTime.now().plus({ month: -11 })
    const currDate = DateTime.now()

    const [state, setState] = useState({
        start: DateTime.local(prevDate.year, prevDate.month, 1),
        end: DateTime.local(currDate.year, currDate.month, currDate.daysInMonth),
    })

    useEffect(() => {
        fetchContratante()
    }, [state.start.toMillis(), state.end.toMillis()])

    const columns = [
        {
            field: 'id',
            headerName: ' ',
            type: 'string',
            width: 50,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => (<IconButton onClick={e => setOpenPolizaDialog({ show: true, id: params.row.poliza.id, reciboId: params.row.id })} color="primary" className="flex items-center" size="small">
                <VisibilityIcon className="text-yellow" size="small" />
            </IconButton>)
        }, {
            field: 'noPoliza',
            headerName: 'No Póliza',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.poliza.noPoliza.toUpperCase()}`
        }, {
            field: 'serie',
            headerName: 'Serie',
            type: 'string',
            sortable: false,
            width: 70,
            editable: false,
            valueGetter: (params) => `${params.row.serie}/${params.row.totalSerie}`
        }, {
            field: 'cliente',
            headerName: 'Cliente',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.poliza.cliente.nombres} ${params.row.poliza.cliente.apPat} ${params.row.poliza.cliente.apMat}`
        }, {
            field: 'vendedor',
            headerName: 'Vendedor',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.poliza.vendedor?.nombres || ""} ${params.row.poliza.vendedor?.apPat || ""} ${params.row.poliza.vendedor?.apMat || ""}`
        }, {
            field: 'aseguradora',
            headerName: 'Aseguradora',
            type: 'string',
            width: 200,
            editable: false,
            renderCell: (params) => (<p className="text-sm">{params.row.poliza.agenteClave.aseguradora.abreviacion}</p>)
        }, {
            field: 'total',
            headerName: 'Total',
            type: 'int',
            width: 120,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="text-right">
                        {`${numberFormatNoDecimal(params.row.total)} ${params.row.poliza.moneda.abreviacion}`}
                    </p>
                </div>
            )
        }, {
            field: 'comision',
            headerName: 'Comisiones',
            type: 'string',
            width: 120,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="text-right">
                        {`${numberFormatNoDecimal(params.row.comision)} ${params.row.poliza.moneda.abreviacion}`}
                    </p>
                </div>
            )
        }, {
            field: 'fecVigDe',
            headerName: 'F. Vigencia De',
            type: 'dateTime',
            width: 130,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecVigDe.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'fecVigA',
            headerName: 'F. Vigencia A',
            type: 'date',
            width: 120,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecVigA.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'fecVence',
            headerName: 'F. Vencimiento',
            type: 'date',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecVence.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'fecPago',
            headerName: 'F. Pago',
            type: 'date',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecPago.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'esRobot',
            type: 'boolean',
            headerName: 'Robot',
            width: 80,
            editable: false,
            renderCell: (params) => (<Checkbox disabled checked={params.row.poliza.esRobot} />)

        }
    ]

    const fetchContratante = async (e) => {
        try {
            setLoading(true)
            const searchResponse1 = await (await APIInvoke.invokeGET(`/reportes/comisiones/acumulados?start=${state.start.toFormat("yyyy-MM-dd")}&end=${state.end.toFormat("yyyy-MM-dd")}`)).json()
            const totalPrimas1 = searchResponse1.body.reduce((previousValue, currentValue) => previousValue + currentValue.prima, 0)
            const totalComisiones1 = searchResponse1.body.reduce((previousValue, currentValue) => previousValue + currentValue.comision, 0)
            const porcentajeComision1 = totalPrimas1 === 0 ? 0 : (totalComisiones1 * 100.0) / totalPrimas1

            setComisiones1({
                totalPrimas: totalPrimas1,
                totalComisiones: totalComisiones1,
                porcentajeComision: porcentajeComision1,
                series: searchResponse1.body
            })

            const start2 = state.start.plus({ years: -1 })
            const end2 = state.end.plus({ years: -1 })

            const searchResponse2 = await (await APIInvoke.invokeGET(`/reportes/comisiones/acumulados?start=${start2.toFormat("yyyy-MM-dd")}&end=${end2.toFormat("yyyy-MM-dd")}`)).json()
            const totalPrimas2 = searchResponse2.body.reduce((previousValue, currentValue) => previousValue + currentValue.prima, 0)
            const totalComisiones2 = searchResponse2.body.reduce((previousValue, currentValue) => previousValue + currentValue.comision, 0)
            const porcentajeComision2 = (totalComisiones2 * 100.0) / totalPrimas2
            const totalDifComisiones = totalComisiones1 - totalComisiones2
            let porcentajeDifComision = (Math.abs(totalComisiones1 - totalComisiones2) / totalComisiones2) * 100
            porcentajeDifComision = totalComisiones1 - totalComisiones2 >= 0 ? porcentajeDifComision : -porcentajeDifComision

            setComisiones2({
                totalComisiones1: totalComisiones1,
                totalComisiones2: totalComisiones2,
                totalDifComisiones: totalDifComisiones,
                porcentajeComision: porcentajeComision2,
                porcentajeDifComision: totalComisiones2 === 0 ? 0 : porcentajeDifComision,
                series: searchResponse2.body
            })
            const reciboResponse = await (await APIInvoke.invokeGET(`/recibos?fecPago[bw]=${state.start.toFormat("yyyy-MM-dd")},${state.end.toFormat("yyyy-MM-dd")}&status=PAGADO&size=10000&order=fecPago%20asc`)).json()
            setData({
                ...reciboResponse.body,
                items: reciboResponse.body.items.map(recibo => {
                    return {
                        ...recibo,
                        fecVigDe: recibo.fecVigDe ? DateTime.fromFormat(recibo.fecVigDe, 'yyyy-MM-dd') : null,
                        fecVigA: recibo.fecVigA ? DateTime.fromFormat(recibo.fecVigA, 'yyyy-MM-dd') : null,
                        fecVence: recibo.fecVence ? DateTime.fromFormat(recibo.fecVence, 'yyyy-MM-dd') : null,
                        fecPago: recibo.fecPago ? DateTime.fromFormat(recibo.fecPago, 'yyyy-MM-dd') : null
                    }
                })
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const getSeries = (comisiones) => {
        return comisiones.series.map(x => {
            return {
                ...x,
                fechaLabel: DateTime.fromFormat(x.fecha, "yyyyMM").setLocale("es").toFormat("MMM-yy")
            }
        })
    }

    const getSeriesPeriodoAnterior = () => {
        const series = [
            {
                name: "Periodo Actual",
                color: "#8884d8",
                data: comisiones1.series.map(x => {
                    return {
                        ...x,
                        fechaLabel: DateTime.fromFormat(x.fecha, "yyyyMM").setLocale("es").toFormat("MMMM")
                    }
                })
            }, {
                name: "Periodo Anterior",
                color: "#82ca9d",
                data: comisiones2.series.map(x => {
                    return {
                        ...x,
                        fechaLabel: DateTime.fromFormat(x.fecha, "yyyyMM").setLocale("es").toFormat("MMMM")
                    }
                })
            }
        ]
        return series;
    }

    const renderTooltipContent = (o) => {
        const { payload, label } = o
        return (
            <div className="customized-tooltip-content">
                <p className="total font-bold">{`${label}`}</p>
                <ul className="list font-bold">
                    {payload.map((entry, index) => (
                        <li key={`item-${index}`} style={{ color: entry.color }}>
                            {`${entry.name}: ${numberFormat(entry.value)}`}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };



    const render = () => {

        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Reporte de Comisiones" />
                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={openPolizaDialog.show}
                    handleClose={() => setOpenPolizaDialog({ show: false, id: null, reciboId: null })}
                >
                    <ConsultaPoliza
                        modal={true}
                        polizaId={openPolizaDialog.id}
                        reciboId={openPolizaDialog.reciboId}
                        onCompleted={() => setOpenPolizaDialog({ show: false, id: null, reciboId: null })}
                    />
                </DalalaDialog>
                <div className="main">
                    <div className="layout">
                        <div className="">
                            <div className="grid grid-cols-1 gap-4 lg:gap-8 ">

                                <div className=" bg-dark-400 p-4 rounded-md">
                                    <div className="grid grid-cols-1 gap-4">
                                        <p className="font-bold"><FilterAltIcon /> Filtros:</p>
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                                <DatePicker
                                                    label="Inicio de Vigencia De"
                                                    views={['year', 'month']}
                                                    value={state.start.toJSDate()}
                                                    onChange={(newValue) => {
                                                        setState({ ...state, start: DateTime.fromJSDate(newValue) })
                                                    }}
                                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                                />
                                            </LocalizationProvider>

                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                                <DatePicker
                                                    label="Inicio de Vigencia A"
                                                    views={['year', 'month']}
                                                    minDate={state.start.toJSDate()}
                                                    value={state.end}
                                                    onChange={(newValue) => {
                                                        setState({ ...state, end: DateTime.fromJSDate(newValue) })
                                                    }}
                                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-dark-400 p-4 rounded-md">
                                    <div className="grid grid-cols-1 gap-4">
                                        <p className="text-center text-lg text-yellow">Comisiones vs Primas</p>
                                        <div className="h-96">
                                            <ResponsiveContainer >
                                                <AreaChart width={730} height={250} data={getSeries(comisiones1)} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                                    <defs>
                                                        <linearGradient id="prima" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                                        </linearGradient>
                                                        <linearGradient id="comision" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                                        </linearGradient>
                                                    </defs>
                                                    <XAxis dataKey="fechaLabel" fontSize={12} />
                                                    <YAxis dataKey="prima" fontSize={12} allowDecimals={false} tickFormatter={(value, prima) => {
                                                        return value === -Infinity ? 0 : millify(value, { precision: 2, decimalSeparator: "," })
                                                    }} />
                                                    <Legend />
                                                    <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                                                    <Tooltip content={renderTooltipContent} />
                                                    <Area type="monotone" dataKey="prima" stroke="#8884d8" name="Primas" fillOpacity={1} fill="url(#prima)" />
                                                    <Area type="monotone" dataKey="comision" stroke="#82ca9d" name="Comisiones" fillOpacity={1} fill="url(#comision)" />
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="bg-dark-500 rounded-md p-4">
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                                <div className="bg-dark-400 p-4 gap-4 grid rounded-md">
                                                    <p className="text-center text-sm text-gray-800">Prima Neta Acumulada</p>
                                                    <p className="text-center text-3xl font-bold text-yellow">{numberFormatNoDecimal(comisiones1.totalPrimas)}</p>
                                                </div>
                                                <div className="bg-dark-400 p-4 gap-4 grid rounded-md">
                                                    <p className="text-center text-sm text-gray-800">Comisiones Acumuladas</p>
                                                    <p className="text-center text-3xl font-bold text-yellow">{numberFormatNoDecimal(comisiones1.totalComisiones)}</p>
                                                </div>
                                                <div className="bg-dark-400 p-4 gap-4 grid rounded-md">
                                                    <p className="text-center text-sm text-gray-800">Comisiones Promedio</p>
                                                    <p className="text-center text-3xl font-bold text-yellow">{`${Number.parseInt(comisiones1.porcentajeComision)}%`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-dark-400 p-4 rounded-md">
                                    <div className="grid grid-cols-1 gap-4">
                                        <p className="text-center text-lg text-yellow">Comisiones vs periodo anterior</p>
                                        <div className="h-96">
                                            <ResponsiveContainer >
                                                <AreaChart width={730} height={250} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                                    <defs>
                                                        <linearGradient id="gradiant-0" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                                        </linearGradient>
                                                        <linearGradient id="gradiant-1" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                                        </linearGradient>
                                                    </defs>
                                                    <XAxis dataKey="fechaLabel" fontSize={12} allowDuplicatedCategory={false} />
                                                    <YAxis dataKey="comision" fontSize={12} allowDecimals={false} tickFormatter={(value, total) => {
                                                        return value === -Infinity ? 0 : millify(value, { precision: 2, decimalSeparator: "," })
                                                    }} />
                                                    <Legend />
                                                    <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                                                    <Tooltip content={renderTooltipContent} />
                                                    {
                                                        getSeriesPeriodoAnterior().map((serie, index) => (
                                                            <Area type="monotone" dataKey="comision" data={serie.data} name={serie.name} key={serie.name} stroke={serie.color} fillOpacity={1} fill={`url(#gradiant-${index})`} />
                                                        ))
                                                    }
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="bg-dark-500 rounded-md p-4">
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                                <div className="bg-dark-400 p-4 gap-4 grid rounded-md">
                                                    <p className="text-center text-sm text-gray-800">Comisiones periodo actual</p>
                                                    <p className="text-center text-3xl font-bold text-yellow">{numberFormatNoDecimal(comisiones2.totalComisiones1)}</p>
                                                </div>
                                                <div className="bg-dark-400 p-4 gap-4 grid rounded-md">
                                                    <p className="text-center text-sm text-gray-800">Diferencia vs periodo anterior</p>
                                                    <p className={comisiones2.totalDifComisiones >= 0 ? "text-center text-3xl font-bold text-green-500 flex justify-center items-start" : "text-center text-3xl font-bold text-red flex justify-center items-start"}>
                                                        {numberFormatNoDecimal(comisiones2.totalDifComisiones)}
                                                        {comisiones2.totalDifComisiones >= 0 ? <ArrowUpwardIcon className="text-green-500" fontSize="small" /> : <ArrowDownwardIcon className="text-red" fontSize="small" />}
                                                    </p>
                                                </div>
                                                <div className="bg-dark-400 p-4 gap-4 grid rounded-md">
                                                    <p className="text-center text-sm text-gray-800">Porcentaje vs periodo anterior</p>
                                                    <p className={comisiones2.porcentajeDifComision >= 0 ? "text-center text-3xl font-bold text-green-500 flex justify-center items-start" : "text-center text-3xl font-bold text-red flex justify-center items-start"}>
                                                        {`${parseInt(comisiones2.porcentajeDifComision)}%`}
                                                        {comisiones2.porcentajeDifComision >= 0 ? <ArrowUpwardIcon className="text-green-500" fontSize="small" /> : <ArrowDownwardIcon className="text-red" fontSize="small" />}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-dark-400 p-4 rounded-md">
                                    <div className="grid grid-cols-1 bg-gray-100 rounded-md" style={{ height: "700px" }} >
                                        <DataGrid
                                            className="bg-dark-300 text-gray-400 border-dark-600"
                                            rows={data.items}
                                            columns={columns}
                                            getRowId={e => e.id}
                                            pageSize={100}
                                            rowsPerPageOptions={[10, 50, 100]}
                                            onPageSizeChange={(pageSize, details) => setData({ ...data, pageSize: pageSize })}
                                            rowCount={data.totalItems}
                                            paginationMode="client"
                                            onFilterModelChange={(model, details) => {
                                                console.log("model", model)
                                                console.log("details", details)
                                            }}
                                            checkboxSelection
                                            disableSelectionOnClick
                                            localeText={DateGridlocaleTextConstantsES}
                                            components={{
                                                Toolbar: GridToolbar,
                                                Row: GridRow, // Default value
                                                //Footer: GridFooter
                                                //Footer: (params) => (<div className="p-2"><Pagination totalItems={data.totalItems} pages={data.totalPages} page={data.currentPage} onPageChange={newPage => setState({ ...state, page: newPage })} /></div>)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default ReporteComisiones