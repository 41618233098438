import { Button, IconButton } from '@material-ui/core'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitle, Tooltip } from '@mui/material';
import { useState } from 'react';
import { deleteColummn } from './kaban-prospecto-actions'
import DalalaDialog from '../../../components/DalalaDialog';
import KanbanNewCard from './KanbanNewCard';
import { numberFormatNoDecimal } from '../../../utils/NumberUtils';
import GrantContent from '../../../components/GrantContent';
import * as Permisos from '../../../utils/Permisos'

const KanbanColumnHeader = (props) => {

    const [state, setState] = useState({
        showDeleteDialog: false,
        showNewCardDialog: false
    })

    const showDeleteDialog = () => {
        setState({ ...state, showDeleteDialog: true })
    }

    const hiddenDeleteDialog = () => {
        setState({ ...state, showDeleteDialog: false })
    }

    const showNewCardDialog = () => {
        setState({ ...state, showNewCardDialog: true })
    }

    const hiddenNewCardDialog = () => {
        setState({ ...state, showNewCardDialog: false })
    }

    const render = () => {
        const { payload } = props
        const totalPrimas = payload.cards.reduce((previousValue, currentValue) => previousValue + currentValue.primaTotal, 0)
        return (
            <>
                <Dialog
                    open={state.showDeleteDialog}
                    onClose={hiddenDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la columna?, si procede perderá todas las actividades de esta columna.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={hiddenDeleteDialog}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={e => deleteColummn(payload.id)} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>
                <DalalaDialog
                    title={"Nueva tarjeta"}
                    open={state.showNewCardDialog}
                    handleClose={hiddenNewCardDialog}
                >
                    <KanbanNewCard
                        modal={true}
                        columnId={payload.id}
                        onCompleted={hiddenNewCardDialog}
                    />
                </DalalaDialog>
                <div className="grid grid-cols-1 gap-2">
                    <div className="flex justify-between items-center p-2 bg-dark-600 rounded-t-md">
                        <span className=" truncate text-sm font-bold">{payload.nombre}</span>
                        <GrantContent permisos={[Permisos.PROSPECCION_D]}>
                            <Tooltip title="Eliminar columna">
                                <IconButton onClick={showDeleteDialog}>
                                    <CloseIcon className="text-sm text-gray-500" />
                                </IconButton>
                            </Tooltip>
                        </GrantContent>
                    </div>
                    {/* 
                    <div className="p-2">
                        <Button
                            onClick={showNewCardDialog}
                            className="btn btn-yellow w-full py-1 flex items-center"
                        >
                            <AddIcon />
                            <span className="text-dark-600 text-sm">Agregar</span>
                        </Button>
                    </div>
                    */}
                    <div className="p-2">
                        <GrantContent permisos={[Permisos.PROSPECCION_W]}>
                            <Button
                                onClick={showNewCardDialog}
                                className="btn btn-dark border border-dashed border-yellow-500 w-full py-1 flex items-center"
                            >
                                <AddIcon className="text-gray-500" />
                                <span className="text-gray-500 text-sm"> oportunidad</span>
                            </Button>
                        </GrantContent>
                        <div className="mt-3 p-2">
                            <p className="text-sm">Total Primas:</p>
                            <p className="text-2xl">${numberFormatNoDecimal(totalPrimas)}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return render()
}
export default KanbanColumnHeader