import React, { useContext, useEffect } from 'react'
import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import { useState } from 'react'
import AdminTemplate from '../../../template/AdminTemplate'
import Toolbar from '../../../components/Toolbar'
import KanbanCard from './KanbanCard'
import { Button, FormControlLabel, TextField } from '@material-ui/core'
import KanbanColumnHeader from './KanbanColumnHeader'
import { useDispatch, useSelector } from 'react-redux';
import {
    updateColummn,
    fetchKanban,
    updateCard,
    setFilters
} from './kaban-prospecto-actions'
import KanbanColumnAdder from './KanbanColumnAdder'
import { Link } from 'react-router-dom'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Checkbox from "@material-ui/core/Checkbox";
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import esLocale from 'date-fns/locale/es';
import { DateTime } from 'luxon'
import UserContext from '../../../state/UserContext'
import * as Permisos from '../../../utils/Permisos'
import * as Roles from '../../../utils/Roles'


const KanbanProspectos = (props) => {

    const dispatch = useDispatch()
    const state = useSelector(state => state.pages.prospectoKanban)
    const { user } = useContext(UserContext)
    const userPermisos = user?.permisos
    const userRoles = user?.rol


    useEffect(() => {
        fetchKanban()
    }, [state.filters])

    const changeFilters = async (e) => {
        const name = e.target.name
        let value = e.target.value

        if (e.target.type === "checkbox") value = e.target.checked

        setFilters({
            ...state.filters,
            [name]: value
        })
    }

    const render = () => {
        const canDrag = userRoles.includes(Roles.ROLE_ADMIN) || userPermisos.includes(Permisos.PROSPECCION_W)
        return (
            <AdminTemplate loading={state.loading}>
                <Toolbar title="Tablero de prospectos" />
                <div className="main">
                    <div className="mx-4 lg:mx-8">
                        <div className="grid grid-cols-1 gap-4 min-h-[500px]">
                            <div className="flex justify-end">
                                <Link className="btn btn-yellow py-2" to={{ pathname: "/prospectos", state: { backUrl: "/prospeccion" } }}>
                                    <HowToRegIcon />
                                    Ver prospectos
                                </Link>
                            </div>
                            <div className="grid grid-cols-1 gap-4 bg-dark-400 rounded-md p-4">
                                <p>Tablero de prospectos</p>
                                <div>
                                    <FormControlLabel
                                        label={<p className="text-sm">Ver oportunidades cerradas</p>}
                                        required
                                        checked={state.filters.showClosed === true}
                                        onChange={changeFilters}
                                        control={
                                            <Checkbox name="showClosed" color="primary" />
                                        }
                                    />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                        <DatePicker
                                            allowSameDateSelection={false}
                                            ignoreInvalidInputs={true}
                                            onAccept={date => console.log("onAccept", date)}
                                            label="Creadas del"
                                            views={['day', 'month', 'year']}
                                            maxDate={state.filters.endDate.toJSDate()}
                                            value={state.filters.startDate.toJSDate()}
                                            onChange={newValue => {
                                                changeFilters({ target: { name: "startDate", value: DateTime.fromJSDate(newValue), type: "date" } })
                                            }}
                                            renderInput={(params) => <TextField {...params} helperText={null} />}
                                        />
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                        <DatePicker
                                            allowSameDateSelection={false}
                                            ignoreInvalidInputs={true}
                                            label="Creadas al"
                                            views={['day', 'month', 'year']}
                                            minDate={state.filters.startDate.toJSDate()}
                                            value={state.filters.endDate}
                                            onChange={newValue => {
                                                changeFilters({ target: { name: "endDate", value: DateTime.fromJSDate(newValue), type: "date" } })
                                            }}
                                            renderInput={(params) => <TextField {...params} helperText={null} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <Board
                                disableColumnDrag={!canDrag}
                                disableCardDrag={!canDrag}
                                allowRenameColumn
                                allowRemoveLane
                                allowRemoveCard
                                allowRemoveColumn
                                allowAddLane
                                allowAddColumn
                                allowAddCard={{ on: "top" }}
                                onColumnRename={console.log}
                                onLaneRemove={console.log}
                                onCardRemove={console.log}
                                onColumnRemove={console.log}
                                onLaneRename={console.log}
                                onColumnNew={console.log}
                                onCardNew={console.log}
                                //initialBoard={state}
                                onColumnDragEnd={(board, column, source, destination) => {
                                    updateColummn({
                                        id: board.id,
                                        nombre: board.nombre,
                                        to: source.toPosition,
                                        from: column.fromPosition
                                    })
                                }}
                                onCardDragEnd={(board, card, source, destination) => {
                                    updateCard({
                                        card: board,
                                        fromColumn: card.fromColumnId,
                                        fromPosition: card.fromPosition,
                                        toColumn: source.toColumnId,
                                        toPosition: source.toPosition,
                                        to: source.toPosition
                                    })

                                }}
                                renderColumnHeader={(payload, cardBag) => (
                                    <KanbanColumnHeader payload={payload} />
                                )}
                                renderColumnAdder={(cardBag) => (
                                    <KanbanColumnAdder />
                                )}
                                renderCard={(payload, cardBag) => (
                                    <KanbanCard
                                        key={payload.id}
                                        payload={payload}
                                        dragging={cardBag.dragging}
                                        removeCard={cardBag.removeCard}
                                    />
                                )}
                            >
                                {state.kanban}
                            </Board>
                        </div>
                    </div>

                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default KanbanProspectos