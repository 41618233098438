import { DateTime } from "luxon"
import { NUEVA_COTIZACION_RESET, NUEVA_COTIZACION_SET } from "../redux-const"
import { v4 as uuidv4 } from 'uuid'

export const planPagoInitalState = () => {
    return {
        uuid: uuidv4(),
        id: null,
        edit: true,
        formaPago: "",
        total: 0,
        primaPrimerRecibo: 0,
        primaReciboSub: 0,
        orden: null,
    }
}

export const aseguradoInitalState = {
    id: null,
    edit: true,
    nombre: "",
    parentesco: "",
    fecNacimiento: null,
    genero: "",
    edad: ""
}

export const coberturaInitialState = {
    id: null,
    edit: true,
    descripcion: "",
    valor: ""
}

export const caracteristicaInitialState = {
    id: null,
    edit: true,
    descripcion: "",
    valor: ""
}

export const itemInitialState = () => {
    return {
        uuid: uuidv4(),
        templates: [],
        templateId: null,
        aseguradoraId: "",
        aseguradora: null,
        plan: "",
        moneda: "",
        sumaAsegurada: "",
        deducible: "",
        coaseguro: "",
        topeCoaseguro: "",
        copago: "",
        caracteristicas: [],
        coberturas: [],
        planesPago: []
    }
}

const initialState = {
    loading: false,
    showDeleteItemDialog: false,
    expandAsegurados: false,
    expandCoberturas: true,
    openNewCliente: false,
    openClienteList: false,
    contratantesLoading: false,
    openNewClienteDialog: false,
    openNewProspectoDialog: false,
    noCotizacion: "",
    ramo: "",
    cliente: null,
    clienteId: null,
    clientesOptions: [],
    correo: "",
    aseguradoras: [],
    asegurados: [],
    monedas: [],
    coberturasOptions: [],
    items: [],
    vehiculo: {
        descripcion: "",
        modelo: ""
    },
    fecVencimiento: DateTime.now().plus({days: 7}).toJSDate(),
    status: "captura",
}

export const reducer = (state = initialState, action) => {
    const { payload } = action
    switch (action.type) {
        case NUEVA_COTIZACION_RESET: {
            return initialState;
        }
        case NUEVA_COTIZACION_SET: {
            return {
                ...state,
                ...payload
            }
        }
    }
    return state
}

export default reducer