
export const hasText = (text) => {
    if (typeof text !== "string") return false
    return text.trim().length > 0
}

export const isEmpty = (text) => {
    if (typeof text !== "string") return true
    return text.trim().length === 0
}


export const hasValue = (value) => {
    if (typeof value === 'string') {
        return hasText(value)
    } else if (typeof value === 'number') {
        return true
    } else if (typeof value === 'boolean') {
        return true
    } else if (typeof value === 'object' && value !== null) {
        return true
    } else {
        return false
    }
}