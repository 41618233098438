import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useState } from 'react';
import { hasValue } from '../../utils/StringUtils';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const FilterBuilder2 = ({
    filters,
    onProcess
}) => {

    const getInitialState = () => {
        const newState = filters.map(filter => {
            let value = ""
            let active = false
            if (hasValue(filter.value)) {
                value = filter.value
                active = true
            }
            else if (hasValue(filter.defaultValue)) {
                value = filter.defaultValue
                active = true
            }

            return {
                ...filter,
                value: value,
                active: active,
                require: filter.require === true,
                colSpan: filter.colSpan ? filter.colSpan : 1,
                processInput: filter.processInput ? filter.processInput : filter.type.processInput ? filter.type.processInput : (value) => value,
                getOptionLabel: filter.getOptionLabel ? filter.getOptionLabel : filter.type.getOptionLabel ? filter.type.getOptionLabel : (value) => value,
                getOptionId: filter.getOptionId ? filter.getOptionId : filter.type.getOptionId ? filter.type.getOptionId : (value) => value
            }
        })
        return newState
    }

    const getActiveFilters = (newState) => {
        const countActive = newState.reduce((count, item) => {
            if (item.active) {
                return count + 1;
            }
            return count;
        }, 0);
        return countActive
    }

    const [expand, setExpand] = useState(true)
    const [state, setState] = useState(getInitialState())
    const [commitedState, setCommitedState] = useState(getInitialState())
    const [filterCount, setFilterCount] = useState(getActiveFilters(state))

    const processFilter = () => {
        const queryBuilder = {
            queryString: "",
            filters: [],
            count: 0
        }

        for (var c = 0; c < state.length; c++) {
            const filter = state[c]
            if (!filter.processInput) {
                filter.processInput = (value) => value
            }
            const value = filter.processInput(filter.value)
            if (hasValue(value)) {
                queryBuilder.filters.push({ [filter.filter]: filter.value })
                queryBuilder.count = queryBuilder.count + 1
                queryBuilder.queryString += `${queryBuilder.queryString === "" ? '?' : "&"}${filter.filter}=${value}`
            }
        }
        onProcess(queryBuilder)
        setCommitedState([...state])
    }

    const findFilterIndexById = (id) => {
        return state.findIndex(x => x.id === id)
    }

    const updateState = (id, value, process = false) => {
        const filterIndex = findFilterIndexById(id)
        state[filterIndex] = { ...state[filterIndex], value: value, active: hasValue(value) && value !== false }
        const newState = [...state]
        setState(newState)
        setFilterCount(getActiveFilters(state))

        if (process) {
            processFilter(state)
        }
    }

    const clean = () => {
        const newState = getInitialState()
        setState(newState)
        setFilterCount(getActiveFilters(newState))
        processFilter(newState)
        setCommitedState(getInitialState())
    }


    const render = () => {
        const activeFilters = commitedState.filter(filter => filter.active === true) || []
        return (
            <div className="grid gap-4">
                <div className="hidden col-span-1 md:col-span-2 lg:col-span-3" />
                <Accordion expanded={expand} onChange={() => setExpand(!expand)} className="bg-dark-400 rounded-md">
                    <AccordionSummary expandIcon={<ExpandMoreIcon className="text-gray-400" />} aria-controls="panel1a-content" id="panel1a-header">
                        <p className="font-bold"><FilterAltIcon /> Filtros: {filterCount > 0 && <span className="rounded-full ml-2 inline-block bg-yellow text-dark-600 text-sm w-5 h-5 text-center">{filterCount}</span>}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="grid grid-cols-1  gap-4 bg-dark-400">
                            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                                {
                                    state.map((filter, index) => (
                                        <div key={filter.id} className={`xl:col-span-${filter.colSpan}`}>
                                            <filter.type lastCommitedState={commitedState[index]} key={index} {...filter} updateState={updateState} processFilter={processFilter} />
                                        </div>)
                                    )
                                }
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="grid grid-cols-2 gap-4 md:col-start-2">
                                    <Button onClick={clean} className="btn h-10 btn-yellow secundary">LIMPIAR</Button>
                                    <Button onClick={e => processFilter(state)} className="btn btn-yellow h-10">APLICAR</Button>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion >

                {activeFilters.length !== 0 && (
                    <div className="bg-dark-400 rounded-md p-2 md:p-4">
                        <Stack direction="row" flexWrap="wrap" alignItems="start" gap={1}>
                            {activeFilters.map((filter, index) => (
                                <Chip key={index} label={`${filter.placeholder}=${filter.getOptionLabel(filter.value)}`} className="bg-dark-200" variant="filled" color="success" onDelete={filter.require ? null : e => updateState(filter.id, "", true)} />
                            ))}
                        </Stack>
                    </div>
                )}
            </div>

        )
    }

    return render()
}

export default FilterBuilder2