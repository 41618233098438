import EditIcon from '@mui/icons-material/Edit';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminTemplate from '../../template/AdminTemplate';
import APIInvoke from '../../utils/APIInvoke'
import Toolbar from '../../components/Toolbar'
import DalalaDialog from '../../components/DalalaDialog';
import Bitacora from './../Bitacora';
import Adjuntos from './../Adjuntos';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@material-ui/core';
import { DialogTitle, IconButton, Tooltip } from '@mui/material';
import { getCotizacionStatuById, getRamoById, getRamos } from '../../utils/EnumUtils';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import CategoryIcon from '@mui/icons-material/Category';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import * as Permisos from '../../utils/Permisos'
import GrantContent from '../../components/GrantContent';

const ConsultaCotizacionTemplate = (props) => {

    const params = useParams()
    const history = useHistory()
    const cotizacionId = props.cotizacionId || params.cotizacionId

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(null)
    const [hasError, setHasError] = useState(false)
    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })
    const [showOkDialog, setShowOkDialog] = useState({ show: false, message: "" })


    useEffect(async () => {

        try {
            setLoading(true)
            const responseConCotizacion = await (await APIInvoke.invokeGET(`/cotizaciones/templates/${cotizacionId}`)).json();
            setResult({
                ...responseConCotizacion.body
            })
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }

    }, [])

    const deleteCotizacion = async () => {
        try {
            setOpenDeleteDialog({ show: false })
            setLoading(true)
            const deleteResponse = await (await APIInvoke.invokeDELETE(`/cotizaciones/templates/${cotizacionId}`)).json()
            if (deleteResponse.ok) {
                history.push("/cotizaciones/templates")
                props.onCompleted(result)
            } else {
                setShowOkDialog({ show: true, message: deleteResponse.message })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {

        const status = getCotizacionStatuById(result?.status) || {}
        const ramo = getRamoById(result?.ramo) || {}


        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitacoraDialog.show}
                    handleClose={() => setOpenBitacoraDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"cotizaciones"} entidadId={openBitacoraDialog.id} onCompleted={() => setOpenBitacoraDialog({ show: false, id: null })} />
                </DalalaDialog>
                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosDialog.show}
                    handleClose={() => setOpenAdjuntosDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"cotizaciones"} entidadId={openAdjuntosDialog.id} onCompleted={() => setOpenAdjuntosDialog({ show: false, id: null })} />
                </DalalaDialog>

                <Dialog
                    open={openDeleteDialog.show}
                    onClose={e => setOpenDeleteDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar el template?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={deleteCotizacion} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showOkDialog.show}
                    onClose={e => setShowOkDialog({ show: false, message: "" })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">{showOkDialog.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn btn-yellow p-2 text-sm" onClick={e => setShowOkDialog({ show: false, message: "" })} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Toolbar title="Detalle del Template de Cotización" backUrl={`/cotizaciones/templates`} modal={props.modal}>
                    {result && (
                        <div className="layout">
                            <div className="flex justify-end gap-2">

                                <GrantContent permisos={[Permisos.COTIZACIONES_TEMP_W]}>
                                    <Tooltip title="Editar">
                                        <Link className="no-underline" to={{ pathname: `/cotizaciones/templates/${cotizacionId}`, state: { backUrl: `/cotizacion/templates/${cotizacionId}` } }}>
                                            <div className="bg-yellow hover:bg-yellow-600 h-10 w-10 text-center flex items-center rounded-md">
                                                <EditIcon className="text-dark-600 ml-2"></EditIcon>
                                            </div>
                                        </Link>
                                    </Tooltip>
                                </GrantContent>

                                <GrantContent permisos={[Permisos.COTIZACIONES_TEMP_D]}>
                                    <Tooltip title="Eliminar">
                                        <IconButton className="btn btn-yellow h-full" onClick={e => setOpenDeleteDialog({ show: true, id: result.id })}>
                                            <DeleteIcon className="text-dark-600" />
                                        </IconButton>
                                    </Tooltip>
                                </GrantContent>
                            </div>
                        </div>
                    )}
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        {result && (
                            <div className="grid gap-8">
                                <p className={`text-right font-bold ${status?.color}`}>{status?.name}</p>
                                <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                    <div className="flex justify-between">
                                        <p className="text-gray-400 text-base font-semibold"><RequestPageIcon fontSize="medium" /> DATOS GENERALES</p>
                                        <span className="text-white text-2xl">{ramo.icon}</span>
                                    </div>

                                    <div className="grid gap-4 bg-dark-500 rounded-md p-4">
                                        <div>
                                            <p className="text-yellow">Nombre</p>
                                            <p className="text-sm uppercase">{result.nombre}</p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Plan</p>
                                            <p className="text-sm">{result.plan}</p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Aseguradora</p>
                                            <p className="text-sm">{result.aseguradora.abreviacion}</p>
                                        </div>
                                        <div>
                                            <p className="text-yellow">Moneda</p>
                                            <p className="text-sm">{result.moneda.abreviacion}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                    <p className="text-gray-400 text-base font-semibold"><PriceChangeIcon fontSize="medium" /> CARACTERÍSTICAS DEL PLAN</p>
                                    <div className="grid gap-4 bg-dark-500 rounded-md p-4">
                                        <div>
                                            <p className="text-yellow">Suma Asegurada</p>
                                            <p className="text-sm uppercase">{result.sumaAsegurada}</p>
                                        </div>
                                        {result.ramo !== "Vida" && (
                                            <div>
                                                <p className="text-yellow">Deducible</p>
                                                <p className="text-sm">{result.deducible}</p>
                                            </div>
                                        )}
                                        {result.ramo === "salud" && (
                                            <>
                                                <div>
                                                    <p className="text-yellow">Coaseguro</p>
                                                    <p className="text-sm">{result.coaseguro}</p>
                                                </div>
                                                <div>
                                                    <p className="text-yellow">Tope Coaseguro</p>
                                                    <p className="text-sm">{result.topeCoaseguro}</p>
                                                </div>
                                                <div>
                                                    <p className="text-yellow">Copago</p>
                                                    <p className="text-sm">{result.copago}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                    <p className="text-gray-400 text-base font-semibold"><CategoryIcon fontSize="medium" /> Características Adicionales ({result.caracteristicas.length})</p>
                                    <div className="grid gap-2">
                                        {result.caracteristicas.map(caracteritica => (
                                            <div key={caracteritica.id} className="items-center gap-2 p-2 px-4 bg-dark-500 rounded-md">
                                                <p className="text-yellow">{caracteritica.descripcion}</p>
                                                <p className="text-sm">{caracteritica.valor}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className=" grid gap-4 bg-dark-400 p-4 rounded-md">
                                    <p className="text-gray-400 text-base font-semibold"><HealthAndSafetyIcon fontSize="medium" /> Coberturas ({result.coberturas.length})</p>
                                    <div className="grid gap-2">
                                        {result.coberturas.map(cobertura => (
                                            <div key={cobertura.id} className="items-center gap-2 p-2 px-4 bg-dark-500 rounded-md">
                                                <p className="text-yellow">{cobertura.descripcion}</p>
                                                <p className="text-sm">{cobertura.valor}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </AdminTemplate>
        )

    }


    return render()

}

export default ConsultaCotizacionTemplate