import {
    RENOVACIONES_RESET,
    RENOVACIONES_UPDATE_STATE,
    RENOVACIONES_UPDATE_DIALOG,
    RENOVACIONES_UPDATE_RECIBO,
    RENOVACIONES_UPDATE_FILTER
} from "../redux-const";

const initialState = {
    loading: false,
    layout: "list",
    filter: {
        breakpoint: -1
    },
    recibos: [],
    breakpoints: [
        {
            range: [-60, 0],
            color: "red"
        },
        {
            range: [1, 7],
            color: "orange"
        },
        {
            range: [8, 14],
            color: "yellow"
        },
        {
            range: [15, 21],
            color: "green"
        },
        {
            range: [22, 60],
            color: "blue"
        }
    ],
    dialogs: {
        poliza: { show: false },
        pago: { show: false },
        correo: { show: false },
        bitacora: { show: false },
        adjuntos: { show: false },
        cerrarPoliza: { show: false },
        cancelar: { show: false }
    }
}

export const reducer = (state = initialState, action) => {
    const payload = action.payload;

    switch (action.type) {
        case RENOVACIONES_UPDATE_STATE: {
            return {
                ...state,
                ...payload
            }
        }
        case RENOVACIONES_RESET: {
            return initialState
        }
        case RENOVACIONES_UPDATE_DIALOG: {
            return {
                ...state,
                dialogs: {
                    ...state.dialogs,
                    [action.dialog]: payload
                }
            }
        }
        case RENOVACIONES_UPDATE_RECIBO: {
            const index = state.recibos.findIndex(r => r.polizaId === payload.id)
            console.log("index", index)
            state.recibos[index] = {
                ...state.recibos[index],
                status: payload.status
            }

            return {
                ...state,
                recibos: [...state.recibos]
            }
        }
        case RENOVACIONES_UPDATE_FILTER: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...payload
                }
            }
        }
        default: return state
    }
}


export default reducer;
