import React from 'react'
import AdminTemplate from '../template/AdminTemplate'
import Toolbar from '../components/Toolbar'
import { Link } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import BarChartIcon from '@mui/icons-material/BarChart';
import EmailIcon from '@mui/icons-material/Email';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import StyleIcon from '@mui/icons-material/Style';
import WidgetsIcon from '@mui/icons-material/Widgets'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import ApartmentIcon from '@mui/icons-material/Apartment';
import Person from '@mui/icons-material/Person'
import GrantContent from '../components/GrantContent';
import * as Roles from '../utils/Roles'
import * as Permisos from '../utils/Permisos'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
const Configuracion = (props) => {
    const render = () => {
        return (
            <AdminTemplate >
                <Toolbar title="Configuración">
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        <div className="grid grid-cols-1 gap-8">
                            <Link to={{ pathname: "/configuracion/mi-cuenta", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                <AccountCircleIcon fontSize="large" className="text-yellow mr-2" />
                                <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Mi Cuenta</span>
                            </Link>
                            <GrantContent permisos={[Permisos.EMPRESA_W]}>
                                <Link to={{ pathname: "/configuracion/empresa", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                    <ApartmentIcon fontSize="large" className="text-yellow mr-2" />
                                    <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Empresa</span>
                                </Link>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.USUARIOS_D]}>
                                <Link to={{ pathname: "/configuracion/usuarios", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                    <Person fontSize="large" className="text-yellow mr-2" />
                                    <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Usuarios</span>
                                </Link>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.CLAVES_W]}>
                                <Link to={{ pathname: "/configuracion/claves", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                    <AssignmentIndIcon fontSize="large" className="text-yellow mr-2" />
                                    <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Claves de Agente</span>
                                </Link>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.CONFIG]}>
                                <Link to={{ pathname: "/configuracion/vencimientos", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                    <EventAvailableIcon fontSize="large" className="text-yellow mr-2" />
                                    <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Fechas de Vencimiento</span>
                                </Link>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.METAS]}>
                                <Link to={{ pathname: "/configuracion/metas", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                    <BarChartIcon fontSize="large" className="text-yellow mr-2" />
                                    <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Metas</span>
                                </Link>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.COMUNICADOS_W]}>
                                <Link to={{ pathname: "/configuracion/modelos", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                    <EmailIcon fontSize="large" className="text-yellow mr-2" />
                                    <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Correos Electrónicos</span>
                                </Link>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.INTEGRACIONES]}>
                                <Link to={{ pathname: "/configuracion/integraciones", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                    <WidgetsIcon fontSize="large" className="text-yellow mr-2" />
                                    <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Integraciones</span>
                                </Link>
                            </GrantContent>
                            <GrantContent permisos={[Permisos.FACTURACION]}>
                                <Link to={{ pathname: "/configuracion/mi-plan", state: { backUrl: "/configuracion" } }} className="no-underline flex items-center ">
                                    <CreditCardRoundedIcon fontSize="large" className="text-yellow mr-2" />
                                    <span className="hover:text-yellow text-lg transition duration-700 ease-in-out">Facturación (Mi plan)</span>
                                </Link>
                            </GrantContent>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default Configuracion