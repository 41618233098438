import { DateTime } from "luxon";
import {
    PROSPECTO_KANBAN_SET_FILTERS,
    PROSPECTO_KANBAN_SET_LOADING,
    PROSPECTO_KANBAN_ADD_COLUMN,
    PROSPECTO_KANBAN_SET_KANBAN,
    PROSPECTO_KANBAN_UPDATE_COLUMN,
    PROSPECTO_KANBAN_DELETE_COLUMN,
    PROSPECTO_KANBAN_UPDATE_CARD,
    PROSPECTO_KANBAN_DELETE_CARD,
    PROSPECTO_KANBAN_ADD_CARD,
    PROSPECTO_KANBAN_SAVE_CARD
} from "../redux-const";

const endDate = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
const startDate = endDate.plus({ day: -60 })

const initialState = {
    loading: false,
    filters: {
        showClosed: false,
        endDate: endDate,
        startDate: startDate
    },
    kanban: {
        columns: []
    }
}

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PROSPECTO_KANBAN_SET_LOADING: {
            state.loading = payload.loading
            return {
                ...state
            }
        }
        case PROSPECTO_KANBAN_ADD_COLUMN: {
            state.kanban.columns.push(payload)
            return { ...state }
        }
        case PROSPECTO_KANBAN_SET_KANBAN: {
            return {
                ...state,
                kanban: payload.kanban
            }
        }
        case PROSPECTO_KANBAN_UPDATE_COLUMN: {
            return {
                ...state,
                kanban: {
                    ...state.kanban,
                    columns: payload.columns
                }
            }
        }
        case PROSPECTO_KANBAN_UPDATE_CARD: {
            return {
                ...state,
                kanban: {
                    ...state.kanban,
                    columns: payload.columns
                }
            }
        }
        case PROSPECTO_KANBAN_DELETE_COLUMN: {
            return {
                ...state,
                kanban: {
                    ...state.kanban,
                    columns: payload.columns
                }
            }
        }
        case PROSPECTO_KANBAN_DELETE_CARD: {
            const { columnIndex, cardIndex } = payload
            state.kanban.columns[columnIndex].cards.splice(cardIndex, 1)
            return {
                ...state,
                kanban: {
                    ...state.kanban,
                    columns: state.kanban.columns
                }
            }
        }
        case PROSPECTO_KANBAN_ADD_CARD: {
            const { columnId, card } = payload
            const columnIdx = state.kanban.columns.findIndex(x => x.id === columnId)
            state.kanban.columns[columnIdx].cards.unshift(card)
            return { ...state }
        }
        case PROSPECTO_KANBAN_SAVE_CARD: {
            const columnIdx = findColumnIndexById(state, payload.columnId)
            const cardIdx = findCardIndexById(state, columnIdx, payload.id)
            state.kanban.columns[columnIdx].cards[cardIdx] = payload
            return { ...state }
        }
        case PROSPECTO_KANBAN_SET_FILTERS: {
            return {
                ...state,
                filters: payload
            }
        }
    }
    return state;
}

const findColumnIndexById = (state, columnId) => {
    return state.kanban.columns.findIndex(x => x.id === columnId)
}

const findCardIndexById = (state, columnIdx, cardId) => {
    console.log("findCardIndexById", columnIdx, cardId)
    return state.kanban.columns[columnIdx].cards.findIndex(x => x.id === cardId)
}

export default reducer;
