import React, { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import AdminTemplate from '../../template/AdminTemplate'
import { addCaracteristica, addCoverage, addInsured, addPlanPago, closeNewCliente, closeNewClienteDialog, closeNewProspectoDialog, downAsegurados, downCaracteristica, downCobertura, downPlanPago, editCaracteristica, editCoverage, editInsured, editPlanPago, fetchClientes, getState, initPage, onChange, onChangeAsegurado, onChangeAseguradoNacimiento, onChangeAseguradora, onChangeCaracteristica, onChangeCobertura, onChangeCoberturaDescripcion, onChangeFechaVencimiento, onChangePlanPagoPrimas, onChangeRamo, onChangeVehiculo, onChanteTemplate, onClienteChange, onCompletedCliente, onCompletedProspecto, openNewCliente, openNewClienteDialog, openNewProspectoDialog, removeCaracteristica, removeCoverage, removeInsured, removePlanPago, reset, save, saveAsegurado, saveCaracteristica, saveCoverage, savePlanPago, toggleClienteList, toggleNewCliente, toggleNewProspecto, upAsegurados, upCaracteristica, upCobertura, upPlanPago } from './nueva-cotizacion-template-actions'
import Toolbar from '../../components/Toolbar'
import { Button, FormControl, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { onlyLetter, onlyNumbers } from '../../utils/FormUtils'
import { getCotizacionStatus, getFormaPago, getGeneros, getParentescoById, getParentescos, getRamos } from '../../utils/EnumUtils'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { IconButton } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { format } from '../../utils/DateUtils'
import CreateIcon from '@mui/icons-material/Create';
import { hasText, isEmpty } from '../../utils/StringUtils'
import RequestPageIcon from '@mui/icons-material/RequestPage';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import CategoryIcon from '@mui/icons-material/Category';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NumberFormat from 'react-number-format';
import Alert from '../../components/Alert'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import debounce from "lodash.debounce";
import { numberFormat, numberFormatNoDecimal } from '../../utils/NumberUtils'
import DalalaDialog from '../../components/DalalaDialog'
import NuevoCliente from '../NuevoCliente'
import NuevoProspecto from '../prospectos/NuevoProspecto'


const filter = createFilterOptions();


const NuevaCotizacionTemplate = ({ modal = false }) => {

    const history = useHistory()
    const cotizacionId = useParams().cotizacionId
    const state = useSelector(state => state.pages.nuevaCotizacionTemplate)


    useEffect(() => {
        initPage(cotizacionId)
        return () => {
            reset()
        }
    }, [])

    const fetchContratanteDebounce = useCallback(debounce(fetchClientes, 500), []);


    const render = () => {

        const formasPago = getFormaPago()

        return (
            <AdminTemplate loading={state.loading} modal={modal}>
                <Toolbar title="Nuevo Template de Cotización" backUrl={`/cotizaciones/templates`} modal={modal}>
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        <form onSubmit={e => save(e, history)}>
                            <div className="grid gap-8">
                                <div className="p-2 rounded-md bg-dark-500">
                                    <div className="grid grid-cols-1 gap-4 p-2">
                                        <p className="flex"><RequestPageIcon className="mr-2" /> Datos generales</p>
                                        
                                        <TextField
                                            label="Nombre del Template"
                                            type="text"
                                            variant="outlined"
                                            required
                                            name="nombre"
                                            onInput={event => onlyLetter(event, 100)}
                                            value={state.nombre}
                                            onChange={onChange}
                                        />
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                                            <FormControl >
                                                <InputLabel id="demo-multiple-checkbox-label" required>Ramo</InputLabel>
                                                <Select
                                                    disabled={!isNaN(state.id)}
                                                    required
                                                    labelId="demo-multiple-checkbox-label"
                                                    name="ramo"
                                                    value={state.ramo}
                                                    onChange={onChangeRamo}
                                                >
                                                    {getRamos().map(ramo => (
                                                        <MenuItem key={ramo.id} value={ramo.id}>{ramo.icon} {ramo.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl >
                                                <InputLabel id="moneda" required>Moneda</InputLabel>
                                                <Select
                                                    required
                                                    labelId="moneda"
                                                    name="moneda"
                                                    value={state.moneda}
                                                    onChange={onChange}
                                                >
                                                    {state.monedas.map((moneda, index) => (
                                                        <MenuItem key={moneda.id} value={moneda.abreviacion}>{moneda.abreviacion} - {moneda.moneda}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <TextField
                                            label="Plan o Paquete"
                                            type="text"
                                            variant="outlined"
                                            required
                                            name="plan"
                                            onInput={event => onlyLetter(event, 20)}
                                            value={state.plan}
                                            onChange={onChange}
                                        />
                                        <Autocomplete
                                            disablePortal
                                            name="aseguradoraId"
                                            value={state.aseguradora}
                                            options={state.aseguradoras}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onChange={onChangeAseguradora}
                                            getOptionLabel={aseguradora => `${aseguradora.id} ${aseguradora.aseguradora} (${aseguradora.abreviacion})`}
                                            renderInput={(params) => <TextField {...params} required label="Aseguradora" />}
                                        />


                                    </div>
                                </div>

                                {state.ramo && (
                                    <>
                                        <div className="p-2 rounded-md bg-dark-500">
                                            <div className="grid grid-cols-1 gap-4 p-2">
                                                <p className="flex"><PriceChangeIcon className="mr-2" /> Características del Plan</p>
                                                <div className="grid grid-cols-1 gap-4">
                                                    <TextField
                                                        label="Suma Asegurada"
                                                        type="text"
                                                        variant="outlined"
                                                        required
                                                        name="sumaAsegurada"
                                                        onFocus={e => e.target.select()}
                                                        onInput={event => onlyLetter(event, 50)}
                                                        value={state.sumaAsegurada}
                                                        onChange={onChange}
                                                    />
                                                    {state.ramo !== "Vida" && (
                                                        <TextField
                                                            label="Deducible"
                                                            type="text"
                                                            variant="outlined"
                                                            name="deducible"
                                                            onFocus={e => e.target.select()}
                                                            onInput={event => onlyLetter(event, 50)}
                                                            value={state.deducible}
                                                            onChange={onChange}
                                                        />
                                                    )}

                                                    {state.ramo === "Salud" && (
                                                        <>
                                                            <TextField
                                                                label="Coaseguro"
                                                                type="text"
                                                                variant="outlined"
                                                                name="coaseguro"
                                                                onFocus={e => e.target.select()}
                                                                onInput={event => onlyLetter(event, 50)}
                                                                value={state.coaseguro}
                                                                onChange={onChange}
                                                            />
                                                            <TextField
                                                                label="Tope Coaseguro"
                                                                type="text"
                                                                variant="outlined"
                                                                name="topeCoaseguro"
                                                                onFocus={e => e.target.select()}
                                                                onInput={event => onlyLetter(event, 50)}
                                                                value={state.topeCoaseguro}
                                                                onChange={onChange}
                                                            />
                                                            <TextField
                                                                label="Copago"
                                                                type="text"
                                                                variant="outlined"
                                                                name="copago"
                                                                onFocus={e => e.target.select()}
                                                                onInput={event => onlyLetter(event, 50)}
                                                                value={state.copago}
                                                                onChange={onChange}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid bg-dark-500 rounded-md p-4 gap-4">
                                            <div className="flex justify-between">
                                                <p className="flex"><CategoryIcon className="mr-2" /> Características Adicionales ({state.caracteristicas.length})</p>
                                                <Tooltip className="hidden md:inline-block" title="Permite agregar características libres como Nombre del Paquete, nivel hospitalario, Tabulador, etc.">
                                                    <HelpOutlineIcon className="text-white" />
                                                </Tooltip>
                                            </div>
                                            <p className="block md:hidden text-xs text-gray-800">Permite agregar características libres como Nombre del Paquete, Nivel Hospitalario, Tabulador, etc.</p>

                                            <List dense={false} className="grid gap-2">
                                                {state.caracteristicas.map((caracteristica, index) => (
                                                    <CaracteristicaItem key={index} caracteristica={caracteristica} index={index} />
                                                ))}
                                            </List>
                                            <Button className="btn bg-dark-300" onClick={addCaracteristica}>AGREGAR CARACTERÍSTICA</Button>
                                        </div>
                                    </>

                                )}

                                {state.ramo && (
                                    <div className="grid bg-dark-500 rounded-md p-4 gap-4">
                                        <p className="flex"><HealthAndSafetyIcon className="mr-2" /> Coberturas ({state.coberturas.length})</p>
                                        <List dense={false} className="grid gap-2">
                                            {state.coberturas.map((cobertura, index) => (
                                                <CoberturaItem key={index} cobertura={cobertura} index={index} />
                                            ))}
                                        </List>
                                        <Button className="btn bg-dark-300" onClick={addCoverage}>AGREGAR COBERTURA</Button>
                                    </div>
                                )}
                                <Button type="submit" disabled={!state.ramo} className="btn btn-yellow">GUARDAR</Button>
                                {state.message && <Alert type={state.message.type} message={state.message.message} />}
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}


const AseguradoItem = ({ asegurado, index }) => {

    const state = getState()

    const editMode = () => {
        return (
            <div key={index} className="bg-dark-400 p-4 border-dark-600 border-2 rounded-md">
                <div className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-1 gap-4">
                        <TextField
                            label="Nombre del asegurado"
                            type="text"
                            variant="outlined"
                            required
                            name="nombre"
                            onInput={event => onlyLetter(event, 200)}
                            value={asegurado.nombre}
                            onChange={e => onChangeAsegurado(e, index)}
                        />
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <FormControl >
                                <InputLabel id="parentesco" required>Parentesco</InputLabel>
                                <Select
                                    required
                                    disabled={index === 0}
                                    labelId="parentesco"
                                    name="parentesco"
                                    value={asegurado.parentesco}
                                    onChange={e => onChangeAsegurado(e, index)}
                                >
                                    {getParentescos().map((parentesco, index) => (
                                        <MenuItem key={index} value={parentesco.id}>{parentesco.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl >
                                <InputLabel id="genero" required>Genero</InputLabel>
                                <Select
                                    required
                                    labelId="genero"
                                    name="genero"
                                    value={asegurado.genero}
                                    onChange={e => onChangeAsegurado(e, index)}
                                >
                                    {getGeneros().map(genero => (
                                        <MenuItem key={genero.id} value={genero.id}>{genero.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    name="nacimiento"
                                    label="Nacimiento"
                                    inputFormat="dd/MM/yyyy"
                                    value={asegurado.nacimiento}
                                    onChange={e => e instanceof Date && !isNaN(e) && onChangeAseguradoNacimiento(e, index)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <TextField
                                label="Edad"
                                type="text"
                                variant="outlined"
                                name="edad"
                                value={asegurado.edad}
                                onChange={e => onChangeAsegurado(e, index)}
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <Button className="btn btn-red text-white p-2" onClick={e => removeInsured(index)}>ELIMINAR</Button>
                            <Button disabled={isEmpty(asegurado.nombre) || isEmpty(asegurado.parentesco) || isEmpty(asegurado.genero)} onClick={e => saveAsegurado(index)} className="btn btn-yellow p-2">GUARDAR</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const readMode = () => {
        return (
            <ListItem
                className=" border-dark-500 border-2 bg-dark-300 rounded-md p-2"
                secondaryAction={
                    <div className="flex flex-col-reverse md:flex-row text-right gap-2">
                        <IconButton className="p-0 btn btn-yellow bg-none" onClick={e => downAsegurados(index)} disabled={index === 0 || state.asegurados.length === (index + 1)} >
                            <ExpandMoreIcon className=" text-dark-600" />
                        </IconButton>
                        <IconButton className=" btn p-0 btn-yellow" onClick={e => upAsegurados(index)} disabled={index === 0 || index === 1} >
                            <ExpandLessIcon className="text-dark-600" />
                        </IconButton>
                    </div>
                }
            >
                <ListItemAvatar>
                    <IconButton onClick={e => editInsured(index)} className="ml-1 btn btn-yellow p-1">
                        <CreateIcon className="text-dark-600" />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText className="text-white mr-11 md:mr-16"
                    primary={<p className="text-sm font-bold">{`${asegurado.nombre} (${asegurado.parentesco})`}</p>}
                    secondary={<p className="text-sm text-gray-800">{`F. Nacimiento: ${typeof asegurado.nacimiento === Date ? format(asegurado.nacimiento, "dd/MM/yyyy") : "Sin definir"}, Edad: ${asegurado.edad}`}</p>}
                />
            </ListItem>
        )
    }

    const render = () => {
        return asegurado.edit ? editMode() : readMode()
    }

    return render()
}


const CoberturaItem = ({ cobertura, index }) => {

    const state = getState()

    const editMode = () => {
        return (
            <div key={cobertura.uuid} className="bg-dark-400 p-2 md:p-4 border-dark-600 border-2 rounded-md">
                <div key={index} className="grid grid-cols-1 gap-4">
                    <Autocomplete
                        freeSolo
                        name="descripcion"
                        clearOnBlur
                        multiple={false}
                        value={cobertura.descripcion}
                        options={state.coberturasOptions.map(x => x.descripcion)}
                        getOptionLabel={e => e || ""}
                        onChange={(e, value) => onChangeCoberturaDescripcion(e, value, index)}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                                filtered.unshift(inputValue)
                            }

                            return filtered;
                        }}
                        renderInput={(params) => <TextField required {...params} label="Cobertura" />}
                    />
                    <TextField
                        label="Valor"
                        type="text"
                        variant="outlined"
                        name="valor"
                        onInput={event => onlyLetter(event, 50)}
                        value={cobertura.valor}
                        onChange={e => onChangeCobertura(e, index)}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Button className="btn btn-red p-2 text-white" onClick={e => removeCoverage(index)}>ELIMINAR</Button>
                        <Button disabled={isEmpty(cobertura.descripcion) || isEmpty(cobertura.valor)} onClick={e => saveCoverage(index)} className="btn btn-yellow p-2">GUARDAR</Button>
                    </div>
                </div>
            </div>
        )
    }

    const readMode = () => {
        return (
            <ListItem
                className=" border-dark-500 border-2 bg-dark-300 rounded-md p-2"
                secondaryAction={
                    <div className="flex flex-col-reverse md:flex-row text-right gap-2">
                        <IconButton className="p-0 btn btn-yellow bg-none" onClick={e => downCobertura(index)} disabled={state.coberturas.length === (index + 1)} >
                            <ExpandMoreIcon className=" text-dark-600" />
                        </IconButton>
                        <IconButton className=" btn p-0 btn-yellow" onClick={e => upCobertura(index)} disabled={index === 0} >
                            <ExpandLessIcon className="text-dark-600" />
                        </IconButton>
                    </div>
                }
            >
                <ListItemAvatar>
                    <IconButton onClick={e => editCoverage(index)} className="ml-1 btn btn-yellow p-1">
                        <CreateIcon className="text-dark-600" />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText className="text-white mr-11 md:mr-16"
                    primary={<p className="text-sm font-bold">{cobertura.descripcion}</p>}
                    secondary={<p className="text-xs text-gray-800">{cobertura.valor}</p>}
                />
            </ListItem>
        )
    }

    const render = () => {
        return cobertura.edit ? editMode() : readMode()
    }

    return render()
}


const CaracteristicaItem = ({ caracteristica, index }) => {

    const state = getState()

    const editMode = () => {
        return (
            <div className="bg-dark-400 p-2 md:p-4 border-dark-600 border-2 rounded-md">
                <div key={index} className="grid grid-cols-1 gap-4">
                    <TextField
                        required
                        label="Descripción"
                        type="text"
                        variant="outlined"
                        name="descripcion"
                        onInput={event => onlyLetter(event, 150)}
                        value={caracteristica.descripcion}
                        onChange={e => onChangeCaracteristica(e, index)}
                    />
                    <TextField
                        required
                        label="Valor"
                        type="text"
                        variant="outlined"
                        name="valor"
                        onInput={event => onlyLetter(event, 50)}
                        value={caracteristica.valor}
                        onChange={e => onChangeCaracteristica(e, index)}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Button className="btn btn-red p-2 text-white" onClick={e => removeCaracteristica(index)}>ELIMINAR</Button>
                        <Button disabled={isEmpty(caracteristica.descripcion) || isEmpty(caracteristica.valor)} onClick={e => saveCaracteristica(index)} className="btn btn-yellow p-2">GUARDAR</Button>
                    </div>
                </div>
            </div>
        )
    }

    const readMode = () => {
        return (
            <ListItem
                className=" border-dark-500 border-2 bg-dark-300 rounded-md p-2"
                secondaryAction={
                    <div className="flex flex-col-reverse md:flex-row text-right gap-2">
                        <IconButton className="p-0 btn btn-yellow bg-none" onClick={e => downCaracteristica(index)} disabled={state.caracteristicas.length === (index + 1)} >
                            <ExpandMoreIcon className=" text-dark-600" />
                        </IconButton>
                        <IconButton className=" btn p-0 btn-yellow" onClick={e => upCaracteristica(index)} disabled={index === 0} >
                            <ExpandLessIcon className="text-dark-600" />
                        </IconButton>
                    </div>
                }
            >
                <ListItemAvatar>
                    <IconButton onClick={e => editCaracteristica(index)} className="ml-1 btn btn-yellow p-1">
                        <CreateIcon className="text-dark-600" />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText className="text-white mr-11 md:mr-16"
                    primary={<p className="text-sm font-bold">{caracteristica.descripcion}</p>}
                    secondary={<p className="text-xs text-gray-800">{caracteristica.valor}</p>}
                />
            </ListItem>
        )
    }

    const render = () => {
        return caracteristica.edit ? editMode() : readMode()
    }

    return render()
}


const PlanPagoItem = ({ planPago, index }) => {

    const state = getState()
    const formasPago = getFormaPago()

    const editMode = () => {
        return (
            <div className="bg-dark-400 p-2 md:p-4 border-dark-600 border-2 rounded-md">
                <div key={index} className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <FormControl >
                            <InputLabel id="formaPago" required>Forma de Pago</InputLabel>
                            <Select
                                required
                                labelId="formaPago"
                                name="formaPago"
                                value={planPago.formaPago}
                                onChange={e => onChangePlanPagoPrimas(e, index)}
                            >
                                {formasPago.map((formaPago, index) => (
                                    <MenuItem key={index} value={formaPago.id}>{formaPago.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {planPago.formaPago && (
                        <>
                            <div className={planPago.formaPago === "contado" ? "hidden" : "grid grid-cols-1 md:grid-cols-2 gap-4"}>
                                <NumberFormat
                                    name="primaPrimerRecibo"
                                    value={planPago.primaPrimerRecibo}
                                    onChange={e => onChangePlanPagoPrimas(e, index)}
                                    customInput={TextField}
                                    onFocus={e => e.target.select()}
                                    label="Prima Primer Recibo"
                                    variant="outlined"
                                    size="medium"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <NumberFormat
                                    name="primaReciboSub"
                                    value={planPago.primaReciboSub}
                                    onChange={e => onChangePlanPagoPrimas(e, index)}
                                    customInput={TextField}
                                    onFocus={e => e.target.select()}
                                    label="Prima Recibos Subsecuentes"
                                    variant="outlined"
                                    size="medium"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </div>
                            <NumberFormat
                                disabled={planPago.formaPago !== "contado"}
                                name="total"
                                value={planPago.total}
                                onChange={e => onChangePlanPagoPrimas(e, index)}
                                customInput={TextField}
                                onFocus={e => e.target.select()}
                                label="Prima Total"
                                variant="outlined"
                                size="medium"
                                inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                type="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={","}
                                allowNegative={false}
                                isNumericString={true}
                                required
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        </>
                    )}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Button className="btn btn-red p-2 text-white" onClick={e => removePlanPago(index)}>ELIMINAR</Button>
                        <Button onClick={e => savePlanPago(index)} className="btn btn-yellow p-2">GUARDAR</Button>
                    </div>
                </div>
            </div>
        )
    }

    const readMode = () => {
        return (
            <ListItem
                className=" border-dark-500 border-2 bg-dark-300 rounded-md p-2"
                secondaryAction={
                    <div className="flex flex-col-reverse md:flex-row text-right gap-2">
                        <IconButton className="p-0 btn btn-yellow bg-none" onClick={e => downPlanPago(index)} disabled={state.caracteristicas.length === (index + 1)} >
                            <ExpandMoreIcon className=" text-dark-600" />
                        </IconButton>
                        <IconButton className=" btn p-0 btn-yellow" onClick={e => upPlanPago(index)} disabled={index === 0} >
                            <ExpandLessIcon className="text-dark-600" />
                        </IconButton>
                    </div>
                }
            >
                <ListItemAvatar>
                    <IconButton onClick={e => editPlanPago(index)} className="ml-1 btn btn-yellow p-1">
                        <CreateIcon className="text-dark-600" />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText className="text-white mr-11 md:mr-16"
                    primary={<p className="text-sm font-bold">{planPago.formaPago.toUpperCase()} - <span>{numberFormatNoDecimal(planPago.total)} {state.moneda}</span></p>}
                    secondary={<p className="text-xs text-gray-800">{planPago.formaPago === "contado" ? `Pago total de contado` : `${numberFormatNoDecimal(planPago.primaPrimerRecibo)}/${numberFormatNoDecimal(planPago.primaReciboSub)}`}</p>}
                />
            </ListItem>
        )
    }

    const render = () => {
        return planPago.edit ? editMode() : readMode()
    }

    return render()
}



export default NuevaCotizacionTemplate