import React, { useEffect, useState } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import Toolbar from '../components/Toolbar'
import APIInvoke from '../utils/APIInvoke'
import { parse, format } from '../utils/DateUtils'
import { Link } from 'react-router-dom'

const Automatizaciones = (props) => {

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState([])

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const response = await (await APIInvoke.invokeGET(`/rpa/logs`)).json()
                setState(response.body)
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        }
        init()
    }, [])

    const render = () => {
        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Automatización" backUrl="/configuracion" helpLink="https://appdalala.blogspot.com/2022/05/importacion-automatica.html" />
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-4">
                            <p className="text-lg mb-4">LISTADO DE PROCESOS</p>

                            {state.map(log => (
                                <Link className="no-underline" to={`/automatizaciones/${log.id}`} key={log.id}>
                                    <article>
                                        <div className="bg-dark-400 p-4 rounded-md">
                                            <div className="grid grid-cols-1 gap-2">
                                                <p className="text-sm">{log.aseguradora.aseguradora}</p>
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                                    <p className="text-sm">Fecha: {format(parse(log.fechaTermino, 'yyyy-MM-dd HH:mm:ss'), "dd MMM yyyy HH:mm a")}</p>
                                                    <p className="text-sm">Total Pólizas Importadas: {log.totalRegistros}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </Link>

                            ))}
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default Automatizaciones