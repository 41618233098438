import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useState } from 'react';
import APIInvoke from '../../../utils/APIInvoke';
import { format, parse } from '../../../utils/DateUtils';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { numberFormat } from '../../../utils/NumberUtils';
import { getFormaPagoById, getRamoById } from '../../../utils/EnumUtils';
import TagIcon from '@mui/icons-material/Tag';
import EventAvailable from '@mui/icons-material/EventAvailable';
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { hasText } from '../../../utils/StringUtils';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import VideocamIcon from '@mui/icons-material/Videocam';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const VisorCotizaciones = (props) => {

    const uuid = useParams().uuid
    const query = useQuery();

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(null)

    useEffect(() => {
        const userUUID = query.get("uuid")
        fetchCotizacion(uuid, userUUID)
    }, [])

    const fetchCotizacion = async (uuid, userUuid) => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/cotizaciones/uuid/${uuid}?uuid=${userUuid}`)).json()
            setState({
                ...response.body,
                fecVencimiento: parse(response.body.fecVencimiento, 'yyyy-MM-dd')
            })
        } catch (error) {
            console.error("Error al cargar la cotización", error)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        if (state === null) return null

        const ramo = getRamoById(state.ramo)
        const cliente = state.cliente

        if (loading) return <div />

        return (
            <div className="bg-white ">
                <div className="bg-dark-600 p-2">
                    {/*
                        <img src="/brand/dalala-logo-lg.svg" alt="dalala.app" className="h-8" />
                    */}
                    <div className="layout">
                        <p className="p-1 m-0 text-center">Cotización a la medida de: <span className="text-yellow">{`${state.cliente.nombres} ${state.cliente.apPat} ${state.cliente.apMat}`}</span></p>
                    </div>
                </div>

                {state.empresa && (
                    <div className="bg-gray-200">
                        <div className="layout ">
                            <div className="pt-8 grid gap-4">
                                <div className="text-center">
                                    <img src={state.empresa.logoUrl} className="inline-block w-80" />
                                </div>
                                <p className="text-dark-600 text-2xl text-center font-bold">{state.empresa.razonSocial}</p>
                                <p className="text-center text-dark-600 text-xl">{state.empresa.direccion}</p>
                                <div className="text-center">
                                    <a href={`tel:+${state.empresa.tel1}`} className="text-dark-600 no-underline"><CallIcon /> {state.empresa.tel1}</a>
                                    {hasText(state.empresa.tel2) && <a href={`tel:+${state.empresa.tel2}`} className="text-dark-600 no-underline">/ {state.empresa.tel2}</a>}
                                </div>
                                <div className="text-center">
                                    <a href={`mailto:${state.empresa.correo1}`} className="text-dark-600 no-underline"><MailIcon /> {state.empresa.correo1}</a>
                                    {hasText(state.empresa.correo2) && <a href={`mailto:${state.empresa.correo2}`} className="text-dark-600 no-underline">/{state.empresa.correo2}</a>}
                                </div>

                                <div className="text-center flex gap-4 justify-center">
                                    {hasText(state.empresa.facebook) && <a href={state.empresa.facebook} target="_blank" className="text-dark-600 no-underline"><FacebookIcon /> Facebook</a>}
                                    {hasText(state.empresa.youtube) && <a href={state.empresa.youtube} target="_blank" className="text-dark-600 no-underline"><YouTubeIcon /> Youtube</a>}
                                    {hasText(state.empresa.tiktok) && <a href={state.empresa.tiktok} target="_blank" className="text-dark-600 no-underline"><VideocamIcon /> Tiktok</a>}
                                    {hasText(state.empresa.twitter) && <a href={state.empresa.twitter} target="_blank" className="text-dark-600 no-underline"><TwitterIcon /> Twitter</a>}
                                    {hasText(state.empresa.web) && <a href={state.empresa.web} target="_blank" className="text-dark-600 no-underline"><LanguageIcon /> Website</a>}
                                </div>
                            </div>
                        </div>
                        <Divider color="#999" className="mt-8" ></Divider>
                    </div>
                )}


                <div className="layout my-0 py-8 md:py-16 ">
                    <div className="grid gap-8">
                        <p className="text-lg md:text-xl lg:text-4xl text-dark-300 text-center font-bold">Datos de la cotización</p>

                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 lg:gap-8">
                            <div className="rounded-md bg-dark-600 text-center p-4">
                                <div className="rounded-full p-2 bg-yellow inline-block mb-2">
                                    <TagIcon className="text-dark-500 text-4xl" />
                                </div>
                                <p className="text-base mb-1">Número de Cotización</p>
                                <p className="text-sm font-thin">{state.id}</p>
                            </div>
                            <div className="rounded-md bg-dark-600 text-center p-4">
                                <div className="rounded-full p-2 bg-yellow inline-block mb-2">
                                    <span className="text-4xl text-dark-600 p-0 m-0">
                                        {getRamoById(state.ramo).icon}
                                    </span>
                                </div>
                                <p className="text-base mb-1">Tipo de seguro</p>
                                <p className="text-sm font-thin capitalize">{state.ramo}</p>
                            </div>
                            <div className="rounded-md bg-dark-600 text-center p-4">
                                <div className="rounded-full p-2 bg-yellow inline-block mb-2">
                                    <EventAvailable className="text-dark-500 text-4xl" />
                                </div>
                                <p className="text-base mb-1">Fecha de Vencimiento</p>
                                <p className="text-sm font-thin capitalize">{format(state.fecVencimiento, "dd/MM/yyyy")}</p>
                            </div>
                            <div className="rounded-md bg-dark-600 text-center p-4">
                                <div className="rounded-full p-2 bg-yellow inline-block mb-2">
                                    <AccountCircleIcon className="text-dark-500 text-4xl" />
                                </div>
                                <p className="text-base mb-1">Nombre del cliente</p>
                                <p className="text-sm font-thin capitalize">{`${cliente.nombres} ${cliente.apPat} ${cliente.apMat}`}</p>
                            </div>
                        </div>


                        {state.ramo === "Diversos" && (
                            <div className="overflow-x-auto">
                                <p className="text-lg md:text-xl lg:text-4xl text-dark-300 text-center font-bold my-8">Asegurados</p>
                                <TableContainer component={Paper} className="border border-dark-600">
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow className="bg-dark-500">
                                                <TableCell className="text-white font-bold">Descripción</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="font-bold">{state.texto}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}

                        {(state.ramo === "Vida" || state.ramo === "Salud") && (
                            <div className="overflow-x-auto">
                                <p className="text-lg md:text-xl lg:text-4xl text-dark-300 text-center font-bold my-8">Asegurados</p>
                                <TableContainer component={Paper} className="border border-dark-600">
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow className="bg-dark-500">
                                                <TableCell className="text-white font-bold">#</TableCell>
                                                <TableCell className="text-white font-bold">Nombre</TableCell>
                                                <TableCell className="text-white font-bold">Parentesco</TableCell>
                                                <TableCell className="text-white font-bold">Género</TableCell>
                                                <TableCell className="text-white font-bold">Edad</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.asegurados.map((asegurado, index) => (
                                                <TableRow key={index} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                                                    <TableCell className="font-bold">{index + 1}</TableCell>
                                                    <TableCell className="font-bold">{asegurado.nombre}</TableCell>
                                                    <TableCell className="font-bold">{asegurado.parentesco}</TableCell>
                                                    <TableCell className="font-bold">{asegurado.genero}</TableCell>
                                                    <TableCell className="font-bold">{asegurado.edad}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}

                        {state.ramo === "Auto" && (
                            <div className="overflow-x-auto">
                                <p className="text-lg md:text-xl lg:text-4xl text-dark-300 text-center font-bold my-8">Vehículo asegurado</p>
                                <TableContainer component={Paper} className="border border-dark-600">
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow className="bg-dark-500">
                                                <TableCell className="text-white font-bold">Descripción</TableCell>
                                                <TableCell className="text-white font-bold">Modelo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.vehiculos.map((vehiculo, index) => (
                                                <TableRow key={index} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                                                    <TableCell className="font-bold">{vehiculo.descripcion}</TableCell>
                                                    <TableCell className="font-bold">{vehiculo.modelo}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}

                        <p className="text-lg md:text-xl lg:text-4xl text-dark-300 text-center font-bold my-8">Cotizaciones</p>
                        <div className="grid gap-8">
                            {state.items.map((item, index) => (
                                <Cotizacion key={item.id} cotizacion={state} item={item} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className=" bg-dark-500 py-8">
                    <div className="layout">
                        <div className="mb-16">
                            <img src="/brand/dalala-logo-white.png" alt="dalala.app" />
                        </div>
                        <div>
                            <p className="text-sm text-center">© 2023. TODOS LOS DERECHOS RESERVADOS</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return render()
}

const Cotizacion = ({ cotizacion, item }) => {

    const render = () => {
        return (
            <>
                <div className="grid gap-4">
                    <div className="border border-dark-500">
                        <div className="bg-dark-500 p-2">
                            <div className="flex justify-between items-center">
                                <p>{item.aseguradora.abreviacion}</p>
                            </div>
                        </div>
                        <div className="bg-dark-300 p-2" >
                            <p className="text-sm">CARACTERÍSTICAS</p>
                        </div>

                        <div className="flex p-4 gap-4">
                            <img src={item.aseguradora.logoM} className="inline-block w-24 h-24" />
                            <div className="flex-1">
                                <div className="grid md:grid-cols-2">
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p className="text-dark-600 font-thin mr-4">Suma asegurada:</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-dark-600 font-bold text-left">{item.sumaAsegurada}</p>
                                                    </td>
                                                </tr>

                                                {cotizacion.ramo !== "Vida" && (
                                                    <tr>
                                                        <td>
                                                            <p className="text-dark-600 font-thin mr-4">Deducible:</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-dark-600 font-bold text-left">{item.deducible}</p>
                                                        </td>
                                                    </tr>
                                                )}
                                                {cotizacion.ramo === "Salud" && (
                                                    <>

                                                        <tr>
                                                            <td>
                                                                <p className="text-dark-600 font-thin mr-4">Coaseguro:</p>
                                                            </td>
                                                            <td>
                                                                <p className="text-dark-600 font-bold text-left">{item.coaseguro}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p className="text-dark-600 font-thin mr-4">Tope Coaseguro:</p>
                                                            </td>
                                                            <td>
                                                                <p className="text-dark-600 font-bold text-left">{item.topeCoaseguro}</p>
                                                            </td>
                                                        </tr>
                                                        {item.copago !== "" && (
                                                            <tr>
                                                                <td>
                                                                    <p className="text-dark-600 font-thin mr-4">Copago:</p>
                                                                </td>
                                                                <td>
                                                                    <p className="text-dark-600 font-bold text-left">{item.copago}</p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <table>
                                            <tbody>
                                                {item.caracteristicas.map(caracteristica => (
                                                    <tr key={caracteristica.id}>
                                                        <td>
                                                            <p className="text-dark-600 font-thin mr-4">{caracteristica.descripcion}:</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-dark-600 font-bold text-left">{caracteristica.valor}</p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid gap-4 m-2 border border-dark-500">
                            <div className="bg-dark-500 p-4">
                                <p className="text-center">COBERTURAS</p>
                            </div>
                            <div className="grid grid-cols-1 p-2">
                                {item.coberturas.map(cobertura => (
                                    <div>
                                        <span className="text-dark-500 font-thin">{cobertura.descripcion}</span>: <span className="font-bold text-dark-500">{cobertura.valor}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="grid gap-4 m-2 border border-dark-500">
                            <div className="bg-dark-500 p-4">
                                <p className="text-center">FORMAS DE PAGO</p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2">
                                {item.planesPago.map(plan => (
                                    <div key={plan.id} className="rounded-md bg-gray-100 p-4">
                                        <div className="grid grid-cols-2 gap-2 ">
                                            <p className="text-dark-500 font-bold text-xl">{plan.formaPago}</p>
                                            <p className="text-dark-500 font-bold text-right text-xl">{numberFormat(plan.total)}</p>
                                            {plan.formaPago !== "contado" &&
                                                <>
                                                    <p className="text-dark-500">Recibo 1:</p>
                                                    <p className="text-dark-500 text-right">{numberFormat(plan.primaPrimerRecibo)} {item.moneda}</p>
                                                    <p className="text-dark-500">Recibo Sub ({getFormaPagoById(plan.formaPago).parcialidades - 1}):</p>
                                                    <p className="text-dark-500 text-right">{numberFormat(plan.primaReciboSub)} {item.moneda}</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return render()
}

export default VisorCotizaciones