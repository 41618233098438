import React, { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import AdminTemplate from '../../template/AdminTemplate'
import { addCaracteristica, addCotizacion, addCoverage, addInsured, addPlanPago, closeNewCliente, closeNewClienteDialog, closeNewProspectoDialog, downAsegurados, downCaracteristica, downCobertura, downPlanPago, editCaracteristica, editCoverage, editInsured, editPlanPago, fetchClientes, fetchTemplates, getState, initPage, onChange, onChangeAsegurado, onChangeAseguradoNacimiento, onChangeAseguradora, onChangeCaracteristica, onChangeCobertura, onChangeCoberturaDescripcion, onChangeFechaVencimiento, onChangeItem, onChangePlanPagoPrimas, onChangeRamo, onChangeVehiculo, onChanteTemplate, onClienteChange, onCompletedCliente, onCompletedProspecto, openNewCliente, openNewClienteDialog, openNewProspectoDialog, removeCaracteristica, removeCotizacion, removeCoverage, removeInsured, removePlanPago, reset, save, saveAsegurado, saveCaracteristica, saveCoverage, savePlanPago, toggleClienteList, toggleNewCliente, toggleNewProspecto, toggleShowDeleteItemDialog, upAsegurados, upCaracteristica, upCobertura, upPlanPago } from './nueva-cotizacion-actions'
import Toolbar from '../../components/Toolbar'
import { Button, FormControl, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { createDecimalPattern, onlyLetter, onlyNumbers } from '../../utils/FormUtils'
import { getCotizacionStatus, getFormaPago, getGeneros, getParentescoById, getParentescos, getRamos } from '../../utils/EnumUtils'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { format } from '../../utils/DateUtils'
import CreateIcon from '@mui/icons-material/Create';
import { hasText, isEmpty } from '../../utils/StringUtils'
import RequestPageIcon from '@mui/icons-material/RequestPage';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import CategoryIcon from '@mui/icons-material/Category';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NumberFormat from 'react-number-format';
import Alert from '../../components/Alert'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import debounce from "lodash.debounce";
import { numberFormat, numberFormatNoDecimal } from '../../utils/NumberUtils'
import DalalaDialog from '../../components/DalalaDialog'
import NuevoCliente from '../NuevoCliente'
import NuevoProspecto from '../prospectos/NuevoProspecto'
import GrantContent from '../../components/GrantContent'
import * as Permisos from '../../utils/Permisos'

const filter = createFilterOptions();


const NuevaCotizacion = ({ modal = false }) => {

    const history = useHistory()
    const cotizacionId = useParams().cotizacionId
    const editMode = cotizacionId != null
    const state = useSelector(state => state.pages.nuevaCotizacion)


    useEffect(() => {
        initPage(cotizacionId)
        return () => {
            reset()
        }
    }, [])


    useEffect(() => {
        fetchTemplates(state.ramo)
    }, [state.ramo])

    const fetchContratanteDebounce = useCallback(debounce(fetchClientes, 500), []);


    const render = () => {

        const formasPago = getFormaPago()

        return (
            <AdminTemplate loading={state.loading} modal={modal}>
                <Toolbar title="Nueva cotización" backUrl={`/cotizaciones`} modal={modal}>
                </Toolbar>
                <div className="main">

                    <DalalaDialog
                        title={"Nuevo Cliente"}
                        open={state.openNewClienteDialog}
                        handleClose={closeNewClienteDialog}
                    >
                        <NuevoCliente modal={true} onCompleted={onCompletedCliente} />
                    </DalalaDialog>

                    <DalalaDialog
                        title={"Nuevo Prospecto"}
                        open={state.openNewProspectoDialog}
                        handleClose={closeNewProspectoDialog}
                    >
                        <NuevoProspecto modal={true} onCompleted={onCompletedProspecto} />
                    </DalalaDialog>

                    <Dialog
                        open={state.showDeleteItemDialog}
                        onClose={e => toggleShowDeleteItemDialog(false, null)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                        <DialogContent>
                            <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la cotización?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={state.loading} className="mr-4" onClick={e => toggleShowDeleteItemDialog(false, null)}>CANCELAR</Button>
                            <Button disabled={state.loading} className="bg-red-500 text-white" onClick={removeCotizacion} autoFocus>ACEPTAR</Button>
                        </DialogActions>
                    </Dialog>

                    <div className="layout-compact">
                        <form onSubmit={event => save(event, history)} onKeyDown={event => { if (event.key === 'Enter') { event.preventDefault(); } }} >
                            <div className="grid gap-8">
                                <div className="p-2 rounded-md bg-dark-500">
                                    <div className="grid grid-cols-1 gap-4 p-2">
                                        <p className="flex"><RequestPageIcon className="mr-2" /> Datos generales</p>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <TextField
                                                disabled
                                                label="Número de cotización"
                                                type="text"
                                                variant="outlined"
                                                required
                                                name="noCotizacion"
                                                onInput={event => onlyLetter(event, 20)}
                                                value={state.noCotizacion ? state.noCotizacion : "<AUTO-GENERADO>"}
                                                onChange={onChange}
                                            />
                                            <FormControl >
                                                <InputLabel id="demo-multiple-checkbox-label" required>Ramo</InputLabel>
                                                <Select
                                                    disabled={!isNaN(state.id)}
                                                    required
                                                    labelId="demo-multiple-checkbox-label"
                                                    name="ramo"
                                                    value={state.ramo}
                                                    onChange={onChangeRamo}
                                                >
                                                    {getRamos().map(ramo => (
                                                        <MenuItem key={ramo.id} value={ramo.id}>{ramo.icon} {ramo.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="flex flex-row gap-4">
                                            <Autocomplete
                                                id="contratante"
                                                name="contratante"
                                                className="flex-1"
                                                required
                                                clearText="clearText"
                                                closeText="closeText"
                                                noOptionsText="Sin resultados"
                                                loadingText="Cargando..."
                                                openText="openText"
                                                open={state.openClienteList}
                                                value={state.cliente}
                                                onOpen={e => toggleClienteList(true)}
                                                onClose={e => toggleClienteList(false)}
                                                groupBy={(option) => option.type.toUpperCase()}
                                                isOptionEqualToValue={(option, value) => `${option.type}-${option.id}` === `${value.type}-${value.id}`}
                                                getOptionLabel={(option) => `${option.nombre.trim()}`}
                                                options={state.clientesOptions}
                                                onInput={fetchContratanteDebounce}
                                                loading={state.contratantesLoading}
                                                onChange={onClienteChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Cliente"
                                                        required
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {state.contratantesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <GrantContent permisos={[Permisos.CLIENTES_W, Permisos.PROSPECTOS_W]} >
                                                <Button
                                                    className="btn btn-yellow p-0 m-0"
                                                    id="newClienteButtom"
                                                    aria-controls={state.openNewCliente ? 'newClienteButtomPositioned' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={state.openNewCliente ? 'true' : undefined}
                                                    onClick={openNewCliente}
                                                >
                                                    <SupervisedUserCircleIcon className="text-dark-600" fontSize="large" />
                                                </Button>
                                            </GrantContent>
                                            <Menu
                                                id="newClienteButtomPositioned"
                                                aria-labelledby="newClienteButtom"
                                                anchorEl={state.clienteAnchor}
                                                open={state.openNewCliente}
                                                onClose={closeNewCliente}
                                                anchorOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <GrantContent permisos={[Permisos.CLIENTES_W]}>
                                                    <MenuItem onClick={openNewClienteDialog}><AddIcon /> Cliente</MenuItem>
                                                </GrantContent>
                                                <GrantContent permisos={[Permisos.PROSPECTOS_W]}>
                                                    <MenuItem onClick={openNewProspectoDialog}><AddIcon /> Prospecto</MenuItem>
                                                </GrantContent>
                                            </Menu>
                                        </div>
                                        <TextField
                                            label="Correo Electrónico"
                                            type="email"
                                            variant="outlined"
                                            required
                                            name="correo"
                                            onInput={event => onlyLetter(event, 200)}
                                            value={state.correo}
                                            onChange={onChange}
                                            inputProps={{
                                                list: "emailList"
                                            }}
                                        />


                                        <datalist id="emailList">
                                            {state.clienteEntity && state.clienteEntity.correos.map(correo => (
                                                <option key={correo.id} value={correo.correo} />
                                            ))}
                                        </datalist>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    name="fecVencimiento"
                                                    label="Fecha de Vencimiento"
                                                    inputFormat="dd/MM/yyyy"
                                                    value={state.fecVencimiento}
                                                    onChange={onChangeFechaVencimiento}
                                                    minDate={new Date()}
                                                    renderInput={(params) => <TextField type="text" required {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <FormControl >
                                                <InputLabel id="status" required>Estatus</InputLabel>
                                                <Select
                                                    required
                                                    labelId="status"
                                                    name="status"
                                                    value={state.status}
                                                    onChange={onChange}
                                                >
                                                    {getCotizacionStatus().map((status, index) => (
                                                        <MenuItem key={index} value={status.id}>{status.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {state.ramo === "Diversos" && (
                                            <div className="grid bg-dark-600 border border-dark-200 rounded-md p-2 gap-4">
                                                <p className="flex"><HomeIcon className="mr-2" />Bien Asegurado</p>
                                                <TextField
                                                    inputProps={{ className: "text-white" }}
                                                    className="w-full"
                                                    color="primary"
                                                    label="Detalle del Bien Asegurado"
                                                    defaultValue=""
                                                    name="texto"
                                                    multiline
                                                    rows={5}
                                                    required
                                                    onInput={event => onlyLetter(event, 1500)}
                                                    value={state.texto}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        )}
                                        {state.ramo === "Auto" && (
                                            <div className="grid bg-dark-600 border border-dark-200 rounded-md p-2 gap-4">
                                                <p className="flex"><DirectionsCarIcon className="mr-2" /> Descripción del Vehiculo</p>

                                                <TextField
                                                    label="Descripción"
                                                    type="text"
                                                    variant="outlined"
                                                    required
                                                    name="descripcion"
                                                    onFocus={e => e.target.select()}
                                                    onInput={event => onlyLetter(event, 100)}
                                                    value={state.vehiculo.descripcion}
                                                    onChange={onChangeVehiculo}
                                                />
                                                <TextField
                                                    label="Modelo (Año)"
                                                    type="text"
                                                    variant="outlined"
                                                    required
                                                    name="modelo"
                                                    onFocus={e => e.target.select()}
                                                    onInput={event => onlyNumbers(event, 4)}
                                                    value={state.vehiculo.modelo}
                                                    onChange={onChangeVehiculo}
                                                />
                                            </div>
                                        )}
                                        {(state.ramo === "Salud" || state.ramo === "Vida") && (
                                            <div className="grid bg-dark-600 border border-dark-200 rounded-md p-2 gap-4">
                                                <p className="flex"><PersonIcon className="mr-2" /> Asegurados ({state.asegurados.length})</p>
                                                <List dense={false} className="grid gap-2">
                                                    {state.asegurados.map((asegurado, index) => (
                                                        <AseguradoItem key={index} asegurado={asegurado} index={index} />
                                                    ))}
                                                </List>
                                                <Button onClick={addInsured} className="btn bg-dark-300">AGREGAR ASEGURADO</Button>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {state.items.map((item, itemIndex) => (
                                    <div key={itemIndex} className="p-2 rounded-md bg-dark-500 ">
                                        <div className="grid grid-cols-1 gap-4 p-2">
                                            <p className="flex items-center justify-between">
                                                <span><RequestPageIcon className="mr-2" /> Propuesta No. {itemIndex + 1}</span>
                                                <InputAdornment position="end" className="mr-4 text-white">
                                                    <Tooltip title={"Una cotización puede estar compuesta de varias cotizaciones, con la finalidad de que el cliente puede comparar los beneficios y los precios de los diferentes productos y aseguradoras"}>
                                                        <HelpOutlineIcon />
                                                    </Tooltip>
                                                </InputAdornment>
                                            </p>
                                            {item.id == null && (
                                                <FormControl variant="outlined" size="medium" >
                                                    <InputLabel id="template" >Template</InputLabel>
                                                    <Select
                                                        labelId="template"
                                                        label="Template"
                                                        size="medium"
                                                        name="razon"
                                                        value={item.templateId}
                                                        onChange={e => onChanteTemplate(e, itemIndex)}
                                                        endAdornment={(
                                                            <InputAdornment position="end" className="mr-4">
                                                                <Tooltip title={"Puedes dar de alta templates para tener cotizaciones preconfiguradas y hacer aún mas rápido la cotización"}>
                                                                    <HelpOutlineIcon />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        )}
                                                    >
                                                        <MenuItem value="" ><em>Seleccione</em></MenuItem>
                                                        {state.templates.map(razon => <MenuItem key={razon.id} value={razon.id} >{razon.nombre}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            )}
                                            <Autocomplete
                                                disablePortal
                                                name="aseguradoraId"
                                                value={item.aseguradora}
                                                options={state.aseguradoras}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                onChange={(event, newValue) => onChangeAseguradora(event, newValue, itemIndex)}
                                                getOptionLabel={aseguradora => `${aseguradora.id} ${aseguradora.aseguradora} (${aseguradora.abreviacion})`}
                                                renderInput={(params) => <TextField {...params} required label="Aseguradora" />}
                                            />

                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <TextField
                                                    label="Plan o Paquete"
                                                    type="text"
                                                    variant="outlined"
                                                    required
                                                    name="plan"
                                                    onInput={event => onlyLetter(event, 20)}
                                                    value={item.plan}
                                                    onChange={e => onChangeItem(e, itemIndex)}
                                                />
                                                <FormControl >
                                                    <InputLabel id="moneda" required>Moneda</InputLabel>
                                                    <Select
                                                        required
                                                        labelId="moneda"
                                                        name="moneda"
                                                        value={item.moneda}
                                                        onChange={e => onChangeItem(e, itemIndex)}
                                                    >
                                                        {state.monedas.map((moneda, index) => (
                                                            <MenuItem key={moneda.id} value={moneda.abreviacion}>{moneda.abreviacion} - {moneda.moneda}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>



                                            <div className="mt-4 grid gap-4 mb-4">
                                                <div className="grid grid-cols-1 gap-4">
                                                    <p className="flex"><PriceChangeIcon className="mr-2" /> Características del Plan</p>
                                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                        <TextField
                                                            label="Suma Asegurada"
                                                            type="text"
                                                            variant="outlined"
                                                            required
                                                            name="sumaAsegurada"
                                                            onFocus={e => e.target.select()}
                                                            onInput={event => onlyLetter(event, 50)}
                                                            value={item.sumaAsegurada}
                                                            onChange={e => onChangeItem(e, itemIndex)}
                                                        />
                                                        {state.ramo !== "Vida" && (
                                                            <TextField
                                                                label="Deducible"
                                                                type="text"
                                                                variant="outlined"
                                                                name="deducible"
                                                                onFocus={e => e.target.select()}
                                                                onInput={event => onlyLetter(event, 50)}
                                                                value={item.deducible}
                                                                onChange={e => onChangeItem(e, itemIndex)}
                                                            />
                                                        )}

                                                        {state.ramo === "Salud" && (
                                                            <>
                                                                <TextField
                                                                    label="Coaseguro"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    name="coaseguro"
                                                                    onFocus={e => e.target.select()}
                                                                    onInput={event => onlyLetter(event, 50)}
                                                                    value={item.coaseguro}
                                                                    onChange={e => onChangeItem(e, itemIndex)}
                                                                />
                                                                <TextField
                                                                    label="Tope Coaseguro"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    name="topeCoaseguro"
                                                                    onFocus={e => e.target.select()}
                                                                    onInput={event => onlyLetter(event, 50)}
                                                                    value={item.topeCoaseguro}
                                                                    onChange={e => onChangeItem(e, itemIndex)}
                                                                />
                                                                <TextField
                                                                    label="Copago"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    name="copago"
                                                                    onFocus={e => e.target.select()}
                                                                    onInput={event => onlyLetter(event, 50)}
                                                                    value={item.copago}
                                                                    onChange={e => onChangeItem(e, itemIndex)}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {state.ramo !== "Auto" && (
                                                <div className="grid bg-dark-600 rounded-md p-2 gap-4 border border-dark-200">
                                                    <div className="flex justify-between">
                                                        <p className="flex"><CategoryIcon className="mr-2" /> Características Adicionales ({item.caracteristicas.length})</p>
                                                        <Tooltip className="hidden md:inline-block" title="Permite agregar características libres como Nombre del Paquete, nivel hospitalario, Tabulador, etc.">
                                                            <HelpOutlineIcon className="text-white" />
                                                        </Tooltip>
                                                    </div>
                                                    <p className="block md:hidden text-xs text-gray-800">Permite agregar características libres como Nombre del Paquete, Nivel Hospitalario, Tabulador, etc.</p>

                                                    <List dense={false} className="grid gap-2">
                                                        {item.caracteristicas.map((caracteristica, index) => (
                                                            <CaracteristicaItem key={index} item={item} itemIndex={itemIndex} caracteristica={caracteristica} index={index} />
                                                        ))}
                                                    </List>
                                                    <Button className="btn bg-dark-300" onClick={e => addCaracteristica(itemIndex)}>AGREGAR CARACTERÍSTICA</Button>
                                                </div>
                                            )}

                                            {state.ramo && (
                                                <div className="grid bg-dark-600 border border-dark-200 rounded-md p-2 gap-4 ">
                                                    <div className="flex justify-between">
                                                        <p className="flex"><HealthAndSafetyIcon className="mr-2" /> Coberturas ({item.coberturas.length})</p>
                                                        <Tooltip className="hidden md:inline-block" title="Agrega diferentes formas de pago para que el cliente pueda comprar los diferentes costos de financiamiento">
                                                            <HelpOutlineIcon className="text-white" />
                                                        </Tooltip>
                                                    </div>
                                                    <List dense={false} className="grid gap-2">
                                                        {item.coberturas.map((cobertura, index) => (
                                                            <CoberturaItem key={index} item={item} itemIndex={itemIndex} cobertura={cobertura} index={index} />
                                                        ))}
                                                    </List>
                                                    <Button className="btn bg-dark-300" onClick={e => addCoverage(itemIndex)}>AGREGAR COBERTURA</Button>
                                                </div>
                                            )}

                                            <div className="grid bg-dark-600 rounded-md p-2 gap-4 border border-dark-200">
                                                <div className="flex justify-between">
                                                    <p className="flex"><MonetizationOnIcon className="mr-2" /> Planes de Pago ({item.planesPago.length})</p>
                                                    <Tooltip className="hidden md:inline-block" title="Agrega diferentes formas de pago para que el cliente pueda comprar los diferentes costos de financiamiento">
                                                        <HelpOutlineIcon className="text-white" />
                                                    </Tooltip>
                                                </div>
                                                <List dense={false} className="grid gap-2">
                                                    {item.planesPago.map((planPago, index) => (
                                                        <PlanPagoItem key={index} item={item} itemIndex={itemIndex} planPago={planPago} index={index} />
                                                    ))}
                                                </List>
                                                <Button className="btn bg-dark-300" onClick={e => addPlanPago(itemIndex)}>AGREGAR PLAN DE PAGO</Button>
                                            </div>
                                            <Button className="btn text-red bg-dark-300" onClick={e => toggleShowDeleteItemDialog(true, itemIndex)}>ELIMINAR COTIZACION</Button>
                                        </div>
                                    </div>
                                ))}
                                <Button onClick={addCotizacion} className="btn bg-dark-300">AGREGAR COTIZACIÓN</Button>
                                <Button type="submit" disabled={!state.ramo} className="btn btn-yellow">GUARDAR</Button>

                                {state.message && <Alert type={state.message.type} message={state.message.message} />}
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}


const AseguradoItem = ({ item, itemIndex, asegurado, index }) => {

    const state = getState()

    const editMode = () => {
        return (
            <div key={index} className="bg-dark-400 p-4 border-dark-600 border-2 rounded-md">
                <div className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-1 gap-4">
                        <TextField
                            label="Nombre del asegurado"
                            type="text"
                            variant="outlined"
                            required
                            name="nombre"
                            onInput={event => onlyLetter(event, 200)}
                            value={asegurado.nombre}
                            onChange={e => onChangeAsegurado(e, index)}
                        />
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <FormControl >
                                <InputLabel id="parentesco" required>Parentesco</InputLabel>
                                <Select
                                    required
                                    disabled={index === 0}
                                    labelId="parentesco"
                                    name="parentesco"
                                    value={asegurado.parentesco}
                                    onChange={e => onChangeAsegurado(e, index)}
                                >
                                    {getParentescos().map((parentesco, index) => (
                                        <MenuItem key={index} value={parentesco.id}>{parentesco.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl >
                                <InputLabel id="genero" required>Genero</InputLabel>
                                <Select
                                    required
                                    labelId="genero"
                                    name="genero"
                                    value={asegurado.genero}
                                    onChange={e => onChangeAsegurado(e, index)}
                                >
                                    {getGeneros().map(genero => (
                                        <MenuItem key={genero.id} value={genero.id}>{genero.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    name="fecNacimiento"
                                    label="Fecha de Nacimiento"
                                    inputFormat="dd/MM/yyyy"
                                    value={asegurado.fecNacimiento}
                                    onChange={date => onChangeAseguradoNacimiento(date, index)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <TextField
                                label="Edad"
                                type="text"
                                variant="outlined"
                                name="edad"
                                value={asegurado.edad}
                                onChange={e => onChangeAsegurado(e, index)}
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <Button className="btn btn-red text-white p-2" onClick={e => removeInsured(index)}>ELIMINAR</Button>
                            <Button disabled={isEmpty(asegurado.nombre) || isEmpty(asegurado.parentesco) || isEmpty(asegurado.genero)} onClick={e => saveAsegurado(index)} className="btn btn-yellow p-2">GUARDAR</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const readMode = () => {
        return (
            <ListItem
                className=" border-dark-500 border-2 bg-dark-300 rounded-md p-2"
                secondaryAction={
                    <div className="flex flex-col-reverse md:flex-row text-right gap-2">
                        <IconButton className="p-0 btn btn-yellow bg-none" onClick={e => downAsegurados(index)} disabled={index === 0 || state.asegurados.length === (index + 1)} >
                            <ExpandMoreIcon className=" text-dark-600" />
                        </IconButton>
                        <IconButton className=" btn p-0 btn-yellow" onClick={e => upAsegurados(index)} disabled={index === 0 || index === 1} >
                            <ExpandLessIcon className="text-dark-600" />
                        </IconButton>
                    </div>
                }
            >
                <ListItemAvatar>
                    <IconButton onClick={e => editInsured(index)} className="ml-1 btn btn-yellow p-1">
                        <CreateIcon className="text-dark-600" />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText className="text-white mr-11 md:mr-16"
                    primary={<p className="text-sm font-bold">{`${asegurado.nombre} (${asegurado.parentesco})`}</p>}
                    secondary={<p className="text-sm text-gray-800">{`F. Nacimiento: ${asegurado.fecNacimiento instanceof Date ? format(asegurado.fecNacimiento, "dd/MM/yyyy") : "Sin definir"}, Edad: ${asegurado.edad}`}</p>}
                />
            </ListItem>
        )
    }

    const render = () => {
        return asegurado.edit ? editMode() : readMode()
    }

    return render()
}


const CoberturaItem = ({ item, itemIndex, cobertura, index }) => {

    const state = getState()

    const editMode = () => {
        return (
            <div key={cobertura.uuid} className="bg-dark-400 p-2 md:p-4 border-dark-600 border-2 rounded-md">
                <div key={index} className="grid grid-cols-1 gap-4">
                    <Autocomplete
                        freeSolo
                        name="descripcion"
                        clearOnBlur
                        multiple={false}
                        value={cobertura.descripcion}
                        options={state.coberturasOptions.map(x => x.descripcion)}
                        getOptionLabel={e => e || ""}
                        onChange={(e, value) => onChangeCoberturaDescripcion(e, value, index, itemIndex)}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                                filtered.unshift(inputValue)
                            }

                            return filtered;
                        }}
                        renderInput={(params) => <TextField required {...params} label="Cobertura" />}
                    />
                    <TextField
                        label="Valor"
                        type="text"
                        variant="outlined"
                        name="valor"
                        onInput={event => onlyLetter(event, 50)}
                        value={cobertura.valor}
                        onChange={e => onChangeCobertura(e, index, itemIndex)}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Button className="btn btn-red p-2 text-white" onClick={e => removeCoverage(index, itemIndex)}>ELIMINAR</Button>
                        <Button disabled={isEmpty(cobertura.descripcion) || isEmpty(cobertura.valor)} onClick={e => saveCoverage(index, itemIndex)} className="btn btn-yellow p-2">GUARDAR</Button>
                    </div>
                </div>
            </div>
        )
    }

    const readMode = () => {
        return (
            <ListItem
                className=" border-dark-500 border-2 bg-dark-300 rounded-md p-2"
                secondaryAction={
                    <div className="flex flex-col-reverse md:flex-row text-right gap-2">
                        <IconButton className="p-0 btn btn-yellow bg-none" onClick={e => downCobertura(index, itemIndex)} disabled={item.coberturas.length === (index + 1)} >
                            <ExpandMoreIcon className=" text-dark-600" />
                        </IconButton>
                        <IconButton className=" btn p-0 btn-yellow" onClick={e => upCobertura(index, itemIndex)} disabled={index === 0} >
                            <ExpandLessIcon className="text-dark-600" />
                        </IconButton>
                    </div>
                }
            >
                <ListItemAvatar>
                    <IconButton onClick={e => editCoverage(index, itemIndex)} className="ml-1 btn btn-yellow p-1">
                        <CreateIcon className="text-dark-600" />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText className="text-white mr-11 md:mr-16"
                    primary={<p className="text-sm font-bold">{cobertura.descripcion}</p>}
                    secondary={<p className="text-xs text-gray-800">{cobertura.valor}</p>}
                />
            </ListItem>
        )
    }

    const render = () => {
        return cobertura.edit ? editMode() : readMode()
    }

    return render()
}


const CaracteristicaItem = ({ item, itemIndex, caracteristica, index }) => {

    const state = getState()

    const editMode = () => {
        return (
            <div className="bg-dark-400 p-2 md:p-4 border-dark-600 border-2 rounded-md">
                <div key={index} className="grid grid-cols-1 gap-4">
                    <TextField
                        required
                        label="Descripción"
                        type="text"
                        variant="outlined"
                        name="descripcion"
                        onInput={event => onlyLetter(event, 150)}
                        value={caracteristica.descripcion}
                        onChange={e => onChangeCaracteristica(e, index, itemIndex)}
                    />
                    <TextField
                        required
                        label="Valor"
                        type="text"
                        variant="outlined"
                        name="valor"
                        onInput={event => onlyLetter(event, 50)}
                        value={caracteristica.valor}
                        onChange={e => onChangeCaracteristica(e, index, itemIndex)}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Button className="btn btn-red p-2 text-white" onClick={e => removeCaracteristica(index, itemIndex)}>ELIMINAR</Button>
                        <Button disabled={isEmpty(caracteristica.descripcion) || isEmpty(caracteristica.valor)} onClick={e => saveCaracteristica(index, itemIndex)} className="btn btn-yellow p-2">GUARDAR</Button>
                    </div>
                </div>
            </div>
        )
    }

    const readMode = () => {
        return (
            <ListItem
                className=" border-dark-500 border-2 bg-dark-300 rounded-md p-2"
                secondaryAction={
                    <div className="flex flex-col-reverse md:flex-row text-right gap-2">
                        <IconButton className="p-0 btn btn-yellow bg-none" onClick={e => downCaracteristica(index, itemIndex)} disabled={item.caracteristicas.length === (index + 1)} >
                            <ExpandMoreIcon className=" text-dark-600" />
                        </IconButton>
                        <IconButton className=" btn p-0 btn-yellow" onClick={e => upCaracteristica(index, itemIndex)} disabled={index === 0} >
                            <ExpandLessIcon className="text-dark-600" />
                        </IconButton>
                    </div>
                }
            >
                <ListItemAvatar>
                    <IconButton onClick={e => editCaracteristica(index, itemIndex)} className="ml-1 btn btn-yellow p-1">
                        <CreateIcon className="text-dark-600" />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText className="text-white mr-11 md:mr-16"
                    primary={<p className="text-sm font-bold">{caracteristica.descripcion}</p>}
                    secondary={<p className="text-xs text-gray-800">{caracteristica.valor}</p>}
                />
            </ListItem>
        )
    }

    const render = () => {
        return caracteristica.edit ? editMode() : readMode()
    }

    return render()
}


const PlanPagoItem = ({ item, itemIndex, planPago, index }) => {

    const state = getState()
    const formasPago = getFormaPago()

    const editMode = () => {
        return (
            <div className="bg-dark-400 p-2 md:p-4 border-dark-600 border-2 rounded-md">
                <div key={index} className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <FormControl >
                            <InputLabel id="formaPago" required>Forma de Pago</InputLabel>
                            <Select
                                required
                                labelId="formaPago"
                                name="formaPago"
                                value={planPago.formaPago}
                                onChange={e => onChangePlanPagoPrimas(e, index, itemIndex)}
                            >
                                {formasPago.map((formaPago, index) => (
                                    <MenuItem key={index} value={formaPago.id}>{formaPago.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {planPago.formaPago && (
                        <>
                            <div className={planPago.formaPago === "contado" ? "hidden" : "grid grid-cols-1 md:grid-cols-2 gap-4"}>
                                <NumberFormat
                                    name="primaPrimerRecibo"
                                    value={planPago.primaPrimerRecibo}
                                    onChange={e => onChangePlanPagoPrimas(e, index, itemIndex)}
                                    customInput={TextField}
                                    onFocus={e => e.target.select()}
                                    label="Prima Primer Recibo"
                                    variant="outlined"
                                    size="medium"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <NumberFormat
                                    name="primaReciboSub"
                                    value={planPago.primaReciboSub}
                                    onChange={e => onChangePlanPagoPrimas(e, index, itemIndex)}
                                    customInput={TextField}
                                    onFocus={e => e.target.select()}
                                    label="Prima Recibos Subsecuentes"
                                    variant="outlined"
                                    size="medium"
                                    inputProps={{ style: { textAlign: 'right' } }} // the change is here
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","}
                                    allowNegative={false}
                                    isNumericString={true}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </div>
                            <NumberFormat
                                disabled={planPago.formaPago !== "contado"}
                                name="total"
                                value={planPago.total}
                                onChange={e => onChangePlanPagoPrimas(e, index, itemIndex)}
                                customInput={TextField}
                                onFocus={e => e.target.select()}
                                label="Prima Total"
                                variant="outlined"
                                size="medium"
                                inputProps={{
                                    style: { textAlign: 'right' },
                                    pattern: createDecimalPattern(2),
                                    title: "Campo requerido, se requiere un valor mayor que cero"
                                }} // the change is here
                                type="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={","}
                                allowNegative={false}
                                isNumericString={true}
                                required
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        </>
                    )}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Button className="btn btn-red p-2 text-white" onClick={e => removePlanPago(index, itemIndex)}>ELIMINAR</Button>
                        <Button onClick={e => savePlanPago(index, itemIndex)} className="btn btn-yellow p-2">GUARDAR</Button>
                    </div>
                </div>
            </div>
        )
    }

    const readMode = () => {
        return (
            <ListItem
                className=" border-dark-500 border-2 bg-dark-300 rounded-md p-2"
                secondaryAction={
                    <div className="flex flex-col-reverse md:flex-row text-right gap-2">
                        <IconButton className="p-0 btn btn-yellow bg-none" onClick={e => downPlanPago(index, itemIndex)} disabled={item.planesPago.length === (index + 1)} >
                            <ExpandMoreIcon className=" text-dark-600" />
                        </IconButton>
                        <IconButton className=" btn p-0 btn-yellow" onClick={e => upPlanPago(index, itemIndex)} disabled={index === 0} >
                            <ExpandLessIcon className="text-dark-600" />
                        </IconButton>
                    </div>
                }
            >
                <ListItemAvatar>
                    <IconButton onClick={e => editPlanPago(index, itemIndex)} className="ml-1 btn btn-yellow p-1">
                        <CreateIcon className="text-dark-600" />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText className="text-white mr-11 md:mr-16"
                    primary={<p className="text-sm font-bold">{planPago.formaPago.toUpperCase()} - <span>{numberFormatNoDecimal(planPago.total)} {state.moneda}</span></p>}
                    secondary={<p className="text-xs text-gray-800">{planPago.formaPago === "contado" ? `Pago total de contado` : `${numberFormatNoDecimal(planPago.primaPrimerRecibo)}/${numberFormatNoDecimal(planPago.primaReciboSub)}`}</p>}
                />
            </ListItem>
        )
    }

    const render = () => {
        return planPago.edit ? editMode() : readMode()
    }

    return render()
}



export default NuevaCotizacion