import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AttachmentIcon from '@mui/icons-material/Attachment';
import EditIcon from '@mui/icons-material/Edit';
import ForumIcon from '@material-ui/icons/Forum';
import { Link, useHistory } from 'react-router-dom';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminTemplate from '../../template/AdminTemplate';
import APIInvoke from '../../utils/APIInvoke'
import { parse, format, fromToFormat } from "../../utils/DateUtils";
import Toolbar from '../../components/Toolbar'
import DalalaDialog from '../../components/DalalaDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@material-ui/core';
import { DialogTitle } from '@mui/material';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';


const ConsultaTereaPage = (props) => {

    const params = useParams()
    const history = useHistory()
    const tareaId = props.tareaId || params.tareaId

    const [state, setState] = useState(null)
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })
    const [showOkDialog, setShowOkDialog] = useState({ show: false, message: "" })

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const response = await (await APIInvoke.invokeGET(`/tareas/${tareaId}`)).json();
                setState({
                    ...response.body,
                })
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false)
            }
        }
        init()
    }, [])

    const deleteTarea = async () => {
        try {
            setOpenDeleteDialog({ show: false })
            setLoading(true)
            const deleteResponse = await (await APIInvoke.invokeDELETE(`/tareas/${tareaId}`)).json()
            if (deleteResponse.ok) {
                history.push("/tareas")
                props.onCompleted(deleteResponse.body)
            } else {
                setShowOkDialog({ show: true, message: deleteResponse.message })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return <AdminTemplate loading={loading} modal={props.modal} />
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Dialog
                    open={openDeleteDialog.show}
                    onClose={e => setOpenDeleteDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la tarea?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={deleteTarea} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showOkDialog.show}
                    onClose={e => setShowOkDialog({ show: false, message: "" })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">{showOkDialog.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn btn-yellow p-2 text-sm" onClick={e => setShowOkDialog({ show: false, message: "" })} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Toolbar title="Detalle de la tarea" backUrl={`/tareas`} modal={props.modal}>
                    <div className="layout">
                        <div className="flex justify-end">
                            <Link className="no-underline p-1" to={`/tareas/${tareaId}/edit`}>
                                <div className="bg-yellow h-10 w-10 text-center flex items-center rounded-md">
                                    <EditIcon className="text-dark-600 ml-2"></EditIcon>
                                </div>
                            </Link>
                            <Link className="no-underline p-1 " to="#" onClick={e => setOpenDeleteDialog({ show: true, id: state.id })}>
                                <div className="bg-yellow  h-10 w-10 text-center flex items-center rounded-md">
                                    <DeleteIcon className="text-dark-600 ml-2" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </Toolbar>
                <div className="main">
                    <div className="layout-compact">
                        <div className="grid grid-cols-1 gap-y-8 mb-8">
                            <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                <p className="text-gray-400 text-base font-semibold"><LibraryAddCheckIcon fontSize="large" /> DATOS DE LA TAREA</p>
                                <section className="rounded-md bg-dark-300 p-4 grid grid-cols-1 gap-4 ">
                                    <div className="grid">
                                        <p className="text-yellow text-sm">Nombre</p>
                                        <p className="text-white text-sm capitalize">{state.nombre}</p>
                                    </div>
                                    <div className="grid">
                                        <p className="text-yellow text-sm">Descripcion</p>
                                        <p className="text-white text-sm capitalize">{state.descripcion}</p>
                                    </div>
                                    <div className="grid ">
                                        <p className="text-yellow text-sm">Fecha de creación</p>
                                        <p className="text-white text-sm capitalize">{fromToFormat(state.fecha, "yyyy-MM-dd HH:mm:ss", "dd MMMM yyyy")}</p>
                                    </div>
                                    <div className="grid">
                                        <p className="text-yellow text-sm">Fecha de Vencimiento</p>
                                        <p className="text-white text-sm">{state.vencimiento ? fromToFormat(state.vencimiento, "yyyy-MM-dd HH:mm:ss", "dd MMMM yyyy") : "No definida"}</p>
                                    </div>
                                    <div className="grid ">
                                        <p className="text-yellow text-sm">Fecha de cierre</p>
                                        <p className="text-white text-sm capitalize">{state.fechaCierre ? fromToFormat(state.fechaCierre, "yyyy-MM-dd HH:mm:ss", "dd MMMM yyyy") : ""}</p>
                                    </div>
                                    <div className="grid">
                                        <p className="text-yellow text-sm">Vendedor</p>
                                        <p className="text-white text-sm capitalize">{state.vendedor ? `${state.vendedor.nombres} ${state.vendedor.apPat} ${state.vendedor.apMat}` : "Sin vendedor"}</p>
                                    </div>
                                    <div className="grid ">
                                        <p className="text-yellow text-sm">Prioridad</p>
                                        <p className="text-white text-sm capitalize">{state.prioridad.displayName}</p>
                                    </div>
                                    <div className="grid ">
                                        <p className="text-yellow text-sm">Tipo</p>
                                        <p className="text-white text-sm capitalize">{state.tipo.displayName}</p>
                                    </div>
                                    <div className="grid ">
                                        <p className="text-yellow text-sm">Estatus</p>
                                        <p className="text-white text-sm capitalize">{state.status.displayName}</p>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )

    }


    return render()

}

export default ConsultaTereaPage