import { React, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import AdminTemplate from '../../template/AdminTemplate'
import Pagination from '../../components/Pagination'
import { Link } from 'react-router-dom'
import { Accordion, AccordionSummary, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox';
import Toolbar from '../../components/Toolbar'
import SearchIcon from '@mui/icons-material/Search';
import ConsultaSiniestro from './ConsultaSiniestro';
import DalalaDialog from '../../components/DalalaDialog';
import { useDispatch } from 'react-redux'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Tooltip from '@mui/material/Tooltip';
import { parse } from '../../utils/DateUtils'
import { DateTime } from 'luxon'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { getSiniestroStatusById } from '../../utils/EnumUtils'
import GrantContent from '../../components/GrantContent'
import * as Permisos from '../../utils/Permisos'

const Siniestros = (props) => {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const [state, setState] = useState({
        siniestros: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 0
    })

    const [filter, setFilter] = useState({
        showTerminated: false,
        noPoliza: "",
        noSiniestro: "",
        cliente: ""
    })

    const [openDetalleDialog, setOpenDetalleDialog] = useState({ show: false, id: null })

    useEffect(async () => {
        fetchSiniestros()
    }, [state.currentPage, state.nombre, filter.showTerminated])

    const fetchSiniestros = async () => {
        setLoading(true)
        try {
            const filterNoPoliza = filter.noPoliza.trim().length > 0 ? `&noPoliza[cn]=${filter.noPoliza.trim()}` : ""
            const filterCliente = filter.cliente.trim().length > 0 ? `&cliente[cn]=${filter.cliente.trim()}` : ""
            const filterNoSiniestro = filter.noSiniestro.trim().length > 0 ? `&noSiniestro[cn]=${filter.noSiniestro.trim()}` : ""
            const filterStatus = filter.showTerminated ? "" : `&status[ni]=FINALIZADO,RECHAZADO`

            const filterValues = `${filterNoPoliza}${filterCliente}${filterNoSiniestro}${filterStatus}`

            const response = await (await APIInvoke.invokeGET(`/siniestros?page=${state.currentPage}${filterValues}&order=fechaEstimadaResolucion asc`)).json()
            setState({
                ...state,
                siniestros: response.body.items.map(siniestro => {
                    return {
                        ...siniestro,
                        fechaSiniestro: DateTime.fromFormat(siniestro.fechaSiniestro, "yyyy-MM-dd"),
                        fechaEstimadaResolucion: DateTime.fromFormat(siniestro.fechaEstimadaResolucion, "yyyy-MM-dd")
                    }
                }),
                totalItems: response.body.totalItems,
                currentPage: response.body.currentPage,
                totalPages: response.body.totalPages,
                pageSize: response.body.pageSize
            })
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const renderSiniestro = (siniestro) => {
        const status = getSiniestroStatusById(siniestro.status)
        return (
            <Link className="no-underline" key={siniestro.id} to="#" onClick={e => setOpenDetalleDialog({ show: true, id: siniestro.id })}>
                <article className="bg-dark-300 rounded-md p-2 lg:p-4 ">
                    <div className="grid gap-1 grid-cols-1 lg:grid-cols-2">
                        <p className="text-sm text-gray-400">No Siniestro: {siniestro.noSiniestro}</p>
                        <p className="text-sm text-gray-400">F. Siniestro: {siniestro.fechaSiniestro.toFormat("dd/MM/yyyy")}</p>
                        <p className="text-sm text-gray-400">No Póliza: {siniestro.noPoliza}</p>
                        <p className="text-sm text-gray-400">F. Resolucion: {siniestro.fechaEstimadaResolucion.toFormat("dd/MM/yyyy")}</p>
                        <p className="text-sm text-gray-400">Contratante: {`${siniestro.nombres} ${siniestro.apPat} ${siniestro.apMat}`}</p>
                        <p className="text-sm text-gray-400">Estatus: <span className={`${status.color} uppercase`}>{status.name}</span></p>
                    </div>
                </article>
            </Link>
        )
    }

    const onChangeFilters = (e) => {
        let { value, name } = e.target
        if (e.target.type === "checkbox") value = e.target.checked
        setFilter({
            ...filter,
            [e.target.name]: value
        })
    }

    const renderFilters = () => {
        return (

            <Accordion className="bg-dark-400 rounded-md">
                <AccordionSummary expandIcon={<ExpandMoreIcon className="text-gray-400" />} aria-controls="panel1a-content" id="panel1a-header">
                    <p className="font-bold"><FilterAltIcon /> Filtros:</p>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="grid grid-cols-1  gap-4 bg-dark-400">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <FormControlLabel
                                name="showTerminated"
                                label={<p className="text-sm">Mostrar Finalizado/Rechazado</p>}
                                required
                                onChange={onChangeFilters}
                                checked={filter.showTerminated}
                                control={
                                    <Checkbox name="showTerminated" color="primary" />
                                }
                            />
                            <TextField
                                label="Número de Siniestro"
                                type="text"
                                variant="outlined"
                                required
                                name="noSiniestro"
                                value={state.noSiniestro}
                                onChange={onChangeFilters}
                            />

                            <TextField
                                label="Cliente"
                                type="text"
                                variant="outlined"
                                required
                                name="cliente"
                                value={state.cliente}
                                onChange={onChangeFilters}
                            />
                            <TextField
                                label="Número de Póliza"
                                type="text"
                                variant="outlined"
                                required
                                name="noPoliza"
                                value={state.noPoliza}
                                onChange={onChangeFilters}
                            />

                        </div>
                        <Button onClick={fetchSiniestros} className="btn btn-yellow">FILTRAR</Button>
                    </div>
                </AccordionDetails>
            </Accordion>

        )
    }

    const onDeleteCompleted = (result) => {
        if (!result) return

        setState({
            ...state,
            siniestros: state.siniestros.filter(x => x.id !== openDetalleDialog.id)
        })

        setOpenDetalleDialog({ show: false, id: null })
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} title="Dalala - Siniestros">
                <DalalaDialog
                    title={"Detalle del siniestro"}
                    open={openDetalleDialog.show}
                    handleClose={() => setOpenDetalleDialog({ show: false, id: null })}
                >
                    <ConsultaSiniestro modal={true} siniestroId={openDetalleDialog.id} onCompleted={onDeleteCompleted} />
                </DalalaDialog>
                <Toolbar title="Siniestros">
                    <div className="layout">
                        <div className="flex flex-row gap-4">
                            <GrantContent permisos={[Permisos.SINIESTROS_W]}>
                                <Link to={{ pathname: "/siniestros/", state: { backUrl: "/siniestros" } }} className="btn btn-yellow px-4 py-2" size="small" ><AddBoxIcon /> Añadir</Link>
                            </GrantContent>
                        </div>
                    </div>
                </Toolbar>
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-4">
                            {renderFilters()}
                            <div className="grid grid-cols-1 gap-2 bg-dark-400 p-2 rounded-md">
                                {state.siniestros && state.siniestros.map((siniestro, i) => renderSiniestro(siniestro))}
                                <div className="">
                                    <Pagination totalItems={state.totalItems} pages={state.totalPages} page={state.currentPage} onPageChange={newPage => setState({ ...state, currentPage: newPage })} />
                                </div>

                                {state.siniestros.length === 0 && (
                                    <p className="text-yellow text-center mb-8">Sin resultados</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}
export default Siniestros