import React, { useState, useEffect } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import Toolbar from '../components/Toolbar'
import { Button, TextField } from '@material-ui/core'
import { onlySimpleCharacters } from '../utils/FormUtils'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParams, useHistory } from 'react-router';
import APIInvoke from '../utils/APIInvoke'
import Alert from '../components/Alert'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const NuevoModelo = (props) => {

    const history = useHistory()
    const { modeloId } = useParams()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [state, setState] = useState({
        nombre: "",
        evento: "",
        cuentaCC: "",
        cuentaCCB: "",
        asunto: "",
        cuerpo: EditorState.createWithContent(ContentState.createFromText("\n\n\n\n")),
        status: "",
        eventos: []
    })

    useEffect(async () => {
        try {
            setLoading(true)
            const eventoResponse = await (await APIInvoke.invokeGET(`/correo-evento`)).json()
            if (modeloId) {
                const response = await (await APIInvoke.invokeGET(`/correo-modelo/${modeloId}`)).json()
                const blocksFromHtml = htmlToDraft(response.body.cuerpo);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                const evento = eventoResponse.body.find(x => x.id === response.body.evento.id)

                setState({
                    ...response.body,
                    cuerpo: editorState,
                    evento: evento.id,
                    eventos: eventoResponse.body
                })
            } else {
                setState({
                    ...state,
                    eventos: eventoResponse.body
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [])

    const onChange = (e) => {

        const name = e.target.name
        const value = e.target.value

        setState({
            ...state,
            [name]: value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const evento = state.eventos.find(x => x.id === state.evento)
            const request = {
                id: state.id ? state.id : undefined,
                nombre: state.nombre,
                correoEventoId: evento.id,
                cuentaCC: state.cuentaCC,
                cuentaCCB: state.cuentaCCB,
                asunto: state.asunto,
                cuerpo: draftToHtml(convertToRaw(state.cuerpo.getCurrentContent()))
            }

            const response = state.id
                ? await (await APIInvoke.invokePUT(`/correo-modelo/${modeloId}`, request)).json()
                : await (await APIInvoke.invokePOST(`/correo-modelo`, request)).json()

            if (response.ok) {
                setState({
                    ...state,
                    id: response.body.id
                })
                setMessage({ type: "success", message: response.message })
                history.push(`/configuracion/modelos/${response.body.id}`)
            } else {
                setMessage({ type: "error", message: response.message })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const deleteModelo = async (e) => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeDELETE(`/correo-modelo/${state.id}`)).json()
            history.push(`/configuracion/modelos`)
        } catch (error) {
            console.error("Error al guardar", error)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {

        const evento = state.eventos.find(x => x.id === state.evento)
        let suggestions = []
        if (evento) {
            suggestions = evento.correoCampos.map(x => {
                return { text: `${x.nombre}`, value: `${x.nombre}`, url: "https://dalala.app/" }
            })
        }

        return (
            <AdminTemplate loading={loading} modal={props.modal} >
                <Dialog
                    open={showDeleteDialog}
                    onClose={e => setShowDeleteDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar el Modelo?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} className="mr-4" onClick={e => setShowDeleteDialog(false)}>CANCELAR</Button>
                        <Button disabled={loading} className="bg-red-500 text-white" onClick={deleteModelo} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Toolbar title="Nuevo Modelo" backUrl="/configuracion/modelos" modal={props.modal} />
                <div className="main">
                    <div className="layout-compact">
                        <form onSubmit={onSubmit}>
                            <div className="grid grid-cols-1 gap-4">
                                <p className="">CONFIGURACIÓN DE MODELO</p>
                                <TextField
                                    label="Nombre del Modelo"
                                    type="text"
                                    variant="outlined"
                                    required
                                    name="nombre"
                                    value={state.nombre}
                                    onChange={onChange}
                                />
                                <FormControl variant="outlined" size="medium" >
                                    <InputLabel id="evento" required>Evento</InputLabel>
                                    <Select
                                        labelId="evento"
                                        label="Evento"
                                        size="medium"
                                        name="evento"
                                        value={state.evento}
                                        onChange={onChange}
                                    >
                                        {state.eventos.map(evento => (
                                            <MenuItem key={evento.id} value={evento.id} >{evento.nombre}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                {evento && (
                                    <>
                                        <p className="text-yellow  mt-8">Enviar correo con copia</p>

                                        <TextField
                                            label="Cuenta para copia"
                                            type="email"
                                            variant="outlined"
                                            name="cuentaCC"
                                            value={state.cuentaCC}
                                            onChange={onChange}
                                        />

                                        <TextField
                                            label="Cuenta para copia oculta"
                                            type="email"
                                            variant="outlined"
                                            name="cuentaCCB"
                                            value={state.cuentaCCB}
                                            onChange={onChange}
                                        />

                                        <TextField
                                            label="Asunto"
                                            type="text"
                                            variant="outlined"
                                            required
                                            name="asunto"
                                            value={state.asunto}
                                            onChange={onChange}
                                        />

                                        <p className="text-yellow  mt-8">Cuerpo del Correo</p>
                                        <small className="text-white "><em>Usa @ para insertar campos dinámicos, ejemplo <span className="text-yellow">@NoPoliza</span></em></small>
                                        <div>
                                            <Editor
                                                wrapperClassName="demo-wrapper bg-dark-300 "
                                                editorClassName="text-sm demo-editor px-3"
                                                toolbarHidden={true}
                                                toolbarClassName="bg-white"
                                                editorState={state.cuerpo}
                                                handlePastedText={(text, html, editorState, onChange) => true}
                                                onEditorStateChange={(editorState) => { setState({ ...state, cuerpo: editorState }) }}
                                                mention={{
                                                    separator: ' ',
                                                    trigger: '@',
                                                    suggestions: suggestions
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-row gap-4">
                                            {state.id && (<Button className="flex-1 btn bg-red text-white" type="button" onClick={() => setShowDeleteDialog(true)}>ELIMINAR</Button>)}
                                            <Button className="flex-1 btn btn-yellow" type="submit">GUARDAR </Button>
                                        </div>
                                        {message && <Alert {...message} />}
                                        <a className="text-center block relative" href={`mailto:seguimiento@tuspolizas.info?subject=${state.asunto}&body=${encodeURIComponent(state.cuerpo.getCurrentContent().getPlainText())}&cc=${state.cuentaCC}&bcc=${state.cuentaCCB}`}>Previsualizar correo</a>
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}
export default NuevoModelo