// https://github.com/mui/mui-x/blob/fd1028c4b5d717455fdec6dc463e07d82c04e2dd/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
module.exports = {
  toolbarDensity: 'Dencidad',
  toolbarDensityLabel: 'Dencidad',
  toolbarDensityCompact: 'Compacto',
  toolbarDensityStandard: 'Regular',
  toolbarDensityComfortable: 'Extendido',
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Descargar como CSV',
  toolbarExportPrint: 'Imprimir',
  toolbarExportExcel: 'Descargar como Excel',

  columnsPanelTextFieldLabel: 'Buscar columna',
  columnsPanelTextFieldPlaceholder: 'Nombre de la columna',
  columnsPanelDragIconLabel: 'Reordenar columna',
  columnsPanelShowAllButton: 'Todas',
  columnsPanelHideAllButton: 'Ninguna',

  filterPanelAddFilter: 'Add filter',
  filterPanelDeleteIconLabel: 'Delete',
  filterPanelLinkOperator: 'Logic operator',
  filterPanelOperators: 'Operator', // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: 'And',
  filterPanelOperatorOr: 'Or',
  filterPanelColumns: 'Columns',
  filterPanelInputLabel: 'Value',
  filterPanelInputPlaceholder: 'Filter value',

  // Filter operators text
  filterOperatorContains: 'contiene',
  filterOperatorEquals: 'igual',
  filterOperatorStartsWith: 'comienza con',
  filterOperatorEndsWith: 'termina con',
  filterOperatorIs: 'es',
  filterOperatorNot: 'no es',
  filterOperatorAfter: 'es despues',
  filterOperatorOnOrAfter: 'está en o después',
  filterOperatorBefore: 'es antes',
  filterOperatorOnOrBefore: 'está en o antes',
  filterOperatorIsEmpty: 'Está vacío',
  filterOperatorIsNotEmpty: 'No esta vacío',
  filterOperatorIsAnyOf: 'es cualquiera de',

  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar columnas',
  columnMenuFilter: 'Filtrar',
  columnMenuHideColumn: 'Ocultar',
  columnMenuUnsort: 'Desordenar',
  columnMenuSortAsc: 'Ordenar ascendente',
  columnMenuSortDesc: 'Ordenar descendente',

  // Filter values text
  filterValueAny: 'Cualquiera',
  filterValueTrue: 'SI',
  filterValueFalse: 'NO',

  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Ocultar filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  toolbarFiltersTooltipActive: (count) => count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,

  MuiTablePagination: {
    labelDisplayedRows: ({ from, to, count, page }) => `${from} - ${to} de  ${count}`,
  }

}