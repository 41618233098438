import React, { useEffect, useState } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import APIInvoke from '../utils/APIInvoke';
import { useParams, useHistory } from "react-router-dom";
import { format, parse } from '../utils/DateUtils'
import Alert from '../components/Alert';
import Toolbar from '../components/Toolbar'
import { maxFieldLength, onlyLetter, onlyNumbers, onlySimpleCharacters } from '../utils/FormUtils'
import { calcularRFC, calcularRFCMoral } from '../utils/RFCGenerator'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const NuevoCliente = (props) => {

    const history = useHistory()
    const params = useParams()
    const { clienteId } = useParams();

    const cliente = props.cliente

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        personalidad: "fisica",
        nombres: "",
        alias: "",
        apPat: "",
        apMat: "",
        sexo: "",
        fecNacimiento: null,
        rfc: "",
        cp: "",
        edoCivil: "",
        direccionId: null,
        direccion: "",
        colonia: "",
        tipoDireccion: null,
        celular: "",
        celularId: null,
        correo: "",
        correoId: null,
        tipoCorreo: null
    })
    const [message, setMessage] = useState(null)
    const [codigos, setCodigos] = useState([])



    useEffect(async () => {
        const parametroClienteId = clienteId || props.cliente?.id
        if (parametroClienteId) {
            try {
                setLoading(true)
                const response = await (await APIInvoke.invokeGET(`/clientes/${parametroClienteId}`)).json()
                const body = response.body
                const direccion = body.direcciones?.[0] ? body.direcciones[0] : {}
                const celular = body.celulares?.[0] ? body.celulares[0] : {}
                const correo = body.correos?.[0] ? body.correos[0] : {}

                setState({
                    id: body.id,
                    personalidad: body.fiscal || "",
                    nombres: body.nombres || "",
                    alias: body.alias || "",
                    apPat: body.apPat || "",
                    apMat: body.apMat || "",
                    sexo: body.sexo || "",
                    fecNacimiento: body.fecNacimiento ? parse(body.fecNacimiento, "yyyy-MM-dd") : null,
                    rfc: body.rfc || "",
                    cp: direccion.cp || "",
                    edoCivil: body.edoCivil || "",
                    direccionId: direccion.id,
                    direccion: direccion.direccion || "",
                    colonia: direccion?.sepomex?.id || null,
                    tipoDireccion: direccion.tipoDireccion,
                    correo: correo.correo || "",
                    correoId: correo.id,
                    celular: celular.numero || "",
                    celularId: celular.id,
                })
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        } else if (cliente) {
            setState(cliente)
        }

    }, [])

    useEffect(async () => {
        if (state.cp.length != 5) return

        const response = await (await APIInvoke.invokeGET(`/sepomex/${state.cp}`)).json()
        setCodigos(response.body)

        const colonia = response.body.find(x => x.id == state.colonia)
        if (colonia) {
            setState({
                ...state,
                colonia: colonia.id
            })
        } else {
            if (response.body.length === 1) {
                setState({
                    ...state,
                    colonia: response.body[0].id
                })
            } else {
                setState({
                    ...state,
                    colonia: ""
                })
            }
        }
    }, [state.cp])

    useEffect(() => {
        try {
            const date = state.fecNacimiento
            if (state.personalidad !== "fisica" || !date || state.nombres.length === 0 || state.apMat.length === 0) return
            const rfc = calcularRFC(state.apPat, state.apMat, state.nombres, `${date.getFullYear()}/${String((date.getMonth() + 1)).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`)
            setState({ ...state, rfc })
        } catch (e) {
            console.log("invalida RFC", e)
        }
    }, [state.nombres, state.apPat, state.apMat, state.fecNacimiento])

    const onChange = (e) => {
        let value = e.target.value
        const name = e.target.name
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }

        if (name === "rfc") {
            value = value.toUpperCase()
        }

        setState({
            ...state,
            [name]: value
        })
    }

    const onChangeCelular = (celular) => {
        setState({
            ...state,
            celular: celular
        })
    }

    const save = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const colonia = codigos.find(x => x.id == state.colonia) || { municipio: "" }

            let request = null
            if (state.personalidad === "fisica") {
                console.log("fisica")
                request = {
                    id: state.id,
                    nombres: state.nombres,
                    alias: state.alias,
                    apPat: state.apPat,
                    apMat: state.apMat,
                    fecNacimiento: state.fecNacimiento ? format(state.fecNacimiento, "yyyy-MM-dd") : null,
                    fiscal: state.personalidad,
                    edoCivil: state.edoCivil,
                    sexo: state.sexo,
                    rfc: state.rfc,
                    direccion: {
                        id: state.direccionId,
                        tipo: state.tipoDireccion || "fisica",
                        direccion: state.direccion,
                        sepomexId: colonia.id,
                    },
                    celular: {
                        id: state.celularId,
                        numero: state.celular
                    },
                    correo: {
                        id: state.correoId,
                        correo: state.correo,
                        tipo: "personal"
                    }
                }
            } else {
                console.log("moral")
                request = {
                    id: state.id,
                    nombres: state.nombres,
                    alias: state.alias,
                    fecRegistro: state.fecRegistro ? format(state.fecRegistro, "yyyy-MM-dd") : null,
                    fiscal: state.personalidad,
                    rfc: state.rfc,
                    direccion: {
                        id: state.direccionId,
                        tipo: state.tipoDireccion || "fisica",
                        direccion: state.direccion,
                        sepomexId: colonia.id,
                    },
                    celular: {
                        id: state.celularId,
                        numero: state.celular
                    },
                    correo: {
                        id: state.correoId,
                        correo: state.correo,
                        tipo: "personal"
                    }
                }
            }

            console.log(JSON.stringify(request))

            const response = state.id
                ? await (await APIInvoke.invokePUT(`/clientes/${clienteId || state.id}`, request)).json()
                : await (await APIInvoke.invokePOST(`/clientes`, request)).json()
            if (response.ok) {
                setState({
                    ...state,
                    id: response.body.id
                })
                setMessage({
                    type: "success",
                    message: "Cliente guardado correctamente"
                })
                if (props.onCompleted) {
                    props.onCompleted(response.body)
                } else {
                    history.push({ pathname: `/clientes/${response.body.id}`, state: { backUrl: `/clientes` } })
                }
            } else {
                setMessage({
                    type: "error",
                    message: response.message
                })
            }
        } catch (e) {
            console.error("error al guardar el cliente", e)
            setMessage({
                type: "error",
                message: "Error al guardar el cliente"
            })
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Toolbar title="Captura de Cliente" backUrl={`/clientes/${clienteId}`} modal={props.modal}>
                </Toolbar>
                <div className="main center">
                    <div className="layout-compact">
                        <form onSubmit={save}>
                            <div className="grid grid-cols-1  gap-4">
                                <FormControl variant="outlined" size="medium" >
                                    <InputLabel id="personalidad" required>Personalidad</InputLabel>
                                    <Select
                                        labelId="personalidad"
                                        label="Personalidad"
                                        size="medium"
                                        name="personalidad"
                                        value={state.personalidad}
                                        onChange={onChange}
                                    >
                                        <MenuItem value="fisica" >Física</MenuItem>
                                        <MenuItem value="moral">Moral</MenuItem>
                                    </Select>
                                </FormControl>
                                {state.personalidad === "fisica" && renderFisica()}
                                {state.personalidad === "moral" && renderMoral()}
                                {renderDireccion()}
                                {renderLocalizacion()}
                                <Button type="submit" className="btn btn-yellow">GUARDAR CLIENTE</Button>

                                {message && <Alert {...message} />}
                            </div>
                        </form>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    const renderFisica = () => {
        return (
            <>
                <TextField
                    label="Nombres"
                    type="text"
                    variant="outlined"
                    required
                    name="nombres"
                    onInput={event => onlyLetter(event, 200)}
                    value={state.nombres}
                    onChange={onChange}
                />
                <TextField
                    label="Apellido Paterno"
                    type="text"
                    variant="outlined"
                    required
                    name="apPat"
                    onInput={event => onlyLetter(event, 60)}
                    value={state.apPat}
                    onChange={onChange}
                />
                <TextField
                    label="Apellido Materno"
                    type="text"
                    variant="outlined"
                    required
                    name="apMat"
                    onInput={event => onlyLetter(event, 60)}
                    value={state.apMat}
                    onChange={onChange}
                />

                <TextField
                    label="Alias"
                    type="text"
                    variant="outlined"
                    required
                    name="alias"
                    onInput={event => onlyLetter(event, 50)}
                    value={state.alias}
                    onChange={onChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title={"Apodo o nombre corto con el que se dirige al cliente en correo o comunicados. En lugar de usar un nombre completo o nombre de la empresa, se puede referir al cliente de otra forma"}>
                                    <HelpOutlineIcon />
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                />

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            name="fecNacimiento"
                            label="Nacimiento"
                            inputFormat="dd/MM/yyyy"
                            value={state.fecNacimiento}
                            onChange={(newValue) => {
                                setState({ ...state, fecNacimiento: newValue })
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    <FormControl variant="outlined" size="medium" >
                        <InputLabel id="sexo" required>Sexo</InputLabel>
                        <Select
                            labelId="sexo"
                            label="Sexo"
                            size="medium"
                            name="sexo"
                            value={state.sexo}
                            onChange={onChange}
                        >
                            <MenuItem value="hombre" >Hombre</MenuItem>
                            <MenuItem value="mujer">Mujer</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <TextField
                    label="RFC"
                    type="text"
                    variant="outlined"
                    required
                    name="rfc"
                    value={state.rfc}
                    onInput={event => onlySimpleCharacters(event, 13)}
                    onChange={onChange}
                    helperText=""
                    error={false}
                />

                <FormControl variant="outlined" size="medium" >
                    <InputLabel id="edoCivil">Estado Civil</InputLabel>
                    <Select
                        labelId="edoCivil"
                        label="Estado Civil"
                        size="medium"
                        name="edoCivil"
                        value={state.edoCivil}
                        onChange={onChange}
                    >
                        <MenuItem value="soltero" >Soltero</MenuItem>
                        <MenuItem value="casado">Casado</MenuItem>
                        <MenuItem value="viudo" >Viudo</MenuItem>
                        <MenuItem value="divorciado">Divorciado</MenuItem>
                        <MenuItem value="concubinato">Concubinato</MenuItem>
                    </Select>
                </FormControl>

            </>
        )
    }

    const renderMoral = () => {
        return (
            <>
                <TextField
                    label="Razón Social"
                    type="text"
                    variant="outlined"
                    required
                    name="nombres"
                    onInput={event => onlySimpleCharacters(event, 200)}
                    value={state.nombres}
                    onChange={onChange}
                />
                <TextField
                    label="Alias"
                    type="text"
                    variant="outlined"
                    required
                    name="alias"
                    onInput={event => onlyLetter(event, 50)}
                    value={state.alias}
                    onChange={onChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title={"Apodo o nombre corto con el que se dirige al cliente en correo o comunicados"}>
                                    <HelpOutlineIcon />
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="RFC"
                    type="text"
                    variant="outlined"
                    required
                    name="rfc"
                    onInput={event => onlySimpleCharacters(event, 12)}
                    value={state.rfc}
                    onChange={onChange}
                />

            </>
        )
    }



    const renderDireccion = () => {
        const colonia = codigos.find(x => x.id == state.colonia) || { municipio: "" }

        return (
            <>
                <p className="mt-8">DIRECCIÓN</p>

                <TextField
                    required
                    label="Calle y Número"
                    type="text"
                    variant="outlined"
                    name="direccion"
                    value={state.direccion}
                    onInput={event => maxFieldLength(event, 150)}
                    onChange={onChange}
                />

                <div className="grid grid-cols-2 ">
                    <TextField
                        required
                        label="CP"
                        type="text"
                        variant="outlined"
                        name="cp"
                        value={state.cp}
                        onInput={event => onlyNumbers(event, 5)}
                        onChange={onChange}
                    />
                </div>

                <FormControl variant="outlined" size="medium" required>
                    <InputLabel id="colonia" required>Colonia</InputLabel>
                    <Select
                        required
                        labelId="colonia"
                        label="Colonia"
                        size="medium"
                        name="colonia"
                        value={state.colonia}
                        onChange={onChange}
                    >
                        <MenuItem value=""><em>Seleccione</em></MenuItem>
                        {codigos.map(codigo => <MenuItem key={codigo.id} value={codigo.id}>{codigo.asentamiento}</MenuItem>)}
                    </Select>
                </FormControl>

                <TextField
                    label="Municipio"
                    type="text"
                    variant="outlined"
                    disabled={true}
                    name="municipio"
                    value={colonia.municipio}
                />

            </>
        )
    }


    const renderLocalizacion = () => {
        return (
            <>
                <p className="mt-8">LOCALIZACIÓN</p>
                <TextField
                    label="Correo"
                    type="email"
                    variant="outlined"
                    name="correo"
                    onInput={event => maxFieldLength(event, 100)}
                    value={state.correo}
                    onChange={onChange}
                />

                <PhoneInput
                    className="block w-full"
                    country={'mx'}
                    name="celular"
                    specialLabel="Celular"
                    preferredCountries={["mx", "us"]}
                    disableDropdown={true}
                    value={state.celular}
                    onChange={onChangeCelular}
                    inputProps={{required: true}}
                />

            </>
        )
    }

    return render()

}

export default NuevoCliente