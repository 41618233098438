import React, { useState } from 'react'
import Toolbar from '../components/Toolbar'
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useLocation } from 'react-router-dom'

import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HomeIcon from '@material-ui/icons/Home';

const ImportarPolizasExcelResult = (props) => {

    const location = useLocation();



    const renderPoliza = (item) => {
        const { ok, message, poliza } = item

        const baxStyle = ok ? "bg-dark-300 rounded-md p-2 border-l-4 border-green" : "bg-dark-300 rounded-md p-2 border-l-4 border-red"

        const statusIcon = ok
            ? <CheckCircleOutlineIcon className="text-green text-md" />
            : <HighlightOffIcon className="text-red text-md" />



        let typeIcon = null;
        if (poliza.ramo.value.toLowerCase() === "salud") typeIcon = <FavoriteIcon />
        else if (poliza.ramo.value.toLowerCase() === "vida") typeIcon = <PersonIcon />
        else if (poliza.ramo.value.toLowerCase() === "auto") typeIcon = <DriveEtaIcon />
        else typeIcon = <HomeIcon />

        return (
            <div className={baxStyle}>
                <div className="grid gap-2">
                    <p className="text-sm">{typeIcon} No Poliza: {poliza.noPoliza.value}</p>
                    <p className="text-sm">Clave de agente: {poliza.claveAgente.value}</p>
                    {ok === false && <p className="font-thin text-sm text-red">{message}</p>}
                </div>
            </div>
        )
    }

    const render = () => {
        const state = location.state?.polizas || []
        return (
            <AdminTemplate loading={false}>
                <Toolbar title="Resultado de la importación" />
                <div className="main">
                    <div className="layout">
                        <div className="grid grid-cols-1 gap-2">
                            <p>Resultado de la importación</p>
                            {state.map(item => renderPoliza(item))}
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}
export default ImportarPolizasExcelResult