import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import config from '../config'
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke'
import { dateTimeFormat } from '../utils/DateUtils';
import Toolbar from '../components/Toolbar'
import FileUploader from '../components/FileUploader';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GrantContent from '../components/GrantContent';
import * as Permisos from '../utils/Permisos'
import DeleteIcon from '@mui/icons-material/Delete';

const Adjuntos = (props) => {

    /* states */
    const [loading, setLoading] = useState(true);
    const [deleteIndex, setDeleteIndex] = useState(null)

    const params = useParams()
    const entidad = props.entidad
    const entidadId = props.entidadId || params.entidadId
    const [adjuntos, setAdjuntos] = useState([])

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const response = await (await APIInvoke.invokeGET(`/adjuntos/${entidad}/${entidadId}`)).json()
                setAdjuntos(response.body)

            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false)
            }
        }
        init()
    }, [])

    const deleteAdjunto = async () => {
        try {
            setLoading(true)
            const deleteTextResponse = await (await APIInvoke.invokeDELETE(`/adjuntos/${deleteIndex}`)).json()
            if (deleteTextResponse.ok) {
                setAdjuntos(adjuntos.filter(x => x.id !== deleteIndex))
                setDeleteIndex(null)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onUpload = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const thisFiles = e.target.files[0]

            const body = new FormData();
            body.append('bucket', config.bucket.name);
            body.append('path', `polizas/${thisFiles.name}`);
            body.append('file', thisFiles)

            const request = {
                method: "POST",
                body
            }

            const uploading = await (await fetch(`${config.uploader.baseUrl}/storage/upload`, request)).json();

            if (uploading.ok !== true) {
                console.error(uploading.message)
                return
            }

            const field = {
                "clientes": "clienteId",
                "polizas": "polizaId",
                "recibos": "reciboId",
                "siniestros": "siniestroId"
            }

            const adjuntoRequest = {
                [field[entidad]]: entidadId,
                dominio: "",
                path: uploading.body.url,
                archivo: thisFiles.name
            }

            const adjuntoResponse = await (await APIInvoke.invokePOST(`/adjuntos`, adjuntoRequest)).json()

            if (adjuntoResponse.ok) {
                setAdjuntos([
                    adjuntoResponse.body,
                    ...adjuntos
                ])
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {
        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                <Toolbar title="Adjuntos" backUrl={`/polizas`} modal={props.modal} />

                <div className="main">
                    <div className="layout-compact">

                        <div className="grid grid-cols-1 gap-y-8 mb-8">
                            <GrantContent permisos={[Permisos.ADJUNTOS_W]}>
                                <section className="grid grid-cols-1">
                                    <FileUploader onUpload={onUpload}>
                                        <div>
                                            <p className="text-lg font-bold normal-case text-left">Carga archivo</p>
                                            <p className="text-sm normal-case text-left"><span className="underline">Da clic aquí </span>para cargar un anexo</p>
                                        </div>
                                    </FileUploader>
                                </section>
                            </GrantContent>


                            <section>

                                <div className="grid grid-cols-1 gap-2 lg:gap-4 bg-dark-400 p-2 lg:p-4 rounded-md">
                                    <p className="font-bold">Adjuntos</p>
                                    {adjuntos.map((adjunto, idx) => (
                                        <section key={idx} className="bg-dark-300 rounded-md p-2 lg:p-4 ">
                                            <div className="grid grid-cols-1 ">
                                                <div>
                                                    <a href={adjunto.path} target="_blank" rel="noreferrer" className="relative text-lg  no-underline text-white hover:text-yellow">
                                                        <AttachFileIcon className="text-inherit" fontSize="small" />
                                                        <span className="text-inherit text-sm">{adjunto.archivo}</span>
                                                    </a>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <p className="text-xs text-gray-800 ml-5"> {dateTimeFormat(adjunto.fecha)}</p>
                                                    <GrantContent permisos={[Permisos.ADJUNTOS_D]}>
                                                        <IconButton color="primary" className="flex items-center " size="small" onClick={e => setDeleteIndex(adjunto.id)}>
                                                            <DeleteIcon className="text-red" fontSize="medium" />
                                                        </IconButton>
                                                    </GrantContent>
                                                </div>
                                            </div>
                                        </section>
                                    ))}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                {
                    deleteIndex !== null && (
                        <Dialog
                            open={deleteIndex !== null}
                            onClose={e => setDeleteIndex(null)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                            <DialogContent>
                                <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar el registro?</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button className="mr-4" onClick={e => setDeleteIndex(null)}>CANCELAR</Button>
                                <Button className="bg-red-500 text-white" onClick={e => deleteAdjunto(e)} autoFocus>ACEPTAR</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </AdminTemplate >
        )
    }



    return render()

}

export default Adjuntos;