import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { hasText } from '../../utils/StringUtils';

const TextFilter = ({
    id,
    name,
    filter,
    placeholder,
    value,
    processFilter,
    updateState,
    required,
    lastCommitedState
}) => {

    const onBlur = (e) => {
        if (value !== lastCommitedState.value) {
            processFilter()
        }
    }

    const render = () => {

        return (
            <FormControl variant="outlined" size="small" required={required} fullWidth={true}>
                <InputLabel htmlFor={id}>{placeholder}</InputLabel>
                <OutlinedInput
                    id={id}
                    name={name}
                    type="text"
                    size="small"
                    label={placeholder}
                    value={value}
                    onBlur={onBlur}
                    onKeyPress={e => { if (e.key === "Enter") processFilter() }}
                    onChange={(e) => { updateState(id, e.target.value, false) }}
                    endAdornment={
                        hasText(value) && (
                            <InputAdornment position="end">
                                <IconButton
                                    className="hover:text-yellow"
                                    aria-label="limpiar"
                                    onClick={(e) => { updateState(id, "", true) }}
                                    edge="end"
                                >
                                    <CancelIcon className="hover:text-yellow" />
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                />
            </FormControl>
        )
    }

    return render()
}

TextFilter.processInput = (value) => value
TextFilter.getOptionLabel = (value) => value
TextFilter.getOptionId = (value) => value

export default TextFilter