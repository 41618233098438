import React from 'react'
import Page500 from '../pages/500'
import UserContext from '../state/UserContext';
import APIInvoke from '../utils/APIInvoke';

export default class ErrorBoundary extends React.Component {

    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error: error
        };
    }

    componentDidCatch(error, info) {
        const request = {
            body: JSON.stringify({
                context: this.contextType,
                location: window.location,
                error: error,
                info: info,
                componentError: this.state.error,
                componentStack: info.componentStack
            })
        }

        APIInvoke.invokePOST(`/app-errors`, request)
            .then(res => res.json())
            .then(res => console.log("error", res))

    }

    render() {
        if (this.state.hasError) {
            return (
                <Page500 />
            )
        }
        return this.props.children;
    }
}