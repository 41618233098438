import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ForumIcon from '@material-ui/icons/Forum';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { Link } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import React, { useEffect, useRef, useState } from 'react';
import UndoIcon from '@mui/icons-material/Undo';
import { useParams } from 'react-router';
import AdminTemplate from '../template/AdminTemplate';
import APIInvoke from '../utils/APIInvoke'
import { format, parse } from "../utils/DateUtils";
import { numberFormat } from '../utils/NumberUtils';
import Toolbar from '../components/Toolbar'
import DalalaDialog from '../components/DalalaDialog';
import Bitacora from './Bitacora';
import PagoRecibo from './PagoRecibo';
import Adjuntos from './Adjuntos';
import CerrarPoliza from './CerrarPoliza';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useHistory } from "react-router-dom";
import Correo from './Correo';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitle, IconButton, Tooltip } from '@mui/material';
import CerrarRecibo from './CerrarRecibo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress } from '@material-ui/core';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { getSiniestroStatusById } from '../utils/EnumUtils';
import GrantContent from '../components/GrantContent'
import * as Permisos from '../utils/Permisos'

const ConsultaPoliza = (props) => {
    const [loading, setLoading] = useState(true);
    const params = useParams()
    const polizaId = props.polizaId || params.polizaId
    const reciboId = props.reciboId || params.reciboId

    const history = useHistory()

    const [polizaData, setPolizaData] = useState({});

    const [openBitacoraDialog, setOpenBitacoraDialog] = useState({ show: false, id: null })
    const [openBitReciboDialog, setOpenBitReciboDialog] = useState({ show: false, id: null })

    const [openAdjuntosDialog, setOpenAdjuntosDialog] = useState({ show: false, id: null })
    const [openAdjuntosReciboDialog, setOpenAdjuntosRecDialog] = useState({ show: false, id: null })
    const [openCancelarReciboDialog, setOpenCancelarReciboDialog] = useState({ show: false, id: null })



    const [openPagoDialog, setOpenPagoDialog] = useState({ show: false, id: null })
    const [openCerrarDialog, setOpenCerrarDialog] = useState({ show: false, id: null })

    const [openCorreo, setOpenCorreoDialog] = useState({ show: false, id: null })
    const [openCorreos, setOpenCorreosDialog] = useState({ show: false, id: null })

    const [openRonovarDialog, setOpenRonovarDialog] = useState({ show: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })
    const [showOkDialog, setShowOkDialog] = useState({ show: false, message: "" })


    useEffect(async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/polizas/${polizaId}`)).json();
            setPolizaData(response.body);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }
    }, [])

    const onCompletedPagoRecibo = (recibo) => {
        const reciboId = recibo.id
        const reciboStatus = recibo.status

        const reciboEditado = polizaData.recibos.find((x) => x.id === reciboId)

        reciboEditado.status = reciboStatus

        setPolizaData({
            ...polizaData,
            recibos: [
                ...polizaData.recibos
            ]
        })

        setOpenPagoDialog({ show: false, id: null })
    }

    const handleEditRenov = () => {
        if (!polizaData.polizaIDRenovo) {
            if (polizaData.status === "vigente" || polizaData.status === "terminada" || polizaData.status === "cancelada") {
                history.push(`/polizas/nuevo`, { polizaId, action: "renovar" })
            } else {
                history.push(`/polizas/${polizaId}`)
            }
        } else {
            setOpenRonovarDialog({ show: true })
        }
    }

    const undoReceiptPay = async (reciboId, index) => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokePUT(`/recibos/${reciboId}/cancelarPago`)).json();
            if (response.ok === true) {
                polizaData.recibos[index] = response.body;
                setPolizaData(polizaData)
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false)
        }
    }

    const onPolizaClosed = (poliza) => {
        setPolizaData(poliza)
        setOpenCerrarDialog({ show: false, id: null })
    }

    const onReciboClosed = (reciboUpdate) => {
        setPolizaData({
            ...polizaData,
            recibos: polizaData.recibos.map(recibo => {
                return recibo.id === reciboUpdate.id ? reciboUpdate : recibo
            })
        })
        setOpenCancelarReciboDialog({ show: false, id: null })
    }

    const deletePoliza = async () => {
        try {
            setOpenDeleteDialog({ show: false })
            setLoading(true)
            const deleteResponse = await (await APIInvoke.invokeDELETE(`/polizas/${polizaId}`)).json()
            if (deleteResponse.ok) {
                history.push("/polizas")
                props.onCompleted(deleteResponse.body)
            } else {
                setShowOkDialog({ show: true, message: deleteResponse.message })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const render = () => {

        const direccion = polizaData?.direccion || {}
        const cliente = polizaData?.cliente || {}
        const moneda = polizaData?.moneda || {}
        const vehiculo = polizaData?.vehiculo || {}
        const texto = polizaData?.texto || {}
        const vendedor = polizaData?.vendedor || {}
        let icons;

        switch (polizaData?.ramo?.modelo) {
            case 1:
                icons = <DirectionsCarIcon fontSize="medium" />;
                break;
            case 3:
                icons = <FavoriteIcon fontSize="medium" />;
                break;
            case 5:
                icons = <PersonIcon fontSize="medium" />;
                break;
            case 7:
                icons = <HomeIcon fontSize="medium" />;
                break;
        }

        return (
            <AdminTemplate loading={loading} modal={props.modal}>
                {/* Bitacora de polizas */}
                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitacoraDialog.show}
                    handleClose={() => setOpenBitacoraDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"polizas"} entidadId={openBitacoraDialog.id} onCompleted={() => setOpenBitacoraDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Envio Correo */}
                <DalalaDialog
                    title={"Correo Electronico"}
                    open={openCorreo.show}
                    handleClose={() => setOpenCorreoDialog({ show: false, id: null })}
                >
                    <Correo modal={true} entidad={"poliza"} correoPara={polizaData?.cliente?.correos?.map(x => x.correo).join(", ") || ""} entidadId={polizaData.id} objectId={openCorreo.id} onCompleted={() => setOpenCorreoDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Envio Correo en Recibo */}
                <DalalaDialog
                    title={"Correo Electronico"}
                    open={openCorreos.show}
                    handleClose={() => setOpenCorreosDialog({ show: false, id: null })}
                >
                    <Correo modal={true} entidad={"recibo"} entidadId={openCorreos.id} objectId={openCorreos.id} onCompleted={() => setOpenCorreosDialog({ show: false, id: null })} />
                </DalalaDialog>


                {/* Bitacora de recibos */}
                <DalalaDialog
                    title={"Bitácora"}
                    open={openBitReciboDialog.show}
                    handleClose={() => setOpenBitReciboDialog({ show: false, id: null })}
                >
                    <Bitacora modal={true} entidad={"recibos"} entidadId={openBitReciboDialog.id} onCompleted={() => setOpenBitReciboDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Pagar recibos */}

                <DalalaDialog
                    title={"Pagar Recibo"}
                    open={openPagoDialog.show}
                    handleClose={() => setOpenPagoDialog({ show: false, id: null })}
                >
                    <PagoRecibo modal={true} reciboId={openPagoDialog.id} onCompleted={onCompletedPagoRecibo} />
                </DalalaDialog>

                {/* Adjuntos de polizas */}

                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosDialog.show}
                    handleClose={() => setOpenAdjuntosDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"polizas"} entidadId={openAdjuntosDialog.id} onCompleted={() => setOpenAdjuntosDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Adjuntos de recibos */}
                <DalalaDialog
                    title={"Adjuntos"}
                    open={openAdjuntosReciboDialog.show}
                    handleClose={() => setOpenAdjuntosRecDialog({ show: false, id: null })}
                >
                    <Adjuntos modal={true} entidad={"recibos"} entidadId={openAdjuntosReciboDialog.id} onCompleted={() => setOpenAdjuntosRecDialog({ show: false, id: null })} />
                </DalalaDialog>

                {/* Cerrar Poliza */}
                <DalalaDialog
                    title={"Cerrar Póliza"}
                    open={openCerrarDialog.show}
                    handleClose={() => setOpenCerrarDialog({ show: false, id: null })}
                >
                    <CerrarPoliza modal={true} entidad={"polizas"} polizaId={openCerrarDialog.id} onCompleted={onPolizaClosed} />
                </DalalaDialog>

                <DalalaDialog
                    title={openCancelarReciboDialog.title}
                    open={openCancelarReciboDialog.show}
                    handleClose={() => setOpenCancelarReciboDialog({ show: false, id: null })}
                >
                    <CerrarRecibo modal={true} poliza={polizaData} entidad={"recibo"} reciboId={openCancelarReciboDialog.id} onCompleted={onReciboClosed} />
                </DalalaDialog>

                <Dialog
                    open={openRonovarDialog.show}
                    onClose={e => setOpenRonovarDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">Esta póliza ya ha sido renovada, cancele o termine la otra póliza antes de poder crear otra renovación, <Link to={`/poliza/${polizaData.polizaIDRenovo}`} className="text-yellow no-underline">ver póliza</Link></DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn btn-yellow p-2 text-sm" onClick={e => setOpenRonovarDialog({ show: false })} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showOkDialog.show}
                    onClose={e => setShowOkDialog({ show: false, message: "" })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">{showOkDialog.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn btn-yellow p-2 text-sm" onClick={e => setShowOkDialog({ show: false, message: "" })} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openDeleteDialog.show}
                    onClose={e => setOpenDeleteDialog({ show: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la póliza?, si procede perderá toda la información de la póliza, botácora y adjuntos.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                        <Button className="bg-red-500 text-white" onClick={deletePoliza} autoFocus>ACEPTAR</Button>
                    </DialogActions>
                </Dialog>

                <Toolbar title="Detalle de Póliza" backUrl={`/polizas`} modal={props.modal}>
                    {polizaData && (
                        <div className="layout">
                            <div className="flex justify-end items-center">
                                <div className="flex items-center gap-2">
                                    <GrantContent permisos={[Permisos.POLIZAS_W]}>
                                        {polizaData.status !== "terminada" && polizaData.status !== "cancelada" && (
                                            <Tooltip title="Cerrar póliza" >
                                                <IconButton className="btn btn-yellow h-full" onClick={e => setOpenCerrarDialog({ show: true, id: polizaData.id })}>
                                                    <ClearIcon className="text-dark-600" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </GrantContent>
                                    <GrantContent permisos={[Permisos.COMUNICADOS_ENVIAR]}>
                                        <Tooltip title="Enviar comunicado" >
                                            <IconButton className="btn btn-yellow h-full" onClick={e => setOpenCorreoDialog({ show: true, id: polizaData.id })}>
                                                <MailIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    </GrantContent>
                                    <GrantContent permisos={[Permisos.POLIZAS_W]}>
                                        <Tooltip title="Renovar póliza" >
                                            <IconButton className="btn btn-yellow h-full" onClick={handleEditRenov}>
                                                <AutorenewIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    </GrantContent>
                                    <GrantContent permisos={[Permisos.POLIZAS_W]}>
                                        <Tooltip title="Editar póliza" >
                                            <IconButton className="btn btn-yellow h-full" onClick={e => history.push(`/polizas/${polizaId}`)}>
                                                <EditIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    </GrantContent>
                                    <GrantContent permisos={[Permisos.ADJUNTOS_R]}>
                                        <Tooltip title="Adjuntos" >
                                            <IconButton className="btn btn-yellow h-full" onClick={e => setOpenAdjuntosDialog({ show: true, id: polizaData.id })}>
                                                <AttachmentIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    </GrantContent>
                                    <GrantContent permisos={[Permisos.BITACORA_R]}>
                                        <Tooltip title="Bitácora" >
                                            <IconButton className="btn btn-yellow h-full" onClick={e => setOpenBitacoraDialog({ show: true, id: polizaData.id })}>
                                                <ForumIcon className="text-dark-600" />
                                            </IconButton>
                                        </Tooltip>
                                    </GrantContent>
                                    <GrantContent permisos={[Permisos.POLIZAS_D]}>
                                        {polizaData.status !== "eliminada" && (
                                            <Tooltip title="Eliminar" >
                                                <IconButton className="btn btn-yellow h-full" onClick={e => setOpenDeleteDialog({ show: true, id: polizaData.id })}>
                                                    <DeleteIcon className="text-dark-600" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </GrantContent>
                                </div>
                            </div>
                        </div>
                    )}
                </Toolbar >

                <div className="main">
                    <div className="layout-compact">
                        {polizaData && (
                            <>
                                <div className="grid grid-cols-1 gap-4 mb-8">
                                    {polizaData.esRobot && (
                                        <div className="flex gap-4 bg-yellow p-4 rounded-md mb-8">
                                            <WarningRoundedIcon className='text-dark-600' />
                                            <p className='flex-1 text-dark-600 text-sm'>Póliza creada automáticamente con la tecnología de sincronización dalala <Link to={"#"} className="text-dark-600" onClick={e => setPolizaData({
                                                ...polizaData,
                                                verInfo: true
                                            })}>Más información</Link> </p>
                                        </div>
                                    )}

                                    {polizaData.polizaIDRenovo && (
                                        <div className="text-right">
                                            <Link to={`/poliza/${polizaData.polizaIDRenovo}`} className="btn-yellow font-bold w-32 no-underline" ><VisibilityIcon fontSize="medium" /> Ver renovación</Link>
                                        </div>
                                    )}

                                    <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                        <div className="flex flex-row content-between justify-between">
                                            <p className="flex items-center text-gray-400 text-base font-semibold flex-1">
                                                <InsertDriveFileIcon fontSize="large" className="text-gray-400 mr-2" />
                                                <span>DATOS GENERALES</span>
                                            </p>
                                            <img src={polizaData?.agenteClave?.aseguradora?.logoBco} className="inline-block max-h-9 h-9 " style={{ maxWidth: "7rem" }} />
                                        </div>

                                        <div className="rounded-md bg-dark-300 p-4 grid grid-cols-1 gap-4">
                                            <div className="flex justify-between">
                                                <div className="flex items-center" >
                                                    <p className={polizaData.status === "eliminada" ? "text-red text-sm font-semibold uppercase" : "text-green-500 text-sm font-semibold uppercase"}>{polizaData.status}</p>
                                                </div>
                                                <div className="bg-dark-600 capitalize rounded-md flex items-center px-1 py-2" >
                                                    <p className="pr-1 ml-2 flex items-center text-sm"> {polizaData?.ramo?.ramo}
                                                        <span className="pl-2 flex items-center px-2">{icons}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">No. de Póliza</p>
                                                <p className="text-white text-sm capitalize">{polizaData.noPoliza}</p>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">Concepto</p>
                                                <p className="text-white text-sm capitalize">{polizaData.concepto}</p>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">Contratante</p>
                                                <p className="text-white text-sm capitalize items-center flex">
                                                    <span className="text-white mr-2">{cliente.nombres} {cliente.apPat} {cliente.apMat}</span>
                                                    <Link to={`/clientes/${cliente.id}`}>
                                                        <OpenInNewIcon fontSize="small" />
                                                    </Link>
                                                </p>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">Dirección</p>
                                                <p className="text-white text-sm capitalize">{direccion.direccion}</p>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">RFC</p>
                                                <p className="text-white text-sm capitalize">{cliente.rfc}</p>
                                            </div>
                                            <div className="grid ">
                                                <p className="text-yellow text-sm">Vendedor</p>
                                                <p className="text-white text-sm capitalize">
                                                    <span className="text-white mr-2">{vendedor.nombres} {vendedor.apPat} {vendedor.apMat}</span>
                                                    {vendedor.id && (
                                                        <Link to={`/vendedores/${vendedor.id}`}>
                                                            <OpenInNewIcon fontSize="small" />
                                                        </Link>
                                                    )}
                                                </p>
                                            </div>

                                            <div className="grid ">
                                                <p className="text-yellow text-sm">Método de pago</p>
                                                <p className="text-white text-sm capitalize">
                                                    <span className="text-white mr-2">{polizaData.conductoDescripcion}</span>
                                                </p>
                                            </div>


                                            {cliente.esRobot && (
                                                <div className="flex gap-4 bg-yellow p-4 rounded-md">
                                                    <WarningRoundedIcon className='text-dark-600' />
                                                    <p className='flex-1 text-dark-600 text-sm'>Cliente creada automáticamente con la tecnología de sincronización dalala <Link to={"#"} className="text-dark-600" onClick={e => setPolizaData({
                                                        ...polizaData,
                                                        verInfo: true
                                                    })}>Más información</Link> </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                        <p className="flex items-center text-gray-400 text-base font-semibold">
                                            <DateRangeIcon fontSize="large" className="text-gray-400 mr-2" />
                                            <span>VIGENCIA</span>
                                        </p>
                                        <div className="rounded-md bg-dark-300 p-4">
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-yellow text-sm">Vigencia</p>
                                                    <p className="text-white text-sm capitalize"> {format(parse(polizaData.fechaVigenciaDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(polizaData.fechaVigenciaA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                </div>

                                            </div>
                                            <div className="grid mt-4">
                                                <p className="text-yellow text-sm">Forma de Pago</p>
                                                <p className="text-white text-sm capitalize">{polizaData.formaPago}</p>
                                            </div>
                                            <div className="grid mt-4">
                                                <p className="text-yellow text-sm">Moneda</p>
                                                <p className="text-white text-sm capitalize">{moneda.moneda} {`(${moneda.abreviacion})`}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {polizaData?.vehiculo && (
                                        <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                            <p className="flex items-center text-gray-400 text-base font-semibold">
                                                <DirectionsCarIcon fontSize="large" className="text-gray-400 mr-2" />
                                                <span>VEHICULO</span>
                                            </p>
                                            <div className="rounded-md bg-dark-300 p-4">
                                                <div className="grid grid-cols-1 gap-4">
                                                    <div>
                                                        <p className="text-yellow text-sm">Descripción</p>
                                                        <p className="text-white text-sm capitalize">{vehiculo.descripcion}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-yellow text-sm">No. de Serie</p>
                                                        <p className="text-white text-sm capitalize">{vehiculo.noSerie}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-yellow text-sm">Modelo</p>
                                                        <p className="text-white text-sm capitalize">{vehiculo.modelo}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-yellow text-sm">Placas</p>
                                                        <p className="text-white text-sm capitalize">{vehiculo.placas}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                    {polizaData.asegurados && polizaData.asegurados.length > 0 && (
                                        <div>
                                            <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                                <p className="flex items-center text-gray-400 text-base font-semibold">
                                                    <GroupIcon fontSize="large" className="text-gray-400 mr-2" />
                                                    <span>ASEGURADOS</span>
                                                </p>
                                                {polizaData.asegurados.map((asegurados, idx) => (
                                                    <article key={idx} className="rounded-md bg-dark-300 p-4">
                                                        <div className="grid gap-2">
                                                            <div className="flex justify-between">
                                                                <div>
                                                                    <p className="text-yellow text-sm">Parentesco</p>
                                                                    <p className="text-white text-sm capitalize">{asegurados.parentesco}</p>
                                                                </div>
                                                            </div>
                                                            <div className="grid mt-4">
                                                                <p className="text-yellow text-sm">Nombre</p>
                                                                <p className="text-white text-sm capitalize">{asegurados.nombres} {asegurados.apPat} {asegurados.apMat}</p>
                                                            </div>
                                                            <div className="grid mt-4">
                                                                <p className="text-yellow text-sm">Nacimiento</p>
                                                                <p className="text-white text-sm capitalize">{asegurados.fecNacimiento}</p>
                                                            </div>
                                                            <div className="grid mt-4">
                                                                <p className="text-yellow text-sm">RFC</p>
                                                                <p className="text-white text-sm capitalize">{asegurados.rfc}</p>
                                                            </div>
                                                        </div>
                                                    </article>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {polizaData?.texto && (
                                        <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                            <p className="flex items-center text-gray-400 text-base font-semibold">
                                                <HomeIcon fontSize="large" className="text-gray-400 mr-2" />
                                                <span>BIEN ASEGURADO</span>
                                            </p>
                                            <div className="bg-dark-300 rounded-md p-4">
                                                <div className="flex justify-between">
                                                    <div>
                                                        <p className="text-yellow text-sm">Detalle</p>
                                                        {/* <p dangerouslySetInnerHTML={{ __html: texto.replace("\n", "<br/>") }} className="text-white text-sm mt-2"></p> */}
                                                        <p className="text-white text-sm mt-2 "><pre className="whitespace-pre-wrap text-justify">{texto}</pre></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}



                                    <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4" >
                                        <p className="flex items-center text-gray-400 text-base font-semibold">
                                            <AttachMoneyIcon fontSize="large" className="text-gray-400 mr-2" />
                                            <span>PRIMAS</span>
                                        </p>
                                        <div className="rounded-md bg-dark-300 p-4">
                                            <div className="grid gap-2">
                                                <div className="grid grid-cols-3">
                                                    <div className="">
                                                        <p className="text-yellow text-sm mb-2">Prima Neta</p>
                                                        <p className="text-yellow text-sm mb-2">Derecho</p>
                                                        <p className="text-yellow text-sm mb-2">Recargo</p>
                                                        <p className="text-yellow text-sm mb-2">I.V.A.</p>
                                                        <p className="text-yellow text-sm">Total</p>
                                                    </div>
                                                    <div className="grid justify-items-end col-span-2 md:col-span-1">
                                                        <p className="text-sm mb-2">{numberFormat(polizaData.prima)} {moneda.abreviacion}</p>
                                                        <p className="text-sm mb-2">{numberFormat(polizaData.derecho)} {moneda.abreviacion}</p>
                                                        <p className="text-sm mb-2">{numberFormat(polizaData.recargo)} {moneda.abreviacion}</p>
                                                        <p className="text-sm mb-2">{numberFormat(polizaData.iva)} {moneda.abreviacion}</p>
                                                        <p className="text-sm">{numberFormat(polizaData.total)} {moneda.abreviacion}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4" >
                                        <p className="flex items-center text-gray-400 text-base font-semibold">
                                            <PriceChangeIcon fontSize="large" className="text-gray-400 mr-2" />
                                            <span>COMISIONES</span>
                                        </p>
                                        <div className="rounded-md bg-dark-300 p-4">
                                            <div className="grid gap-2">
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <p className="text-yellow text-sm mb-1">Porcentaje</p>
                                                        <p className="text-sm text-white">{polizaData.porcentajeComision}%</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-yellow text-sm mb-1">Comisiones</p>
                                                        <p className="text-sm text-white">{numberFormat(polizaData.comisiones)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {polizaData.recibos && (
                                        <div className="bg-dark-400 rounded-md grid grid-cols-1 gap-2 p-2 lg:gap-4 lg:p-4">
                                            <p className="flex items-center text-gray-400 text-base font-semibold">
                                                <MonetizationOnIcon fontSize="large" className="text-gray-400 mr-2" />
                                                <span>RECIBOS</span>
                                            </p>
                                            {polizaData.recibos && polizaData.recibos.map((recibo, idx) => {
                                                let className = '';
                                                if (recibo.status === "pagado") {
                                                    className = "font-bold uppercase text-blue-200"
                                                } else if (recibo.status === "pendiente") {
                                                    className = "font-bold uppercase text-green-200"
                                                } else if (recibo.status === "cancelado") {
                                                    className = "font-bold uppercase text-red"
                                                } else {
                                                    className = "font-bold uppercase text-red-100"
                                                }

                                                return (
                                                    <div key={idx} id={`recibo-${recibo.id}`} >
                                                        <div className="rounded-md bg-dark-300 p-4">
                                                            <div className="grid gap-2">
                                                                <div className="flex justify-between">
                                                                    <p className={className}>{recibo.status}</p>
                                                                </div>

                                                                {recibo.status === "pagado" && (
                                                                    <>
                                                                        <div className="grid grid-cols-5">
                                                                            <div className="grid gap-2 col-span-2">
                                                                                <p className="text-yellow text-sm">Recibo</p>
                                                                                <p className="text-yellow text-sm">Vigencia</p>
                                                                                <p className="text-yellow text-sm">Vencimiento</p>
                                                                                <p className="text-yellow text-sm">Total</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3">
                                                                                <p className="text-sm" >{recibo.serie}/{recibo.totalSerie}</p>
                                                                                <p className="text-sm"> {format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.total)}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="border-b-0 border bg-white mt-2 mb-2"></div>

                                                                        <div className="grid grid-cols-5 ">
                                                                            <div className="grid gap-2 col-span-2">
                                                                                <p className="text-yellow text-sm">Fecha de Pago</p>
                                                                                <p className="text-yellow text-sm">Folio</p>
                                                                                <p className="text-yellow text-sm">Tipo de Cambio</p>
                                                                                <p className="text-yellow text-sm">Observaciones</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3">
                                                                                <p className="text-sm">{recibo.fecPago ? format(parse(recibo.fecPago, "yyyy-MM-dd"), "dd MMM yyyy") : null}</p>
                                                                                <p className="text-sm">{recibo.folioPago}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.tipoCambio)}</p>
                                                                                <p className="text-sm">-{recibo.observacion}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center gap-2">
                                                                            <GrantContent permisos={[Permisos.POLIZAS_W]}>
                                                                                <Tooltip title="Revertir pago">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => undoReceiptPay(recibo.id, idx)}>
                                                                                        <UndoIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>

                                                                            <GrantContent permisos={[Permisos.BITACORA_R]}>
                                                                                <Tooltip title="Revertir pago">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenBitReciboDialog({ show: true, id: recibo.id })}>
                                                                                        <ForumIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>

                                                                            <GrantContent permisos={[Permisos.BITACORA_R]}>
                                                                                <Tooltip title="Revertir pago">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenAdjuntosRecDialog({ show: true, id: recibo.id })}>
                                                                                        <AttachmentIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {recibo.status === "pendiente" && (
                                                                    <>
                                                                        <div className="grid grid-cols-5">
                                                                            <div className="grid gap-2 col-span-2">
                                                                                <p className="text-yellow text-sm">Recibo</p>
                                                                                <p className="text-yellow text-sm">Vigencia</p>
                                                                                <p className="text-yellow text-sm">Vencimiento</p>
                                                                                <p className="text-yellow text-sm">Total</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3">
                                                                                <p className="text-sm">{recibo.serie}/{recibo.totalSerie}</p>
                                                                                <p className="text-sm"> {format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.total)}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center gap-2">
                                                                            <GrantContent permisos={[Permisos.BITACORA_W]}>
                                                                                <Tooltip title="Pagar recibo">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenPagoDialog({ show: true, id: recibo.id })}>
                                                                                        <AttachMoneyIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.COMUNICADOS_ENVIAR]}>
                                                                                <Tooltip title="Enviar comunicado">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenCorreosDialog({ show: true, id: recibo.id })}>
                                                                                        <MailIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.BITACORA_R]}>
                                                                                <Tooltip title="Bitácora">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenBitReciboDialog({ show: true, id: recibo.id })}>
                                                                                        <ForumIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.ADJUNTOS_R]}>
                                                                                <Tooltip title="Adjuntos">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenAdjuntosRecDialog({ show: true, id: recibo.id })}>
                                                                                        <AttachmentIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.POLIZAS_W]}>
                                                                                <Tooltip title="Cancelar recibo">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenCancelarReciboDialog({ show: true, id: recibo.id, title: "Cerrar Recibo" })}>
                                                                                        <ClearIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {recibo.status === "vencido" && (
                                                                    <>
                                                                        <div className="border-b-0 border bg-white mt-2 mb-2"></div>

                                                                        <div className="grid grid-cols-5">
                                                                            <div className="grid gap-2 col-span-2">
                                                                                <p className="text-yellow text-sm">Recibo</p>
                                                                                <p className="text-yellow text-sm">Vigencia</p>
                                                                                <p className="text-yellow text-sm">Vencimiento</p>
                                                                                <p className="text-yellow text-sm">Total</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3">
                                                                                <p className="text-sm">{recibo.serie}/{recibo.totalSerie}</p>
                                                                                <p className="text-sm"> {format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.total)}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center gap-2">
                                                                            <GrantContent permisos={[Permisos.POLIZAS_W]}>
                                                                                <Tooltip title="Pagar recibo">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenPagoDialog({ show: true, id: recibo.id })}>
                                                                                        <AttachMoneyIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.COMUNICADOS_ENVIAR]}>
                                                                                <Tooltip title="Enviar comunicado">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenCorreosDialog({ show: true, id: recibo.id })}>
                                                                                        <MailIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.BITACORA_R]}>
                                                                                <Tooltip title="Bitácora">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenBitReciboDialog({ show: true, id: recibo.id })}>
                                                                                        <ForumIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.ADJUNTOS_R]}>
                                                                                <Tooltip title="Adjuntos">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenAdjuntosRecDialog({ show: true, id: recibo.id })}>
                                                                                        <AttachmentIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.POLIZAS_W]}>
                                                                                <Tooltip title="Cancelar recibo">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenCancelarReciboDialog({ show: true, id: recibo.id, title: "Cerrar Recibo" })}>
                                                                                        <ClearIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {recibo.status === "cancelado" && (
                                                                    <>
                                                                        <div className="grid grid-cols-5">
                                                                            <div className="grid gap-2 col-span-2">
                                                                                <p className="text-yellow text-sm">Recibo</p>
                                                                                <p className="text-yellow text-sm">Vigencia</p>
                                                                                <p className="text-yellow text-sm">Vencimiento</p>
                                                                                <p className="text-yellow text-sm">Total</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3">
                                                                                <p className="text-sm">{recibo.serie}/{recibo.totalSerie}</p>
                                                                                <p className="text-sm"> {format(parse(recibo.fecVigDe, "yyyy-MM-dd"), "dd MMM yyyy")} - {format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{format(parse(recibo.fecVence, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm">{numberFormat(recibo.total)}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="border-b-0 border bg-white mt-2 mb-2"></div>

                                                                        <div className="grid grid-cols-5 ">
                                                                            <div className="grid gap-2 col-span-2">
                                                                                <p className="text-yellow text-sm">Fecha</p>
                                                                                <p className="text-yellow text-sm">Razón</p>
                                                                                <p className="text-yellow text-sm">Observaciones</p>
                                                                            </div>
                                                                            <div className="grid gap-2 col-start-3 col-span-3">
                                                                                <p className="text-sm">{format(parse(recibo.fecVigA, "yyyy-MM-dd"), "dd MMM yyyy")}</p>
                                                                                <p className="text-sm"> - </p>
                                                                                <p className="text-sm"> {recibo.observacion} </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center gap-2">
                                                                            <GrantContent permisos={[Permisos.COMUNICADOS_ENVIAR]}>
                                                                                <Tooltip title="Enviar comunicados">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenCorreosDialog({ show: true, id: recibo.id })}>
                                                                                        <MailIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.BITACORA_R]}>
                                                                                <Tooltip title="Bitácora">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenBitReciboDialog({ show: true, id: recibo.id })}>
                                                                                        <ForumIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.ADJUNTOS_R]}>
                                                                                <Tooltip title="Adjuntos">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenAdjuntosRecDialog({ show: true, id: recibo.id })}>
                                                                                        <AttachmentIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                            <GrantContent permisos={[Permisos.POLIZAS_W]}>
                                                                                <Tooltip title="Reactivar Recibo">
                                                                                    <IconButton className="btn btn-yellow h-full" onClick={e => setOpenCancelarReciboDialog({ show: true, id: recibo.id, title: "Reactivar Recibo" })}>
                                                                                        <UndoIcon className="text-dark-600" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </GrantContent>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}

                                    <GrantContent permisos={[Permisos.SINIESTROS_R]}>
                                        <SiniestrosSection polizaId={polizaId} />
                                    </GrantContent>
                                    {polizaData.verInfo === true && (
                                        <Dialog
                                            open={polizaData.verInfo === true}
                                            onClose={e => setPolizaData({
                                                ...polizaData,
                                                verInfo: false
                                            })}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent style={{ width: "330px", maxWidth: "100%" }}>
                                                <DialogContentText className="text-gray-400" id="alert-dialog-description">
                                                    <p>
                                                        La póliza o cliente se integró desde la <span className="text-yellow font-bold">sincronización automática</span>, este proceso utiliza el PDF original de la póliza para obtener los datos de la póliza o el cliente, aunque este proceso se perfecciona día con día es <span className="text-yellow font-bold">indispensable</span> sea validado por el agente de forma puntual para así garantizar la <span className="font-bold">confiabilidad</span> de la información, por esto recomendamos revisar los siguientes puntos:
                                                    </p>
                                                    <p className="pl-4">
                                                        <ul className="py-6 list-disc">
                                                            <li>Datos del Cliente</li>
                                                            <li>Primas de la póliza</li>
                                                            <li>Vigencias de la póliza</li>
                                                            <li>No. de Recibos</li>
                                                            <li>Primas de Recibos</li>
                                                            <li>Vigencias de Recibos</li>
                                                        </ul>
                                                    </p>
                                                    <p>
                                                        También te recomendamos completar el correo y teléfono de tu cliente para los procesos de comunicación operativos
                                                    </p>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions className="justify-center pb-6">
                                                <Button className="btn-yellow font-bold w-32" onClick={e => setPolizaData({
                                                    ...polizaData,
                                                    verInfo: false
                                                })}>ACEPTAR</Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </AdminTemplate >
        )
    }

    return render()
}


const SiniestrosSection = ({ polizaId }) => {

    const [loading, setLoading] = useState(true)
    const [state, setState] = useState([])

    useEffect(() => {
        fetchSiniestros()
    }, [])

    const fetchSiniestros = async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/siniestros?polizaId=${polizaId}`)).json()
            setState(response.body.items.map(siniestro => {
                return {
                    ...siniestro,
                    fechaEstimadaResolucion: parse(siniestro.fechaEstimadaResolucion, "yyyy-MM-dd"),
                    fechaSiniestro: parse(siniestro.fechaSiniestro, "yyyy-MM-dd"),
                }
            }))
        } catch (error) {
            console.error("Error al cargar la siniestro", error)
        } finally {
            setLoading(false)
        }
    }


    const render = () => {
        return (
            <div className="grid gap-2 bg-dark-400 p-4 rounded-md">
                <p className="text-gray-400 text-base font-semibold"><NewReleasesIcon fontSize="large" /> SINIESTROS</p>
                <div className="grid gap-2 bg-dark-500 rounded-md p-4">
                    {loading && (<CircularProgress className="w-6 h-6" />)}
                    {!loading && state.length === 0 && (
                        <div className="grid gap-4 bg-dark-500 rounded-md p-4">
                            <p className="text-xs"><em>Póliza sin siniestros</em></p>
                        </div>
                    )}

                    {(!loading && state.length > 0) && state.map(siniestro => {
                        const status = getSiniestroStatusById(siniestro.status)
                        return (
                            <Link key={siniestro.id} to={{ pathname: `/siniestro/${siniestro.id}`, state: { backUrl: `/poliza/${polizaId}` } }} className="no-underline inline-block" >
                                <span className="text-white text-sm mr-2">{`${siniestro.noSiniestro} - `} <span className={status.color}>{status.name}</span> {` (${format(siniestro.fechaSiniestro, "dd/MM/yyyy")})`}</span><OpenInNewIcon fontSize="small" />
                            </Link>
                        )
                    })}
                </div>
            </div>
        )
    }

    return render()
}

export default ConsultaPoliza