import React, { useState, useEffect } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import APIInvoke from '../utils/APIInvoke'
import Toolbar from '../components/Toolbar'
import { format, parse } from "../utils/DateUtils"
import { Link } from 'react-router-dom'

const MiPlan = (props) => {

    const [state, setState] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/suscripciones/activa`)).json()
            setState(response.body)
        } catch (error) {
            console.log("Error al cargar la suscripción activa", error)
        } finally {
            setLoading(false)
        }
    }, [])

    const render = () => {

        if (!state) return <AdminTemplate loading={true} />

        const periodicidad = state.plan.periodicidad
        const vigenciaA = parse(state.vigenciaA, "yyyy-MM-dd HH:mm:ss")

        return (
            <AdminTemplate loading={loading} >
                <Toolbar title="Mi plan">
                </Toolbar>

                <div className="main">
                    <div className="layout-compact">
                        <div className="grid grid-cols-1 gap-8">
                            <div className="bg-dark-400 rounded-md p-4">
                                <div className="grid grid-cols-1 gap-4">
                                    <p className="text-yellow">Suscripción actual</p>
                                    <p className="">{state.plan.nombre}</p>
                                    <p className="text-yellow">{`Tu suscripción ${periodicidad == 'prueba' ? "termina el " : "se ronovará el "}`}</p>
                                    <p className="">{`${format(vigenciaA, "dd/MM/yyyy")}`}</p>

                                </div>
                            </div>
                            {
                                state.plan.periodicidad != "prueba"
                                    ? (
                                        <p className="text-center">
                                            <a className="no-underline text-sm" href={state.portalUrl}>Cancelar mi suscripción</a>
                                        </p>
                                    )
                                    : (
                                        <p className="text-center">
                                            <Link to={{ pathname: `/planes`, state: { backUrl: `/configuracion/mi-plan` } }} className="block text-center no-underline btn btn-yellow ">Contratar un plan</Link>
                                        </p>
                                    )
                            }

                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()
}

export default MiPlan