import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom";
import AdminTemplate from '../../template/AdminTemplate';
import Toolbar from '../../components/Toolbar';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, ListItemIcon, MenuItem, MenuList, TextField, Tooltip } from '@mui/material';
import { maxFieldLength, onlyLetter } from '../../utils/FormUtils';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import toast from 'react-hot-toast';
import APIInvoke from '../../utils/APIInvoke';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { hasValue } from '../../utils/StringUtils';
import ExtensionIcon from '@mui/icons-material/Extension';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TuneIcon from '@mui/icons-material/Tune';


export default function NuevoUsuario(props) {

    const history = useHistory()
    const { usuarioId } = useParams();
    const usuario = props.usuario

    const [loading, setLoading] = useState(false)
    const [roles, setRoles] = useState(null)
    const [state, setState] = useState({
        nombres: "",
        apPat: "",
        apMat: "",
        celular: "",
        correo: "",
        roles: {
            "ROLE_ADMIN": false,
            "ROLE_CLIENTES": false,
            "ROLE_CLIENTES_R": false,
            "ROLE_CLIENTES_W": false,
            "ROLE_CLIENTES_D": false,
            "ROLE_PROSPECTOS": false,
            "ROLE_PROSPECTOS_R": false,
            "ROLE_PROSPECTOS_W": false,
            "ROLE_PROSPECTOS_D": false,
            "ROLE_VENDEDORES": false,
            "ROLE_VENDEDORES_R": false,
            "ROLE_VENDEDORES_W": false,
            "ROLE_VENDEDORES_D": false,
            "ROLE_POLIZAS": false,
            "ROLE_POLIZAS_R": false,
            "ROLE_POLIZAS_W": false,
            "ROLE_POLIZAS_D": false,
            "ROLE_POLIZAS_RENOVACIONES": false,
            "ROLE_POLIZAS_IMPORT": false,
            "ROLE_POLIZAS_COBRANZA": false,
            "ROLE_SINIESTROS": false,
            "ROLE_SINIESTROS_R": false,
            "ROLE_SINIESTROS_W": false,
            "ROLE_SINIESTROS_D": false,
            "ROLE_COTIZACIONES": false,
            "ROLE_COTIZACIONES_D": false,
            "ROLE_COTIZACIONES_W": false,
            "ROLE_COTIZACIONES_R": false,
            "ROLE_COTIZACIONES_TEMP": false,
            "ROLE_CALENDARIO": false,
            "ROLE_TAREAS": false,
            "ROLE_REPORTES": false,
            "ROLE_REPORTES_POLIZAS": false,
            "ROLE_REPORTES_RECIBOS": false,
            "ROLE_REPORTES_CLIENTES": false,
            "ROLE_REPORTES_COMISIONES": false,
            "ROLE_REPORTES_AUTOMATIZACIONES": false,
            "ROLE_REPORTE_SEMANAL": false,
            "ROLE_CLAVES": false,
            "ROLE_CLAVES_R": false,
            "ROLE_CLAVES_D": false,
            "ROLE_CLAVES_W": false,
            "ROLE_USUARIOS": false,
            "ROLE_FACTURACION": false,
            "ROLE_INTEGRACIONES": false,
            "ROLE_METAS": false,
            "ROLE_PROSPECCION": false,
            "ROLE_PROSPECCION_D": false,
            "ROLE_PROSPECCION_W": false,
            "ROLE_PROSPECCION_R": false
        }
    })

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            const rawResponse = await (await APIInvoke.invokeGET(`/seguridad/roles`)).json()

            if (hasValue(usuarioId)) {
                const rawUsuarioResponse = await (await APIInvoke.invokeGET(`/usuarios/${usuarioId}`)).json()
                const roles = state.roles
                rawUsuarioResponse.body.roles.forEach(rol => {
                    roles[rol] = true
                })

                setState({
                    id: rawUsuarioResponse.body.id,
                    nombres: rawUsuarioResponse.body.nombres,
                    apPat: rawUsuarioResponse.body.apPat,
                    apMat: rawUsuarioResponse.body.apMat,
                    status: rawUsuarioResponse.body.status,
                    celular: rawUsuarioResponse.body.celular,
                    correo: rawUsuarioResponse.body.correo,
                    roles: roles
                })
            }

            setRoles(rawResponse.body)
        } catch (error) {
            toast.error("Error al cargar la página")
            console.error("Error al cargar la página", error)
        } finally {
            setLoading(false)
        }
    }

    const save = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)

            const request = {
                id: state.id,
                nombres: state.nombres,
                apPat: state.apPat,
                apMat: state.apMat,
                status: state.status,
                celular: state.celular,
                correo: state.correo,
                roles: Object.keys(state.roles).filter(role => state.roles[role] === true)
            }

            const rawResponse = state.id
                ? await ((await APIInvoke.invokePUT(`/usuarios`, request)).json())
                : await ((await APIInvoke.invokePOST(`/usuarios`, request)).json())

            if (!rawResponse.ok) {
                toast.error(rawResponse.message)
                return
            }

            setState({
                ...state,
                id: rawResponse.body.id
            })

            toast.success("Usuario guardado correctamente")
            history.push(`/configuracion/usuarios`)
        } catch (error) {
            console.error(error)
            toast.error("Error al guardar el usuario")
        } finally {
            setLoading(false)
        }
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const onChangeCelular = (celular) => {
        setState({
            ...state,
            celular: celular
        })
    }

    const findRoleByName = (roleName) => {
        return roles.find(rol => rol.role === roleName)
    }

    const onChangeRole = (event) => {
        setState({
            ...state,
            roles: {
                ...state.roles,
                [event.target.name]: event.target.checked
            }
        })
    }

    const renderRole = (roleName) => {
        const role = findRoleByName(roleName)
        return (
            <div>
                <div className="flex items-center justify-between conte">
                    <FormControl className="inline-block">
                        <FormControlLabel
                            control={
                                <Checkbox checked={state.roles[role.role]} name={role.role} onChange={onChangeRole} />
                            }
                            label={role.nombre}
                        />
                    </FormControl>
                    <Tooltip placement="left" className="max-w-none"
                        title={
                            <div>
                                <p className="font-bold text-lg mb-2">Permisos otorgados:</p>
                                <ul>
                                    {role.permisos.map(permisos => (
                                        <li key={permisos.id} className="mb-2">
                                            <p><span className="font-bold">{permisos.nombre}:</span> {permisos.descripcion}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                    >
                        <HelpOutlineIcon className="text-gray-600" />
                    </Tooltip>
                </div>
                <p className="text-xs text-gray-800 ml-8">{role.descripcion}</p>
            </div>
        )
    }

    return (
        <AdminTemplate loading={loading} modal={props.modal}>
            <Toolbar title="Usuarios" backUrl={`/configuracion/usuarios`} modal={props.modal}>
            </Toolbar>
            <div className="main center">
                <div className="layout-compact">
                    <form onSubmit={save}>
                        <div className="grid gap-4">
                            <TextField
                                label="Correo"
                                type="email"
                                variant="outlined"
                                name="correo"
                                value={state.correo}
                                onInput={event => maxFieldLength(event, 100)}
                                required
                                onChange={onChange}
                            />
                            <TextField
                                label="Nombres"
                                type="text"
                                variant="outlined"
                                required
                                name="nombres"
                                onInput={event => onlyLetter(event, 200)}
                                value={state.nombres}
                                onChange={onChange}
                            />
                            <TextField
                                label="Apellido Paterno"
                                type="text"
                                variant="outlined"
                                required
                                name="apPat"
                                onInput={event => onlyLetter(event, 60)}
                                value={state.apPat}
                                onChange={onChange}
                            />
                            <TextField
                                label="Apellido Materno"
                                type="text"
                                variant="outlined"
                                required
                                name="apMat"
                                onInput={event => onlyLetter(event, 60)}
                                value={state.apMat}
                                onChange={onChange}
                            />
                            <PhoneInput
                                className="block w-full"
                                country={'mx'}
                                name="celular"
                                specialLabel="Celular"
                                preferredCountries={["mx", "us"]}
                                disableDropdown={true}
                                value={state.celular}
                                onChange={onChangeCelular}
                                inputProps={{ required: true }}
                            />

                            <div>
                                <h2 className="text-gray-100">Privilegios</h2>
                                <p className="text-sm font-thin text-dark-100">Por seguridad, se recomienda dar los accesos mínimos necesarios para que un usuario cumpla sus tareas</p>
                            </div>

                            <div className=" ">
                                {roles && (
                                    <div className="grid gap-4">



                                        <div className="bg-dark-300 rounded-md">
                                            <div className="flex items-center gap-2 p-4">
                                                <ListAltIcon className="text-gray-100" />
                                                <span>Catálogos</span>
                                            </div>
                                            <Divider className="border rounded-md border-dark-400" />

                                            <div className="grid gap-2 p-4">
                                                {renderRole('ROLE_CLIENTES')}
                                                <div className="ml-8">
                                                    {renderRole('ROLE_CLIENTES_R')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_CLIENTES_W')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_CLIENTES_D')}
                                                </div>
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_PROSPECTOS')}
                                                <div className="ml-8">
                                                    {renderRole('ROLE_PROSPECTOS_R')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_PROSPECTOS_W')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_PROSPECTOS_D')}
                                                </div>
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_VENDEDORES')}
                                                <div className="ml-8">
                                                    {renderRole('ROLE_VENDEDORES_R')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_VENDEDORES_W')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_VENDEDORES_D')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bg-dark-300 rounded-md">
                                            <div className="flex items-center gap-2 p-4">
                                                <ExtensionIcon className="text-gray-100" />
                                                <span>Módulos</span>
                                            </div>
                                            <Divider className="border rounded-md border-dark-400" />

                                            <div className="grid gap-2 p-4">
                                                {renderRole('ROLE_POLIZAS')}
                                                <div className="ml-8">
                                                    {renderRole('ROLE_POLIZAS_R')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_POLIZAS_W')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_POLIZAS_D')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_POLIZAS_RENOVACIONES')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_POLIZAS_COBRANZA')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_POLIZAS_IMPORT')}
                                                </div>
                                                <Divider className="border rounded-md border-dark-400" />

                                                {renderRole('ROLE_SINIESTROS')}
                                                <div className="ml-8">
                                                    {renderRole('ROLE_SINIESTROS_R')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_SINIESTROS_W')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_SINIESTROS_D')}
                                                </div>
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_COTIZACIONES')}
                                                <div className="ml-8">
                                                    {renderRole('ROLE_COTIZACIONES_R')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_COTIZACIONES_W')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_COTIZACIONES_D')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_COTIZACIONES_TEMP')}
                                                </div>
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_CALENDARIO')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_TAREAS')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_PROSPECCION')}
                                                <div className="ml-8">
                                                    {renderRole('ROLE_PROSPECCION_R')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_PROSPECCION_W')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_PROSPECCION_D')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bg-dark-300 rounded-md">
                                            <div className="flex items-center gap-2 p-4">
                                                <AssessmentIcon className="text-gray-100" />
                                                <span>Reportes</span>
                                            </div>
                                            <Divider className="border rounded-md border-dark-400" />

                                            <div className="grid gap-2 p-4">
                                                {renderRole('ROLE_REPORTES')}
                                                <div className="ml-8">
                                                    {renderRole('ROLE_REPORTES_POLIZAS')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_REPORTES_RECIBOS')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_REPORTES_CLIENTES')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_REPORTES_COMISIONES')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_REPORTES_AUTOMATIZACIONES')}
                                                </div>
                                                <div className="ml-8">
                                                    {renderRole('ROLE_REPORTE_SEMANAL')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bg-dark-300 rounded-md">
                                            <div className="flex items-center gap-2 p-4">
                                                <TuneIcon className="text-gray-100" />
                                                <span>Administración</span>
                                            </div>
                                            <Divider className="border rounded-md border-dark-400" />
                                            <div className="grid gap-2 p-4">
                                                {renderRole('ROLE_ADMIN')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_USUARIOS')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_FACTURACION')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_EMPRESA')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_CONFIG')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_INTEGRACIONES')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_CLAVES')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_ASEGURADORAS')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_COMUNICADOS')}
                                                <Divider className="border rounded-md border-dark-400" />
                                                {renderRole('ROLE_METAS')}
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                            <Button type="submit" className="btn btn-yellow">GUARDAR</Button>
                        </div>
                    </form>
                </div>
            </div>
        </AdminTemplate>
    )
}