import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import APIInvoke from '../../utils/APIInvoke';
import { useParams, useHistory } from "react-router-dom";
import { format, parse } from '../../utils/DateUtils'
import Alert from '../../components/Alert';
import Toolbar from '../../components/Toolbar'
import { maxFieldLength, onlyLetter, onlyNumbers, onlySimpleCharacters } from '../../utils/FormUtils'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { DateTime } from 'luxon';


const NuevaTareaPage = (props) => {

    const history = useHistory()
    const { tareaId } = useParams()
    const [vendedores, setVendedores] = useState([])
    const [prioridades, setPrioridades] = useState([])
    const [status, setStatus] = useState([])
    const [tipos, setTipos] = useState([])

    const tarea = props.tarea

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        "nombre": "",
        "descripcion": "",
        "vencimiento": new Date(),
        "vendedorId": "",
        "prioridadId": "media",
        "statusId": "abierta",
        "tipoId": "tarea"
    })
    const [message, setMessage] = useState(null)

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)

                const responseVendedores = await (await APIInvoke.invokeGET(`/vendedores?size=99999`)).json()
                const responsePrioridades = await (await APIInvoke.invokeGET(`/tareas/prioridades`)).json()
                const responseStatus = await (await APIInvoke.invokeGET(`/tareas/status`)).json()
                const responseTipos = await (await APIInvoke.invokeGET(`/tareas/tipos`)).json()

                setVendedores(responseVendedores.body.items)
                setPrioridades(responsePrioridades.body)
                setStatus(responseStatus.body)
                setTipos(responseTipos.body)

                const tareaIdParam = tareaId || props.tarea?.id
                if (!isNaN(tareaIdParam)) {
                    const response = await (await APIInvoke.invokeGET(`/tareas/${tareaIdParam}`)).json();
                    setState({
                        ...response.body,
                        vencimiento: response.body.vencimiento && DateTime.fromFormat(response.body.vencimiento, "yyyy-MM-dd HH:mm:ss").toJSDate(),
                        prioridadId: response.body.prioridad.name,
                        statusId: response.body.status.name,
                        tipoId: response.body.tipo.name,
                    })
                }
            } catch (error) {
                console.error("Error al inicializar la página", error)
            } finally {
                setLoading(false)
            }
        }
        init()
    }, [])


    const onChange = (e) => {
        let value = e.target.value
        const name = e.target.name
        if (e.target.type == "checkbox") {
            value = e.target.checked
        }

        setState({
            ...state,
            [name]: value
        })
    }

    const save = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)

            const request = {
                nombre: state.nombre,
                descripcion: state.descripcion,
                vencimiento: state.vencimiento ? format(state.vencimiento, "yyyy-MM-dd HH:mm:ss") : null,
                prioridad: state.prioridadId,
                tipo: state.tipoId,
                vendedor: state.vendedorId,
                status: state.statusId,
            }

            const response = state.id
                ? await (await APIInvoke.invokePUT(`/tareas/${tareaId || state.id}`, request)).json()
                : await (await APIInvoke.invokePOST(`/tareas`, request)).json()

            if (response.ok) {
                setState({
                    ...state,
                    id: response.body.id
                })
                setMessage({
                    type: "success",
                    message: "Tarea guardada correctamente"
                })
                if (props.onCompleted) {
                    props.onCompleted(response.body)
                } else {
                    history.push({ pathname: `/tareas/${response.body.id}`, state: { backUrl: `/tareas` } })
                }
            } else {
                setMessage({
                    type: "error",
                    message: response.message
                })
            }
        } catch (e) {
            console.error("error al guardar la tarea", e)
            setMessage({
                type: "error",
                message: "Error al guardar la tarea"
            })
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return (<AdminTemplate loading={loading} modal={props.modal} />)
    }

    return (
        <AdminTemplate loading={loading} modal={props.modal}>
            <Toolbar title="Tareas" backUrl={`/tareas/${tareaId}`} modal={props.modal}>
            </Toolbar>
            <div className="main center">
                <div className="layout-compact">
                    <form onSubmit={save}>
                        <div className="grid grid-cols-1  gap-4">
                            <TextField
                                label="Nombre"
                                type="text"
                                variant="outlined"
                                required
                                name="nombre"
                                onInput={event => onlyLetter(event, 100)}
                                value={state.nombre}
                                onChange={onChange}
                            />

                            <div className="grid grid-cols-2 gap-4">
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <DatePicker
                                        name="vencimiento"
                                        label="Fecha de vencimiento"
                                        inputFormat="dd/MM/yyyy"
                                        value={state.vencimiento}
                                        onChange={newDatetime => setState({ ...state, vencimiento: newDatetime })}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <FormControl variant="outlined" size="medium" required>
                                    <InputLabel id="prioridad" >Prioridad</InputLabel>
                                    <Select
                                        labelId="prioridad"
                                        label="Prioridad"
                                        size="medium"
                                        name="prioridadId"
                                        value={state.prioridadId}
                                        onChange={onChange}
                                        required
                                    >
                                        <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                        {prioridades.map((item, index) => (
                                            <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <FormControl variant="outlined" size="medium" required>
                                    <InputLabel id="tipo" >Tipo</InputLabel>
                                    <Select
                                        labelId="tipo"
                                        label="Tipo"
                                        size="medium"
                                        name="tipoId"
                                        value={state.tipoId}
                                        onChange={onChange}
                                        required
                                    >
                                        <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                        {tipos.map((item, index) => (
                                            <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>

                                {state.id && (
                                    <FormControl variant="outlined" size="medium" required>
                                        <InputLabel id="status" >Estatus</InputLabel>
                                        <Select
                                            labelId="status"
                                            label="Estatus"
                                            size="medium"
                                            name="statusId"
                                            value={state.statusId}
                                            onChange={onChange}
                                            required
                                        >
                                            <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                            {status.map((item, index) => (
                                                <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                            <FormControl variant="outlined" size="medium">
                                <InputLabel id="vendedor" >Vendedor</InputLabel>
                                <Select
                                    labelId="vendedor"
                                    label="vendedor"
                                    size="medium"
                                    name="vendedorId"
                                    value={state.vendedorId}
                                    onChange={onChange}
                                >
                                    <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                    {vendedores.map((vendedor, index) => (
                                        <MenuItem key={index} value={vendedor.id}>{`${vendedor.nombres} ${vendedor.apPat || ''} ${vendedor.apMat || ''}`}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            <TextField
                                inputProps={{ className: "text-white" }}
                                className="w-full"
                                color="primary"
                                id="descripcion"
                                onInput={event => onlyLetter(event, 1000)}
                                label="Descripción"
                                name="descripcion"
                                value={state.descripcion}
                                onChange={onChange}
                                multiline
                                rows={8}
                                required
                            />

                            <Button type="submit" className="btn btn-yellow">GUARDAR TAREA</Button>
                            {message && <Alert {...message} />}
                        </div>
                    </form>
                </div>
            </div>
        </AdminTemplate>
    )
}

export default NuevaTareaPage