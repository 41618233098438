import React from 'react'
import { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import AdminTemplate from '../../template/AdminTemplate';
import Toolbar from '../../components/Toolbar'
import toast from 'react-hot-toast';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import APIInvoke from '../../utils/APIInvoke';
import { onlyLetter } from '../../utils/FormUtils';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { hasValue } from '../../utils/StringUtils';
import DeleteIcon from '@mui/icons-material/Delete';


export default function NuevaAseguradora(props) {

    const history = useHistory()
    const { aseguradoraId } = useParams()
    const [loading, setLoading] = useState(false)
    const [aseguradora, setAseguradora] = useState({
        aseguradora: "",
        abreviacion: "",
        status: "activo",
        logoBco: ""
    })
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null })


    useEffect(() => {
        if (aseguradoraId) fetchAseguradora(aseguradoraId)
    }, [])

    const fetchAseguradora = async (id) => {
        try {
            setLoading(true)
            const rawResponse = await (await APIInvoke.invokeGET(`/aseguradoras/${aseguradoraId}`)).json()

            if (!hasValue(rawResponse.body) || rawResponse.body?.estandar === true) {
                history.push("/404")
                return
            }

            setAseguradora({
                id: rawResponse.body.id,
                aseguradora: rawResponse.body.aseguradora,
                abreviacion: rawResponse.body.abreviacion,
                status: rawResponse.body.status
            })
        } catch (error) {
            toast.error("Error al cargar la página")
            console.error("Error al inicializar la página", error)
        } finally {
            setLoading(false)
        }
    }

    const onChange = (e) => {
        setAseguradora({
            ...aseguradora,
            [e.target.name]: e.target.value
        })
    }

    const save = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            const request = {
                aseguradora: aseguradora.aseguradora,
                abreviacion: aseguradora.abreviacion,
                status: aseguradora.status
            }

            const rawResponse = aseguradora.id
                ? await (await APIInvoke.invokePUT(`/aseguradoras/${aseguradora.id}`, request)).json()
                : await (await APIInvoke.invokePOST(`/aseguradoras`, request)).json()

            if (!rawResponse.ok) {
                toast.error(rawResponse.message)
                return
            }

            setAseguradora({
                ...aseguradora,
                id: rawResponse.body.id
            })

            toast.success("Guardado exitosamente")
        } catch (error) {
            console.error("Error al guardar ", error)
            toast.success("Error al guardar")
        } finally {
            setLoading(false)
        }
    }

    const deleteAseguradora = async () => {
        try {
            setOpenDeleteDialog({ id: null, show: false })
            setLoading(true)

            const rawResponse = await (await APIInvoke.invokeDELETE(`/aseguradoras/${aseguradora.id}`)).json()
            if (!rawResponse.ok) {
                toast.error(rawResponse.message)
                return
            }

            toast.success("Aseguradora eliminada exitosamente")
            history.push("/aseguradoras")

        } catch (e) {
            toast.error("Error al eliminar la aseguradora")
            console.error("Error al eliminar la aseguradora", e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <AdminTemplate loading={loading} modal={props.modal}>
            <Dialog
                open={openDeleteDialog.show}
                onClose={e => setOpenDeleteDialog({ show: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
                <DialogContent>
                    <DialogContentText className="text-gray-400" id="alert-dialog-description">¿Desea eliminar la aseguradora?, esta acción es irreversible.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="mr-4" onClick={e => setOpenDeleteDialog({ show: false })}>CANCELAR</Button>
                    <Button className="bg-red-500 text-white" onClick={deleteAseguradora} autoFocus>ACEPTAR</Button>
                </DialogActions>
            </Dialog>
            <Toolbar title="Aseguradora" backUrl={`/aseguradoras`} modal={props.modal}>
                <div className="layout">
                    <div className="flex justify-end">
                        <Tooltip title="Eliminar">
                            <IconButton className="btn btn-yellow" onClick={e => setOpenDeleteDialog({ show: true, id: aseguradora.id })}>
                                <DeleteIcon className="text-dark-600" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>

            <div className="main center">
                <div className="layout-compact">
                    <form onSubmit={save}>
                        <div className="grid gap-4">
                            <TextField
                                label="Nombre"
                                type="text"
                                variant="outlined"
                                required
                                name="aseguradora"
                                onInput={event => onlyLetter(event, 100)}
                                value={aseguradora.aseguradora}
                                onChange={onChange}
                            />

                            <TextField
                                label="Abreviación"
                                type="text"
                                variant="outlined"
                                required
                                name="abreviacion"
                                onInput={event => onlyLetter(event, 10)}
                                value={aseguradora.abreviacion}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={"Nombre corto con el que se conoce a la aseguradora"}>
                                                <HelpOutlineIcon />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <FormControl variant="outlined" size="medium" >
                                <InputLabel id="status" required>Estatus</InputLabel>
                                <Select
                                    labelId="status"
                                    label="Estatus"
                                    size="medium"
                                    name="status"
                                    value={aseguradora.status}
                                    onChange={onChange}
                                >
                                    <MenuItem value="inactivo" ><span className="inline-block rounded-full bg-red w-3 h-3 mr-2" /> Inactivo</MenuItem>
                                    <MenuItem value="activo"><span className="inline-block rounded-full bg-green w-3 h-3 mr-2" /> Activo</MenuItem>
                                </Select>
                            </FormControl>
                            <Button type="submit" className="btn btn-yellow">GUARDAR ASEGURADORA</Button>
                        </div>
                    </form>
                </div>
            </div>
        </AdminTemplate>
    )
}