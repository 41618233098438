import React from 'react'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Page500 = (props) => {

    return (
        <div className="main">
            <div className="layout mt-32">
                <div className="flex flex-col  items-center justify-center content-center ">
                    <img src="/icons/error-yellow.svg" alt="Página no encontrada" className="inline-block relative w-2/3 lg:w-1/3 mb-8" />
                    <p className="text-xl text-center mb-4">Upss... algo salio mal!!!</p>
                    <Link to="/" className="text-yellow no-underline"><ArrowBackIcon /> Regresar</Link>
                </div>
            </div>
        </div>
    )
}
export default Page500