import {
    CLAVES_UPDATE_ROOT,
    CLAVES_UPDATE_CLAVE,
    CLAVES_ADD_CLAVE,
    CLAVES_DELETE_CLAVE
} from "../redux-const"

const initialState = {
    loading: false,
    aseguradoras: [],
    claves: [],
    expanded: "",
    deleteIndex: null
}

export const reducer = (state = initialState, action) => {
    const payload = action.payload;
    const index = action.index;
    const section = action.section;

    switch (action.type) {
        case CLAVES_UPDATE_ROOT:
            return {
                ...state,
                ...payload
            }
        case CLAVES_UPDATE_CLAVE:
            let clave = state.claves[index]
            Object.assign(clave, payload)
            return {
                ...state,
                claves: [
                    ...state.claves
                ]
            }
        case CLAVES_DELETE_CLAVE:
            const deleteClave = state.claves[state.deleteIndex]
            return {
                ...state,
                expanded: "",
                deleteIndex: null,
                claves: state.claves.filter((item, index) => index !== state.deleteIndex)
            }
        case CLAVES_ADD_CLAVE:
            const newSelectedMeta = state.claves.length
            return {
                ...state,
                expanded: `panel-${newSelectedMeta}`,
                claves: [
                    ...state.claves,
                    {
                        aseguradora: "-1",
                        ramo: "todos",
                        anio: new Date().getFullYear(),
                        meta: "anual",
                        polizas: 0,
                        primas: 0.0,
                        terminos: false,
                        sincronizacion: false,
                        aseguradoraId: "",
                        clave: "",
                        tipos: ['autos', 'vida', 'salud', 'diversos'],
                        portalClave: "",
                        portalPassword: "",
                        showPassword: false,
                        comisionVida: 0,
                        comisionAutos: 0,
                        comisionSalud: 0,
                        comisionDiversos: 0
                    }
                ]
            }

    }
    return state;
};

export default reducer;
