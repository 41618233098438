import React, { useEffect, useState } from 'react'
import AdminTemplate from '../../template/AdminTemplate'
import Toolbar from '../../components/Toolbar'
import APIInvoke from '../../utils/APIInvoke';
import { DateTime } from 'luxon';
import { numberFormat } from '../../utils/NumberUtils';
import { DataGrid, GridToolbar, GridRow } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import millify from "millify";
import DateGridlocaleTextConstantsES from '../../utils/DateGridlocaleTextConstantsES'
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DalalaDialog from '../../components/DalalaDialog';
import ConsultaPoliza from '../ConsultaPoliza';
import { format } from '../../utils/DateUtils'
import { getRamoById } from '../../utils/EnumUtils';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FilterBuilder2 from '../../components/FilterBuilder/FilterBuilder2';
import SelectFilter from '../../components/FilterBuilder/SelectFilter';
import SearchFilter from '../../components/FilterBuilder/SearchFilter';
import { hasValue, hasText } from '../../utils/StringUtils';
import DateFilter from '../../components/FilterBuilder/DateFilter';

const filters = [
    { // fechaVigenciaDe[ge]
        id: "fechaVigenciaDe[ge]",
        name: "fechaVigenciaDe[ge]",
        placeholder: "F. Inicio Vigencia De",
        colSpan: 1,
        require: true,
        filter: "fechaVigenciaDe[ge]",
        type: DateFilter,
        minDate: DateTime.now().minus({years: 20}),
        maxDate: DateTime.now().plus({years: 20}),
        defaultValue: (() => DateTime.now().minus({ years: 1 }).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate())(),
        getOptionLabel: (option) => hasValue(option) ? format(option, "dd/MM/yyyy") : "",
        processInput: (value) => {
            if (!hasValue(value)) return null
            return format(value, "yyyy-MM-dd")
        }
    }, { // fechaVigenciaDe[le]
        id: "fechaVigenciaDe[le]",
        name: "fechaVigenciaDe[le]",
        placeholder: "F. Inicio Vigencia A",
        colSpan: 1,
        require: true,
        filter: "fechaVigenciaDe[le]",
        type: DateFilter,
        minDate: DateTime.now().minus({years: 20}),
        maxDate: DateTime.now().plus({years: 5}),
        defaultValue: (() => DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate())(),
        getOptionLabel: (option) => {
            return hasValue(option) ? format(option, "dd/MM/yyyy") : ""
        },
        processInput: (value) => {
            if (!hasValue(value)) return null
            return format(value, "yyyy-MM-dd")
        }
    }, { // status
        id: "status",
        name: "status",
        placeholder: "Estatus",
        filter: "status",
        type: SelectFilter,
        values: (async () => {
            const response = await (await APIInvoke.invokeGET("/catalogos/polizas/status")).json()
            return response.body
        })(),
        defaultValue: ""
    }, { // Ramo
        id: "ramo",
        name: "ramo",
        placeholder: "Ramo",
        filter: "ramo",
        type: SelectFilter,
        values: ["Vida", "Auto", "Salud", "Diversos"].map(ramo => {
            return {
                key: ramo,
                value: ramo,
                icon: getRamoById(ramo).icon
            }
        }),
        defaultValue: ""
    }, { // formaPago
        id: "formaPago",
        name: "formaPago",
        placeholder: "Forma de Pago",
        filter: "formaPago",
        type: SelectFilter,
        values: (async () => {
            const response = await (await APIInvoke.invokeGET("/catalogos/forma-pago")).json()
            return response.body
        })(),
        defaultValue: ""
    }, { // moneda
        id: "moneda",
        name: "moneda",
        placeholder: "Moneda",
        filter: "moneda",
        type: SelectFilter,
        values: (async () => {
            const response = await (await APIInvoke.invokeGET("/monedas")).json()
            return response.body.map(moneda => {
                return {
                    key: moneda.abreviacion,
                    value: moneda.abreviacion
                }
            })
        })(),
        defaultValue: ""
    }, { // cliente
        id: "nombreId",
        name: "nombreId",
        placeholder: "Cliente",
        colSpan: 1,
        filter: "clienteId",
        type: SearchFilter,
        values: async (filter) => {
            if (filter.value.length < 3) return []
            const response = await (await APIInvoke.invokeGET(`/clientes?nombre=${filter.value}&size=20&order=nombres%20asc`)).json()
            return response.body.items
        },
        getOptionLabel: (option) => {
            return hasValue(option) ? `${option.nombres} ${option.apPat} ${option.apMat}` : ""
        },
        isOptionEqualToValue: (option, value) => {
            return option.id === value.id
        },
        processInput: (value) => {
            return hasValue(value) ? value.id : ""
        },
        defaultValue: ""
    }, { // vendedorId
        id: "vendedorId",
        name: "vendedorId",
        placeholder: "Vendedor",
        filter: "vendedorId",
        type: SearchFilter,
        values: async (filter) => {
            if (filter.value.length < 3) return []
            const response = await (await APIInvoke.invokeGET(`/vendedores?nombre[cn]=${filter.value}&size=20&order=nombres%20asc`)).json()
            return response.body.items
        },
        getOptionLabel: (option) => {
            return hasValue(option) ? `${option.nombres} ${option.apPat} ${option.apMat}` : ""
        },
        isOptionEqualToValue: (option, value) => {
            return option.id === value.id
        },
        processInput: (value) => {
            return hasValue(value) ? value.id : ""
        },
        defaultValue: ""
    }, { // aseguradora
        id: "aseguradoraId",
        name: "aseguradoraId",
        placeholder: "Aseguradora",
        filter: "aseguradoraId",
        type: SelectFilter,
        values: (async () => {
            const response = await (await APIInvoke.invokeGET("/aseguradoras")).json()
            return response.body.items.map(aseguradora => {
                return {
                    key: aseguradora.id,
                    value: `${aseguradora.aseguradora} (${aseguradora.abreviacion})`
                }
            })
        })(),
        /*getOptionLabel: (option) => {
            return hasValue(option) ? `${option}` : ""
        },*/
        defaultValue: ""
    }
]

const ReportePolizas = (props) => {

    const [data, setData] = useState({
        items: [],
        totalItems: 1,
        currentPage: 1,
        totalPages: 1,
        pageSize: 50
    })
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        queryBuilder: {
            queryString: `?fechaVigenciaDe[ge]=${DateTime.now().minus({years: 1}).toFormat("yyyy-MM-dd")}&fechaVigenciaDe[le]=${DateTime.now().toFormat("yyyy-MM-dd")}&size=999999`
        },
        poliza: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        pageSize: 50,
    })

    useEffect(async () => {
        filter(state)
    }, [])

    const onFilterProcess = async (queryBuilder) => {
        const newState = {
            ...state,
            queryBuilder: queryBuilder,
            poliza: [],
            totalItems: 0,
            currentPage: 1,
            totalPages: 0,
            pageSize: 50,
        }
        setState(newState)
        filter(newState)
    }

    const filter = async (state) => {
        try {
            setLoading(true)
            const query = state.queryBuilder.queryString
            const response = await (await APIInvoke.invokeGET(`/polizas${state.queryBuilder.queryString}${hasText(query) ? "&" : "?"}page=${state.currentPage}&size=999999`)).json();
            setState({
                ...state,
                poliza: response.body.items,
                totalItems: response.body.totalItems,
                currentPage: response.body.currentPage,
                totalPages: response.body.totalPages,
                pageSize: 50
            });


            setData({
                ...response.body,
                pageSize: 50,
                items: response.body.items.map(x => {
                    return {
                        ...x,
                        fechaVigenciaDe: DateTime.fromFormat(x.fechaVigenciaDe, "yyyy-MM-dd").set({ hour: 0, minute: 0, second: 0 }),
                        fechaVigenciaA: DateTime.fromFormat(x.fechaVigenciaA, "yyyy-MM-dd").set({ hour: 0, minute: 0, second: 0 }),
                        fecha: DateTime.fromFormat(x.fecha, "yyyy-MM-dd HH:mm:ss")
                    }
                })
            })

            const views = response.body.items.reduce((previes, current) => {
                previes.primas += current.total

                if (previes.status[current.status]) {
                    previes.status[current.status].count = previes.status[current.status].count + 1
                    previes.status[current.status].sum = previes.status[current.status].sum + current.total
                } else {
                    previes.status[current.status] = {
                        name: current.status,
                        count: 1,
                        sum: current.total
                    }
                }

                if (previes.ramos[current.ramo]) {
                    previes.ramos[current.ramo].count = previes.ramos[current.ramo].count + 1
                    previes.ramos[current.ramo].sum = previes.ramos[current.ramo].sum + current.total
                } else {
                    previes.ramos[current.ramo] = {
                        name: current.ramo,
                        count: 1,
                        sum: current.total
                    }
                }

                if (previes.aseguradoras[current.aseguradora]) {
                    previes.aseguradoras[current.aseguradora].count = previes.aseguradoras[current.aseguradora].count + 1
                    previes.aseguradoras[current.aseguradora].sum = previes.aseguradoras[current.aseguradora].sum + current.total
                } else {
                    previes.aseguradoras[current.aseguradora] = {
                        name: current.aseguradora,
                        count: 1,
                        sum: current.total
                    }
                }

                return previes
            }, { primas: 0, polizas: response.body.items.length, status: {}, ramos: {}, aseguradoras: {} })

            setViews({
                polizas: views.polizas,
                primas: views.primas,
                polizasCountXStatus: Object.keys(views.status).map(x => {
                    return {
                        name: views.status[x].name,
                        value: views.status[x].count
                    }
                }),
                polizasSumXStatus: Object.keys(views.status).map(x => {
                    return {
                        name: views.status[x].name,
                        value: views.status[x].sum
                    }
                }),
                polizasCountXRamo: Object.keys(views.ramos).map(x => {
                    return {
                        name: views.ramos[x].name,
                        value: views.ramos[x].count
                    }
                }),
                polizasSumXRamo: Object.keys(views.ramos).map(x => {
                    return {
                        name: views.ramos[x].name,
                        value: views.ramos[x].sum
                    }
                }),
                polizasCountXAseguradora: Object.keys(views.aseguradoras).map(x => {
                    return {
                        name: views.aseguradoras[x].name,
                        value: views.aseguradoras[x].count
                    }
                }),
                polizasSumXAseguradora: Object.keys(views.aseguradoras).map(x => {
                    return {
                        name: views.aseguradoras[x].name,
                        value: views.aseguradoras[x].sum
                    }
                })
            })

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const [views, setViews] = useState({
        polizasCountXAseguradora: [], polizasSumXAseguradora: [],
        polizasCountXStatus: [], polizasSumXStatus: [],
        polizasCountXRamo: [], polizasSumXRamo: []
    })
    const [tab, setTab] = useState(0)

    const [openPolizaDialog, setOpenPolizaDialog] = useState({ show: false, id: null })
    //'#0088FE', '#00C49F', '#FFBB28', '#FF8042', 
    const COLORS = ['#FED140', '#6EC81D', '#006FD3', '#B40983', '#E73131', '#FF9420', '#FF9420', '#BEEE62', '#F4743B', '#FF69EB', '#83B5D1'];


    const columns = [
        {
            field: 'id',
            headerName: ' ',
            type: 'string',
            width: 50,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => (<IconButton onClick={e => setOpenPolizaDialog({ show: true, id: params.row.polizaId })} color="primary" className="flex items-center" size="small">
                <VisibilityIcon className="text-yellow" size="small" />
            </IconButton>)
        }, {
            field: 'noPoliza',
            headerName: 'No Póliza',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: (params) => `${params.row.noPoliza.toUpperCase()}`
        }, {
            field: 'concepto',
            headerName: 'Concepto',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.concepto.toLowerCase()}`
        }, {
            field: 'paquete',
            headerName: 'Paquete',
            type: 'string',
            width: 200,
            editable: false,
            renderCell: (params) => (<p className="text-sm">{hasText(params.row.paquete) ? params.row.paquete.toLowerCase() : "" }</p>) 
        }, {
            field: 'fecha',
            headerName: 'Fecha Creación',
            type: 'dateTime',
            width: 200,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fecha.toFormat("dd/MM/yyyy HH:mm:ss")}
                    </p>
                </div>
            )
        }, {
            field: 'fechaVigenciaDe',
            headerName: 'Vigencia De',
            type: 'date',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fechaVigenciaDe.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'fechaVigenciaA',
            headerName: 'Vigencia A',
            type: 'date',
            width: 200,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="">
                        {params.row.fechaVigenciaA.toFormat("dd/MM/yyyy")}
                    </p>
                </div>
            )
        }, {
            field: 'prima',
            headerName: 'Prima neta',
            type: 'string',
            width: 170,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="text-right">
                        {`${numberFormat(params.row.prima)} ${params.row.moneda}`}
                    </p>
                </div>
            )
        }, {
            field: 'total',
            headerName: 'Prima Total',
            type: 'string',
            width: 170,
            editable: false,
            renderCell: (params) => (
                <div className="w-full py-1 my-1">
                    <p className="text-right">
                        {`${numberFormat(params.row.total)} ${params.row.moneda}`}
                    </p>
                </div>
            )
        }, {
            field: 'formaPago',
            headerName: 'Forma de Pago',
            type: 'string',
            width: 120,
            editable: false,
            valueGetter: (params) => `${params.row.formaPago.toUpperCase()}`
        }, {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            width: 120,
            editable: false,
            valueGetter: (params) => `${params.row.status.toUpperCase()}`
        }, {
            field: 'ramo',
            headerName: 'Ramo',
            type: 'string',
            width: 100,
            editable: false,
            valueGetter: (params) => `${params.row.ramo.toUpperCase()}`
        }, {
            field: 'aseguradora',
            headerName: 'Aseguradora',
            type: 'string',
            width: 120,
            editable: false,
            valueGetter: (params) => `${params.row.aseguradora}`
        }, {
            field: 'clave',
            headerName: 'Clave',
            type: 'string',
            width: 110,
            editable: false,
            valueGetter: (params) => `${params.row.clave}`
        }, {
            field: 'Contratante',
            headerName: 'Contratante',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row.nombres} ${params.row.apPat} ${params.row.apMat}`
        }, {
            field: 'Vendedor',
            headerName: 'Vendedor',
            type: 'string',
            width: 200,
            editable: false,
            valueGetter: (params) => `${params.row?.vendedorNombres} ${params.row?.vendedorApPat} ${params.row?.vendedorApMat}`
        }, {
            field: 'esRobot',
            type: 'boolean',
            headerName: 'Robot',
            width: 80,
            editable: false,
            renderCell: (params) => (<Checkbox disabled checked={params.row.esRobot} />)

        }
    ]




    const render = () => {
        const pieProps = {
            height: 200,
            innerRadius: 40,
            outerRadius: 50,
            fontSize: 12
        }

        return (
            <AdminTemplate loading={loading}>
                <Toolbar title="Reporte de pólizas" />

                <DalalaDialog
                    title={"Detalle de Póliza"}
                    open={openPolizaDialog.show}
                    handleClose={() => setOpenPolizaDialog({ show: false, id: null })}
                >
                    <ConsultaPoliza modal={true} polizaId={openPolizaDialog.id} onCompleted={() => setOpenPolizaDialog({ show: false, id: null })} />
                </DalalaDialog>

                <div className="main">
                    <div className="mx-4 lg:mx-8">
                        <div className="grid grid-cols-1 gap-4 ">
                            <FilterBuilder2 filters={filters} onProcess={onFilterProcess} />

                            <div className="border-b-2 border-dark-300" />

                            <div className="grid grid-cols-1 gap-4" >
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mb-4">
                                        <Tabs value={tab} aria-label="basic tabs example" onChange={(e, index) => setTab(index)}>
                                            <Tab className={tab === 0 ? "text-yellow" : "text-gray-400"} label="Ramos" />
                                            <Tab className={tab === 1 ? "text-yellow" : "text-gray-400"} label="Estatus" />
                                            <Tab className={tab === 2 ? "text-yellow" : "text-gray-400"} label="Aseguradoras" />
                                        </Tabs>
                                    </Box>

                                    <div style={{ display: tab === 0 ? "block" : "none" }}>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4" >
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center" >
                                                    <p className="font-bold">Conteo de pólizas por Ramo</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart  >
                                                            <Pie
                                                                data={views.polizasCountXRamo}
                                                                label={(e) => `${e.payload.name} - ${e.payload.value}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {views.polizasCountXRamo.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasCountXRamo.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${entry.value})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Total de pólizas: {views.polizas}</p>
                                            </div>
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Acumulados de primas por Ramo</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart >
                                                            <Pie
                                                                data={views.polizasSumXRamo}
                                                                label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {views.polizasSumXRamo.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasSumXRamo.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${millify(entry.value, { precision: 2 })})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Primas acumuladas {numberFormat(views.primas)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: tab === 1 ? "block" : "none" }}>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Conteo de pólizas por Estatus</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart >
                                                            <Pie
                                                                data={views.polizasCountXStatus}
                                                                label={(e) => `${e.payload.name} - ${e.payload.value}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {Object.keys(views.polizasCountXStatus).map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasCountXStatus.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${entry.value})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Total de pólizas: {views.polizas}</p>
                                            </div>
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Acumulados de primas por Estatus</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart  >
                                                            <Pie
                                                                data={views.polizasSumXStatus}
                                                                label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {Object.keys(views.polizasSumXStatus).map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasSumXStatus.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${millify(entry.value, { precision: 2 })})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Primas acumuladas {numberFormat(views.primas)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: tab === 2 ? "block" : "none" }}>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Conteo de pólizas por Aseguradora</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart  >
                                                            <Pie
                                                                data={views.polizasCountXAseguradora}
                                                                label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {Object.keys(views.polizasCountXAseguradora).map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasCountXAseguradora.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${entry.value})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Total de pólizas: {views.polizas}</p>
                                            </div>
                                            <div className=" bg-dark-400 p-4 rounded-md" >
                                                <div className="grid grid-cols-1 gap-4 text-center items-center">
                                                    <p className="font-bold">Acumulados de primas por Aseguradora</p>
                                                    <ResponsiveContainer height={pieProps.height}>
                                                        <PieChart>
                                                            <Pie
                                                                data={views.polizasSumXAseguradora}
                                                                label={(e) => `${e.payload.name} - ${millify(e.payload.value, { precision: 2 })}`}
                                                                fontSize={pieProps.fontSize}
                                                                innerRadius={pieProps.innerRadius}
                                                                outerRadius={pieProps.outerRadius}
                                                                fill="#8884d8"
                                                                paddingAngle={5}
                                                                dataKey="value"
                                                            >
                                                                {Object.keys(views.polizasSumXAseguradora).map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className="flex flex-row gap-2 justify-center flex-wrap mb-4">
                                                    {views.polizasSumXAseguradora.map((entry, index) => (
                                                        <span key={`${index}`} className="flex flex-row gap-1 items-center text-gray-400 mr-2 text-xs"><span className="inline-block rounded-sm" style={{ height: "15px", width: "15px", backgroundColor: `${COLORS[index % COLORS.length]}` }} /> {`${entry.name}(${millify(entry.value, { precision: 2 })})`}</span>
                                                    ))}
                                                </div>
                                                <p className="text-center">Primas acumuladas {numberFormat(views.primas)}</p>
                                            </div>
                                        </div>
                                    </div>

                                </Box>
                            </div>





                            <div className=" bg-dark-400 p-4 rounded-md">
                                <div className="grid grid-cols-1 bg-gray-100 rounded-md" style={{ height: "700px" }} >
                                    <DataGrid
                                        className="bg-dark-300 text-gray-400 border-dark-600"
                                        rows={data.items}
                                        columns={columns}
                                        getRowId={e => e.polizaId}
                                        pageSize={data.pageSize}
                                        rowsPerPageOptions={[10, 50, 100]}
                                        onPageSizeChange={(pageSize, details) => setData({ ...data, pageSize: pageSize })}
                                        rowCount={data.totalItems}
                                        paginationMode="client"
                                        checkboxSelection
                                        disableSelectionOnClick
                                        localeText={DateGridlocaleTextConstantsES}
                                        components={{
                                            Toolbar: GridToolbar,
                                            Row: GridRow, // Default value
                                            //Footer: GridFooter
                                            //Footer: (params) => (<div className="p-2"><Pagination totalItems={data.totalItems} pages={data.totalPages} page={data.currentPage} onPageChange={newPage => setState({ ...state, page: newPage })} /></div>)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminTemplate>
        )
    }

    return render()

}

export default ReportePolizas