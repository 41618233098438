import config from '../config'



class APIInvoke {

    getAPIHeader() {
        const token = window.localStorage.getItem("token")
        let headers = {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
            "mode": "cors"
        }

        if (token) {
            headers["Authorization"] = `Bearer ${token}`
        }

        return headers;
    }

    getAPIUploadHeader() {
        const token = window.localStorage.getItem("token")
        let headers = {
            'Content-Type': 'multipart/form-data',
            "cache-control": "no-cache"
        }

        if (token) {
            headers["Authorization"] = `Bearer ${token}`
        }

        return headers;
    }

    invokeGET(url) {
        let params = {
            method: 'get',
            headers: this.getAPIHeader()
        }
        return this.invoke(url, params);
    }

    invokePUT(url, body) {
        let params = {
            method: 'put',
            headers: this.getAPIHeader(),
            body: JSON.stringify(body)
        };

        return this.invoke(url, params);
    }

    invokePOST(url, body) {
        let params = {
            method: 'post',
            headers: this.getAPIHeader(),
            body: JSON.stringify(body)
        };

        return this.invoke(url, params);
    }

    invokeUpload(url, body) {
        console.log("invokeUpload", config)
        let params = {
            method: 'post',
            headers: this.getAPIUploadHeader(),
            body: body
        };

        return this.invoke(url, params);
    }

    invokeDELETE(url) {
        let params = {
            method: 'delete',
            headers: this.getAPIHeader(),
            //body: JSON.stringify(body)
        };

        return this.invoke(url, params);
    }

    invoke(url, params) {
        let completeURL = url.toLowerCase().startsWith("http")
        let finalURL = completeURL ? url : `${config.api.baseUrl}${url}`
        return fetch(finalURL, params)
    }

}
export default new APIInvoke();
