import { DeveloperBoardOff } from '@mui/icons-material'
import React, { useContext, useEffect, useState } from 'react'
import AdminTemplate from '../template/AdminTemplate'
import { Link, useLocation } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import APIInvoke from '../utils/APIInvoke'
import UserContext from '../state/UserContext';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PaymentComplete = (props) => {

    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(null)
    const query = useQuery();
    const context = useContext(UserContext)

    useEffect(async () => {
        try {
            setLoading(true)
            const sessionId = query.get("session_id")
            const response = await (await APIInvoke.invokeGET(`/payments/stripe/checkout?sessionId=${sessionId}`)).json()
            setStatus(response.body.status)
            if (status === "complete") {
                APIInvoke.invokePOST(`/usuarios/login`)
                    .then((res) => res.json())
                    .then((res) => {
                        window.localStorage.setItem("token", res.body.token);
                        context.setUser({
                            ...res.body.usuario,
                            agenteId: res.body.agenteId,
                            suscripcion: res.body.suscripcion,
                            rol: res.body.role,
                            permisos: res.body.permisos
                        });

                        setTimeout(() => {
                            const tawk = window.Tawk_API || {}
                            const tawkRequest = {
                                name: `${res.body.usuario.nombres} ${res.body.usuario.apPat} ${res.body.usuario.apMat}`,
                                email: res.body.usuario.correo,
                                hash: res.body.tawktoToken
                            }
                            if(tawk.setAttributes) tawk.setAttributes(tawkRequest)
                        }, 5000)
                    })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [])

    return (
        <AdminTemplate loading={loading} loged={false} >
            {status === "complete" && (
                <div className="main">
                    <div className="layout mt-32">
                        <div className="flex flex-col  items-center justify-center content-center ">
                            <img src="/icons/gracias-yellow.svg" alt="Página no encontrada" className="inline-block relative w-2/3 lg:w-1/3" />
                            <p className="text-md text-center mb-4">Ya estás suscrito, muchas gracias</p>
                            <a className="btn btn-lg block btn-yellow text-center" href="/">DA CLICK PARA COMENZAR</a>
                        </div>
                    </div>
                </div>
            )}
            {status === "no_complete" && (
                <div className="main">
                    <div className="layout mt-32">
                        <div className="flex flex-col items-center justify-center content-center ">
                            <img src="/icons/payment-error-yellow.svg" alt="Página no encontrada" className="inline-block relative w-2/3 lg:w-1/2" />
                            <p className="text-md text-center mb-4">Ups... Algo salio mal, en breve nos pondremos en contacto contigo</p>
                            <Link to="/planes" className="text-yellow no-underline"><ArrowBackIcon /> Regresar</Link>
                        </div>
                    </div>
                </div>
            )}
        </AdminTemplate >
    )
}
export default PaymentComplete