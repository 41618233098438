import { FormControl, TextField } from '@mui/material'
import React from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { format } from '../../utils/DateUtils';
import { hasText } from '../../utils/StringUtils';
const { DateTime } = require("luxon");


const DateFilter = ({
    id,
    name,
    filter,
    placeholder,
    value,
    processFilter,
    updateState,
    required,
    lastCommitedState
}) => {

    function isValid(dateAsString) {
        try {
            const parseDate = DateTime.fromFormat(dateAsString, "dd/MM/yyyy")
            return !parseDate.invalid
        } catch (error) {
            return false
        }
    }

    const render = () => {
        return (
            <FormControl variant="outlined" size="small" required={required} fullWidth={true}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} size="small"  >
                    <DatePicker
                        name={name}
                        id={id}
                        label={placeholder}
                        value={value}
                        minDate={filter.minDate}
                        maxDate={filter.maxDate}
                        onChange={(newValue, dateAsString) => {
                            console.log("newValue", newValue)
                            if(dateAsString === "") updateState(id, "", true)
                            const valid = isValid(dateAsString)
                            if (valid) updateState(id, newValue, true)
                        }}
                        onAccept={(newValue) => updateState(id, newValue, true)}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} helperText={null} size="small" />}
                    />
                </LocalizationProvider>
            </FormControl>
        )
    }

    return render()
}

DateFilter.processInput = (value) => value
DateFilter.getOptionLabel = (value) => value
DateFilter.getOptionId = (value) => value

export default DateFilter