import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DalalaDialog = ({ open, title, children, handleClose }) => {

    return (
        <div >
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                
            >
                <AppBar sx={{ position: 'fixed' }} onClick={handleClose}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" className="text-dark-500">
                            {title}
                        </Typography>
                        <IconButton
                            edge="start"
                            className="text-dark-500"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="mt-14 sm:mt-16 bg-dark-600">
                    {children}
                </div>
            </Dialog>
        </div>
    );
}

export default DalalaDialog